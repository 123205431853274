import React, { useMemo, useState, useEffect } from 'react';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { usePagination, useSortBy, useTable } from 'react-table';
import Card from 'components/card';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { IoMdFunnel } from 'react-icons/io';

function QueriesTable({
  columnsData,
  startDate,
  endDate,
  showClicks,
  showImpressions,
  showCTR,
  showPosition,
  queryFilter,
  setQueryFilter,
  pageFilter,
  deviceFilter,
  countryFilter,
  selectedSearchType,
  queryFilterType,
  pageFilterType,
  siteUrl,
}) {
  const [googleData, setGoogleData] = useState([]);
  const accessToken = window.localStorage.getItem('accessToken');

  const dimensionFilters = [
    {
      dimension: 'query',
      expression: queryFilter ? queryFilter : '',
      operator: queryFilter ? queryFilterType : 'contains',
    },
    {
      dimension: 'page',
      expression: pageFilter ? pageFilter : '',
      operator: pageFilter ? pageFilterType : 'contains',
    },
    {
      dimension: 'device',
      expression: deviceFilter ? deviceFilter : '',
      operator: deviceFilter ? 'equals' : 'contains',
    },
    {
      dimension: 'country',
      expression: countryFilter ? countryFilter : '',
      operator: countryFilter ? 'equals' : 'contains',
    },
  ];

  const dimensionFilterGroups = dimensionFilters.filter(
    (filter) => filter.expression !== '',
  );

  useEffect(() => {
    const googleConsole = async () => {
      fetch(
        `https://www.googleapis.com/webmasters/v3/sites/${encodeURIComponent(
          siteUrl,
        )}/searchAnalytics/query`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            startDate: startDate,
            endDate: endDate,
            dimensions: ['query'],
            rowLimit: 25000,
            type: selectedSearchType,
            dataState: 'all',
            dimensionFilterGroups: dimensionFilterGroups.length
              ? [{ filters: dimensionFilterGroups }]
              : undefined,
          }),
        },
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {
            return;
          }
          if (!data.rows) {
            return;
          }
          const groups = {};
          data.rows.forEach((row) => {
            const query = row.keys[0];
            const clicks = row.clicks;
            const impressions = row.impressions;
            const ctr = row.ctr;
            const position = row.position;
            if (!groups[query]) {
              groups[query] = {
                query,
                clicks: 0,
                impressions: 0,
                ctr: 0,
                position: 0,
              };
            }
            groups[query].clicks += clicks;
            groups[query].impressions += impressions;
            groups[query].ctr += ctr;
            groups[query].ctr = (groups[query].ctr * 100).toFixed(1) + '%';
            groups[query].position += position;
            groups[query].position =
              Math.round(groups[query].position * 10) / 10;
          });
          const tableData = Object.values(groups);
          setGoogleData(tableData);
        })
        .catch((error) => {
          console.error(error);
        });
    };
    if (!siteUrl || !accessToken) {
      return;
    } else {
      googleConsole();
    }
  }, [
    accessToken,
    siteUrl,
    startDate,
    endDate,
    countryFilter,
    queryFilter,
    pageFilter,
    deviceFilter,
    selectedSearchType,
    queryFilterType,
    pageFilterType,
  ]);

  const handleCellClick = (value) => {
    setQueryFilter(value);
  };

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => googleData, [googleData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,

    prepareRow,
    nextPage,
    previousPage,

    setPageSize,
    state,
    rows,
  } = tableInstance;

  const { pageSize } = state;

  return (
    <Card extra={'h-full w-full pt-3 pb-10 md:px-8 rounded-sm'}>
      <div className="mt-3 overflow-x-scroll 2xl:overflow-x-hidden">
        <table
          {...getTableProps()}
          className="w-full table-auto border-collapse whitespace-nowrap"
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => {
                  if (
                    (column.id === 'clicks' && !showClicks) ||
                    (column.id === 'impressions' && !showImpressions) ||
                    (column.id === 'ctr' && !showCTR) ||
                    (column.id === 'position' && !showPosition)
                  ) {
                    return null;
                  }
                  return (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className="w-full border-b border-gray-200 pb-[10px] pr-8 dark:!border-navy-700"
                      key={index}
                    >
                      <div className="text-xs font-bold tracking-wide text-gray-600 lg:text-xs">
                        <div className="flex items-center gap-2">
                          {column.render('Header')}
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <BsChevronUp className="mr-1 inline h-4 w-4" />
                            ) : (
                              <BsChevronDown className="mr-1 inline h-4 w-4" />
                            )
                          ) : (
                            <IoMdFunnel className="mr-1 inline h-4 w-4" />
                          )}
                        </div>
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    if (
                      (cell.column.id === 'clicks' && !showClicks) ||
                      (cell.column.id === 'impressions' && !showImpressions) ||
                      (cell.column.id === 'ctr' && !showCTR) ||
                      (cell.column.id === 'position' && !showPosition)
                    ) {
                      return null;
                    }
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={`border-b border-gray-200 py-3 text-sm dark:!border-navy-700
                         lg:text-xs ${
                           cell.column.id === 'query'
                             ? 'cursor-pointer hover:bg-gray-100 dark:hover:bg-navy-700'
                             : ''
                         }
                    `}
                        key={index}
                        onClick={
                          cell.column.id === 'query'
                            ? () => handleCellClick(cell.value)
                            : undefined
                        }
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* pagination */}
      <div className="mt-2 flex h-[80px] w-full items-center justify-between">
        {/* left side */}
        <div className="flex items-center gap-2">
          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
            className="h-10 w-[70px] rounded-xl border border-gray-200 px-2 text-sm  text-gray-600 dark:!border-white/10 dark:!bg-navy-800"
            name=""
            id=""
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </select>
          <p className="text-sm text-gray-700">
            Showing {page.length} of {rows.length}
          </p>
        </div>
        {/* right side */}
        <div className="flex items-center gap-2">
          <button
            onClick={() => previousPage()}
            className={`linear flex items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
          >
            <MdChevronLeft />
          </button>

          <p className="text-sm text-gray-700">
            {state.pageIndex + 1} of {Math.ceil(googleData.length / pageSize)}
          </p>

          <button
            onClick={() => nextPage()}
            className={`linear flex items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
          >
            <MdChevronRight />
          </button>
        </div>
      </div>
    </Card>
  );
}

export default QueriesTable;
