import React, { useMemo, useState, useEffect } from 'react';
import Select from 'react-select';
import { ToastContainer } from 'react-toastify';
import { toastSuccess, toastError } from 'utils/toasts';
import CryptoJS from 'crypto-js';
import Card from 'components/card';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { post, get } from 'api';
import { FiSearch } from 'react-icons/fi';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { IoMdFunnel } from 'react-icons/io';
import InputField from './InputField';
import TooltipHorizon from 'components/tooltip';

const ProjectsTable = (props) => {
  const { columnsData } = props;
  const [projects, setProjects] = useState([]);
  const [suggestion, setSuggestion] = useState([]);
  const [marketplaceDomain, setMarketplaceDomain] = useState([]);
  const [submitting, setSubmitting] = useState({});
  const [selectedProject, setSelectedProject] = useState(null);

  const filteredProjects = useMemo(() => {
    if (selectedProject) {
      return projects.filter((item) => item.id === selectedProject);
    }
    return projects;
  }, [projects, selectedProject]);

  useEffect(() => {
    const getProjects = async () => {
      await get('/api/project').then((res) =>
        res.json().then((data) => {
          setProjects(data);
          setSubmitting(
            data.reduce((acc, item) => {
              return {
                ...acc,
                [item.id]: false,
              };
            }, {}),
          );
        }),
      );
    };
    getProjects();
  }, []);

  useEffect(() => {
    const getMarketplaceDomain = async () => {
      await get('/api/marketplace').then((res) =>
        res.json().then((response) => {
          const encryptedData = response.data;
          const bytes = CryptoJS.AES.decrypt(
            encryptedData,
            process.env.REACT_APP_CRYPTO_SECRET,
          );
          const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          setMarketplaceDomain(data);
        }),
      );
    };
    getMarketplaceDomain();
  }, []);

  const handleSuggestion = (e, itemId) => {
    const { value } = e.target;
    setSuggestion({ ...suggestion, [itemId]: value });
  };

  const handleSubmitSuggestion = async (itemId) => {
    setSubmitting({ ...submitting, [itemId]: true });
    const body = {
      projectId: itemId,
      marketplaceId: suggestion[itemId],
    };
    await post(`/api/suggestions`, body).then((res) => {
      if (res.status === 200) {
        toastSuccess('Suggestion added successfully');
        setSubmitting({ ...submitting, [itemId]: false });
      } else {
        toastError('Something went wrong');
      }
    });
  };

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => filteredProjects, [filteredProjects]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 15 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,

    prepareRow,
    nextPage,
    previousPage,

    setGlobalFilter,
    setPageSize,
    state,
    rows,
  } = tableInstance;

  const { pageSize } = state;

  const handleSelectProject = (e) => {
    if (e.value === 'all') {
      setSelectedProject(null);
    } else {
      setSelectedProject(e.value);
    }
  };

  return (
    <Card extra={'w-full sm:overflow-auto p-4'}>
      <Select
        className="mb-4 w-1/4"
        options={[
          { value: 'all', label: 'Select All' },
          ...projects.map((item) => ({
            value: item.id,
            label: item.projectName,
          })),
        ]}
        onChange={(e) => handleSelectProject(e)}
        placeholder="Select project"
      />

      <ToastContainer
        position="top-center"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* Search */}
      <div
        onChange={(e) => setGlobalFilter(e.target.value)}
        className="flex items-center rounded-[10px] bg-white shadow-2xl shadow-white dark:!bg-navy-800 dark:shadow-none md:mt-2 md:w-3/4 md:w-[400px]"
      >
        <div className="flex h-9 w-full flex-grow items-center rounded-[10px] bg-lightPrimary text-sm text-gray-600 dark:!bg-navy-900">
          <FiSearch className="mx-2 h-4 w-4 !text-gray-700 dark:!text-white" />
          <input
            type="text"
            placeholder="Search...."
            className="block h-full w-full rounded-full bg-lightPrimary text-sm text-navy-700 outline-none dark:!bg-navy-900 dark:text-white"
          />
        </div>
      </div>
      <div className="mt-8 h-full max-w-[100%] overflow-x-auto">
        <table
          {...getTableProps()}
          className="w-full table-auto border-collapse whitespace-nowrap"
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="border-b border-gray-200 pb-[10px] pr-16 dark:!border-navy-700"
                    key={index}
                  >
                    <div className="mr-[8px] text-start text-xs font-bold tracking-wide text-gray-600 lg:text-xs">
                      <div className="flex items-center gap-2">
                        {column.render('Header')}
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <BsChevronUp className="mr-1 inline h-4 w-4" />
                          ) : (
                            <BsChevronDown className="mr-1 inline h-4 w-4" />
                          )
                        ) : (
                          <IoMdFunnel className="mr-1 inline h-4 w-4" />
                        )}
                      </div>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {projects.length === 0 && (
              <tr>
                <td
                  colSpan="7"
                  className="pt-4 text-center text-sm font-bold text-gray-700 dark:text-white"
                >
                  There are no projects to show
                </td>
              </tr>
            )}
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  key={index}
                  className="h-[120px] items-center border-b border-gray-200 dark:!border-white/10"
                >
                  {row.cells.map((cell, index) => {
                    let data = '';
                    if (cell.column.Header === 'PROJECT ID') {
                      data = (
                        <div className="flex items-center gap-2">
                          <div className="text-sm font-bold text-navy-700 dark:text-white">
                            #{cell.value}
                          </div>
                        </div>
                      );
                    } else if (cell.column.Header === 'PROJECT NAME') {
                      data = (
                        <div className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </div>
                      );
                    } else if (cell.column.Header === 'DOMAIN NAME') {
                      data = (
                        <a
                          href={cell.value}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <TooltipHorizon
                            trigger={
                              <p className="text-sm font-bold text-navy-700 dark:text-white">
                                {cell.value && cell.value.slice(0, 20)}
                                {cell.value && cell.value.length > 20 && (
                                  <span>...</span>
                                )}
                              </p>
                            }
                            content={
                              <p className="text-sm font-bold text-navy-700 dark:text-white">
                                {cell.value}
                              </p>
                            }
                            placement="top"
                          />
                        </a>
                      );
                    } else if (cell.column.Header === 'CATEGORIES') {
                      data = (
                        <div className="flex flex-col text-sm font-medium text-navy-700 dark:text-white">
                          {cell.value.map((item, index) => (
                            <span key={index} className="mr-2">
                              {item.category.category}
                            </span>
                          ))}
                        </div>
                      );
                    } else if (cell.column.Header === 'COUNTRIES') {
                      data = (
                        <div className="flex flex-col text-sm font-medium text-navy-700 dark:text-white">
                          {cell.value.map((item, index) => (
                            <span key={index} className="mr-2">
                              {item.country.country}
                            </span>
                          ))}
                        </div>
                      );
                    } else if (cell.column.Header === 'LANGUAGES') {
                      data = (
                        <div className="flex flex-col text-sm font-medium text-navy-700 dark:text-white">
                          {cell.value.map((item, index) => (
                            <span key={index} className="mr-2">
                              {item.language.language}
                            </span>
                          ))}
                        </div>
                      );
                    } else if (cell.column.Header === 'DESCRIPTION') {
                      data = (
                        <div className="w-full whitespace-break-spaces break-words text-sm font-medium text-navy-700 dark:text-white">
                          {cell.value}
                        </div>
                      );
                    } else if (cell.column.Header === 'SUGGESTION ID') {
                      const itemId = cell.row.original.id;
                      data = (
                        <div className="flex items-center gap-2">
                          <InputField
                            id="suggestionId"
                            value={suggestion[itemId] || ''}
                            type="text"
                            placeholder="Market id"
                            name="suggestionId"
                            state="normal"
                            onChange={(e) => handleSuggestion(e, itemId)}
                          />
                        </div>
                      );
                    } else if (cell.column.Header === 'MARKETPLACE DOMAIN') {
                      const itemId = cell.row.original.id;
                      const marketId = parseInt(suggestion[itemId]);
                      const domain = marketplaceDomain.find(
                        (item) => item.id === marketId,
                      )?.domain;
                      data = (
                        <a
                          href={cell.value}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <TooltipHorizon
                            trigger={
                              <p className="text-sm font-bold text-navy-700 dark:text-white">
                                {domain && domain.slice(0, 10)}
                                {domain && domain.length > 10 && (
                                  <span>...</span>
                                )}
                              </p>
                            }
                            content={
                              <p className="text-sm font-bold text-navy-700 dark:text-white">
                                {domain}
                              </p>
                            }
                            placement="top"
                          />
                        </a>
                      );
                    } else if (cell.column.Header === 'UPDATE') {
                      const itemId = cell.row.original.id;
                      data = (
                        <button
                          disabled={submitting[itemId]}
                          onClick={() => handleSubmitSuggestion(itemId)}
                          className={`linear text-md flex items-center justify-center rounded-full bg-brand-500 p-2 text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
                        >
                          {submitting[itemId] ? (
                            <div className="flex items-center justify-center">
                              <div className="mr-2 h-4 w-4 animate-spin rounded-full border-b-2 border-white" />
                              <span>Updating..</span>
                            </div>
                          ) : (
                            <span>Update</span>
                          )}
                        </button>
                      );
                    }
                    return (
                      <td
                        {...cell.getCellProps()}
                        key={index}
                        className="pb-[16px] pt-[14px] sm:text-[14px]"
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* pagination */}
      <div className="mt-10 flex h-[80px] w-full items-center justify-between px-6">
        {/* left side */}
        <div className="flex items-center gap-2">
          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
            className="h-10 w-[70px] rounded-xl border border-gray-200 px-2 text-sm  text-gray-600 dark:!border-white/10 dark:!bg-navy-800"
            name=""
            id=""
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </select>
          <p className="text-sm text-gray-700">
            Showing {page.length} of {rows.length}
          </p>
        </div>
        {/* right side */}
        <div className="flex items-center gap-2">
          <button
            onClick={() => previousPage()}
            className={`linear flex items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
          >
            <MdChevronLeft />
          </button>

          <p className="text-sm text-gray-700">
            {state.pageIndex + 1} of{' '}
            {Math.ceil(rows.length / state.pageSize) || 1}
          </p>

          <button
            onClick={() => nextPage()}
            className={`linear flex items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
          >
            <MdChevronRight />
          </button>
        </div>
      </div>
    </Card>
  );
};

export default ProjectsTable;
