import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { CartContext } from 'contexts/CartContext';
import { BsCart } from 'react-icons/bs';

const ShoppingCart = () => {
  const navigate = useNavigate();
  const { cartItems } = useContext(CartContext);

  return (
    <div className="mt-5 flex items-center justify-end">
      <div className="flex items-center justify-between border-b border-gray-200 p-2 dark:border-white/10">
        <div className="mr-2 text-lg font-bold text-navy-700 dark:text-white">
          Your Shopping Cart
        </div>
        <div
          className="relative"
          onClick={() => navigate('/admin/marketplace/cart')}
        >
          <span className="cursor-pointer text-2xl text-blue-500 hover:text-gray-700">
            <BsCart />
          </span>
          {cartItems.length > 0 && (
            <div className="absolute bottom-4 left-5 right-0 flex h-5 w-5 items-center justify-center rounded-full bg-red-500 text-xs text-white">
              {cartItems.length}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ShoppingCart;
