import React from 'react';
import BacklinksTable from './components/BacklinksTable';
import { backlinksHeaders } from './variables/columnsData';

const AdminBacklinks = () => {
  return (
    <div>
      <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
        <BacklinksTable columnsData={backlinksHeaders} />
      </div>
    </div>
  );
};

export default AdminBacklinks;
