import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { MdBarChart } from 'react-icons/md';
import { get, post, getUserId, getIsUserActivated } from 'api';
import ProjectCard from './components/ProjectCard';

const WebsitesOverview = () => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [googleSiteUrls, setGoogleSiteUrls] = useState([]);
  const userActivated = getIsUserActivated();
  const accessToken = window.localStorage.getItem('accessToken');
  const refreshToken = window.localStorage.getItem('refreshToken');

  useEffect(() => {
    fetch('https://www.googleapis.com/webmasters/v3/sites', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        const { siteEntry } = data;
        if (!siteEntry) {
          return;
        }
        const siteUrls = siteEntry.map((site) => {
          return site.siteUrl;
        });
        setGoogleSiteUrls(siteUrls);
      });
  }, [accessToken]);

  const handleClick = () => {
    navigate('/admin/dashboards/projects/newProject');
  };

  const refreshAccessToken = async () => {
    await post('/login/refreshAccessToken', {
      refreshToken,
    })
      .then((response) => response.json())
      .then((data) => {
        const { accessToken, expiresIn } = data;
        window.localStorage.setItem('accessToken', accessToken);
        const expiresAt = moment().add(expiresIn, 'seconds');
        const expiresAtDateTime = expiresAt.format('YYYY-MM-DD HH:mm:ss');
        window.localStorage.setItem('expiresAt', expiresAtDateTime);
      })

      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    const expiresAt = window.localStorage.getItem('expiresAt');
    const currentDateTime = moment().format('YYYY-MM-DD HH:mm:ss');
    if (!expiresAt) {
      refreshAccessToken();
      setLoaded(true);
    } else if (expiresAt && currentDateTime > expiresAt) {
      refreshAccessToken();
      setLoaded(true);
    } else {
      setLoaded(true);
    }
  }, [refreshToken]);

  useEffect(() => {
    const fetchData = async () => {
      const userId = getUserId();
      await get(`/api/project/user/${userId}`)
        .then((res) => res.json())
        .then((data) => {
          setProjects(data);
        });
    };
    fetchData();
  }, []);

  if (!loaded) {
    return null;
  }

  return (
    <div className="mt-3 flex h-full w-full flex-col gap-[20px] rounded-[20px] xl:flex-row">
      <div className="h-full w-full rounded-[20px]">
        <div
          className={`mt-0 flex flex-row items-center 
        ${userActivated ? 'justify-end' : 'justify-between'}
        `}
        >
          {!userActivated && (
            <div className="w-[60%] rounded-[20px] bg-white p-4 shadow-md dark:bg-gray-800">
              <p className="text-xl font-bold text-gray-800 dark:text-white">
                Your account has not been activated yet. Please update your
                company details first. If you have already done so, please
                schedule an onboarding call with us at{' '}
                <a
                  href="https://calendly.com/karolisbu/onboarding-linkjuice-marketplace"
                  target="_blank"
                  rel="noreferrer"
                  className="cursor-pointer text-blue-500 hover:text-blue-600 dark:text-blue-400 dark:hover:text-blue-500"
                >
                  Onboarding Call Schedule
                </a>
              </p>
            </div>
          )}
          <button
            onClick={() => handleClick()}
            className="rounded-[20px] bg-blue-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
          >
            + Add project
          </button>
        </div>
        <div className="col-span-9 h-full w-full rounded-t-2xl xl:col-span-6">
          <div className="mt-[38px] grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-1">
            {projects.length === 0 && (
              <p className="text-left text-gray-500 dark:text-gray-400">
                No projects yet
              </p>
            )}
            {projects.length > 0 &&
              projects.map((project) => {
                const hostname = new URL(project.domainName).hostname;
                const domainName = hostname.split('.').slice(-2).join('.');
                const googleSiteUrl = googleSiteUrls.find((url) =>
                  url.includes(domainName),
                );

                return (
                  <ProjectCard
                    key={project.id}
                    icon={<MdBarChart className="h-10 w-10" />}
                    projectName={project.projectName}
                    domainName={googleSiteUrl}
                    id={project.id}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebsitesOverview;
