import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const PrivateRoute = ({ children, ...rest }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isAuthenticated = localStorage.getItem('idToken') !== null;

  useEffect(() => {
    if (!isAuthenticated) {
      const params = new URLSearchParams(location.search);
      const referral = params.get('referral');

      if (referral) {
        localStorage.setItem('referralCode', referral);
        params.delete('referral');
        location.search = params.toString();
      }

      sessionStorage.setItem('preLoginLocation', JSON.stringify(location));
      navigate('/');
    }
  }, [isAuthenticated, navigate, location]);

  if (!isAuthenticated) {
    return null;
  } else {
    return children;
  }
};

export default PrivateRoute;
