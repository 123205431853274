import { createContext } from 'react';
import { useCartState } from './states/CartState';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const cartState = useCartState();

  return (
    <CartContext.Provider value={cartState}>{children}</CartContext.Provider>
  );
};
