import React from 'react';
import ShoppingCart from '../overview/components/ShoppingCart';
import CartTable from './components/CartTable';
import { columnsData } from './variables/columnsData';

const Cart = () => {
  return (
    <div>
      <div className="mt-5 flex items-center justify-end">
        <ShoppingCart />
      </div>
      <div className="flex items-center justify-between space-x-3 md:flex md:space-x-5">
        <CartTable columnsData={columnsData} />
      </div>
    </div>
  );
};

export default Cart;
