import React, { useContext } from 'react';
import Dropdown from 'components/dropdown';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { CartContext } from 'contexts/CartContext';
import { FavoritesContext } from 'contexts/FavoritesContext';
import { BlacklistContext } from 'contexts/BlacklistContext';

const OrderActions = ({
  data,
  pageName,
  item,
  setIsDenyReasonModalOpen,
  setDenyReasonData,
}) => {
  const { cartItems, addToCart, removeFromCart } = useContext(CartContext);
  const { favorites, addToFavorites, removeFromFavorites } =
    useContext(FavoritesContext);
  const { blacklist, addToBlacklist, removeFromBlacklist } =
    useContext(BlacklistContext);

  const isMarketplaceCart = Object.values(cartItems).find(
    (cartItem) => cartItem.id === data?.id,
  );

  const isMarketplaceFavorite = Object.values(favorites).find(
    (favorite) => favorite.id === data?.id,
  );

  const isMarketplaceBlacklist = Object.values(blacklist).find(
    (blacklist) => blacklist.id === data?.id,
  );

  const handleDenySuggestion = async (suggestionId, domain) => {
    setIsDenyReasonModalOpen(true);
    const data = [{ suggestionId, domain }];
    setDenyReasonData(data);
  };

  if (pageName === 'favorites' || pageName === 'blacklist') {
    return null;
  }

  return (
    <Dropdown
      button={
        <span className="ml-2 cursor-pointer text-xl text-blue-500 hover:text-gray-700">
          <BsThreeDotsVertical />
        </span>
      }
      children={
        <div className="rounded-md bg-white py-1 shadow-lg">
          {pageName === 'blacklist' ? null : (
            <button
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              role="menuitem"
              onClick={() =>
                pageName === 'marketplace' && isMarketplaceFavorite
                  ? removeFromFavorites(data.id)
                  : pageName === 'marketplace' && !isMarketplaceFavorite
                  ? addToFavorites(data)
                  : null
              }
            >
              {pageName === 'marketplace' && isMarketplaceFavorite
                ? 'Remove from favorites'
                : pageName === 'marketplace' && !isMarketplaceFavorite
                ? 'Add to favorites'
                : null}
            </button>
          )}
          {pageName === 'suggestions' ? (
            <button
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              role="menuitem"
              onClick={() =>
                handleDenySuggestion(data.suggestionId, data.domain)
              }
            >
              Deny suggestion
            </button>
          ) : null}
          <button
            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
            role="menuitem"
            onClick={() =>
              pageName === 'blacklist'
                ? removeFromBlacklist(item)
                : (pageName === 'marketplace' || pageName === 'suggestions') &&
                  isMarketplaceBlacklist
                ? removeFromBlacklist(data.id)
                : (pageName === 'marketplace' || pageName === 'suggestions') &&
                  !isMarketplaceBlacklist
                ? addToBlacklist(data)
                : null
            }
          >
            {pageName === 'blacklist'
              ? 'Remove from blacklist'
              : (pageName === 'marketplace' || pageName === 'suggestions') &&
                isMarketplaceBlacklist
              ? 'Remove from blacklist'
              : (pageName === 'marketplace' || pageName === 'suggestions') &&
                !isMarketplaceBlacklist
              ? 'Add to blacklist'
              : null}
          </button>
          {pageName === 'blacklist' ? null : (
            <button
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              role="menuitem"
              onClick={() =>
                (pageName === 'marketplace' || pageName === 'suggestions') &&
                !isMarketplaceCart
                  ? addToCart(data, pageName)
                  : removeFromCart(data.id, pageName)
              }
            >
              {(pageName === 'marketplace' || pageName === 'suggestions') &&
              !isMarketplaceCart
                ? 'Add to cart'
                : 'Remove from cart'}
            </button>
          )}
        </div>
      }
      classNames={'py-2 bottom-[-60px] -left-[180px] w-max'}
    />
  );
};

export default OrderActions;
