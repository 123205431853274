import { createContext, useState, useEffect } from 'react';

export const FavoritesContext = createContext();

export const FavoritesProvider = ({ children }) => {
  const [favorites, setFavorites] = useState({});

  useEffect(() => {
    const data = window.localStorage.getItem('favorites');
    if (data) {
      setFavorites(JSON.parse(data));
    }
  }, []);

  const addToFavorites = (item) => {
    setFavorites((prev) => ({ ...prev, [item.id]: item }));
    window.localStorage.setItem(
      'favorites',
      JSON.stringify({ ...favorites, [item.id]: item }),
    );
  };

  const bulkAddToFavorites = (data) => {
    const newFavorites = { ...favorites };
    data.forEach((item) => {
      newFavorites[item.id] = item;
    });
    setFavorites(newFavorites);
    window.localStorage.setItem('favorites', JSON.stringify(newFavorites));
  };

  const removeFromFavorites = (id) => {
    const { [id]: removed, ...rest } = favorites;
    setFavorites(rest);
    window.localStorage.setItem('favorites', JSON.stringify(rest));
  };

  const bulkRemoveFromFavorites = (data) => {
    const newFavorites = { ...favorites };
    data.forEach((item) => {
      delete newFavorites[item.id];
    });
    setFavorites(newFavorites);
    window.localStorage.setItem('favorites', JSON.stringify(newFavorites));
  };

  return (
    <FavoritesContext.Provider
      value={{
        favorites,
        addToFavorites,
        removeFromFavorites,
        bulkAddToFavorites,
        bulkRemoveFromFavorites,
      }}
    >
      {children}
    </FavoritesContext.Provider>
  );
};
