import React from 'react';

function SingleMessage(props) {
  const { message, time, extra, text, timecolor } = props;

  const convertUrlsToAnchors = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(
      urlRegex,
      (url) =>
        `<a href="${url}" target="_blank" rel="noopener noreferrer" style="text-decoration: underline;">${url}</a>`,
    );
  };

  const messageWithAnchors = convertUrlsToAnchors(message);

  return (
    <div className={`mb-3 mr-2 dark:!bg-navy-700 ${extra} p-5`}>
      <p
        className={`text-md pb-2 text-navy-700 dark:text-white ${text}`}
        dangerouslySetInnerHTML={{ __html: messageWithAnchors }}
      />
      <p className={`${timecolor} text-sm font-medium`}> {time} </p>
    </div>
  );
}

export default SingleMessage;
