import React, { useState, useContext, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import Card from 'components/card';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import {
  MdCancel,
  MdOutlineError,
  MdChevronLeft,
  MdChevronRight,
  MdContentCopy,
  MdCheck,
} from 'react-icons/md';
import { useMemo } from 'react';
import { FiSearch } from 'react-icons/fi';
import { ProjectContext } from 'contexts/ProjectContext';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { IoMdFunnel } from 'react-icons/io';
import { get, getUserId } from 'api';
import TooltipHorizon from 'components/tooltip';
import moment from 'moment';
import Checkbox from 'components/checkbox';

const BacklinksTable = ({ columnsData, selectedRows, setSelectedRows }) => {
  const { currentProject } = useContext(ProjectContext);
  const [projectOrders, setProjectOrders] = useState([]);
  const [copied, setCopied] = useState({});
  const currencySymbol = localStorage.getItem('currency');
  const [bulkSelect, setBulkSelect] = useState(false);

  const handleSelectRow = (e, row) => {
    if (e.target.checked) {
      setSelectedRows([...selectedRows, row]);
    } else {
      setSelectedRows(selectedRows.filter((item) => item.id !== row.id));
    }
  };

  const handleBulkSelect = () => {
    setBulkSelect(!bulkSelect);
    if (bulkSelect) {
      setSelectedRows([]);
    } else {
      setSelectedRows(projectOrders);
    }
  };

  useEffect(() => {
    const fetchOrders = async () => {
      const userId = getUserId();
      await get(`/api/orders?userId=${userId}&projectId=${currentProject.id}`)
        .then((res) => res.json())
        .then((data) => {
          if (data.statusCode === 500) {
            setProjectOrders([]);
            return;
          }
          const newData = data.map((item) => {
            return {
              ...item,
              totalPrice:
                parseFloat(item.articlePrice) + parseFloat(item.price),
            };
          });
          setProjectOrders(newData.sort((a, b) => b.id - a.id));
          setSelectedRows(newData.sort((a, b) => b.id - a.id));
        });
    };
    if (!currentProject || Object.keys(currentProject).length === 0) {
      return;
    } else {
      fetchOrders();
    }
  }, [currentProject]);

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => projectOrders, [projectOrders]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 15 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,

    prepareRow,
    nextPage,
    previousPage,

    setGlobalFilter,
    setPageSize,
    state,
    rows,
  } = tableInstance;

  const { pageSize } = state;

  return (
    <Card extra={'mt-5 w-full h-full p-4 sm:overflow-x-auto'}>
      <ToastContainer
        position="top-center"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* Search */}
      <div className="flex w-full items-center">
        <div className="flex items-center justify-start gap-2">
          <Checkbox onChange={handleBulkSelect} />
          <span className="text-sm font-medium text-gray-600">Select all</span>
        </div>
        <div
          onChange={(e) => setGlobalFilter(e.target.value)}
          className="flex w-3/4 items-center rounded-[10px] bg-white shadow-2xl shadow-white dark:!bg-navy-800 dark:shadow-none md:ml-4 md:mt-4 md:w-[400px]"
        >
          <div className="flex h-9 w-full flex-grow items-center rounded-[10px] bg-lightPrimary text-sm text-gray-600 dark:!bg-navy-900">
            <FiSearch className="mx-2 h-4 w-4 !text-gray-700 dark:!text-white" />
            <input
              type="text"
              placeholder="Search...."
              className="block h-full w-full rounded-full bg-lightPrimary text-sm text-navy-700 outline-none dark:!bg-navy-900 dark:text-white"
            />
          </div>
        </div>
      </div>
      <div className="mt-4 h-full max-w-[100%] overflow-x-auto">
        <table
          {...getTableProps()}
          className="w-full table-auto border-collapse whitespace-nowrap"
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => {
                  const isActionColumn = column.Header === 'ORDER ACTIONS';
                  return (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className="border-b border-gray-200 pb-[10px] dark:!border-navy-700"
                      key={index}
                    >
                      <div className="mr-[30px] text-start text-xs font-bold tracking-wide text-gray-600 lg:text-xs">
                        <div className="flex items-center gap-2">
                          {isActionColumn ? '' : column.render('Header')}
                          {column.isSorted &&
                          column.Header !== 'ORDER ACTIONS' ? (
                            column.isSortedDesc ? (
                              <BsChevronUp className="mr-1 inline h-4 w-4" />
                            ) : (
                              <BsChevronDown className="mr-1 inline h-4 w-4" />
                            )
                          ) : (
                            column.Header !== 'ORDER ACTIONS' && (
                              <IoMdFunnel className="mr-1 inline h-4 w-4" />
                            )
                          )}
                        </div>
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={index}>
                  {page.includes(row) &&
                    row.cells.map((cell, index) => {
                      let data = '';
                      if (cell.column.Header === 'ORDER ID') {
                        data = (
                          <div className="flex w-[100px] items-center gap-2">
                            <Checkbox
                              checked={selectedRows.some(
                                (selectedRow) =>
                                  selectedRow.id === cell.row.original.id,
                              )}
                              onChange={(e) =>
                                handleSelectRow(e, cell.row.original)
                              }
                            />
                            <div className="text-sm font-medium text-navy-700 dark:text-white">
                              #{cell.value}
                            </div>
                          </div>
                        );
                      } else if (cell.column.Header === 'DOMAIN') {
                        data = (
                          <TooltipHorizon
                            key={index}
                            extra="max-h-[200px] overflow-y-auto"
                            trigger={
                              <p className="w-[150px] text-sm font-bold text-navy-700 dark:text-white">
                                <a
                                  href={cell.value}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {cell.value && cell.value.slice(0, 18)}
                                  {cell.value && cell.value.length > 18 && (
                                    <span>...</span>
                                  )}
                                </a>
                              </p>
                            }
                            content={
                              <p className="text-sm font-bold text-navy-700 dark:text-white">
                                {cell.value}
                              </p>
                            }
                            placement="top"
                          />
                        );
                      } else if (cell.column.Header === 'DATE') {
                        data = (
                          <div className="text-sm font-bold text-navy-700 dark:text-white">
                            {moment(cell.value).format('YYYY-MM-DD')}
                          </div>
                        );
                      } else if (cell.column.Header === 'ANCHORS') {
                        const anchors = cell.row.original.anchors;
                        data = (
                          <div className="w-[150px] text-sm font-bold text-navy-700 dark:text-white">
                            {anchors.map((item, index) => (
                              <div key={index} className="flex">
                                <span>{item.urlAnchor}</span>
                              </div>
                            ))}
                          </div>
                        );
                      } else if (cell.column.Header === 'PUBLICATION URL') {
                        const orderId = cell.row.original.id;
                        const articleStatus = cell.row.original.articleStatus;
                        const orderStatus = cell.row.original.orderStatus;
                        const articleURL = cell.row.original.publicationUrl;
                        const isOrderDone =
                          orderStatus === 'DONE' && articleStatus === 'DONE';

                        data =
                          isOrderDone && articleURL ? (
                            <div className="flex w-[200px] items-center gap-2">
                              <a
                                href={articleURL}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <button className="rounded-full bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700">
                                  Publication URL
                                </button>
                              </a>
                              <MdContentCopy
                                className="ml-2 cursor-pointer text-xl text-gray-700 dark:text-white"
                                onClick={() => {
                                  navigator.clipboard.writeText(articleURL);
                                  setCopied((prevCopied) => ({
                                    ...prevCopied,
                                    [orderId]: true,
                                  }));
                                  setTimeout(() => {
                                    setCopied((prevCopied) => ({
                                      ...prevCopied,
                                      [orderId]: false,
                                    }));
                                  }, 500);
                                }}
                              />
                              {copied[orderId] && (
                                <MdCheck
                                  className="ml-2 text-green-500"
                                  style={{ marginTop: '2px' }}
                                />
                              )}
                              {copied[orderId] && (
                                <div className="absolute rounded-md bg-gray-800 px-2 py-1 text-white">
                                  Publication URL Copied!
                                </div>
                              )}
                            </div>
                          ) : orderStatus === 'IN PROGRESS' ? (
                            <div className="flex items-center gap-2 text-xl">
                              <MdOutlineError className="text-blue-300" />
                              <p className="text-sm font-bold text-navy-700 dark:text-white">
                                {orderStatus}
                              </p>
                            </div>
                          ) : orderStatus === 'CANCELLED' ? (
                            <div className="flex items-center gap-2 text-xl">
                              <MdCancel className="text-red-500" />
                              <p className="text-sm font-bold text-navy-700 dark:text-white">
                                {orderStatus}
                              </p>
                            </div>
                          ) : orderStatus === 'IN REVISION' ? (
                            <div className="flex items-center gap-2 text-xl">
                              <MdOutlineError className="text-orange-400" />
                              <p className="text-sm font-bold text-navy-700 dark:text-white">
                                {orderStatus}
                              </p>
                            </div>
                          ) : null;
                      } else if (cell.column.Header === 'PRICE') {
                        data = (
                          <p className="w-[100px] text-sm font-bold text-navy-700 dark:text-white">
                            {currencySymbol}
                            {cell.value.toLocaleString()}
                          </p>
                        );
                      } else if (cell.column.Header === 'INDEXED') {
                        data = (
                          <div
                            className={`mr-4 flex h-7 w-[100px] items-center justify-center text-sm ${
                              cell.value === true
                                ? 'bg-[#F8F8F8] dark:bg-green-50'
                                : 'bg-[#F8F8F8] dark:bg-blue-50'
                            } rounded-[10px] text-base font-medium `}
                          >
                            <div
                              className={`${
                                cell.value === true
                                  ? 'text-[#20A144]'
                                  : 'text-[#E56E19]'
                              } uppercase `}
                            >
                              {cell.value === true ? 'Yes' : 'Processing'}
                            </div>
                          </div>
                        );
                      }
                      return (
                        <td
                          className="pb-[18px] pt-[14px] sm:text-[14px]"
                          {...cell.getCellProps()}
                          key={index}
                        >
                          {data}
                        </td>
                      );
                    })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* pagination */}
      <div className="mt-2 flex h-[80px] w-full items-center justify-between">
        {/* left side */}
        <div className="flex items-center gap-2">
          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
            className="h-10 w-[70px] rounded-xl border border-gray-200 px-2 text-sm  text-gray-600 dark:!border-white/10 dark:!bg-navy-800"
            name=""
            id=""
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </select>
          <p className="text-sm text-gray-700">
            Showing {page.length} of {rows.length}
          </p>
        </div>
        {/* right side */}
        <div className="flex items-center gap-2">
          <button
            onClick={() => previousPage()}
            className={`linear flex items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
          >
            <MdChevronLeft />
          </button>

          <p className="text-sm text-gray-700">
            {state.pageIndex + 1} of{' '}
            {Math.ceil(projectOrders.length / pageSize)}
          </p>

          <button
            onClick={() => nextPage()}
            className={`linear flex items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
          >
            <MdChevronRight />
          </button>
        </div>
      </div>
    </Card>
  );
};

export default BacklinksTable;
