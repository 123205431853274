import React, { useMemo, useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { toastSuccess, toastError } from 'utils/toasts';
import Card from 'components/card';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { get, put } from 'api';
import { FiSearch } from 'react-icons/fi';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { IoMdFunnel } from 'react-icons/io';
import Switch from 'components/switch';
import InputField from './InputField';

const Companies = (props) => {
  const { columnsData } = props;
  const [companies, setCompanies] = useState([]);
  const [vatPayer, setVatPayer] = useState([]);
  const [discount, setDiscount] = useState([]);

  const getCompanies = async () => {
    await get('/api/company').then((res) =>
      res.json().then((data) => {
        setCompanies(data);
        const vatPayerData = data.map((item) => ({
          id: item.id,
          value: item.isVatPayer,
        }));
        setVatPayer(vatPayerData);
        const discountData = data.map((item) => ({
          id: item.id,
          value: item.discount,
        }));
        setDiscount(discountData);
      }),
    );
  };

  useEffect(() => {
    getCompanies();
  }, []);

  const handleUpdateCompany = async (id) => {
    const body = {
      isVatPayer: vatPayer.find((item) => item.id === id)?.value,
      discount: discount.find((item) => item.id === id)?.value,
    };
    await put(`/api/company/${id}`, body).then((res) => {
      if (res.status === 200) {
        toastSuccess('Company updated successfully');
        getCompanies();
      } else {
        toastError('Something went wrong');
      }
    });
  };

  const createHandler =
    (state, setState, isCheckbox = false) =>
    (e, id) => {
      const value = isCheckbox ? e.target.checked : e.target.value;
      if (state.some((item) => item.id === id)) {
        setState((prevState) =>
          prevState.map((item) =>
            item.id === id ? { ...item, value: value } : item,
          ),
        );
      } else {
        setState((prevState) => [...prevState, { id, value }]);
      }
    };

  const handleVatPayerChange = createHandler(vatPayer, setVatPayer, true);
  const handleDiscountChange = createHandler(discount, setDiscount);

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => companies, [companies]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 15 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,

    prepareRow,
    nextPage,
    previousPage,

    setGlobalFilter,
    setPageSize,
    state,
    rows,
  } = tableInstance;

  const { pageSize } = state;

  return (
    <Card extra={'w-full sm:overflow-auto p-4'}>
      <ToastContainer
        position="top-center"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* Search */}
      <div
        onChange={(e) => setGlobalFilter(e.target.value)}
        className="flex w-3/4 items-center rounded-[10px] bg-white shadow-2xl shadow-white dark:!bg-navy-800 dark:shadow-none md:mt-2 md:w-1/3"
      >
        <div className="flex h-9 w-full flex-grow items-center rounded-[10px] bg-lightPrimary text-sm text-gray-600 dark:!bg-navy-900">
          <FiSearch className="mx-2 h-4 w-4 !text-gray-700 dark:!text-white" />
          <input
            type="text"
            placeholder="Search...."
            className="block h-full w-full rounded-full bg-lightPrimary text-sm text-navy-700 outline-none dark:!bg-navy-900 dark:text-white"
          />
        </div>
      </div>
      <div className="mt-8 h-full max-w-[100%] overflow-x-auto">
        <table
          {...getTableProps()}
          className="w-full table-auto border-collapse whitespace-nowrap"
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="border-b border-gray-200 pb-[10px] pr-16 dark:!border-navy-700"
                    key={index}
                  >
                    <div className="text-start text-xs font-bold tracking-wide text-gray-600 lg:text-xs">
                      <div className="flex items-center gap-2">
                        {column.render('Header')}
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <BsChevronUp className="mr-1 inline h-4 w-4" />
                          ) : (
                            <BsChevronDown className="mr-1 inline h-4 w-4" />
                          )
                        ) : (
                          <IoMdFunnel className="mr-1 inline h-4 w-4" />
                        )}
                      </div>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  key={index}
                  className="h-[60px] items-center border-b border-gray-200 dark:!border-white/10"
                >
                  {row.cells.map((cell, index) => {
                    let data = '';
                    if (cell.column.Header === 'ID') {
                      data = (
                        <div className="flex items-center gap-2">
                          <div className="text-sm font-bold text-navy-700 dark:text-white">
                            #{cell.value}
                          </div>
                        </div>
                      );
                    } else if (cell.column.Header === 'COMPANY NAME') {
                      data = (
                        <div className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </div>
                      );
                    } else if (cell.column.Header === 'ADDRESS') {
                      data = (
                        <div className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </div>
                      );
                    } else if (cell.column.Header === 'ZIP CODE') {
                      data = (
                        <div className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </div>
                      );
                    } else if (cell.column.Header === 'CITY') {
                      data = (
                        <div className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </div>
                      );
                    } else if (cell.column.Header === 'COUNTRY') {
                      data = (
                        <div className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </div>
                      );
                    } else if (cell.column.Header === 'COMPANY CODE') {
                      data = (
                        <div className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </div>
                      );
                    } else if (cell.column.Header === 'BILLING EMAIL') {
                      data = (
                        <div className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </div>
                      );
                    } else if (cell.column.Header === 'VAT NUMBER') {
                      data = (
                        <div className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </div>
                      );
                    } else if (cell.column.Header === 'VAT PAYER') {
                      const companyId = cell.row.original.id;
                      data = (
                        <div className="text-sm font-bold text-navy-700 dark:text-white">
                          <Switch
                            checked={vatPayer.some(
                              (item) => item.id === companyId && item.value,
                            )}
                            onChange={(e) => handleVatPayerChange(e, companyId)}
                          />
                        </div>
                      );
                    } else if (cell.column.Header === 'DISCOUNT %') {
                      const companyId = cell.row.original.id;
                      data = (
                        <div className="flex items-center gap-2">
                          <InputField
                            extra="w-1/2"
                            id="discount"
                            value={
                              discount.find((item) => item.id === companyId)
                                ?.value
                            }
                            type="text"
                            placeholder="Discount %"
                            name="discount"
                            state="normal"
                            onChange={(e) => handleDiscountChange(e, companyId)}
                          />
                        </div>
                      );
                    } else if (cell.column.Header === 'UPDATE') {
                      const companyId = cell.row.original.id;
                      data = (
                        <button
                          onClick={() => handleUpdateCompany(companyId)}
                          className="linear text-md flex w-1/2 items-center justify-center rounded-full bg-brand-500 p-2 text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                        >
                          Update
                        </button>
                      );
                    }
                    return (
                      <td
                        {...cell.getCellProps()}
                        key={index}
                        className="pb-[16px] pt-[14px] sm:text-[14px]"
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* pagination */}
      <div className="mt-10 flex h-[80px] w-full items-center justify-between px-6">
        {/* left side */}
        <div className="flex items-center gap-2">
          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
            className="h-10 w-[70px] rounded-xl border border-gray-200 px-2 text-sm  text-gray-600 dark:!border-white/10 dark:!bg-navy-800"
            name=""
            id=""
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </select>
          <p className="text-sm text-gray-700">
            Showing {page.length} of {rows.length}
          </p>
        </div>
        {/* right side */}
        <div className="flex items-center gap-2">
          <button
            onClick={() => previousPage()}
            className={`linear flex items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
          >
            <MdChevronLeft />
          </button>

          <p className="text-sm text-gray-700">
            {state.pageIndex + 1} of {Math.ceil(companies.length / pageSize)}
          </p>

          <button
            onClick={() => nextPage()}
            className={`linear flex items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
          >
            <MdChevronRight />
          </button>
        </div>
      </div>
    </Card>
  );
};

export default Companies;
