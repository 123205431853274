import React from 'react';
import { orderManagerHeader } from './variables/columnsData';
import OrderTable from './components/OrderTable';

const OrderManager = () => {
  return (
    <div>
      <OrderTable columnsData={orderManagerHeader} />
    </div>
  );
};

export default OrderManager;
