import React, { useContext, useEffect, useState } from 'react';
import InputField from './InputField';
import { ProjectContext } from 'contexts/ProjectContext';

const AddNewAnchor = ({ handleDataChange }) => {
  const { currentProject, getProjectInfo } = useContext(ProjectContext);
  const [newUrl, setNewUrl] = useState(currentProject?.domainName || '');
  const [newAnchor, setNewAnchor] = useState('');

  useEffect(() => {
    setNewUrl(currentProject?.domainName || '');
  }, [currentProject]);

  const handleNewUrl = (value) => {
    setNewUrl(value);
  };

  const handleNewAnchor = (value) => {
    setNewAnchor(value);
  };

  return (
    <div className="flex w-full flex-col items-center gap-2 md:flex-row">
      <div className="flex w-full items-center gap-2">
        <p className="w-[60px] text-sm font-bold dark:text-white md:w-auto">
          URL:{' '}
        </p>
        <InputField
          extra="w-full"
          placeholder="New URL"
          id="newURL"
          type="text"
          value={newUrl}
          onChange={(e) => handleNewUrl(e.target.value)}
        />
      </div>
      <div className="flex w-full items-center gap-2">
        <p className="w-[60px] text-sm font-bold dark:text-white md:w-auto">
          Anchor:{' '}
        </p>
        <InputField
          extra="w-full"
          placeholder="New Anchor"
          id="newURL"
          type="text"
          onChange={(e) => handleNewAnchor(e.target.value)}
        />
      </div>
      <div className="flex w-full items-center md:w-auto">
        <button
          onClick={() => handleDataChange('selectedAnchors', newAnchor, newUrl)}
          className="mt-2 w-full rounded-md bg-blue-500 px-4 py-2 text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 md:mt-0"
        >
          Add
        </button>
      </div>
    </div>
  );
};

export default AddNewAnchor;
