export const reportsHeaders = [
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'DOMAIN NAME',
    accessor: 'domain',
  },
  {
    Header: 'DR',
    accessor: 'domainRating',
  },
  {
    Header: 'OT',
    accessor: 'organicTraffic',
  },
  {
    Header: 'RD',
    accessor: 'referringDomains',
  },
  {
    Header: 'LD',
    accessor: 'linkedDomains',
  },
  {
    Header: 'TOPIC',
    accessor: 'topicSuggestion',
  },
  {
    Header: 'ARTICLE URL',
    accessor: 'publicationUrl',
  },
  {
    Header: 'ARTICLE ANCHOR',
    accessor: 'articleAnchor',
  },
  {
    Header: 'PRICE',
    accessor: 'totalPrice',
  },
];
