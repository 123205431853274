import React, { useState, useEffect } from 'react';
import TopUp from './components/TopUp';
import TopUpHistory from './components/TopUpHistory';
import OrderHistory from './components/OrderHistory';
import { get, getUserId } from 'api';
import Spinner from 'components/loaders/Spinner';

const Credits = () => {
  const [currentBalance, setCurrentBalance] = useState(0);
  const [reservedBalance, setReservedBalance] = useState(0);
  const [currentView, setCurrentView] = useState('topup');
  const currencySymbol = localStorage.getItem('currency');
  const [balanceLoaded, setBalanceLoaded] = useState(false);

  const fetchCredits = async () => {
    const userId = getUserId();
    if (!userId) return;
    await get(`/api/user/${userId}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          return;
        }
        const credits = data.credits;
        const reservedCredits = data.reservedCredits;
        setCurrentBalance(credits);
        setReservedBalance(reservedCredits);
        setBalanceLoaded(true);
      });
  };

  useEffect(() => {
    fetchCredits();
  }, []);

  return (
    <div className="h-full w-full rounded-[20px] pt-7 md:px-3">
      <div className="!z-5 relative flex w-full flex-col rounded-[20px] bg-white bg-clip-border !p-5 shadow-3xl shadow-shadow-100 dark:!bg-navy-800  dark:text-white dark:shadow-none md:w-[75%]">
        <p className="text-lg font-bold text-navy-700 dark:text-white">
          Your current balance
        </p>
        <div className="mt-1 flex items-center justify-start">
          <p className="flex items-center text-3xl font-bold text-navy-700 dark:text-white">
            {currencySymbol}{' '}
            {balanceLoaded ? (
              currentBalance
            ) : (
              <Spinner width={6} height={6} ml={2} />
            )}
          </p>

          {reservedBalance > 0 && (
            <p className="ml-2 text-sm text-gray-700 dark:text-white">
              ({currencySymbol} {reservedBalance} reserved)
            </p>
          )}
        </div>
        <div className="mt-5 flex flex-col items-center justify-start md:flex-row">
          <div
            onClick={() => setCurrentView('topup')}
            className={`
                ${
                  currentView === 'topup'
                    ? 'border-b-2 border-navy-700 text-navy-700'
                    : 'text-gray-400'
                }
            text-md cursor-pointer font-bold  hover:border-b-2 hover:border-navy-700 dark:text-white`}
          >
            Add more credits
          </div>
          <div
            onClick={() => setCurrentView('topupHistory')}
            className={`
                ${
                  currentView === 'topupHistory'
                    ? 'border-b-2 border-navy-700 text-navy-700'
                    : 'text-gray-400'
                }
            text-md mt-4 cursor-pointer font-bold hover:border-b-2 hover:border-navy-700  dark:text-white md:ml-12 md:mt-0`}
          >
            Top Ups History
          </div>
          <div
            onClick={() => setCurrentView('ordersHistory')}
            className={`
                ${
                  currentView === 'ordersHistory'
                    ? 'border-b-2 border-navy-700 text-navy-700'
                    : 'text-gray-400'
                }
            text-md mt-4 cursor-pointer font-bold hover:border-b-2 hover:border-navy-700  dark:text-white md:ml-12 md:mt-0`}
          >
            Orders History
          </div>
        </div>
        <div className="mt-5 flex w-full">
          {currentView === 'topup' ? (
            <TopUp />
          ) : currentView === 'topupHistory' ? (
            <TopUpHistory />
          ) : (
            <OrderHistory />
          )}
        </div>
      </div>
    </div>
  );
};

export default Credits;
