import { MdCancel, MdCheckCircle, MdOutlineError } from 'react-icons/md';

function Chat(props) {
  const { photo, active, name, status, message, last, newMessage } = props;

  return (
    <div
      className={`flex w-full items-center gap-3 px-2 ${
        last ? 'pb-3 pt-6' : 'border-b border-gray-100 py-6'
      }  cursor-pointer dark:!border-navy-700 lg:cursor-pointer`}
    >
      <div className="relative h-[50px] !w-[60px] rounded-full">
        <img className="h-full w-full rounded-full" src={photo} alt="" />
        {active ? (
          <div className="absolute bottom-0 right-0 h-4 w-4 rounded-full border-2 border-white bg-green-500"></div>
        ) : (
          <div className="absolute bottom-0 right-0 h-4 w-4 rounded-full border-2 border-white bg-gray-500"></div>
        )}
        {newMessage && (
          <div className="absolute -left-2 -top-2 flex h-5 w-5 items-center justify-center rounded-full bg-red-500 text-xs text-white">
            <p className="text-xs font-bold">{newMessage}</p>
          </div>
        )}
      </div>
      <div className="w-full">
        <div className="flex w-full flex-col justify-between md:flex-row md:items-center">
          <div className="flex flex-col">
            <p className="word-break w-3/4 text-sm font-bold text-navy-700 dark:text-white md:text-lg">
              {name}
            </p>
            <p className="text-sm font-normal tracking-normal text-navy-700 dark:text-white">
              {message}{' '}
            </p>
          </div>
          <div className="md:items-left flex w-full flex-row md:w-auto md:flex-col md:justify-center">
            <div className="flex items-center">
              <p className="mr-1 text-sm font-medium text-gray-700">
                {status === '' ? '' : 'Article Status'}
              </p>
              {status === 'DONE' ? (
                <MdCheckCircle className="text-green-500" />
              ) : status === 'CANCELLED' ? (
                <MdCancel className="text-red-500" />
              ) : status === 'WAITING FOR ARTICLE' ? (
                <MdOutlineError className="text-yellow-300" />
              ) : status === 'IN PROGRESS' ? (
                <MdOutlineError className="text-blue-300" />
              ) : status === 'WRITING' ? (
                <MdOutlineError className="text-green-300" />
              ) : status === 'ARTICLE READY' ? (
                <MdOutlineError className="text-green-500" />
              ) : status === 'IN REVISION' ? (
                <MdOutlineError className="text-orange-400" />
              ) : (
                <MdOutlineError className="text-blue-500" />
              )}
            </div>
            <h4 className="ml-1 text-sm font-medium text-gray-700 md:ml-0">
              {status === '' ? '' : status}
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Chat;
