import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { toastSuccess, toastError } from 'utils/toasts';
import Card from 'components/card';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import {
  MdCheckCircle,
  MdCancel,
  MdOutlineError,
  MdChevronLeft,
  MdChevronRight,
  MdContentCopy,
  MdCheck,
} from 'react-icons/md';
import { useMemo } from 'react';
import { FiSearch } from 'react-icons/fi';
import ColumnsViewModal from 'views/admin/marketplace/overview/components/ColumnsViewModal';
import { ColumnsViewContext } from 'contexts/ColumnsViewContext';
import { ProjectContext } from 'contexts/ProjectContext';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { IoMdFunnel } from 'react-icons/io';
import ActionModal from './ActionModal';
import { get, getUserId, getUserRole } from 'api';
import TooltipHorizon from 'components/tooltip';
import ArticleModal from './ArticleModal';
import DataTable from 'components/loaders/TableSkeleton';
import Checkbox from 'components/checkbox';
import BulkActions from './BulkActions';

const OrderTable = () => {
  const navigate = useNavigate();
  const { currentProject } = useContext(ProjectContext);
  const [projectOrders, setProjectOrders] = useState([]);
  const [projectView, setProjectView] = useState('CURRENT');
  const { orderManagerColumnsView } = useContext(ColumnsViewContext);
  const [activeFilter, setActiveFilter] = useState('ALL');
  const [copied, setCopied] = useState({});
  const [domainCopied, setDomainCopied] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [bulkSelect, setBulkSelect] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const userRole = getUserRole();
  const currencySymbol = localStorage.getItem('currency');

  useEffect(() => {
    setDataLoaded(true);
  }, [projectOrders]);

  const fetchOrders = async () => {
    const userId = getUserId();
    if (projectView === 'ALL') {
      await get(`/api/orders/all/${userId}`)
        .then((res) => res.json())
        .then((data) => {
          if (data.statusCode === 500) {
            setProjectOrders([]);
            return;
          }
          const newData = data.map((item) => {
            return {
              ...item,
              totalPrice:
                parseFloat(item.articlePrice) + parseFloat(item.price),
            };
          });
          setProjectOrders(newData);
        });
      return;
    } else if (projectView === 'CURRENT') {
      if (!currentProject || Object.keys(currentProject).length === 0) {
        return;
      }
      await get(`/api/orders?userId=${userId}&projectId=${currentProject.id}`)
        .then((res) => res.json())
        .then((data) => {
          if (data.statusCode === 500) {
            setProjectOrders([]);
            return;
          }
          const newData = data.map((item) => {
            return {
              ...item,
              totalPrice:
                parseFloat(item.articlePrice) + parseFloat(item.price),
            };
          });
          setProjectOrders(newData);
        });
    }
  };

  const handleChatClick = (itemId) => {
    navigate(`/admin/chat?orderId=${itemId}`);
  };

  useEffect(() => {
    fetchOrders();
  }, [currentProject, projectView]);

  const checkColumnView = (columnName) => {
    return orderManagerColumnsView.find((view) => view.Header === columnName)
      .showColumn;
  };

  const columns = useMemo(
    () => orderManagerColumnsView,
    [orderManagerColumnsView],
  );
  const data = useMemo(() => projectOrders, [projectOrders]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 15 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,

    prepareRow,
    nextPage,
    previousPage,

    setGlobalFilter,
    setPageSize,
    state,
    rows,
  } = tableInstance;

  const { pageSize } = state;

  const filteredRows = page.filter((row) => {
    if (activeFilter === 'ALL') {
      return true;
    }
    if (activeFilter === 'NEED ATTENTION') {
      return (
        row.values.orderStatus === 'DRAFT ARTICLE' ||
        row.values.orderStatus === 'PUBLICATION URL'
      );
    }
    return row.values.orderStatus === activeFilter;
  });

  const handleSelectRow = (e, row) => {
    if (e.target.checked) {
      setSelectedRows([...selectedRows, row]);
    } else {
      setSelectedRows(selectedRows.filter((item) => item.id !== row.id));
    }
  };

  const handleBulkSelect = () => {
    setBulkSelect(!bulkSelect);
    if (bulkSelect) {
      setSelectedRows([]);
    } else {
      setSelectedRows(page.map((row) => row.original));
    }
  };

  return (
    <Card extra={'mt-5 w-full h-full p-4 sm:overflow-x-auto'}>
      <ToastContainer
        position="top-center"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="flex flex-col items-center justify-between md:flex-row">
        <div className="flex w-full flex-col items-center justify-start md:w-[35%] md:flex-row">
          <button
            onClick={() => setProjectView('CURRENT')}
            className={`
            ${projectView === 'CURRENT' ? 'bg-blue-500' : 'bg-gray-200'}
          mb-2 w-full rounded-full bg-blue-500 px-2 py-2 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 md:mb-0 md:mr-4 md:h-[50px]`}
          >
            CURRENT PROJECT
          </button>
          <button
            onClick={() => setProjectView('ALL')}
            className={`
            ${projectView === 'ALL' ? 'bg-blue-500' : 'bg-gray-200'}
          w-full rounded-full bg-blue-500 px-2 py-2 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 md:h-[50px]`}
          >
            ALL PROJECTS
          </button>
        </div>
        <div className="mt-5 flex w-full items-center justify-end md:mt-0">
          <ColumnsViewModal page="orderManager" />
        </div>
      </div>
      <div className="mb-5 mt-5 flex items-center justify-between md:mt-5">
        <div className="grid w-full grid-cols-2 gap-2 md:grid-cols-5">
          <button
            onClick={() => setActiveFilter('ALL')}
            className={`
            ${activeFilter === 'ALL' ? 'bg-blue-500' : 'bg-gray-200'}
          h-[auto] w-full rounded-full bg-blue-500 px-2 py-2 text-sm text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50`}
          >
            ALL
          </button>
          <button
            onClick={() => setActiveFilter('IN PROGRESS')}
            className={`
            ${activeFilter === 'IN PROGRESS' ? 'bg-blue-500' : 'bg-gray-200'}
          h-[auto] w-full rounded-full bg-blue-500 px-2 py-2 text-sm text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50`}
          >
            IN PROGRESS
          </button>
          <button
            onClick={() => setActiveFilter('NEED ATTENTION')}
            className={`
            ${activeFilter === 'NEED ATTENTION' ? 'bg-blue-500' : 'bg-gray-200'}
          h-[auto] w-full rounded-full bg-blue-500 px-2 py-2 text-sm text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50`}
          >
            NEED ATTENTION
          </button>
          <button
            onClick={() => setActiveFilter('DONE')}
            className={`
            ${activeFilter === 'DONE' ? 'bg-blue-500' : 'bg-gray-200'}
          h-[auto] w-full rounded-full bg-blue-500 px-2 py-2 text-sm text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50`}
          >
            DONE
          </button>
          <button
            onClick={() => setActiveFilter('CANCELLED')}
            className={`
            ${activeFilter === 'CANCELLED' ? 'bg-blue-500' : 'bg-gray-200'}
          h-[auto] w-full rounded-full bg-blue-500 px-2 py-2 text-sm text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50`}
          >
            CANCELLED
          </button>
        </div>
      </div>
      {/* Search */}
      <div
        onChange={(e) => setGlobalFilter(e.target.value)}
        className="flex w-full items-center rounded-[10px] bg-white shadow-2xl shadow-white dark:!bg-navy-800 dark:shadow-none md:w-3/4 md:w-[400px]"
      >
        <div className="flex h-9 w-full flex-grow items-center rounded-[10px] bg-lightPrimary text-sm text-gray-600 dark:!bg-navy-900">
          <FiSearch className="mx-2 h-4 w-4 !text-gray-700 dark:!text-white" />
          <input
            type="text"
            placeholder="Search...."
            className="block h-full w-full rounded-full bg-lightPrimary text-sm text-navy-700 outline-none dark:!bg-navy-900 dark:text-white"
          />
        </div>
      </div>
      {/* Bulk actions */}
      <div className="mt-4 flex items-center justify-start">
        <div className="flex items-center gap-2">
          <Checkbox checked={bulkSelect} onChange={handleBulkSelect} />
          <span className="text-sm font-medium text-gray-600">Select all</span>
        </div>
        <div className="ml-2 flex items-center gap-2">
          <BulkActions
            data={selectedRows}
            fetchOrders={fetchOrders}
            setBulkSelect={setBulkSelect}
          />
        </div>
      </div>
      {!dataLoaded ? (
        <DataTable />
      ) : (
        <div className="mt-8 h-full max-w-[100%] overflow-x-auto">
          <table
            {...getTableProps()}
            className="w-full table-auto border-collapse whitespace-nowrap"
          >
            <thead>
              {headerGroups.map((headerGroup, index) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => {
                    const isActionColumn = column.Header === 'ORDER ACTIONS';
                    const showColumn = orderManagerColumnsView.find(
                      (view) =>
                        view.Header === column.Header &&
                        view.showColumn &&
                        (userRole === 'admin'
                          ? view.admin === true || view.admin === false
                          : view.admin === false),
                    );
                    if (!showColumn) return null;
                    return (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps(),
                        )}
                        className="border-b border-gray-200 pb-[10px] dark:!border-navy-700"
                        key={index}
                      >
                        <div className="mr-[30px] text-start text-xs font-bold tracking-wide text-gray-600 lg:text-xs">
                          <div className="flex items-center gap-2">
                            {isActionColumn ? '' : column.render('Header')}
                            {column.isSorted &&
                            column.Header !== 'ORDER ACTIONS' ? (
                              column.isSortedDesc ? (
                                <BsChevronUp className="mr-1 inline h-4 w-4" />
                              ) : (
                                <BsChevronDown className="mr-1 inline h-4 w-4" />
                              )
                            ) : (
                              column.Header !== 'ORDER ACTIONS' && (
                                <IoMdFunnel className="mr-1 inline h-4 w-4" />
                              )
                            )}
                          </div>
                        </div>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, index) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} key={index}>
                    {filteredRows.includes(row) &&
                      row.cells
                        .filter((cell) => checkColumnView(cell.column.Header))
                        .map((cell, index) => {
                          let data = '';
                          if (cell.column.Header === 'DOMAIN') {
                            const orderId = cell.row.original.id;
                            const domain = cell.value;
                            data = (
                              <div className="flex w-[180px] items-center gap-2">
                                <Checkbox
                                  checked={selectedRows.includes(
                                    cell.row.original,
                                  )}
                                  onChange={(e) =>
                                    handleSelectRow(e, cell.row.original)
                                  }
                                />
                                <TooltipHorizon
                                  extra="max-h-[180px] overflow-y-auto"
                                  trigger={
                                    <p
                                      className="w-[160px] cursor-pointer overflow-hidden overflow-ellipsis whitespace-nowrap text-sm
                                    font-bold text-navy-700 dark:text-white"
                                      onClick={() => {
                                        navigator.clipboard.writeText(
                                          cell.value,
                                        );
                                        setDomainCopied((prevCopied) => ({
                                          ...prevCopied,
                                          [orderId]: true,
                                        }));
                                        setTimeout(() => {
                                          setDomainCopied((prevCopied) => ({
                                            ...prevCopied,
                                            [orderId]: false,
                                          }));
                                        }, 500);
                                      }}
                                    >
                                      {domainCopied[orderId]
                                        ? 'Domain copied!'
                                        : domain}
                                    </p>
                                  }
                                  content={
                                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                                      {domain}
                                    </p>
                                  }
                                  placement="top"
                                />
                              </div>
                            );
                          } else if (cell.column.Header === 'URL') {
                            const urls = cell.row.original.anchors;
                            data = (
                              <div className="text-sm font-bold text-navy-700 dark:text-white">
                                {urls.map((item, index) => (
                                  <TooltipHorizon
                                    key={index}
                                    extra="max-h-[200px] overflow-y-auto"
                                    trigger={
                                      <p className="w-[150px] text-sm font-bold text-navy-700 dark:text-white">
                                        <a
                                          href={item.urlSlug}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          {item.urlSlug &&
                                            item.urlSlug.slice(0, 20)}
                                          {item.urlSlug &&
                                            item.urlSlug.length > 20 && (
                                              <span>...</span>
                                            )}
                                        </a>
                                      </p>
                                    }
                                    content={
                                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                                        {item.urlSlug}
                                      </p>
                                    }
                                    placement="top"
                                  />
                                ))}
                              </div>
                            );
                          } else if (cell.column.Header === 'ANCHORS') {
                            const anchors = cell.row.original.anchors;
                            data = (
                              <div className="w-[150px] text-sm font-bold text-navy-700 dark:text-white">
                                {anchors.map((item, index) => (
                                  <TooltipHorizon
                                    key={index}
                                    extra="max-h-[200px] overflow-y-auto"
                                    trigger={
                                      <p className="w-[150px] text-sm font-bold text-navy-700 dark:text-white">
                                        <a
                                          href={item.urlAnchor}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          {item.urlAnchor &&
                                            item.urlAnchor.slice(0, 20)}
                                          {item.urlAnchor &&
                                            item.urlAnchor.length > 20 && (
                                              <span>...</span>
                                            )}
                                        </a>
                                      </p>
                                    }
                                    content={
                                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                                        {item.urlAnchor}
                                      </p>
                                    }
                                    placement="top"
                                  />
                                ))}
                              </div>
                            );
                          } else if (cell.column.Header === 'ARTICLE STATUS') {
                            const status = cell.value;
                            data = (
                              <div className="flex w-[200px] items-center gap-2">
                                <div className={`rounded-full text-xl`}>
                                  {status === 'DONE' ? (
                                    <MdCheckCircle className="text-green-500" />
                                  ) : status === 'CANCELLED' ? (
                                    <MdCancel className="text-red-500" />
                                  ) : status === 'WAITING FOR ARTICLE' ? (
                                    <MdOutlineError className="text-yellow-300" />
                                  ) : status === 'IN PROGRESS' ? (
                                    <MdOutlineError className="text-blue-300" />
                                  ) : status === 'WRITING' ? (
                                    <MdOutlineError className="text-green-300" />
                                  ) : status === 'ARTICLE READY' ? (
                                    <MdOutlineError className="text-green-500" />
                                  ) : status === 'IN REVISION' ? (
                                    <MdOutlineError className="text-orange-400" />
                                  ) : status === 'PENDING' ? (
                                    <MdOutlineError className="text-brand-400" />
                                  ) : (
                                    <MdOutlineError className="text-blue-500" />
                                  )}
                                </div>
                                <p className="text-sm font-bold text-navy-700 dark:text-white">
                                  {status}
                                </p>
                              </div>
                            );
                          } else if (cell.column.Header === 'ORDER STATUS') {
                            const orderId = cell.row.original.id;
                            const projectId = cell.row.original.projectId;
                            const articleStatus =
                              cell.row.original.articleStatus;
                            const orderStatus = cell.row.original.orderStatus;
                            const isProvideArticle =
                              articleStatus === 'WAITING FOR ARTICLE';
                            const articleURL = cell.row.original.publicationUrl;
                            const isDraftArticle =
                              orderStatus === 'DRAFT ARTICLE';
                            const isPublicationArticle =
                              orderStatus === 'PUBLICATION URL';
                            const isOrderDone =
                              orderStatus === 'DONE' &&
                              articleStatus === 'DONE';

                            data =
                              isOrderDone && articleURL ? (
                                <div className="flex w-[200px] items-center gap-2">
                                  <a
                                    href={articleURL}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <button className="rounded-full bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700">
                                      Publication URL
                                    </button>
                                  </a>
                                  <MdContentCopy
                                    className="ml-2 cursor-pointer text-xl text-gray-700 dark:text-white"
                                    onClick={() => {
                                      navigator.clipboard.writeText(articleURL);
                                      setCopied((prevCopied) => ({
                                        ...prevCopied,
                                        [orderId]: true,
                                      }));
                                      setTimeout(() => {
                                        setCopied((prevCopied) => ({
                                          ...prevCopied,
                                          [orderId]: false,
                                        }));
                                      }, 500);
                                    }}
                                  />
                                  {copied[orderId] && (
                                    <MdCheck
                                      className="ml-2 text-green-500"
                                      style={{ marginTop: '2px' }}
                                    />
                                  )}
                                  {copied[orderId] && (
                                    <div className="absolute rounded-md bg-gray-800 px-2 py-1 text-white">
                                      Publication URL Copied!
                                    </div>
                                  )}
                                </div>
                              ) : isProvideArticle ? (
                                <ArticleModal
                                  itemId={orderId}
                                  projectId={projectId}
                                  fetchOrders={fetchOrders}
                                />
                              ) : isDraftArticle || isPublicationArticle ? (
                                <ActionModal
                                  article={isDraftArticle}
                                  orderId={orderId}
                                  articleUrl={articleURL}
                                  articleStatus={articleStatus}
                                  toastError={toastError}
                                  toastSuccess={toastSuccess}
                                  fetchOrders={fetchOrders}
                                />
                              ) : orderStatus === 'IN PROGRESS' ? (
                                <div className="flex items-center gap-2 text-xl">
                                  <MdOutlineError className="text-blue-300" />
                                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                                    {orderStatus}
                                  </p>
                                </div>
                              ) : orderStatus === 'PENDING' ? (
                                <div className="flex items-center gap-2 text-xl">
                                  <MdOutlineError className="text-brand-400" />
                                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                                    {orderStatus}
                                  </p>
                                </div>
                              ) : orderStatus === 'CANCELLED' ? (
                                <div className="flex items-center gap-2 text-xl">
                                  <MdCancel className="text-red-500" />
                                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                                    {orderStatus}
                                  </p>
                                </div>
                              ) : orderStatus === 'IN REVISION' ? (
                                <div className="flex items-center gap-2 text-xl">
                                  <MdOutlineError className="text-orange-400" />
                                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                                    {orderStatus}
                                  </p>
                                </div>
                              ) : (
                                <p className="text-sm font-bold text-navy-700 dark:text-white">
                                  Waiting for publication URL
                                </p>
                              );
                          } else if (cell.column.Header === 'PUBLICATION URL') {
                            const orderId = cell.row.original.id;
                            const articleStatus =
                              cell.row.original.articleStatus;
                            const isLocalArticle =
                              cell.value && cell.value.includes('google');
                            data =
                              articleStatus === 'DONE' &&
                              cell.value &&
                              cell.value.includes('https://') ? (
                                <div className="flex items-center gap-2">
                                  <a
                                    href={cell.value}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <button className="rounded-full bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700">
                                      Publication URL
                                    </button>
                                  </a>
                                  <MdContentCopy
                                    className="ml-2 cursor-pointer text-xl text-gray-700 dark:text-white"
                                    onClick={() => {
                                      navigator.clipboard.writeText(cell.value);
                                      setCopied(true);
                                      setTimeout(() => {
                                        setCopied(false);
                                      }, 500);
                                    }}
                                  />
                                  {copied && (
                                    <MdCheck
                                      className="ml-2 text-green-500"
                                      style={{ marginTop: '2px' }}
                                    />
                                  )}
                                  {copied && (
                                    <div className="absolute rounded-md bg-gray-800 px-2 py-1 text-white">
                                      Publication URL Copied!
                                    </div>
                                  )}
                                </div>
                              ) : articleStatus === 'NEED ATTENTION' ? (
                                <ActionModal
                                  article={isLocalArticle}
                                  orderId={orderId}
                                  articleUrl={cell.value}
                                  articleStatus={articleStatus}
                                  toastError={toastError}
                                  toastSuccess={toastSuccess}
                                />
                              ) : articleStatus === 'IN PROGRESS' ? (
                                <button className="rounded-full bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700">
                                  In progress
                                </button>
                              ) : articleStatus === 'CANCELLED' ? (
                                <button className="rounded-full bg-red-500 px-4 py-2 font-bold text-white hover:bg-red-700">
                                  Cancelled
                                </button>
                              ) : null;
                          } else if (cell.column.Header === 'PRICE') {
                            data = (
                              <p className="w-[100px] text-sm font-bold text-navy-700 dark:text-white">
                                {currencySymbol}
                                {cell.value.toLocaleString()}
                              </p>
                            );
                          } else if (cell.column.Header === 'DEADLINE') {
                            data = (
                              <p className="w-[100px] text-sm font-bold text-navy-700 dark:text-white">
                                {cell.value}
                              </p>
                            );
                          } else if (cell.column.Header === 'CHAT') {
                            const itemId = cell.row.original.id;
                            data = (
                              <button
                                onClick={() => handleChatClick(itemId)}
                                className={`linear text-md flex items-center justify-center rounded-full bg-brand-500 p-2 text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
                              >
                                Let's Chat
                              </button>
                            );
                          }
                          return (
                            <td
                              className="pb-[18px] pt-[14px] sm:text-[14px]"
                              {...cell.getCellProps()}
                              key={index}
                            >
                              {data}
                            </td>
                          );
                        })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      {/* pagination */}
      <div className="mt-2 flex h-[80px] w-full items-center justify-between">
        {/* left side */}
        <div className="flex items-center gap-2">
          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
            className="h-10 w-[70px] rounded-xl border border-gray-200 px-2 text-sm  text-gray-600 dark:!border-white/10 dark:!bg-navy-800"
            name=""
            id=""
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </select>
          <p className="text-sm text-gray-700">
            Showing {page.length} of {rows.length}
          </p>
        </div>
        {/* right side */}
        <div className="flex items-center gap-2">
          <button
            onClick={() => previousPage()}
            className={`linear flex items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
          >
            <MdChevronLeft />
          </button>

          <p className="text-sm text-gray-700">
            {state.pageIndex + 1} of{' '}
            {Math.ceil(projectOrders.length / pageSize)}
          </p>

          <button
            onClick={() => nextPage()}
            className={`linear flex items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
          >
            <MdChevronRight />
          </button>
        </div>
      </div>
    </Card>
  );
};

export default OrderTable;
