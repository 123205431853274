import React, { useState, useEffect } from 'react';
import { IoPaperPlane } from 'react-icons/io5';
import Card from 'components/card';
import { get, post, getUserId } from 'api';
import { ToastContainer } from 'react-toastify';
import { toastSuccess } from 'utils/toasts';

const Invite = () => {
  const [copied, setCopied] = useState(false);
  const [referralCode, setReferralCode] = useState(null);
  const [email, setEmail] = useState('');

  useEffect(() => {
    const getReferralCode = async () => {
      const userId = getUserId();
      await get(`/api/user/${userId}`)
        .then((res) => res.json())
        .then((data) => {
          setReferralCode(data.referralCode);
        });
    };
    getReferralCode();
  }, []);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSendInvite = async () => {
    const body = { email, referralCode };
    await post(`/api/referrals/invitation`, body).then((res) => {
      if (res.status === 200) {
        toastSuccess('Invitation has been sent!');
        setEmail('');
      }
    });
  };

  return (
    <Card extra={'w-full h-full pb-[35px] pt-[30px] pr-[23px] pl-[33px]'}>
      <ToastContainer
        position="top-center"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* Header */}
      <div>
        <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
          Invite your friends!
        </h4>
        <p className="mt-3 text-base text-gray-600">
          Add your friends email addresses and sent them invitations to join
          Linkjuice Market.
        </p>
      </div>
      {/* Email input */}

      <div className="mt-[31px] flex w-full items-center gap-3 rounded-full border border-gray-200 py-1 pl-[20px] pr-1 dark:!border-white/10">
        <input
          type="email"
          id="email"
          placeholder="Email address..."
          value={email || ''}
          onChange={handleEmailChange}
          className="placeholder:text-lightFourth h-full w-full text-sm text-navy-700 outline-none dark:!bg-navy-800 dark:text-white"
        />
        <button
          onClick={() => handleSendInvite()}
          className="flex items-center justify-center rounded-full bg-brand-500 p-3 text-base text-white transition duration-200 hover:cursor-pointer  hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
        >
          <IoPaperPlane />
        </button>
      </div>
      {/* share section */}
      <div className="mt-[60px]">
        <p className="text-xl font-bold text-navy-700 dark:text-white">
          Share the referral link
        </p>
        <p className="mt-3 text-base leading-6 text-gray-600">
          You can also share your referral link by copying and sending it to
          your friends directly.
        </p>
      </div>

      {/* links */}
      <div
        onClick={() => {
          setCopied(true);
          navigator.clipboard.writeText(
            `https://app.linkjuice.market/sign-up?referral=${referralCode}`,
          );
          setTimeout(() => {
            setCopied(false);
          }, 2000);
        }}
        className="mt-4 flex h-fit w-full flex-wrap items-start gap-2 md:!flex-nowrap md:items-center lg:justify-between"
      >
        <div className="flex w-full items-center justify-between rounded-full bg-lightPrimary py-3 hover:cursor-pointer dark:!bg-navy-700 3xl:w-full">
          <p className="w-3/4 px-3 text-sm font-medium text-navy-700 dark:text-white">
            app.linkjuice.market/sign-up?referral={referralCode}
          </p>
          <p className="w-1/4 to-brand-500 pr-3 text-end text-xs font-bold text-brand-500 dark:text-white">
            {copied ? 'Link Copied!' : 'Copy Link'}
          </p>
        </div>
      </div>
    </Card>
  );
};

export default Invite;
