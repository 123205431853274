import InvoiceTable from './InvoiceTable';
import { tableColumnsInvoice } from 'views/admin/account/invoice/variables/tableColumnsInvoice';
import tableDataInvoice from 'views/admin/account/invoice/variables/tableDataInvoice.json';

const Content = ({ invoice }) => {
  return (
    <div
      className={`h-full w-full rounded-[20px] bg-white pb-4 font-dm dark:!bg-navy-800`}
    >
      <div className="flex w-full flex-col justify-between rounded-[20px] p-7 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none md:h-[220px] md:flex-row">
        <div className="mb-2">
          <p className="text-base text-gray-600">Invoice for:</p>
          <p className="mb-1.5 mt-[13px] text-lg font-bold text-navy-700 dark:text-white">
            {invoice[0].Company.companyName}
          </p>
          <p className="leading-2 lg:text-md text-base text-gray-600">
            {invoice[0].Company.address1}, {invoice[0].Company.address2}
          </p>
          <p className="leading-2 lg:text-md text-base text-gray-600">
            {invoice[0].Company.zipCode}, {invoice[0].Company.country}
          </p>
          {invoice[0].Company.vatNumber && (
            <p className="leading-2 lg:text-md text-base text-gray-600">
              VAT Number: {invoice[0].Company.vatNumber}
            </p>
          )}
          <p className="leading-2 lg:text-md text-base text-gray-600">
            Company Code: {invoice[0].Company.companyCode}
          </p>
        </div>

        <div className="flex flex-col items-start">
          <p className="mb-2 mr-20 text-base text-gray-600">Amount due</p>
          <p className="mb-1.5 text-[35px] font-bold text-navy-700 dark:text-white lg:text-[32px]">
            {invoice[0].amountPaid.toLocaleString()}{' '}
            {invoice[0].currency.toUpperCase()}
          </p>
          {/* <div className="flex items-center justify-center rounded-[10px] bg-gradient-to-br from-[#FF416C] to-[#FF4B2B] px-2.5 py-1.5 text-base font-medium text-white">
            Due on Sep 30, 2022
          </div> */}
        </div>
      </div>
      {/* table */}
      <div className="mt-4 w-full">
        <InvoiceTable tableData={invoice} columnsData={tableColumnsInvoice} />
      </div>

      {/* Note */}
      <div className="mt-8 flex h-fit flex-col justify-between md:flex-row">
        <div>
          <p className="text-lg font-bold text-navy-700 dark:text-white">
            Note
          </p>
          <p className="w-[290px] text-gray-600">
            If you have any questions about this invoice, please contact
            <span className="font-bold"> Linkjuice market</span>support
            directly.
          </p>
        </div>

        <div className="mt-1 flex flex-col">
          <div className="flex items-center justify-between gap-[120px]">
            <p className="text-base text-navy-700 dark:text-white">Amount</p>
            <p className="text-lg font-bold text-navy-700 dark:text-white">
              {invoice[0].creditsAdded.toLocaleString()}{' '}
              {invoice[0].currency.toUpperCase()}
            </p>
          </div>
          {invoice[0].taxesPaid > 0 && (
            <div className="mt-2 flex items-center justify-between gap-[120px]">
              <p className="text-base text-navy-700 dark:text-white">
                VAT (20%)
              </p>
              <p className="text-lg font-bold text-navy-700 dark:text-white">
                {invoice[0].taxesPaid} {invoice[0].currency.toUpperCase()}
              </p>
            </div>
          )}
          <div className="mt-3 h-px w-full bg-gray-200 dark:bg-white/10 md:w-[230px]" />
          <div className="mt-3 flex items-center justify-between gap-[80px]">
            <p className="text-base text-navy-700 dark:text-white">
              Total amount paid
            </p>
            <p className="text-lg font-bold text-navy-700 dark:text-white">
              {invoice[0].amountPaid} {invoice[0].currency.toUpperCase()}
            </p>
          </div>
          <div className="mt-1 flex items-center justify-between gap-[80px]">
            <p className="text-base text-navy-700 dark:text-white">Paid date</p>
            <p className="text-md font-bold text-navy-700 dark:text-white">
              {new Date(invoice[0].createdAt).toISOString().slice(0, 10)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
