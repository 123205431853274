export const orderManagerHeader = [
  {
    Header: 'CLIENT PROJECT',
    accessor: 'projectName',
    showColumn: false,
    editable: true,
    admin: true,
  },
  {
    Header: 'DOMAIN',
    accessor: 'domain',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'URL',
    accessor: 'urls',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'ANCHORS',
    accessor: 'anchors',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'ARTICLE STATUS',
    accessor: 'articleStatus',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'ORDER STATUS',
    accessor: 'orderStatus',
    showColumn: true,
    editable: true,
    admin: false,
  },
  // {
  //   Header: 'PUBLICATION URL',
  //   accessor: 'publicationUrl',
  //   showColumn: true,
  //   editable: true,
  //   admin: false,
  // },
  {
    Header: 'PROVIDED ARTICLE',
    accessor: 'providedArticleUrl',
    showColumn: false,
    editable: true,
    admin: true,
  },
  {
    Header: 'CLIENT APPROVAL',
    accessor: 'articleApproval',
    showColumn: false,
    editable: true,
    admin: true,
  },
  {
    Header: 'PRICE',
    accessor: 'totalPrice',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'DEADLINE',
    accessor: 'deadline',
    showColumn: false,
    editable: true,
    admin: false,
  },
  {
    Header: 'CHAT',
    accessor: 'chat',
    showColumn: true,
    editable: false,
    admin: false,
  },
  {
    Header: 'UPDATE',
    accessor: 'update',
    showColumn: false,
    editable: true,
    admin: true,
  },
];
