import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { get } from 'api';

const DomainTags = ({
  projectInfo,
  updateProjectInfo,
  setNewCategories,
  setNewCountries,
  setNewLanguages,
  setDeletedCategories,
  setDeletedCountries,
  setDeletedLanguages,
  originalCategories,
  originalCountries,
  originalLanguages,
}) => {
  const { categories, countries, languages } = projectInfo;
  const [category, setCategory] = useState([]);
  const [country, setCountry] = useState([]);
  const [language, setLanguage] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      await get('/api/admin/categories')
        .then((res) => res.json())
        .then((data) => {
          data.map((item) => {
            setCategory((prev) => [
              ...prev,
              { id: item.id, value: item.category, label: item.category },
            ]);
          });
        });
      await get('/api/admin/countries')
        .then((res) => res.json())
        .then((data) => {
          data.map((item) => {
            setCountry((prev) => [
              ...prev,
              { id: item.id, value: item.country, label: item.country },
            ]);
          });
        });
      await get('/api/admin/languages')
        .then((res) => res.json())
        .then((data) => {
          data.map((item) => {
            setLanguage((prev) => [
              ...prev,
              { id: item.id, value: item.language, label: item.language },
            ]);
          });
        });
    };
    fetchData();
  }, []);

  const handleCategoryChange = (selectedOptions) => {
    const newCategoriesAdded = selectedOptions.filter(
      (option) => !originalCategories.includes(option),
    );
    const originalCategoriesIds = originalCategories.map(
      (category) => category.id,
    );
    const newCategoriesIds = newCategoriesAdded.map((category) => category.id);
    const newCategoriesToBeAdded = newCategoriesIds.filter(
      (id) => !originalCategoriesIds.includes(id),
    );
    setNewCategories(newCategoriesToBeAdded);
    const deletedCategoriesRemoved = selectedOptions.filter((category) =>
      originalCategories.includes(category),
    );
    const deletedCategoriesIds = deletedCategoriesRemoved.map(
      (category) => category.id,
    );
    const deletedCategoriesIdsToRemove = originalCategoriesIds.filter(
      (id) => !deletedCategoriesIds.includes(id),
    );
    const currentCategories = selectedOptions.map((category) => category.id);
    const deletedCategoriesToBeRemoved = deletedCategoriesIdsToRemove.filter(
      (id) => !currentCategories.includes(id),
    );
    setDeletedCategories(deletedCategoriesToBeRemoved);
    updateProjectInfo({ categories: selectedOptions });
  };

  const handleCountryChange = (selectedOptions) => {
    const newCountriesAdded = selectedOptions.filter(
      (option) => !originalCountries.includes(option),
    );
    const originalCountriesIds = originalCountries.map((country) => country.id);
    const newCountriesIds = newCountriesAdded.map((country) => country.id);
    const newCountriesToBeAdded = newCountriesIds.filter(
      (id) => !originalCountriesIds.includes(id),
    );
    setNewCountries(newCountriesToBeAdded);
    const deletedCountriesRemoved = selectedOptions.filter((country) =>
      originalCountries.includes(country),
    );
    const deletedCountriesIds = deletedCountriesRemoved.map(
      (country) => country.id,
    );
    const deletedCountriesIdsToRemove = originalCountriesIds.filter(
      (id) => !deletedCountriesIds.includes(id),
    );
    const currentCountries = selectedOptions.map((country) => country.id);
    const deletedCountriesToBeRemoved = deletedCountriesIdsToRemove.filter(
      (id) => !currentCountries.includes(id),
    );
    setDeletedCountries(deletedCountriesToBeRemoved);
    updateProjectInfo({ countries: selectedOptions });
  };

  const handleLanguageChange = (selectedOptions) => {
    const newLanguagesAdded = selectedOptions.filter(
      (option) => !originalLanguages.includes(option),
    );
    const originalLanguagesIds = originalLanguages.map(
      (language) => language.id,
    );
    const newLanguagesIds = newLanguagesAdded.map((language) => language.id);
    const newLanguagesToBeAdded = newLanguagesIds.filter(
      (id) => !originalLanguagesIds.includes(id),
    );
    setNewLanguages(newLanguagesToBeAdded);
    const deletedLanguagesRemoved = selectedOptions.filter((language) =>
      originalLanguages.includes(language),
    );
    const deletedLanguagesIds = deletedLanguagesRemoved.map(
      (language) => language.id,
    );
    const deletedLanguagesIdsToRemove = originalLanguagesIds.filter(
      (id) => !deletedLanguagesIds.includes(id),
    );
    const currentLanguages = selectedOptions.map((language) => language.id);
    const deletedLanguagesToBeRemoved = deletedLanguagesIdsToRemove.filter(
      (id) => !currentLanguages.includes(id),
    );
    setDeletedLanguages(deletedLanguagesToBeRemoved);
    updateProjectInfo({ languages: selectedOptions });
  };

  return (
    <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
      {/* Header */}
      <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
        Domain tags
      </h4>

      {/* content */}
      <div className="mt-4">
        <div>
          <p className="mb-2 ml-3 text-sm font-bold text-navy-700 dark:text-white">
            Category
          </p>
          <Select
            isMulti
            options={category}
            value={categories}
            onChange={handleCategoryChange}
            name="categories"
            closeMenuOnSelect={false}
          />
        </div>
        <div className="mt-2">
          <p className="mb-2 ml-3 text-sm font-bold text-navy-700 dark:text-white">
            Countries
          </p>
          <Select
            isMulti
            options={country}
            value={countries}
            onChange={handleCountryChange}
            name="countries"
            closeMenuOnSelect={false}
          />
        </div>
        <div className="mt-2">
          <p className="mb-2 ml-3 text-sm font-bold text-navy-700 dark:text-white">
            Languages
          </p>
          <Select
            isMulti
            options={language}
            value={languages}
            onChange={handleLanguageChange}
            name="languages"
            closeMenuOnSelect={false}
          />
        </div>
      </div>
    </div>
  );
};

export default DomainTags;
