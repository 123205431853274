import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import { CartProvider } from 'contexts/CartContext';
import { ProjectProvider } from 'contexts/ProjectContext';
import { ColumnsViewProvider } from 'contexts/ColumnsViewContext';
import ScrollToTop from 'utils/scrollTop';
import App from './App';
import { FavoritesProvider } from 'contexts/FavoritesContext';
import { BlacklistProvider } from 'contexts/BlacklistContext';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <ScrollToTop />
    <ProjectProvider>
      <CartProvider>
        <ColumnsViewProvider>
          <FavoritesProvider>
            <BlacklistProvider>
              <App />
            </BlacklistProvider>
          </FavoritesProvider>
        </ColumnsViewProvider>
      </CartProvider>
    </ProjectProvider>
  </BrowserRouter>,
);
