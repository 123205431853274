import React from 'react';
import ContentLoader from 'react-content-loader';

const DataTable = (props) => (
  <ContentLoader width={1500} height={400} viewBox="0 0 1500 400" {...props}>
    <rect x="0" y="32" rx="10" ry="10" width="250" height="19" />
    <rect x="290" y="32" rx="10" ry="10" width="100" height="19" />
    <rect x="430" y="32" rx="10" ry="10" width="60" height="19" />
    <rect x="530" y="32" rx="10" ry="10" width="60" height="19" />
    <rect x="630" y="32" rx="10" ry="10" width="60" height="19" />
    <rect x="730" y="32" rx="10" ry="10" width="60" height="19" />
    <rect x="830" y="32" rx="10" ry="10" width="60" height="19" />
    <rect x="930" y="32" rx="10" ry="10" width="60" height="19" />
    <rect x="1030" y="32" rx="10" ry="10" width="100" height="19" />
    <rect x="1170" y="32" rx="10" ry="10" width="100" height="19" />

    <rect x="0" y="80" rx="4" ry="4" width="20" height="19" />
    <circle cx="35" cy="90" r="8" />
    <rect x="50" y="80" rx="8" ry="8" width="20" height="19" />
    <rect x="80" y="80" rx="10" ry="10" width="170" height="19" />
    <rect x="290" y="80" rx="10" ry="10" width="100" height="19" />
    <rect x="430" y="80" rx="10" ry="10" width="60" height="19" />
    <rect x="530" y="80" rx="10" ry="10" width="60" height="19" />
    <rect x="630" y="80" rx="10" ry="10" width="60" height="19" />
    <rect x="730" y="80" rx="10" ry="10" width="60" height="19" />
    <rect x="830" y="80" rx="10" ry="10" width="60" height="19" />
    <rect x="930" y="80" rx="10" ry="10" width="60" height="19" />
    <rect x="1030" y="80" rx="10" ry="10" width="100" height="19" />
    <rect x="1170" y="80" rx="10" ry="10" width="100" height="19" />
    <circle cx="1310" cy="90" r="12" />
    <rect x="1330" y="78" rx="5" ry="5" width="6" height="25" />
    <circle cx="1357" cy="90" r="12" />

    <rect x="0" y="128" rx="4" ry="4" width="20" height="19" />
    <circle cx="35" cy="138" r="8" />
    <rect x="50" y="128" rx="8" ry="8" width="20" height="19" />
    <rect x="80" y="128" rx="10" ry="10" width="170" height="19" />
    <rect x="290" y="128" rx="10" ry="10" width="100" height="19" />
    <rect x="430" y="128" rx="10" ry="10" width="60" height="19" />
    <rect x="530" y="128" rx="10" ry="10" width="60" height="19" />
    <rect x="630" y="128" rx="10" ry="10" width="60" height="19" />
    <rect x="730" y="128" rx="10" ry="10" width="60" height="19" />
    <rect x="830" y="128" rx="10" ry="10" width="60" height="19" />
    <rect x="930" y="128" rx="10" ry="10" width="60" height="19" />
    <rect x="1030" y="128" rx="10" ry="10" width="100" height="19" />
    <rect x="1170" y="128" rx="10" ry="10" width="100" height="19" />
    <circle cx="1310" cy="138" r="12" />
    <rect x="1330" y="126" rx="5" ry="5" width="6" height="25" />
    <circle cx="1357" cy="138" r="12" />

    <rect x="0" y="176" rx="4" ry="4" width="20" height="19" />
    <circle cx="35" cy="186" r="8" />
    <rect x="50" y="176" rx="8" ry="8" width="20" height="19" />
    <rect x="80" y="176" rx="10" ry="10" width="170" height="19" />
    <rect x="290" y="176" rx="10" ry="10" width="100" height="19" />
    <rect x="430" y="176" rx="10" ry="10" width="60" height="19" />
    <rect x="530" y="176" rx="10" ry="10" width="60" height="19" />
    <rect x="630" y="176" rx="10" ry="10" width="60" height="19" />
    <rect x="730" y="176" rx="10" ry="10" width="60" height="19" />
    <rect x="830" y="176" rx="10" ry="10" width="60" height="19" />
    <rect x="930" y="176" rx="10" ry="10" width="60" height="19" />
    <rect x="1030" y="176" rx="10" ry="10" width="100" height="19" />
    <rect x="1170" y="176" rx="10" ry="10" width="100" height="19" />
    <circle cx="1310" cy="186" r="12" />
    <rect x="1330" y="174" rx="5" ry="5" width="6" height="25" />
    <circle cx="1357" cy="186" r="12" />

    <rect x="0" y="224" rx="4" ry="4" width="20" height="19" />
    <circle cx="35" cy="234" r="8" />
    <rect x="50" y="224" rx="8" ry="8" width="20" height="19" />
    <rect x="80" y="224" rx="10" ry="10" width="170" height="19" />
    <rect x="290" y="224" rx="10" ry="10" width="100" height="19" />
    <rect x="430" y="224" rx="10" ry="10" width="60" height="19" />
    <rect x="530" y="224" rx="10" ry="10" width="60" height="19" />
    <rect x="630" y="224" rx="10" ry="10" width="60" height="19" />
    <rect x="730" y="224" rx="10" ry="10" width="60" height="19" />
    <rect x="830" y="224" rx="10" ry="10" width="60" height="19" />
    <rect x="930" y="224" rx="10" ry="10" width="60" height="19" />
    <rect x="1030" y="224" rx="10" ry="10" width="100" height="19" />
    <rect x="1170" y="224" rx="10" ry="10" width="100" height="19" />
    <circle cx="1310" cy="234" r="12" />
    <rect x="1330" y="222" rx="5" ry="5" width="6" height="25" />
    <circle cx="1357" cy="234" r="12" />

    <rect x="0" y="272" rx="4" ry="4" width="20" height="19" />
    <circle cx="35" cy="282" r="8" />
    <rect x="50" y="272" rx="8" ry="8" width="20" height="19" />
    <rect x="80" y="272" rx="10" ry="10" width="170" height="19" />
    <rect x="290" y="272" rx="10" ry="10" width="100" height="19" />
    <rect x="430" y="272" rx="10" ry="10" width="60" height="19" />
    <rect x="530" y="272" rx="10" ry="10" width="60" height="19" />
    <rect x="630" y="272" rx="10" ry="10" width="60" height="19" />
    <rect x="730" y="272" rx="10" ry="10" width="60" height="19" />
    <rect x="830" y="272" rx="10" ry="10" width="60" height="19" />
    <rect x="930" y="272" rx="10" ry="10" width="60" height="19" />
    <rect x="1030" y="272" rx="10" ry="10" width="100" height="19" />
    <rect x="1170" y="272" rx="10" ry="10" width="100" height="19" />
    <circle cx="1310" cy="282" r="12" />
    <rect x="1330" y="270" rx="5" ry="5" width="6" height="25" />
    <circle cx="1357" cy="282" r="12" />

    <rect x="0" y="320" rx="4" ry="4" width="20" height="19" />
    <circle cx="35" cy="330" r="8" />
    <rect x="50" y="320" rx="8" ry="8" width="20" height="19" />
    <rect x="80" y="320" rx="10" ry="10" width="170" height="19" />
    <rect x="290" y="320" rx="10" ry="10" width="100" height="19" />
    <rect x="430" y="320" rx="10" ry="10" width="60" height="19" />
    <rect x="530" y="320" rx="10" ry="10" width="60" height="19" />
    <rect x="630" y="320" rx="10" ry="10" width="60" height="19" />
    <rect x="730" y="320" rx="10" ry="10" width="60" height="19" />
    <rect x="830" y="320" rx="10" ry="10" width="60" height="19" />
    <rect x="930" y="320" rx="10" ry="10" width="60" height="19" />
    <rect x="1030" y="320" rx="10" ry="10" width="100" height="19" />
    <rect x="1170" y="320" rx="10" ry="10" width="100" height="19" />
    <circle cx="1310" cy="330" r="12" />
    <rect x="1330" y="318" rx="5" ry="5" width="6" height="25" />
    <circle cx="1357" cy="330" r="12" />

    <rect x="0" y="368" rx="4" ry="4" width="20" height="19" />
    <circle cx="35" cy="378" r="8" />
    <rect x="50" y="368" rx="8" ry="8" width="20" height="19" />
    <rect x="80" y="368" rx="10" ry="10" width="170" height="19" />
    <rect x="290" y="368" rx="10" ry="10" width="100" height="19" />
    <rect x="430" y="368" rx="10" ry="10" width="60" height="19" />
    <rect x="530" y="368" rx="10" ry="10" width="60" height="19" />
    <rect x="630" y="368" rx="10" ry="10" width="60" height="19" />
    <rect x="730" y="368" rx="10" ry="10" width="60" height="19" />
    <rect x="830" y="368" rx="10" ry="10" width="60" height="19" />
    <rect x="930" y="368" rx="10" ry="10" width="60" height="19" />
    <rect x="1030" y="368" rx="10" ry="10" width="100" height="19" />
    <rect x="1170" y="368" rx="10" ry="10" width="100" height="19" />
    <circle cx="1310" cy="378" r="12" />
    <rect x="1330" y="366" rx="5" ry="5" width="6" height="25" />
    <circle cx="1357" cy="378" r="12" />
  </ContentLoader>
);

export default DataTable;
