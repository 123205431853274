import React from 'react';
import InputField from 'components/fields/InputField';
import Radio from 'components/radio';

const CompanyDetails = ({ billingInfo, updateBillingInfo }) => {
  const { companyName, billingEmail, vatNumber, companyCode, isVatPayer } =
    billingInfo;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    updateBillingInfo({ [name]: value });
  };

  return (
    <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
      {/* Header */}
      <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
        Company Details
      </h4>
      {/* inputs */}
      <div className="mt-4">
        <InputField
          label="Company Name*"
          placeholder="eg. Company Name"
          id="companyName"
          name="companyName"
          onChange={handleInputChange}
          value={companyName || ''}
        />
      </div>
      <div className="mt-2">
        <InputField
          label="Billing Email*"
          placeholder="eg. email@company.com"
          id="billingEmail"
          name="billingEmail"
          onChange={handleInputChange}
          value={billingEmail || ''}
        />
      </div>
      <div className="mt-2">
        <InputField
          label="Company Code*"
          placeholder="eg. 123456789"
          id="companyCode"
          name="companyCode"
          onChange={handleInputChange}
          value={companyCode || ''}
        />
      </div>
      <div className="mt-2">
        <label
          htmlFor="vatNumber"
          className="ml-3 text-sm font-bold text-navy-700 dark:text-white"
        >
          Are you a VAT payer?* (Client without VAT number will be charged 20%
          additionally)
        </label>
        <div className="mt-2 flex items-center">
          <div className="mt-2 flex items-center">
            <Radio
              id="vatNumber"
              color="blue"
              name="vatNumber"
              checked={isVatPayer === true || false}
              onChange={() => updateBillingInfo({ isVatPayer: !isVatPayer })}
            />
            <span className="ml-2 text-sm font-bold text-navy-700 dark:text-white">
              Yes
            </span>
          </div>
          <div className="ml-8 mt-2 flex items-center">
            <Radio
              id="vatNumber"
              color="blue"
              checked={isVatPayer === false || false}
              onChange={() => updateBillingInfo({ isVatPayer: !isVatPayer })}
            />
            <span className="ml-2 text-sm font-bold text-navy-700 dark:text-white">
              No
            </span>
          </div>
        </div>
        {isVatPayer && (
          <InputField
            isLabel={false}
            placeholder="VAT Number"
            id="vatNumber"
            name="vatNumber"
            onChange={handleInputChange}
            value={vatNumber || ''}
            extra="mt-4"
          />
        )}
      </div>
    </div>
  );
};

export default CompanyDetails;
