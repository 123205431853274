import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalBody } from '@chakra-ui/modal';
import { useDisclosure } from '@chakra-ui/hooks';
import Card from 'components/card';
import DraftReview from './DraftReview';
import PublicationReview from './PublicationReview';

const ActionModal = ({
  article,
  articleUrl,
  orderId,
  toastSuccess,
  toastError,
  fetchOrders,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <div>
      <button
        onClick={onOpen}
        className="flex h-7 items-center justify-center rounded-md bg-blue-500 px-4 py-2 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        role="menuitem"
      >
        Action
      </button>
      <Modal className="!z-[100]" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay className="bg-[#000] !opacity-30" />
        <ModalContent className="top-[22vh] !m-auto md:top-[12vh] md:!max-w-[40%]">
          <ModalBody>
            <Card extra={'w-full sm:overflow-auto p-4 pt-4'}>
              {article && (
                <DraftReview
                  article={article}
                  articleUrl={articleUrl}
                  orderId={orderId}
                  onClose={onClose}
                  toastSuccess={toastSuccess}
                  toastError={toastError}
                  fetchOrders={fetchOrders}
                />
              )}
              {!article && (
                <PublicationReview
                  article={article}
                  articleUrl={articleUrl}
                  orderId={orderId}
                  onClose={onClose}
                  toastSuccess={toastSuccess}
                  toastError={toastError}
                  fetchOrders={fetchOrders}
                />
              )}
              <div className="mt-6 flex justify-end">
                <button
                  onClick={onClose}
                  className="bg-transparent rounded-md border border-gray-500 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
                >
                  Cancel
                </button>
              </div>
            </Card>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ActionModal;
