import React, { useState } from 'react';
import moment from 'moment';
import { Modal, ModalOverlay, ModalContent, ModalBody } from '@chakra-ui/modal';
import { useDisclosure } from '@chakra-ui/hooks';
import Card from 'components/card';
import Checkbox from 'components/checkbox';
import InputField from 'components/fields/InputField';
import { MdEdit } from 'react-icons/md';

const DatesModal = ({ startDate, setStartDate, endDate, setEndDate }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedRange, setSelectedRange] = useState('3');
  const [customStartDate, setCustomStartDate] = useState('');
  const [customEndDate, setCustomEndDate] = useState('');
  const [selectedDateRange, setSelectedDateRange] = useState('Last 3 months');

  const handleSave = () => {
    switch (selectedRange) {
      case '1':
        setStartDate(moment().subtract(9, 'days').format('YYYY-MM-DD'));
        setEndDate(moment().subtract(2, 'days').format('YYYY-MM-DD'));
        setSelectedDateRange('Last 7 days');
        break;
      case '2':
        setStartDate(moment().subtract(29, 'days').format('YYYY-MM-DD'));
        setEndDate(moment().subtract(2, 'days').format('YYYY-MM-DD'));
        setSelectedDateRange('Last 28 days');
        break;
      case '3':
        setStartDate(
          moment()
            .subtract(2, 'days')
            .subtract(3, 'months')
            .format('YYYY-MM-DD'),
        );
        setEndDate(moment().subtract(2, 'days').format('YYYY-MM-DD'));
        setSelectedDateRange('Last 3 months');
        break;
      case '4':
        setStartDate(
          moment()
            .subtract(2, 'days')
            .subtract(6, 'months')
            .format('YYYY-MM-DD'),
        );
        setEndDate(moment().subtract(2, 'days').format('YYYY-MM-DD'));
        setSelectedDateRange('Last 6 months');
        break;
      case '5':
        setStartDate(
          moment()
            .subtract(2, 'days')
            .subtract(12, 'months')
            .format('YYYY-MM-DD'),
        );
        setEndDate(moment().subtract(2, 'days').format('YYYY-MM-DD'));
        setSelectedDateRange('Last 12 months');
        break;
      case '6':
        setStartDate(
          moment()
            .subtract(2, 'days')
            .subtract(16, 'months')
            .format('YYYY-MM-DD'),
        );
        setEndDate(moment().subtract(2, 'days').format('YYYY-MM-DD'));
        setSelectedDateRange('Last 16 months');
        break;
      case '7':
        setStartDate(moment(customStartDate).format('YYYY-MM-DD'));
        setEndDate(moment(customEndDate).format('YYYY-MM-DD'));
        setSelectedDateRange(
          `${moment(customStartDate).format('MMM D, YYYY')} - ${moment(
            customEndDate,
          ).format('MMM D, YYYY')}`,
        );
        break;
      default:
        break;
    }
    onClose();
  };

  const handleReset = () => {
    onClose();
  };

  const handleCheckboxChange = (e) => {
    setSelectedRange(e.target.id);
  };

  const handleCustomStartDateChange = (e) => {
    if (e.target.id === 'startDate') {
      setCustomStartDate(e.target.value);
    }
    if (e.target.id === 'endDate') {
      setCustomEndDate(e.target.value);
    }
  };

  return (
    <div>
      <button
        onClick={onOpen}
        className="text-md flex h-auto items-center rounded-full bg-blue-500 px-4 py-2 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 md:ml-4"
      >
        Date:{' '}
        {selectedDateRange === '' ? 'Select Date Range' : selectedDateRange}
        <span className="ml-2">
          <MdEdit />
        </span>
      </button>

      <Modal className="!z-[100]" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay className="bg-[#000] !opacity-30" />
        <ModalContent className="top-[22vh] !m-auto !w-max !max-w-[85%] md:top-[7vh]">
          <ModalBody>
            <Card extra={'w-full sm:overflow-auto p-4 pt-4'}>
              <div className="mt-5 flex flex-col">
                <div className="text-2xl dark:text-white">Date Range</div>
                <div className="mt-1 flex flex-col">
                  <div className="mt-1 flex">
                    <Checkbox
                      label="Last 7 days"
                      checked={selectedRange === '1' ? true : false}
                      onChange={handleCheckboxChange}
                      id="1"
                    />
                    <p className="ml-2 text-gray-800 dark:text-gray-400">
                      Last 7 days
                    </p>
                  </div>
                  <div className="mt-1 flex">
                    <Checkbox
                      label="Last 28 days"
                      checked={selectedRange === '2' ? true : false}
                      onChange={handleCheckboxChange}
                      id="2"
                    />
                    <p className="ml-2 text-gray-800 dark:text-gray-400">
                      Last 28 days
                    </p>
                  </div>
                  <div className="mt-1 flex">
                    <Checkbox
                      label="Last 3 months"
                      checked={selectedRange === '3' ? true : false}
                      onChange={handleCheckboxChange}
                      id="3"
                    />
                    <p className="ml-2 text-gray-800 dark:text-gray-400">
                      Last 3 months
                    </p>
                  </div>
                  <div className="mt-1 flex">
                    <Checkbox
                      label="Last 6 months"
                      checked={selectedRange === '4' ? true : false}
                      onChange={handleCheckboxChange}
                      id="4"
                    />
                    <p className="ml-2 text-gray-800 dark:text-gray-400">
                      Last 6 months
                    </p>
                  </div>
                  <div className="mt-1 flex">
                    <Checkbox
                      label="Last 12 months"
                      checked={selectedRange === '5' ? true : false}
                      onChange={handleCheckboxChange}
                      id="5"
                    />
                    <p className="ml-2 text-gray-800 dark:text-gray-400">
                      Last 12 months
                    </p>
                  </div>
                  <div className="mt-1 flex">
                    <Checkbox
                      label="Last 16 months"
                      checked={selectedRange === '6' ? true : false}
                      onChange={handleCheckboxChange}
                      id="6"
                    />
                    <p className="ml-2 text-gray-800 dark:text-gray-400">
                      Last 16 months
                    </p>
                  </div>
                  <div className="mt-3 flex flex-col">
                    <div className="flex">
                      <Checkbox
                        label="Last 16 months"
                        checked={selectedRange === '7' ? true : false}
                        onChange={handleCheckboxChange}
                        id="7"
                      />
                      <p className="ml-2 text-gray-800 dark:text-gray-400">
                        Custom date range
                      </p>
                    </div>
                    <div className="mt-2 flex">
                      <InputField
                        label="Start Date"
                        value={
                          selectedRange === '7' ? customStartDate : startDate
                        }
                        type="date"
                        id="startDate"
                        onChange={handleCustomStartDateChange}
                        extra="mr-2"
                      />
                      <InputField
                        label="End Date"
                        value={selectedRange === '7' ? customEndDate : endDate}
                        type="date"
                        id="endDate"
                        onChange={handleCustomStartDateChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5 flex justify-start">
                <button
                  onClick={() => handleSave()}
                  className="rounded-md bg-blue-500 px-4 py-2 text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                >
                  APPLY
                </button>
                <button
                  onClick={() => handleReset()}
                  className="bg-transparent ml-3 rounded-md border border-gray-500 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
                >
                  CANCEL
                </button>
              </div>
            </Card>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default DatesModal;
