import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { get } from 'api';

const DomainTags = ({ projectInfo, updateProjectInfo }) => {
  const { categories, countries, languages } = projectInfo;
  const [category, setCategory] = useState([]);
  const [country, setCountry] = useState([]);
  const [language, setLanguage] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      await get('/api/admin/categories')
        .then((res) => res.json())
        .then((data) => {
          data.map((item) => {
            setCategory((prev) => [
              ...prev,
              { id: item.id, value: item.category, label: item.category },
            ]);
          });
        });
      await get('/api/admin/countries')
        .then((res) => res.json())
        .then((data) => {
          data.map((item) => {
            setCountry((prev) => [
              ...prev,
              { id: item.id, value: item.country, label: item.country },
            ]);
          });
        });
      await get('/api/admin/languages')
        .then((res) => res.json())
        .then((data) => {
          data.map((item) => {
            setLanguage((prev) => [
              ...prev,
              { id: item.id, value: item.language, label: item.language },
            ]);
          });
        });
    };
    fetchData();
  }, []);

  const handleCategoryChange = (selectedOptions) => {
    updateProjectInfo({ categories: selectedOptions });
  };

  const handleCountryChange = (selectedOptions) => {
    updateProjectInfo({ countries: selectedOptions });
  };

  const handleLanguageChange = (selectedOptions) => {
    updateProjectInfo({ languages: selectedOptions });
  };

  return (
    <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
      {/* Header */}
      <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
        Domain tags
      </h4>

      {/* content */}
      <div className="mt-4">
        <div>
          <p className="mb-2 ml-3 text-sm font-bold text-navy-700 dark:text-white">
            Category
          </p>
          <Select
            isMulti
            options={category}
            value={categories || ''}
            onChange={handleCategoryChange}
            name="categories"
            closeMenuOnSelect={false}
          />
        </div>
        <div className="mt-2">
          <p className="mb-2 ml-3 text-sm font-bold text-navy-700 dark:text-white">
            Countries
          </p>
          <Select
            isMulti
            options={country}
            value={countries || ''}
            onChange={handleCountryChange}
            name="countries"
            closeMenuOnSelect={false}
          />
        </div>
        <div className="mt-2">
          <p className="mb-2 ml-3 text-sm font-bold text-navy-700 dark:text-white">
            Languages
          </p>
          <Select
            isMulti
            options={language}
            value={languages || ''}
            onChange={handleLanguageChange}
            name="languages"
            closeMenuOnSelect={false}
          />
        </div>
      </div>
    </div>
  );
};

export default DomainTags;
