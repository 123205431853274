export const backlinksHeaders = [
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'DOMAIN NAME',
    accessor: 'articleDomain',
  },
  {
    Header: 'URL',
    accessor: 'targetURL',
  },
  {
    Header: 'ANCHOR',
    accessor: 'targetAnchor',
  },
  {
    Header: 'ANCHOR FOUND',
    accessor: 'anchorFound',
  },
  {
    Header: 'BACKLINK STATUS',
    accessor: 'backlinkStatus',
  },
  {
    Header: 'ACTIONS',
    accessor: 'delete',
  },
];
