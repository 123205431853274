export const tableColumnsInvoice = [
  {
    Header: 'Item',
    accessor: 'item',
  },
  {
    Header: 'Credits',
    accessor: 'creditsAdded',
  },
  {
    Header: 'Rate',
    accessor: 'rate',
  },
  {
    Header: 'Amount',
    accessor: 'amount',
  },
];
