import React, { useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalBody } from '@chakra-ui/modal';
import { useDisclosure } from '@chakra-ui/hooks';
import Card from 'components/card';
import HireForm from './HireForm';
import ProvideContentForm from './ProvideContentForm';
import { toastError } from 'utils/toasts';
import { ToastContainer } from 'react-toastify';

const ArticleModal = ({
  itemId,
  handleHireArticleData,
  hireContentWriterData,
  provideContentData,
  handleProvideContentData,
  handleCancelButton,
  handleApproveButton,
  setArticleStatus,
  articleWordCount,
  articleCondition,
}) => {
  const {
    anchors,
    wordCount,
    topicSuggestion,
    instructions,
    provideLater: hireContentLater,
  } = hireContentWriterData[itemId] || {};

  const {
    anchors: provideAnchors,
    instructions: provideInstructions,
    uploadFiles,
    provideLater: provideContentLater,
  } = provideContentData[itemId] || {};
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activeFilter, setActiveFilter] = useState('hire');

  const handleApproveClick = () => {
    if (activeFilter === 'hire') {
      if (hireContentLater) {
        const status = 'LATER';
        setArticleStatus((prevData) => {
          const updatedData = { ...prevData };
          if (updatedData[itemId]) {
            updatedData[itemId] = {
              ...updatedData[itemId],
              articleStatus: status,
            };
          } else {
            updatedData[itemId] = {
              itemId: itemId,
              articleStatus: status,
            };
          }
          return updatedData;
        });
        handleApproveButton(itemId, 'later');
        onClose();
        return;
      }
      const missingFields = [];
      if (!anchors) missingFields.push('anchors');
      if (!wordCount) missingFields.push('word count');
      if (!topicSuggestion) missingFields.push('topic suggestion');
      if (!instructions) missingFields.push('instructions');
      if (instructions && instructions.length > 2500) {
        toastError('Instructions should be less than 2500 characters');
        return;
      }
      if (missingFields.length > 0) {
        const missingFieldsString = missingFields.join(', ');
        if (missingFieldsString === 'anchors') {
          toastError(
            `Please fill both URL and Anchor before clicking the 'Add' button.`,
          );
          return;
        } else if (missingFieldsString === 'word count') {
          toastError(`Please select the word count.`);
          return;
        } else {
          toastError(
            `Please fill in all the fields before clicking the 'Submit' button. Ensure that you haven't missed any information, and then proceed to click 'Submit' to complete the process.`,
          );
          return;
        }
      } else {
        const status = 'HIRED';
        setArticleStatus((prevData) => {
          const updatedData = { ...prevData };
          if (updatedData[itemId]) {
            updatedData[itemId] = {
              ...updatedData[itemId],
              articleStatus: status,
            };
          } else {
            updatedData[itemId] = {
              itemId: itemId,
              articleStatus: status,
            };
          }
          return updatedData;
        });
        handleApproveButton(itemId, activeFilter);
        onClose();
      }
    } else if (activeFilter === 'provide') {
      const missingFields = [];
      if (provideContentLater) {
        const status = 'LATER';
        setArticleStatus((prevData) => {
          const updatedData = { ...prevData };
          if (updatedData[itemId]) {
            updatedData[itemId] = {
              ...updatedData[itemId],
              articleStatus: status,
            };
          } else {
            updatedData[itemId] = {
              itemId: itemId,
              articleStatus: status,
            };
          }
          return updatedData;
        });
        handleApproveButton(itemId, 'later');
        onClose();
        return;
      }
      if (!provideAnchors) missingFields.push('anchors');
      if (!uploadFiles) missingFields.push('upload files');
      if (provideInstructions && provideInstructions.length > 2500) {
        toastError('Instructions should be less than 2500 characters');
        return;
      }
      if (missingFields.length > 0) {
        const missingFieldsString = missingFields.join(', ');
        if (missingFieldsString === 'anchors') {
          toastError(
            `Please fill both URL and Anchor before clicking the 'Add' button.`,
          );
          return;
        } else if (missingFieldsString === 'upload files') {
          toastError(
            `Please upload the file before clicking the 'Submit' button.`,
          );
          return;
        } else {
          toastError(
            `Please fill in all the fields before clicking the 'Submit' button. Ensure that you haven't missed any information, and then proceed to click 'Submit' to complete the process.`,
          );
          return;
        }
      } else {
        const status = 'PROVIDED';
        setArticleStatus((prevData) => {
          const updatedData = { ...prevData };
          if (updatedData[itemId]) {
            updatedData[itemId] = {
              ...updatedData[itemId],
              articleStatus: status,
            };
          } else {
            updatedData[itemId] = {
              itemId: itemId,
              articleStatus: status,
            };
          }
          return updatedData;
        });
        handleApproveButton(itemId, activeFilter);
        onClose();
      }
    }
  };

  return (
    <div>
      <button
        onClick={onOpen}
        className="flex h-7 items-center justify-center rounded-md bg-blue-500 px-4 py-2 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        role="menuitem"
      >
        Action
      </button>
      <Modal className="!z-[100]" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay className="bg-[#000] !opacity-30" />
        <ModalContent className="top-[5vh] !m-auto max-w-[90%] md:!max-w-[50%]">
          <ModalBody className="max-h-[90vh] overflow-y-auto rounded-md scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300">
            <Card extra={'w-full p-4 rounded-md'}>
              <div className="relative flex items-center justify-center border-b-2 border-gray-200 dark:border-gray-700">
                <div
                  onClick={() => setActiveFilter('hire')}
                  className={`${
                    activeFilter === 'hire'
                      ? 'border-b-2 border-blue-400 font-bold text-blue-400'
                      : ' font-medium text-navy-700 dark:text-white'
                  } text-md hover:cursor-pointer`}
                >
                  Hire Content Writer
                </div>
                <div
                  onClick={() => setActiveFilter('provide')}
                  className={`${
                    activeFilter === 'provide'
                      ? 'border-b-2 border-blue-400 font-bold text-blue-400'
                      : ' font-medium text-navy-700 dark:text-white'
                  } text-md ml-7 hover:cursor-pointer`}
                >
                  Provide Content
                </div>
              </div>
              <div className="mt-5 flex flex-col">
                <div className="text-3xl dark:text-white">
                  {activeFilter === 'hire' && 'Order details'}
                  {activeFilter === 'provide' && 'Provide content'}
                </div>
                <div className="mt-1 flex flex-col">
                  {activeFilter === 'hire' && (
                    <HireForm
                      itemId={itemId}
                      handleHireArticleData={handleHireArticleData}
                      hireContentWriterData={hireContentWriterData}
                      articleWordCount={articleWordCount}
                      articleCondition={articleCondition}
                    />
                  )}
                  {activeFilter === 'provide' && (
                    <ProvideContentForm
                      itemId={itemId}
                      provideContentData={provideContentData}
                      handleProvideContentData={handleProvideContentData}
                      articleWordCount={articleWordCount}
                      articleCondition={articleCondition}
                    />
                  )}
                </div>
              </div>
              <div className="mt-5 flex justify-between">
                <button
                  onClick={() => {
                    const article =
                      activeFilter === 'hire' ? 'hire' : 'provide';
                    handleCancelButton(itemId, article);
                    onClose();
                  }}
                  className="bg-transparent ml-0 rounded-md border border-gray-500 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
                >
                  Cancel
                </button>
                <button
                  onClick={handleApproveClick}
                  className="rounded-md bg-blue-500 px-4 py-2 text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                >
                  {activeFilter === 'hire' ? 'Approve' : 'Submit'}
                </button>
              </div>
            </Card>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ArticleModal;
