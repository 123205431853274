import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { toastSuccess, toastError } from 'utils/toasts';
import InputField from './InputField';
import getStripe from 'services/stripe';
import { get, post, put, getUserId } from 'api';

const TopUp = () => {
  const navigate = useNavigate();
  const [creditsBalance, setCreditsBalance] = useState();
  const [checkoutData, setCheckoutData] = useState(null);
  const [session, setSession] = useState({});
  const [company, setCompany] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [taxRates, setTaxRates] = useState([]);
  const [isCompanyVatPayer, setIsCompanyVatPayer] = useState(false);

  useEffect(() => {
    let companyData = window.localStorage.getItem('company');
    if (companyData !== null && companyData !== 'undefined') {
      companyData = JSON.parse(companyData);
      setCompanyData(companyData);
    } else {
      setCompanyData(null);
    }
  }, []);

  const getTaxRates = async () => {
    const response = await fetch('https://api.stripe.com/v1/tax_rates', {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
      },
    });
    const data = await response.json();
    const taxRates = data.data.map((taxRate) => ({
      id: taxRate.id,
      displayName: taxRate.display_name,
    }));
    return taxRates;
  };

  useEffect(() => {
    const fetchTaxRates = async () => {
      const taxRates = await getTaxRates();
      setTaxRates(taxRates);
    };

    fetchTaxRates();
  }, []);

  useEffect(() => {
    const fetchBillingInfo = async () => {
      const userId = getUserId();
      await get(`/api/company/user/${userId}`)
        .then((res) => res.json())
        .then((data) => {
          setCompany(data.Company);
          setIsCompanyVatPayer(data.Company?.isVatPayer);
          window.localStorage.setItem('company', JSON.stringify(data.Company));
        });
    };
    fetchBillingInfo();
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const sessionId = urlParams.get('session_id');

    const fetchCheckoutData = async () => {
      const response = await fetch(
        `https://api.stripe.com/v1/checkout/sessions/${sessionId}`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
          },
        },
      );
      const data = await response.json();
      setCheckoutData(data);
    };

    fetchCheckoutData();
  }, []);

  const handleTopUp = useCallback(async () => {
    const userId = getUserId();
    const creditsAdded = Number(checkoutData.amount_subtotal / 100);
    const amountPaid = checkoutData.amount_total / 100;
    const taxesPaid = checkoutData.total_details.amount_tax / 100;
    await put(`/api/user/credits/${userId}`, {
      credits: creditsAdded,
    }).then((res) => {
      if (res.status === 200) {
        toastSuccess('Credits added');
        const transactionInfo = {
          userId: userId,
          companyId: companyData.id,
          creditsAdded: creditsAdded,
          amountPaid: amountPaid,
          taxesPaid: taxesPaid,
          currency: checkoutData.currency,
        };
        post(`/api/transaction`, {
          transactionInfo,
        }).then((res) => {
          if (res.status === 200) {
            setTimeout(() => {
              window.localStorage.removeItem('company');
              window.location.href = '/admin/account/credits';
            }, 750);
          }
        });
      } else {
        toastError('Credits not added');
      }
    });
  }, [checkoutData, companyData]);

  useEffect(() => {
    if (checkoutData && checkoutData.payment_status === 'paid') {
      handleTopUp();
    } else if (checkoutData && checkoutData.payment_status === 'unpaid') {
      alert('Payment failed');
    }
  }, [checkoutData]);

  const handleCheckout = async () => {
    if (!company) {
      toastError('Please add billing information first');
      setTimeout(() => {
        navigate('/admin/account/billing');
      }, 2500);
    }
    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout({
      sessionId: session.id,
    });
    console.warn(error.message);
  };

  function buildQuery(data, prefix) {
    var type = Object.prototype.toString.call(data).slice(8, -1).toLowerCase();
    return Object.keys(data)
      .map(function (key, index) {
        var value = data[key];
        if (type === 'array') {
          key = prefix + '[' + index + ']';
        } else if (type === 'object') {
          key = prefix ? prefix + '[' + key + ']' : key;
        }

        if (typeof value === 'object') {
          return buildQuery(value, key);
        }

        return key + '=' + encodeURIComponent(value);
      })
      .join('&');
  }

  useEffect(() => {
    async function getStripeSession() {
      let stripeRequest = await fetch(
        'https://api.stripe.com/v1/checkout/sessions',
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
            'Content-type': 'application/x-www-form-urlencoded',
          },
          body: buildQuery({
            payment_method_types: ['card'],
            mode: 'payment',
            success_url: `${process.env.REACT_APP_URL}/admin/account/credits?session_id={CHECKOUT_SESSION_ID}`,
            cancel_url: `${process.env.REACT_APP_URL}/admin/account/credits`,
            customer_email: company && company.billingEmail,
            client_reference_id: company && company.id,
            allow_promotion_codes: true,
            line_items: [
              {
                price_data: {
                  currency: 'eur',
                  unit_amount: creditsBalance * 100, // Convert to cents
                  product_data: {
                    name: 'Credits top up',
                  },
                },
                quantity: 1,
                tax_rates: isCompanyVatPayer
                  ? [taxRates[0] && taxRates[0].id]
                  : [taxRates[1] && taxRates[1].id],
              },
            ],
          }),
        },
      );
      let stripeResponse = await stripeRequest.json();
      setSession(stripeResponse);
    }
    getStripeSession();
  }, [creditsBalance]);

  useEffect(() => {
    document.querySelector('input').value = creditsBalance;
  }, [creditsBalance]);

  return (
    <div className="flex w-full flex-col items-center justify-center md:w-1/2">
      <ToastContainer
        position="top-center"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="w-full">
        <div className="mt-4 flex flex gap-2">
          <button
            className="w-full rounded bg-blue-500 font-bold text-white hover:bg-blue-700"
            onClick={() => setCreditsBalance('1000')}
          >
            1,000
          </button>
          <button
            className="w-full rounded bg-blue-500 font-bold text-white hover:bg-blue-700"
            onClick={() => setCreditsBalance('2000')}
          >
            2,000
          </button>
          <button
            className="w-full rounded bg-blue-500 font-bold text-white hover:bg-blue-700"
            onClick={() => setCreditsBalance('5000')}
          >
            5,000
          </button>
          <button
            className="w-full rounded bg-blue-500 font-bold text-white hover:bg-blue-700"
            onClick={() => setCreditsBalance('10000')}
          >
            10,000
          </button>
        </div>
        <InputField
          type="number"
          defaultValue={creditsBalance}
          placeholder="Enter amount to top up"
          onChange={(e) => setCreditsBalance(e.target.value)}
        />
      </div>
      <button
        className="mt-4 w-full rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
        onClick={handleCheckout}
      >
        Pay
      </button>
    </div>
  );
};

export default TopUp;
