import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { io } from 'socket.io-client';
import SingleChat from './components/SingleChat';
import { FiSearch } from 'react-icons/fi';
import Chat from './components/Chat';
import Card from 'components/card';
import { get } from 'api';
import { post } from 'api';

const Messages = () => {
  const socket = useRef();
  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [currentUser, setCurrentUser] = useState(undefined);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [lastOnlineTimes, setLastOnlineTimes] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [newMessages, setNewMessages] = useState({});

  useEffect(() => {
    const userRole = JSON.parse(localStorage.getItem('userData')).role;
    if (userRole === 'user') {
      const getAdmins = async () => {
        const res = await get('/api/user/admins');
        const data = await res.json();
        setUsers(data);
      };
      getAdmins();
    } else {
      const getUsers = async () => {
        const res = await get('/api/user/users');
        const data = await res.json();
        setUsers(data);
      };
      getUsers();
    }
  }, []);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('userData'));
    setCurrentUser(user);
  }, []);

  useEffect(() => {
    if (currentUser) {
      socket.current = io(`${process.env.REACT_APP_BACKEND_URL}/live-chat`);
      socket.current.emit('add-user', currentUser.id);
    }

    return () => {
      if (socket.current) {
        socket.current.disconnect();
      }
    };
  }, [currentUser]);

  useEffect(() => {
    if (socket.current) {
      socket.current.on('users', (users) => {
        setOnlineUsers(users);
      });

      socket.current.on('user-connected', (userId) => {
        socket.current.emit('get-last-online', userId, (lastOnline) => {
          setLastOnlineTimes((prevTimes) => ({
            ...prevTimes,
            [userId]: new Date(lastOnline),
          }));
        });
      });

      socket.current.on('user-disconnected', (userId) => {
        socket.current.emit('get-last-online', userId, (lastOnline) => {
          setLastOnlineTimes((prevTimes) => ({
            ...prevTimes,
            [userId]: new Date(lastOnline),
          }));
        });
      });
    }
  }, [socket.current]);

  useEffect(() => {
    if (socket.current && users) {
      let newLastOnlineTimes = { ...lastOnlineTimes };
      users.forEach((user) => {
        socket.current.emit('get-last-online', user.id, (lastOnline) => {
          const date = new Date(lastOnline);
          newLastOnlineTimes[user.id] = date;
          setLastOnlineTimes(newLastOnlineTimes);
        });
      });
    }
  }, [socket.current, users]);

  useEffect(() => {
    const getUnreadMessages = async () => {
      const res = await get('/api/messages/unread?userId=' + currentUser.id);
      const data = await res.json();
      const unreadMessages = data.reduce((acc, message) => {
        acc[message.fromUserId] = (acc[message.fromUserId] || 0) + 1;
        return acc;
      }, {});
      setNewMessages(unreadMessages);
    };
    if (currentUser) {
      getUnreadMessages();
    }
  }, [currentUser]);

  useEffect(() => {
    if (socket.current) {
      socket.current.on('receive-message', (chat) => {
        if (chat.toUserId === currentUser.id) {
          setNewMessages((prevMessages) => ({
            ...prevMessages,
            [chat.fromUserId]: (prevMessages[chat.fromUserId] || 0) + 1,
          }));
        }
      });

      return () => {
        if (socket.current) {
          socket.current.off('receive-message');
        }
      };
    }
  }, [currentUser, socket.current]);

  useEffect(() => {
    if (open && window.innerWidth < 800)
      document.documentElement.style.overflow = 'hidden';
    else document.documentElement.style.overflow = 'unset';
  });

  const openChat = async (chatPartnerId) => {
    const res = await post('/api/messages/read', {
      toUserId: currentUser.id,
      fromUserId: chatPartnerId,
    });

    if (res.ok) {
      setNewMessages((prevMessages) => {
        const newMessages = { ...prevMessages };
        delete newMessages[chatPartnerId];
        return newMessages;
      });
    }
  };

  return (
    <div className="mt-3 flex w-full grid-cols-10 flex-col gap-5 md:mt-[20px] md:mt-[80px] lg:mt-6 lg:min-h-[85vh] lg:flex-row">
      <div className="w-full lg:w-[40%]">
        <Card extra={'w-full md:min-h-[80vh] 3xl:min-h-[80vh] p-4'}>
          {/* header */}
          <div className="mb-3 flex w-full flex-col justify-between md:flex-row md:items-center">
            <h1 className="font-dm text-[24px] font-bold text-navy-700 dark:text-white">
              Your Chats
            </h1>
            {/* search */}
            <div className="flex items-center">
              <div className="flex h-[42px] w-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white ">
                <p className="pl-3 pr-2 text-xl">
                  <FiSearch className="h-4 w-4 text-gray-400" />
                </p>
                <input
                  type="text"
                  placeholder="Search..."
                  className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
          </div>
          {/* messages */}
          <div className="mt-4 overflow-y-auto scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300 2xl:max-h-[450px] 3xl:max-h-[600px]">
            {users
              .filter((user) =>
                (user.firstName + ' ' + user.lastName)
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase()),
              )
              .sort((a, b) => {
                const aHasNewMessages = !!newMessages[a.id];
                const bHasNewMessages = !!newMessages[b.id];
                if (aHasNewMessages !== bHasNewMessages) {
                  return bHasNewMessages - aHasNewMessages;
                } else {
                  const aLastOnline = lastOnlineTimes[a.id] || new Date(0);
                  const bLastOnline = lastOnlineTimes[b.id] || new Date(0);
                  return bLastOnline - aLastOnline;
                }
              })
              .map((user, index) => (
                <div
                  onClick={() => {
                    openChat(user.id);
                    setOpen(true);
                    setSelectedUser(user);
                    setNewMessages((prevMessages) => ({
                      ...prevMessages,
                      [user.id]: false,
                    }));
                  }}
                  key={index}
                >
                  <Chat
                    photo={user.avatar}
                    active={onlineUsers.includes(user.id)}
                    name={user.firstName + ' ' + user.lastName}
                    time={
                      lastOnlineTimes[user.id]
                        ? moment(lastOnlineTimes[user.id]).format(
                            'YYYY-MM-DD HH:mm',
                          )
                        : ''
                    }
                    message={user.role === 'user' ? 'User' : 'Admin'}
                    last={index === users.length - 1}
                    newMessage={newMessages[user.id]}
                  />
                </div>
              ))}
          </div>
        </Card>
      </div>
      {open ? (
        <div className="w-full lg:w-[60%]">
          <SingleChat
            open={open}
            onClose={() => setOpen(false)}
            selectedUser={selectedUser}
            socket={socket}
            currentUser={currentUser}
            onlineUsers={onlineUsers}
            setNewMessages={setNewMessages}
          />
        </div>
      ) : (
        <div className="hidden w-full md:block lg:w-[64.5%]">
          <Card extra={'w-full h-full p-4'}>
            <div className="flex h-full items-center justify-center">
              <h1 className="text-2xl font-medium text-gray-400 dark:text-white">
                Select a chat to start messaging
              </h1>
            </div>
          </Card>
        </div>
      )}
    </div>
  );
};

export default Messages;
