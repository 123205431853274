import React, { useState } from 'react';
import TextField from 'components/fields/TextField';
import Checkbox from 'components/checkbox';
import { put } from 'api';
import { MdContentCopy } from 'react-icons/md';

const PublicationReview = ({
  articleUrl,
  orderId,
  onClose,
  toastSuccess,
  toastError,
  fetchOrders,
}) => {
  const [revise, setRevise] = useState(false);
  const [copied, setCopied] = useState({});
  const [revisionText, setRevisionText] = useState('');
  const [sendDirectlyToPublisher, setSendDirectlyToPublisher] = useState(false);

  const handleRevisionText = (e) => {
    setRevisionText(e.target.value);
  };

  const handleRevisionSubmit = async () => {
    const body = {
      articleApproval: sendDirectlyToPublisher,
      articleRevision: revisionText,
      articleStatus: 'DONE',
      orderStatus: 'IN REVISION',
      sendDirectlyToPublisher: sendDirectlyToPublisher,
    };
    await put(`/api/orders/revision/${orderId}`, body).then((res) => {
      if (res.status === 200) {
        toastSuccess('Article revision submitted!');
        onClose();
        fetchOrders();
      } else {
        toastError('Something went wrong!');
      }
    });
  };

  const handleApprove = async () => {
    const body = {
      articleApproval: true,
      articleStatus: 'DONE',
      orderStatus: 'DONE',
    };
    await put(`/api/orders/approve/${orderId}`, body).then((res) => {
      if (res.status === 200) {
        toastSuccess('Article approved!');
        onClose();
        fetchOrders();
      } else {
        toastError('Something went wrong!');
      }
    });
  };

  return (
    <div className="mt-3 h-full w-full rounded-[20px]">
      <div className="text-xl dark:text-white">
        {!revise &&
          `Your backlink is now live and indexed for order Nr. ${orderId}`}
        {revise &&
          `What specific revisions would you like to make to the article, and what elements do you believe are currently lacking?`}
      </div>
      {!revise && (
        <div className="mt-3 grid grid-cols-2 gap-4 md:grid-cols-3">
          <div className="flex w-full items-center">
            <a
              href={articleUrl}
              target="_blank"
              rel="noreferrer"
              className="mt-3 w-full rounded-[10px] bg-blue-400 px-4 py-2 text-center text-white hover:cursor-pointer hover:bg-blue-500"
            >
              View Article
            </a>
            <MdContentCopy
              className="ml-1 mt-3 cursor-pointer text-xl text-gray-700 dark:text-white"
              onClick={() => {
                navigator.clipboard.writeText(articleUrl);
                setCopied((prevCopied) => ({
                  ...prevCopied,
                  [orderId]: true,
                }));
                setTimeout(() => {
                  setCopied((prevCopied) => ({
                    ...prevCopied,
                    [orderId]: false,
                  }));
                }, 500);
              }}
            />
            {copied[orderId] && (
              <div className="absolute rounded-md bg-gray-800 px-2 py-1 text-white">
                Article URL Copied!
              </div>
            )}
          </div>
          <button
            onClick={() => setRevise(true)}
            className="mt-3 rounded-[10px] bg-orange-400 px-4 py-2 text-white hover:cursor-pointer hover:bg-orange-500"
          >
            Revise first
          </button>
          <button
            onClick={() => handleApprove()}
            className="mt-3 rounded-[10px] bg-blue-400 px-4 py-2 text-white hover:cursor-pointer hover:bg-blue-500"
          >
            Approve
          </button>
        </div>
      )}
      {revise && (
        <div className="mt-0 flex flex-col">
          <TextField
            placeholder="Describe it here..."
            id="textarea"
            cols="30"
            rows="5"
            value={revisionText}
            onChange={handleRevisionText}
          />
          <div className="mb-3 mt-6 grid grid-cols-1 gap-4 md:grid-cols-3">
            <button
              onClick={() => setRevise(false)}
              className="rounded-[10px] bg-blue-400 px-4 py-2 text-white hover:cursor-pointer hover:bg-blue-500"
            >
              Back
            </button>
            <button
              onClick={() => handleRevisionSubmit()}
              disabled={!revisionText}
              className="rounded-[10px] bg-blue-400 px-4 py-2 text-white hover:cursor-pointer hover:bg-blue-500"
            >
              Send for Revision
            </button>
            <div className="flex items-center gap-2">
              <Checkbox
                checked={sendDirectlyToPublisher}
                onChange={() =>
                  setSendDirectlyToPublisher(!sendDirectlyToPublisher)
                }
                id="wC300"
              />
              <p className="mb-0 text-sm font-medium  dark:text-white">
                You may send it directly to the publisher after the revisions
                are made.
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PublicationReview;
