import React from 'react';
import { toastSuccess, toastError } from 'utils/toasts';
import Dropdown from 'components/dropdown';
import { put } from 'api';

const BulkActions = ({ data, fetchOrders, setBulkSelect }) => {
  const handleBulkCopy = (data) => {
    const text = data.map((item) => item.domain).join('\n');
    navigator.clipboard.writeText(text);
  };

  const handleBulkApprove = async (data) => {
    const body = {
      articleApproval: true,
      articleStatus: 'DONE',
      orderStatus: 'DONE',
    };

    let allApprovalsSuccessful = true;

    for (const item of data) {
      const res = await put(`/api/orders/approve/${item.id}`, body);
      if (res.status !== 200) {
        allApprovalsSuccessful = false;
        break;
      }
    }

    if (allApprovalsSuccessful) {
      toastSuccess('All articles approved!');
      fetchOrders();
      setBulkSelect(false);
    } else {
      toastError('Something went wrong!');
    }
  };

  return (
    <Dropdown
      button={
        <button className="rounded-md border border-gray-300 px-2 py-1 text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50">
          Bulk Actions
        </button>
      }
      classNames="origin-top-right transition-all duration-300 ease-in-out"
    >
      <div className="rounded-md bg-white py-1 shadow-lg">
        <button
          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
          onClick={() => handleBulkCopy(data)}
        >
          Copy domains
        </button>
        <button
          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
          role="menuitem"
          onClick={() => handleBulkApprove(data)}
        >
          Approve Orders
        </button>
      </div>
    </Dropdown>
  );
};

export default BulkActions;
