import React from 'react';
import ReportsTable from './components/ReportsTable';
import { reportsHeaders } from './variables/columnsData';

const Reports = () => {
  return (
    <div>
      <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
        <ReportsTable columnsData={reportsHeaders} />
      </div>
    </div>
  );
};

export default Reports;
