import React, { useState } from 'react';
import InputField from './InputField';
import TextField from 'components/fields/TextField';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import Checkbox from 'components/checkbox';
import AddNewAnchor from './AddNewAnchor';
import Radio from 'components/radio';

const HireForm = ({
  itemId,
  handleHireArticleData,
  hireContentWriterData,
  articleWordCount,
  articleCondition,
}) => {
  const {
    anchors,
    wordCount,
    topicSuggestion,
    instructions,
    writingStyle,
    preferredVoice,
    allUrls,
    provideLater,
  } = hireContentWriterData[itemId] || {};

  const [showAdvanceWritingInfo, setShowAdvancedWritingInfo] = useState(false);
  const [showGuidelines, setShowGuidelines] = useState(true);
  const [selectedAnchors, setSelectedAnchors] = useState([]);

  const checkIfAnchorExists = (url, anchor) => {
    const existingItemIndex = selectedAnchors.findIndex(
      (item) => item.url.value === url && item.anchor.value === anchor,
    );
    return existingItemIndex !== -1;
  };

  const handleAnchorSelected = (selectedUrl, selectedAnchor) => {
    const existingItemIndex = selectedAnchors.findIndex(
      (item) => item.url.id === selectedUrl.id,
    );

    if (existingItemIndex !== -1) {
      setSelectedAnchors((prevSelectedAnchors) =>
        prevSelectedAnchors.filter(
          (item, index) => index !== existingItemIndex,
        ),
      );
    }

    setSelectedAnchors((prevSelectedAnchors) => [
      ...prevSelectedAnchors,
      { url: selectedUrl, anchor: selectedAnchor },
    ]);

    handleHireArticleData({
      itemId: itemId,
      anchors: {
        ...anchors,
        [selectedUrl.value]: {
          url: selectedUrl.value,
          anchor: {
            id: selectedAnchor.id || '',
            value: selectedAnchor.value,
            label: selectedAnchor.value,
          },
          urlSlugId: selectedUrl.id,
        },
      },
    });
  };

  const handleDataChange = (id, value, urlName, urlId) => {
    if (id === 'selectedAnchors') {
      const existingItemIndex = selectedAnchors.findIndex(
        (item) => item.url.value === urlName,
      );

      if (existingItemIndex !== -1) {
        setSelectedAnchors((prevSelectedAnchors) =>
          prevSelectedAnchors.filter(
            (item, index) => index !== existingItemIndex,
          ),
        );
      }

      setSelectedAnchors((prevSelectedAnchors) => [
        ...prevSelectedAnchors,
        {
          url: { value: urlName, label: urlName },
          anchor: { value: value, label: value },
        },
      ]);

      handleHireArticleData({
        itemId: itemId,
        anchors: {
          ...anchors,
          [urlName]: {
            url: urlName,
            anchor: {
              id: value.id || '',
              value: value,
              label: value,
            },
            urlSlugId: value.urlSlugId || urlId || '',
          },
        },
      });
    } else {
      handleHireArticleData({ itemId: itemId, [id]: value });
    }
  };

  const handleRemoveSelection = (urlId, anchorId, urlName) => {
    const newAnchors = { ...anchors };
    delete newAnchors[urlName];
    handleHireArticleData({ itemId: itemId, anchors: newAnchors });

    setSelectedAnchors((prevSelectedAnchors) =>
      prevSelectedAnchors.filter(
        (item) =>
          item.url.id !== urlId ||
          item.anchor.id !== anchorId ||
          item.url.value !== urlName,
      ),
    );
  };

  return (
    <div className="h-full w-full rounded-[20px]">
      <div className="mt-3">
        <p
          className="mb-0 text-sm font-bold hover:cursor-pointer dark:text-white"
          onClick={() => setShowGuidelines(!showGuidelines)}
        >
          Article guidelines{' '}
          {showGuidelines ? (
            <BsChevronUp className="mr-1 inline h-4 w-4" />
          ) : (
            <BsChevronDown className="mr-1 inline h-4 w-4" />
          )}
        </p>
      </div>
      {showGuidelines && (
        <div className="mt-1">
          <div className="flex items-center gap-2">
            <p className="mb-0 text-sm font-medium  dark:text-white">
              <strong>Word Count:</strong>{' '}
              {articleWordCount ? articleWordCount : 'Minimum 500 words'}
            </p>
          </div>
          <div className="flex items-center gap-2">
            <p className="mb-0 text-sm font-medium  dark:text-white">
              <strong>Condition:</strong>{' '}
              {articleCondition
                ? articleCondition
                : 'Topics suppose to be related to website style'}
            </p>
          </div>
        </div>
      )}
      <div className="mt-5">
        <div>
          <p className="mb-0 text-sm font-bold  dark:text-white">Word count</p>
          <div className="mt-1 grid grid-cols-2 gap-3 md:grid-cols-4">
            <div className="flex items-center gap-2">
              <Radio
                id="wC300"
                name="wordCount"
                color="blue"
                onChange={() => handleDataChange('wordCount', 200)}
                checked={wordCount === 200 || false}
              />
              <p className="mb-0 text-sm font-medium  dark:text-white">
                300-500 (15€)
              </p>
            </div>
            <div className="flex items-center gap-2">
              <Radio
                id="wC500"
                name="wordCount"
                color="blue"
                onChange={() => handleDataChange('wordCount', 500)}
                checked={wordCount === 500 || false}
              />
              <p className="mb-0 text-sm font-medium  dark:text-white">
                500-750 (25€)
              </p>
            </div>
            <div className="flex items-center gap-2">
              <Radio
                id="wC750"
                name="wordCount"
                color="blue"
                onChange={() => handleDataChange('wordCount', 750)}
                checked={wordCount === 750 || false}
              />
              <p className="mb-0 text-sm font-medium  dark:text-white">
                750-1000 (37.5€)
              </p>
            </div>
            <div className="flex items-center gap-2">
              <Radio
                id="wC1000"
                name="wordCount"
                color="blue"
                onChange={() => handleDataChange('wordCount', 1000)}
                checked={wordCount === 1000 || false}
              />
              <p className="mb-0 text-sm font-medium  dark:text-white">
                1000-1250 (50€)
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <p className="text-sm font-bold  dark:text-white">
            Add one target URL and anchor to your website, another link you can
            suggest the authority website
          </p>
          <div className="mt-2 flex flex-col gap-2">
            <div className="flex w-full flex-col items-center gap-2">
              <AddNewAnchor
                allUrls={allUrls}
                onAnchorSelected={handleAnchorSelected}
                handleDataChange={handleDataChange}
              />
              <ul className="flex w-full flex-col gap-2">
                {selectedAnchors &&
                  selectedAnchors.map((anchor) => (
                    <li
                      key={`${anchor.url.id}-${anchor.anchor.id}`}
                      className="flex items-center justify-start rounded-lg bg-gray-100 px-2 py-1 dark:bg-gray-800"
                    >
                      <button
                        onClick={() => {
                          handleRemoveSelection(
                            anchor.url.id,
                            anchor.anchor.id,
                            anchor.url.value,
                          );
                        }}
                        className="mr-2 flex h-4 w-4 items-center justify-center rounded-full bg-red-500 font-bold text-white"
                      >
                        x
                      </button>
                      {anchor.url.value} - {anchor.anchor.value}
                    </li>
                  ))}
                {anchors &&
                  Object.values(anchors).map((anchor) => {
                    if (checkIfAnchorExists(anchor.url, anchor.anchor.value)) {
                      return null;
                    }
                    return (
                      <li
                        key={anchor.urlSlugId}
                        className="flex items-center justify-start rounded-lg bg-gray-100 px-2 py-1 dark:bg-gray-800"
                      >
                        <button
                          onClick={() => {
                            handleRemoveSelection(
                              anchor.urlSlugId,
                              anchor.anchor.id,
                              anchor.url,
                            );
                          }}
                          className="mr-2 flex h-4 w-4 items-center justify-center rounded-full bg-red-500 font-bold text-white"
                        >
                          x
                        </button>
                        {anchor.url} - {anchor.anchor.value}
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <p className="mb-2 text-sm font-bold  dark:text-white">
            Topic suggestion
          </p>
          <div className="grid grid-cols-1 gap-3">
            <InputField
              extra="mb-1"
              placeholder="Topic suggestion"
              id="topicSuggestion"
              type="text"
              onChange={(e) => handleDataChange(e.target.id, e.target.value)}
              value={topicSuggestion || ''}
            />
          </div>
        </div>
        <div className="mt-1">
          <TextField
            placeholder="Add special instructions here"
            id="instructions"
            cols="30"
            rows="7"
            onChange={(e) => handleDataChange(e.target.id, e.target.value)}
            value={instructions || ''}
          />
        </div>
        <div className="mt-3 flex justify-between">
          <p
            className="mb-0 text-sm font-bold hover:cursor-pointer dark:text-white"
            onClick={() => setShowAdvancedWritingInfo(!showAdvanceWritingInfo)}
          >
            Advance Writing Information{' '}
            {showAdvanceWritingInfo ? (
              <BsChevronUp className="mr-1 inline h-4 w-4" />
            ) : (
              <BsChevronDown className="mr-1 inline h-4 w-4" />
            )}
          </p>
          <div className="flex items-center justify-end">
            <Checkbox
              id="provideLater"
              checked={provideLater || false}
              onChange={(e) => handleDataChange(e.target.id, e.target.checked)}
            />
            <p className="ml-2 text-sm font-bold  dark:text-white">
              I will provide the article later
            </p>
          </div>
        </div>
        {showAdvanceWritingInfo && (
          <>
            <div className="mt-3">
              <p className="mb-2 text-sm font-bold  dark:text-white">
                Writing style
              </p>
              <div className="grid grid-cols-1 gap-3">
                <InputField
                  extra="mb-1"
                  placeholder="Informative / Educational"
                  id="writingStyle"
                  type="text"
                  onChange={(e) =>
                    handleDataChange(e.target.id, e.target.value)
                  }
                  value={writingStyle || ''}
                />
              </div>
            </div>
            <div className="mt-3">
              <p className="mb-2 text-sm font-bold  dark:text-white">
                Preferred voice
              </p>
              <div className="grid grid-cols-1 gap-3">
                <InputField
                  extra="mb-3"
                  placeholder="First person"
                  id="preferredVoice"
                  type="text"
                  onChange={(e) =>
                    handleDataChange(e.target.id, e.target.value)
                  }
                  value={preferredVoice || ''}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default HireForm;
