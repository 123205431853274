export const columnsData = [
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'DOMAIN',
    accessor: 'domain',
  },
  {
    Header: 'PROJECT',
  },
  {
    Header: 'ARTICLE',
    accessor: 'article',
  },
  {
    Header: 'ORDER ACTIONS',
    accessor: 'actions',
  },
  {
    Header: 'BACKLINK PRICE',
    accessor: 'price',
  },
  {
    Header: 'ARTICLE PRICE',
    accessor: 'articlePrice',
  },
  {
    Header: 'DELETE',
    accessor: 'delete',
  },
];
