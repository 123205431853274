export const categoriesHeaders = [
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'CATEGORY NAME',
    accessor: 'category',
  },
  {
    Header: 'DELETE',
    accessor: 'delete',
  },
];

export const countriesHeaders = [
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'COUNTRY NAME',
    accessor: 'country',
  },
  {
    Header: 'DELETE',
    accessor: 'delete',
  },
];

export const languagesHeaders = [
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'LANGUAGE',
    accessor: 'language',
  },
  {
    Header: 'DELETE',
    accessor: 'delete',
  },
];

export const notificationsHeaders = [
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'TITLE',
    accessor: 'title',
  },
  {
    Header: 'NOTIFICATION',
    accessor: 'message',
  },
  {
    Header: 'DELETE',
    accessor: 'delete',
  },
];

export const companiesHeaders = [
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'COMPANY NAME',
    accessor: 'companyName',
  },
  {
    Header: 'ADDRESS',
    accessor: 'address1',
  },
  {
    Header: 'ZIP CODE',
    accessor: 'zipCode',
  },
  {
    Header: 'CITY',
    accessor: 'city',
  },
  {
    Header: 'COUNTRY',
    accessor: 'country',
  },
  {
    Header: 'COMPANY CODE',
    accessor: 'companyCode',
  },
  {
    Header: 'BILLING EMAIL',
    accessor: 'billingEmail',
  },
  {
    Header: 'VAT NUMBER',
    accessor: 'vatNumber',
  },
  {
    Header: 'VAT PAYER',
    accessor: 'isVatPayer',
  },
  {
    Header: 'DISCOUNT %',
    accessor: 'discount',
  },
  {
    Header: 'UPDATE',
    accessor: 'update',
  },
];

export const usersHeaders = [
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'FIRST NAME',
    accessor: 'firstName',
  },
  {
    Header: 'LAST NAME',
    accessor: 'lastName',
  },
  {
    Header: 'USER ACTIVATED',
    accessor: 'isActivated',
  },
  {
    Header: 'COMPANY ID',
    accessor: 'companyId',
  },
  {
    Header: 'COMPANY NAME',
    accessor: 'companyName',
  },
  {
    Header: 'CREDITS',
    accessor: 'credits',
  },
  {
    Header: 'REFERRAL CODE',
    accessor: 'referralCode',
  },
  {
    Header: 'REFERRAL BONUS %',
    accessor: 'referralPercentage',
  },
  {
    Header: 'TOTAL REFERRALS',
    accessor: 'totalReferrals',
  },
  {
    Header: 'REFERRALS SPENT',
    accessor: 'referralsSpent',
  },
  {
    Header: 'REFERRALS PROFIT',
    accessor: 'referralsBonus',
  },
  {
    Header: 'UPDATE',
    accessor: 'update',
  },
];
