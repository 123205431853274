import { createContext, useState, useEffect } from 'react';
import {
  marketplaceHeader,
  competitorHeader,
  suggestionHeader,
  favoritesHeader,
  blacklistHeader,
} from '../views/admin/marketplace/overview/variables/columnsData';
import { orderManagerHeader } from '../views/admin/orders/manager/variables/columnsData';
import { orderAdminHeader } from '../views/admin/orders/admin/variables/columnsData';

export const ColumnsViewContext = createContext();

export const ColumnsViewProvider = ({ children }) => {
  const [columnsView, setColumnsView] = useState([]);
  const [marketplaceColumnsView, setMarketplaceColumnsView] = useState([]);
  const [competitorColumnsView, setCompetitorColumnsView] = useState([]);
  const [suggestionColumnsView, setSuggestionColumnsView] = useState([]);
  const [orderManagerColumnsView, setOrderManagerColumnsView] = useState([]);
  const [orderAdminColumnsView, setOrderAdminColumnsView] = useState([]);
  const [favoritesColumnsView, setFavoritesColumnsView] = useState([]);
  const [blacklistColumnsView, setBlacklistColumnsView] = useState([]);

  useEffect(() => {
    const storedColumnsView = localStorage.getItem('columnsView');
    if (storedColumnsView) {
      const parsedColumnsView = JSON.parse(storedColumnsView);
      setMarketplaceColumnsView(
        parsedColumnsView.find(
          (pageColumnsView) => pageColumnsView.page === 'marketplace',
        ).columnsView,
      );
      setCompetitorColumnsView(
        parsedColumnsView.find(
          (pageColumnsView) => pageColumnsView.page === 'competitors',
        ).columnsView,
      );
      setSuggestionColumnsView(
        parsedColumnsView.find(
          (pageColumnsView) => pageColumnsView.page === 'suggestions',
        ).columnsView,
      );
      setOrderManagerColumnsView(
        parsedColumnsView.find(
          (pageColumnsView) => pageColumnsView.page === 'orderManager',
        ).columnsView,
      );
      setOrderAdminColumnsView(
        parsedColumnsView.find(
          (pageColumnsView) => pageColumnsView.page === 'orderAdmin',
        ).columnsView,
      );
      setFavoritesColumnsView(
        parsedColumnsView.find(
          (pageColumnsView) => pageColumnsView.page === 'favorites',
        ).columnsView,
      );
      setBlacklistColumnsView(
        parsedColumnsView.find(
          (pageColumnsView) => pageColumnsView.page === 'blacklist',
        ).columnsView,
      );
    } else {
      localStorage.setItem(
        'columnsView',
        JSON.stringify([
          { page: 'marketplace', columnsView: marketplaceHeader },
          { page: 'competitors', columnsView: competitorHeader },
          { page: 'suggestions', columnsView: suggestionHeader },
          { page: 'orderManager', columnsView: orderManagerHeader },
          { page: 'orderAdmin', columnsView: orderAdminHeader },
          { page: 'favorites', columnsView: favoritesHeader },
          { page: 'blacklist', columnsView: blacklistHeader },
        ]),
      );
      setMarketplaceColumnsView(marketplaceHeader);
      setCompetitorColumnsView(competitorHeader);
      setSuggestionColumnsView(suggestionHeader);
      setOrderManagerColumnsView(orderManagerHeader);
      setOrderAdminColumnsView(orderAdminHeader);
      setFavoritesColumnsView(favoritesHeader);
      setBlacklistColumnsView(blacklistHeader);
    }
  }, []);

  const toggleColumnView = (columnName, page) => {
    const storedColumnsView = localStorage.getItem('columnsView');
    if (storedColumnsView) {
      const parsedColumnsView = JSON.parse(storedColumnsView);
      const updatedColumnsView = parsedColumnsView.map((pageColumnsView) =>
        pageColumnsView.page === page
          ? {
              page,
              columnsView: pageColumnsView.columnsView.map((column) =>
                column.Header === columnName
                  ? { ...column, showColumn: !column.showColumn }
                  : column,
              ),
            }
          : pageColumnsView,
      );
      localStorage.setItem('columnsView', JSON.stringify(updatedColumnsView));
      const pageColumnsView = updatedColumnsView.find(
        (pageColumnsView) => pageColumnsView.page === page,
      );
      if (pageColumnsView) {
        switch (page) {
          case 'marketplace':
            setMarketplaceColumnsView(pageColumnsView.columnsView);
            break;
          case 'competitors':
            setCompetitorColumnsView(pageColumnsView.columnsView);
            break;
          case 'suggestions':
            setSuggestionColumnsView(pageColumnsView.columnsView);
            break;
          case 'orderManager':
            setOrderManagerColumnsView(pageColumnsView.columnsView);
            break;
          case 'orderAdmin':
            setOrderAdminColumnsView(pageColumnsView.columnsView);
            break;
          case 'favorites':
            setFavoritesColumnsView(pageColumnsView.columnsView);
            break;
          case 'blacklist':
            setBlacklistColumnsView(pageColumnsView.columnsView);
            break;
          default:
            break;
        }
      }
      setColumnsView(
        updatedColumnsView.find(
          (pageColumnsView) => pageColumnsView.page === page,
        ).columnsView,
      );
    } else {
      localStorage.setItem(
        'columnsView',
        JSON.stringify([
          { page: 'marketplace', columnsView: marketplaceHeader },
          { page: 'competitors', columnsView: competitorHeader },
          { page: 'suggestions', columnsView: suggestionHeader },
          { page: 'orderManager', columnsView: orderManagerHeader },
          { page: 'orderAdmin', columnsView: orderAdminHeader },
          { page: 'favorites', columnsView: favoritesHeader },
          { page: 'blacklist', columnsView: blacklistHeader },
        ]),
      );
    }
  };

  return (
    <ColumnsViewContext.Provider
      value={{
        columnsView,
        marketplaceColumnsView,
        competitorColumnsView,
        suggestionColumnsView,
        orderManagerColumnsView,
        orderAdminColumnsView,
        favoritesColumnsView,
        blacklistColumnsView,
        toggleColumnView,
      }}
    >
      {children}
    </ColumnsViewContext.Provider>
  );
};
