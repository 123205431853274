export const columnsDataReferrals = [
  {
    Header: 'FULL NAME',
    accessor: 'fullName',
  },
  {
    Header: 'ORDERS',
    accessor: 'totalOrders',
  },
  {
    Header: 'TOTAL ORDERS VALUE',
    accessor: 'totalOrderPrice',
  },
  {
    Header: 'YOUR PROFIT',
    accessor: 'totalProfit',
  },
];
