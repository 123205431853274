import React, { useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalBody } from '@chakra-ui/modal';
import { useDisclosure } from '@chakra-ui/hooks';
import Card from 'components/card';
import Checkbox from 'components/checkbox';
import { MdCancel } from 'react-icons/md';

const PageFilterModal = ({
  pageFilter,
  setPageFilter,
  pageFilterType,
  setPageFilterType,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedFilter, setSelectedFilter] = useState(
    pageFilterType === 'equals'
      ? '1'
      : pageFilterType === 'contains'
      ? '2'
      : '3',
  );

  const handleSave = () => {
    switch (selectedFilter) {
      case '1':
        setPageFilterType('equals');
        break;
      case '2':
        setPageFilterType('contains');
        break;
      case '3':
        setPageFilterType('notContains');
        break;
      default:
        break;
    }
    onClose();
  };

  const handleReset = () => {
    onClose();
  };

  const handleCheckboxChange = (e) => {
    setSelectedFilter(e.target.id);
  };

  return (
    <div>
      <button
        onClick={onOpen}
        className="ml-3 flex cursor-default cursor-pointer items-center justify-between rounded-md bg-blue-500 px-2 py-1 text-white"
      >
        Page: {pageFilter}
        <span className="ml-2 cursor-pointer" onClick={() => setPageFilter('')}>
          <MdCancel />
        </span>
      </button>

      <Modal className="!z-[100]" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay className="bg-[#000] !opacity-30" />
        <ModalContent className="top-[22vh] !m-auto !w-max !max-w-[85%] md:top-[7vh]">
          <ModalBody>
            <Card extra={'w-full sm:overflow-auto p-4 pt-4'}>
              <div className="mt-5 flex flex-col">
                <div className="text-2xl dark:text-white">Page filter</div>
                <div className="mt-1 flex flex-col">
                  <div className="mt-1 flex">
                    <Checkbox
                      label="Exact URL"
                      checked={selectedFilter === '1' ? true : false}
                      onChange={handleCheckboxChange}
                      id="1"
                    />
                    <p className="ml-2 text-gray-800 dark:text-gray-400">
                      Exact URL
                    </p>
                  </div>
                  <div className="mt-1 flex">
                    <Checkbox
                      label="URLs containing"
                      checked={selectedFilter === '2' ? true : false}
                      onChange={handleCheckboxChange}
                      id="2"
                    />
                    <p className="ml-2 text-gray-800 dark:text-gray-400">
                      URLs containing
                    </p>
                  </div>
                  <div className="mt-1 flex">
                    <Checkbox
                      label="URLs not containing"
                      checked={selectedFilter === '3' ? true : false}
                      onChange={handleCheckboxChange}
                      id="3"
                    />
                    <p className="ml-2 text-gray-800 dark:text-gray-400">
                      URLs not containing
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 flex justify-start">
                <button
                  onClick={() => handleSave()}
                  className="rounded-md bg-blue-500 px-4 py-2 text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                >
                  APPLY
                </button>
                <button
                  onClick={() => handleReset()}
                  className="bg-transparent ml-3 rounded-md border border-gray-500 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
                >
                  CANCEL
                </button>
              </div>
            </Card>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default PageFilterModal;
