import Card from 'components/card';
import React, { useMemo, useEffect, useState } from 'react';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { get, getUserId } from 'api';

const ReferralsTable = (props) => {
  const {
    columnsData,
    setTotalOrders,
    setTotalOrderPrice,
    setTotalProfit,
    setAverageValue,
    setTotalReferrals,
    setReferralPercentage,
  } = props;
  const [referredUsers, setReferredUsers] = useState([]);

  useEffect(() => {
    const getReferredUsers = async () => {
      const userId = getUserId();
      try {
        const res = await get(`/api/referrals/referrer/${userId}`);
        const data = await res.json();
        if (!data) return;
        const { referrals, referralPercentage } = data;
        const refPercentage = parseFloat(referralPercentage / 100);
        const totalOrderPrice = referrals.map((item) => item.totalOrderPrice);
        const totalOrders = referrals.map((item) => item.totalOrders);
        const totalOrderPriceSum = totalOrderPrice.reduce((a, b) => a + b, 0);
        const totalOrdersSum = totalOrders.reduce((a, b) => a + b, 0);
        const totalProfit = (totalOrderPriceSum * refPercentage).toFixed(2);
        const averageValue = (totalOrderPriceSum / totalOrdersSum).toFixed(2);
        const totalReferrals = referrals.length;
        setReferralPercentage(parseFloat(referralPercentage));
        setTotalOrders(totalOrdersSum);
        setTotalOrderPrice(totalOrderPriceSum);
        setTotalProfit(totalProfit);
        setAverageValue(averageValue);
        setTotalReferrals(totalReferrals);

        const newData = referrals.map((item) => {
          return {
            ...item,
            fullName: item.Users[0]?.firstName + ' ' + item.Users[0]?.lastName,
            avatar: item.Users[0]?.avatar,
            totalProfit: (item.totalOrderPrice * refPercentage).toFixed(2),
          };
        });
        setReferredUsers(newData);
      } catch (err) {
        console.log(err);
      }
    };
    getReferredUsers();
  }, [
    setTotalOrders,
    setTotalOrderPrice,
    setTotalProfit,
    setAverageValue,
    setTotalReferrals,
    setReferralPercentage,
  ]);

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => referredUsers, [referredUsers]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 15 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,

    prepareRow,
    nextPage,
    previousPage,

    setPageSize,
    state,
    rows,
  } = tableInstance;

  const { pageSize } = state;

  return (
    <Card extra={'w-full sm:overflow-auto p-4'}>
      <div className="mt-8 h-full max-w-[100%] overflow-x-auto">
        <table
          {...getTableProps()}
          className="w-full table-auto border-collapse whitespace-nowrap"
        >
          <thead className="w-full">
            {headerGroups.map((headerGroup, index) => (
              <tr
                className="items-center border-b border-gray-200 dark:!border-white/10"
                {...headerGroup.getHeaderGroupProps()}
                key={index}
              >
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="px-[27px] pb-[12px] pt-[32px] text-start text-xs tracking-wide text-gray-600"
                    key={index}
                  >
                    <div className="text-xs font-bold">
                      {column.render('Header')}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="w-full">
            {page.length === 0 && (
              <tr>
                <td
                  colSpan="7"
                  className="pt-4 text-center text-sm font-bold text-gray-700 dark:text-white"
                >
                  You have no referred users yet.
                </td>
              </tr>
            )}
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr
                  className="border-b border-gray-200 dark:!border-white/10"
                  {...row.getRowProps()}
                  key={index}
                >
                  {row.cells.map((cell, index) => {
                    let data = '';
                    if (cell.column.Header === 'FULL NAME') {
                      const avatar = cell.row.original.avatar;
                      data = (
                        <div className="flex  w-full items-center gap-[12px]">
                          <div className="flex h-[60px] w-[60px] items-center justify-center rounded-full bg-blue-300">
                            <img
                              className="h-full w-full rounded-full bg-cover bg-no-repeat"
                              src={avatar}
                              alt=""
                            />
                          </div>
                          <p className="font-medium text-navy-700 dark:text-white">
                            {' '}
                            {cell.value}
                          </p>
                        </div>
                      );
                    } else if (cell.column.Header === 'TOTAL ORDERS VALUE') {
                      data = (
                        <div className="w-full text-base font-medium text-navy-700 dark:text-white">
                          ${cell.value && cell.value.toLocaleString()}
                        </div>
                      );
                    } else if (cell.column.Header === 'YOUR PROFIT') {
                      data = (
                        <div className="w-full text-base font-medium text-navy-700 dark:text-white">
                          ${cell.value && cell.value.toLocaleString()}
                        </div>
                      );
                    } else if (cell.column.Header === 'ORDERS') {
                      data = (
                        <div className="w-full text-base font-medium text-navy-700 dark:text-white">
                          {cell.value && cell.value.toLocaleString()}
                        </div>
                      );
                    }
                    return (
                      <td
                        {...cell.getCellProps()}
                        key={index}
                        className="mt-[20px] py-[16px] pl-[26px] pr-4"
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* pagination */}
      <div className="mt-2 flex h-[80px] w-full items-center justify-between md:px-6">
        {/* left side */}
        <div className="flex items-center gap-2">
          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
            className="h-10 w-[70px] rounded-xl border border-gray-200 px-2 text-sm  text-gray-600 dark:!border-white/10 dark:!bg-navy-800"
            name=""
            id=""
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </select>
          <p className="text-sm text-gray-700">
            Showing {page.length} of {rows.length}
          </p>
        </div>
        {/* right side */}
        <div className="flex items-center gap-2">
          <button
            onClick={() => previousPage()}
            className={`linear flex items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
          >
            <MdChevronLeft />
          </button>

          <p className="text-sm text-gray-700">
            {state.pageIndex + 1} of{' '}
            {Math.ceil(referredUsers.length / pageSize)}
          </p>

          <button
            onClick={() => nextPage()}
            className={`linear flex items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
          >
            <MdChevronRight />
          </button>
        </div>
      </div>
    </Card>
  );
};

export default ReferralsTable;
