import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { toastSuccess, toastError } from 'utils/toasts';
import Stepper from './components/Stepper';
import StepperControl from './components/StepperControl';
import { UseContextProvider } from './contexts/StepperContext';
import CompanyDetails from './components/steps/CompanyDetails';
import CompanyAddress from './components/steps/CompanyAddress';
import Card from 'components/card';
import { post, put, get, getUserId } from 'api';
import { europeanCountries } from 'constants/countries';

const NewProject = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [billingInfo, setBillingInfo] = useState({
    isVatPayer: false,
  });
  const [dataExisted, setDataExisted] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const checkForVatPayer = () => {
    const { country, isVatPayer } = billingInfo;
    if (country === 'Estonia') {
      return false;
    } else if (!europeanCountries.includes(country)) {
      return true;
    } else if (europeanCountries.includes(country) && isVatPayer === true) {
      return true;
    } else if (europeanCountries.includes(country) && isVatPayer === false) {
      return false;
    }
  };

  const fetchBillingInfo = async () => {
    const userId = getUserId();
    await get(`/api/company/user/${userId}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.message !== 'Company not found') {
          setBillingInfo(data.Company);
          setDataExisted(true);
        } else {
          setDataExisted(false);
        }
      });
  };

  useEffect(() => {
    fetchBillingInfo();
  }, []);

  const steps = [
    { stepNo: 1, name: 'Details' },
    { stepNo: 2, name: 'Address' },
  ];

  const displayStep = (step) => {
    switch (step.stepNo) {
      case 1:
        return (
          <CompanyDetails
            billingInfo={billingInfo}
            updateBillingInfo={updateBillingInfo}
          />
        );
      case 2:
        return (
          <CompanyAddress
            billingInfo={billingInfo}
            updateBillingInfo={updateBillingInfo}
          />
        );
      default:
    }
  };

  const updateBillingInfo = (info) => {
    setBillingInfo((prevInfo) => ({ ...prevInfo, ...info }));
  };

  const handleClick = (direction) => {
    let newStep = currentStep;
    direction === 'next' ? newStep++ : newStep--;
    newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);
  };

  const handleSubmit = async () => {
    setSubmitting(true);
    const userId = getUserId();
    const companyName = billingInfo.companyName;
    const billingEmail = billingInfo.billingEmail;
    const vatNumber = billingInfo.vatNumber;
    const companyCode = billingInfo.companyCode;
    const address1 = billingInfo.address1;
    const address2 = billingInfo.address2;
    const city = billingInfo.city;
    const country = billingInfo.country;
    const state = billingInfo.state;
    const zipCode = billingInfo.zipCode;
    const isVatPayer = checkForVatPayer();

    const data = {
      userId,
      companyName,
      billingEmail,
      vatNumber,
      companyCode,
      address1,
      address2,
      city,
      country,
      state,
      zipCode,
      isVatPayer,
    };
    if (isVatPayer === '' || isVatPayer === undefined) {
      toastError('Please select if you are a VAT payer!');
      setSubmitting(false);
      return;
    }
    if (!address1 || !city || !country || !zipCode) {
      toastError('Please fill in all the required fields');
      setSubmitting(false);
      return;
    }
    setSubmitting(false);
    await post('/api/company', data).then((res) => {
      if (res.status === 200) {
        toastSuccess(
          'Your account is under review, you can add project right now for your campaign',
        );
        fetchBillingInfo();
        setTimeout(() => {
          setSubmitting(false);
          navigate(-1);
        }, 2500);
      } else {
        toastError('Something went wrong!');
      }
    });
  };

  const handleUpdate = async () => {
    const companyId = billingInfo.id;
    const companyName = billingInfo.companyName;
    const billingEmail = billingInfo.billingEmail;
    const vatNumber = billingInfo.vatNumber;
    const companyCode = billingInfo.companyCode;
    const address1 = billingInfo.address1;
    const address2 = billingInfo.address2;
    const city = billingInfo.city;
    const country = billingInfo.country;
    const state = billingInfo.state;
    const zipCode = billingInfo.zipCode;
    const isVatPayer = billingInfo.isVatPayer;
    const data = {
      companyName,
      billingEmail,
      vatNumber,
      companyCode,
      address1,
      address2,
      city,
      country,
      state,
      zipCode,
      isVatPayer,
    };
    await put(`/api/company/${companyId}`, data).then((res) => {
      if (res.status === 200) {
        toastSuccess('Company information updated successfully!');
        fetchBillingInfo();
        setCurrentStep(1);
      } else {
        toastError('Something went wrong!');
      }
    });
  };

  return (
    <div className="mt-3 h-full w-full">
      <ToastContainer
        position="top-center"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="h-[350px] w-full rounded-[20px] bg-gradient-to-br from-brand-400 to-brand-600 md:h-[390px]" />
      <div className="w-md:2/3 mx-auto h-full w-5/6 md:px-3  3xl:w-7/12">
        <div className="m-auto -mt-[280px] w-[50%] pb-10 md:-mt-[240px] md:px-[0px]">
          <Stepper
            action={setCurrentStep}
            steps={steps}
            currentStep={currentStep}
          />
        </div>

        <Card extra={'h-full mx-auto pb-3'}>
          <div className="rounded-[20px]">
            <UseContextProvider>
              {displayStep(steps[currentStep - 1])}
            </UseContextProvider>
          </div>
          {/* navigation button */}
          <StepperControl
            handleClick={handleClick}
            currentStep={currentStep}
            steps={steps}
            handleSubmit={handleSubmit}
            dataExisted={dataExisted}
            handleUpdate={handleUpdate}
            submitting={submitting}
            billingInfo={billingInfo}
          />
        </Card>
      </div>
    </div>
  );
};

export default NewProject;
