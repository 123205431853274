function Chat(props) {
  const { photo, active, name, time, message, last, newMessage } = props;

  return (
    <div
      className={`flex w-full items-center gap-3 px-2 ${
        last ? 'pb-3 pt-6' : 'border-b border-gray-100 py-6'
      }  cursor-pointer dark:!border-navy-700 lg:cursor-pointer`}
    >
      <div className="relative h-[50px] !w-[60px] rounded-full">
        <img className="h-full w-full rounded-full" src={photo} alt="" />
        {active ? (
          <div className="absolute bottom-0 right-0 h-4 w-4 rounded-full border-2 border-white bg-green-500"></div>
        ) : (
          <div className="absolute bottom-0 right-0 h-4 w-4 rounded-full border-2 border-white bg-gray-500"></div>
        )}
      </div>

      {/*  */}
      <div className="w-full">
        <div className="flex w-full flex-col justify-between md:flex-row md:items-center">
          <div className="relative flex flex-col">
            {newMessage && (
              <div className="absolute -left-16 -top-2 flex h-5 w-5 items-center justify-center rounded-full bg-red-500 text-xs text-white">
                <p className="text-xs font-bold">{newMessage}</p>
              </div>
            )}
            <h1 className="text-md font-bold capitalize text-navy-700 dark:text-white md:text-xl">
              {' '}
              {name}{' '}
            </h1>
            <p className="text-sm font-normal tracking-normal text-navy-700 dark:text-white">
              {message}{' '}
            </p>
          </div>
          <div className="flex w-full flex-row md:w-auto md:flex-col md:items-center md:justify-center">
            <p className="text-sm font-medium text-gray-400">
              {time === '1970-01-01 03:00'
                ? ''
                : '1970-01-01 08:00'
                ? ''
                : 'Last seen online'}
            </p>
            <h4 className="text-sm font-medium text-gray-400 ">
              {time === '1970-01-01 03:00'
                ? 'Not used messages yet'
                : '1970-01-01 08:00'
                ? ''
                : time}{' '}
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Chat;
