import React, {
  useMemo,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useLocation } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import { ToastContainer } from 'react-toastify';
import { toastSuccess, toastError } from 'utils/toasts';
import Card from 'components/card';
import Checkbox from 'components/checkbox';
import { CartContext } from 'contexts/CartContext';
import { ColumnsViewContext } from 'contexts/ColumnsViewContext';
import { ProjectContext } from 'contexts/ProjectContext';
import { FavoritesContext } from 'contexts/FavoritesContext';
import { BlacklistContext } from 'contexts/BlacklistContext';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { FiSearch } from 'react-icons/fi';
import {
  BsCartDash,
  BsCartPlus,
  BsChevronDown,
  BsChevronUp,
  BsStarFill,
  BsStar,
} from 'react-icons/bs';
import { MdChevronLeft, MdChevronRight, MdInfoOutline } from 'react-icons/md';
import { IoMdFunnel } from 'react-icons/io';
import OrderActions from './OrderActions';
import BulkActions from './BulkActions';
import TooltipHorizon from 'components/tooltip';
import { get, getUserRole } from 'api';
import ahrefs from 'assets/img/logo/ahrefs.png';
import DomainModal from './DomainModal';
import DataTable from 'components/loaders/TableSkeleton';

const MarketplaceTable = ({ filters, setActiveComponent }) => {
  const { cartItems, addToCart, removeFromCart } = useContext(CartContext);
  const { marketplaceColumnsView } = useContext(ColumnsViewContext);
  const { currentProject } = useContext(ProjectContext);
  const { favorites, removeFromFavorites, addToFavorites } =
    useContext(FavoritesContext);
  const { blacklist } = useContext(BlacklistContext);
  const [bulkSelect, setBulkSelect] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [marketplaceData, setMarketplaceData] = useState([]);
  const pageName = 'marketplace';
  const [companyDiscount, setCompanyDiscount] = useState(0);
  const [dataLoaded, setDataLoaded] = useState(false);
  const userRole = getUserRole();
  const currencySymbol = localStorage.getItem('currency');
  const [domainCopied, setDomainCopied] = useState({});
  const [isDomainModalOpen, setIsDomainModalOpen] = useState(false);
  const [domainModalData, setDomainModalData] = useState({});
  const [isProjectIgaming, setIsProjectIgaming] = useState(false);
  const [hasErrorBeenShown, setHasErrorBeenShown] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const domain = params.get('domain');

  useEffect(() => {
    if (currentProject && currentProject.id) {
      setIsLoading(false);
    } else if (Object.keys(currentProject).length === 0) {
      setIsLoading(true);
    }
  }, [currentProject]);

  useEffect(() => {
    if (marketplaceData.length > 0) {
      setDataLoaded(true);
    }
  }, [marketplaceData]);

  useEffect(() => {
    if (domain && marketplaceData.length > 0) {
      const filteredData = marketplaceData.filter(
        (item) => item.domain === domain,
      );
      if (filteredData.length > 0) {
        setIsDomainModalOpen(true);
        setDomainModalData(filteredData[0]);
      }
    }
  }, [domain, domainModalData, marketplaceData]);

  const checkIfFavorite = useCallback(
    (id) => {
      return Object.values(favorites).findIndex((item) => item.id === id) >= 0;
    },
    [favorites],
  );

  const checkColumnView = useCallback(
    (columnName) => {
      return marketplaceColumnsView.find((view) => view.Header === columnName)
        .showColumn;
    },
    [marketplaceColumnsView],
  );

  useEffect(() => {
    if (currentProject && currentProject.company) {
      setCompanyDiscount(parseFloat(currentProject.company.discount));
      setIsProjectIgaming(currentProject.isIgaming);
    }
  }, [currentProject]);

  useEffect(() => {
    const fetchData = async (url) => {
      const response = await get(url);
      const data = await response.json();

      const encryptedData = data.data;
      const bytes = CryptoJS.AES.decrypt(
        encryptedData,
        process.env.REACT_APP_CRYPTO_SECRET,
      );
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

      if (!decryptedData) {
        return;
      }

      const newArray = decryptedData
        .filter((item) =>
          isProjectIgaming
            ? item.rejectedTopics === null ||
              (!item.rejectedTopics.includes('iGaming') &&
                !item.rejectedTopics.includes('Adult') &&
                !item.rejectedTopics.includes('CBD'))
            : true,
        )
        .map((item) => {
          // const price = isProjectIgaming ? item.price : item.generalPrice;
          const price = item.price;

          return {
            ...item,
            domainRating: item.marketahref[0]?.domainRating,
            organicTraffic: item.marketahref[0]?.organicTraffic,
            organicKeywords: item.marketahref[0]?.organicKeywords,
            referringDomains: item.marketahref[0]?.referringDomains,
            linkedDomains: item.marketahref[0]?.linkedDomains,
            price: parseFloat(
              Number(price) - Number(price) * (companyDiscount / 100),
            ).toFixed(2),
          };
        });

      setMarketplaceData(newArray);
    };

    if (isLoading) {
      return;
    }

    if (currentProject.id && userRole !== 'admin') {
      fetchData(`/api/marketplace/project/${currentProject.id}`);
    } else if (userRole === 'admin' || !currentProject.id) {
      fetchData(`/api/marketplace`);
    }
  }, [
    companyDiscount,
    currentProject,
    userRole,
    isProjectIgaming,
    hasErrorBeenShown,
    isLoading,
  ]);

  useEffect(() => {
    if (!currentProject.id && !hasErrorBeenShown) {
      toastError(
        'Please select or add a project to view marketplace data',
        5000,
      );
      setHasErrorBeenShown(true);
    }
  }, [currentProject, hasErrorBeenShown]);

  const handleSelectRow = (e, row) => {
    if (e.target.checked) {
      setSelectedRows([...selectedRows, row]);
    } else {
      setSelectedRows(selectedRows.filter((item) => item.id !== row.id));
    }
  };

  const filterByRange = (data, range, key) => {
    if (range.from) {
      data = data.filter((item) => item[key] >= range.from);
    }
    if (range.to) {
      data = data.filter((item) => item[key] <= range.to);
    }
    return data;
  };

  const filterByIncludes = (data, filter, keys, checkRating = false) => {
    if (filter.length > 0) {
      data = data.filter((item) =>
        filter.some(
          (f) =>
            (!checkRating && keys.some((key) => item[key] === f.value)) ||
            (checkRating &&
              item.rating !== null &&
              item.rating.toLowerCase().includes(f.label.toLowerCase())),
        ),
      );
    }
    return data;
  };

  // const filterByTopic = (data, filter, key) => {
  //   if (filter.length > 0) {
  //     data = data.filter(
  //       (item) =>
  //         !item[key] ||
  //         !filter.some((f) =>
  //           item[key].toLowerCase().includes(f.value.toLowerCase()),
  //         ),
  //     );
  //   }
  //   return data;
  // };

  const filteredData = useMemo(() => {
    let filteredData = marketplaceData;
    if (filters.general) {
      // filteredData = filterByTopic(
      //   filteredData,
      //   filters.general.acceptedTopic,
      //   'rejectedTopics',
      // );
      filteredData = filterByIncludes(
        filteredData,
        filters.general.category,
        ['categoryId'],
        true,
      );
      filteredData = filterByIncludes(filteredData, filters.general.country, [
        'firstCountryId',
        'secondCountryId',
        'thirdCountryId',
      ]);
      filteredData = filterByIncludes(
        filteredData,
        filters.general.doFollowLinks,
        ['doFollowLinks'],
      );
      filteredData = filterByRange(
        filteredData,
        filters.general.backlinkPrice,
        'price',
      );
    }
    if (filters.ahrefs) {
      filteredData = filterByRange(
        filteredData,
        filters.ahrefs.domainRating,
        'domainRating',
      );
      filteredData = filterByRange(
        filteredData,
        filters.ahrefs.organicTraffic,
        'organicTraffic',
      );
      filteredData = filterByRange(
        filteredData,
        filters.ahrefs.referringDomains,
        'referringDomains',
      );
      filteredData = filterByRange(
        filteredData,
        filters.ahrefs.linkedDomains,
        'linkedDomains',
      );
      filteredData = filterByRange(
        filteredData,
        filters.ahrefs.linkedDomains,
        'linkedDomains',
      );
      filteredData = filterByRange(
        filteredData,
        filters.ahrefs.organicKeywords,
        'organicKeywords',
      );
    }
    return filteredData;
  }, [filters, marketplaceData]);

  const columns = useMemo(
    () => marketplaceColumnsView,
    [marketplaceColumnsView],
  );

  const data = useMemo(() => filteredData, [filteredData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 15 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,

    prepareRow,
    nextPage,
    previousPage,

    setGlobalFilter,
    setPageSize,
    state,
    rows,
  } = tableInstance;

  const { pageSize } = state;

  const handleBulkSelect = () => {
    setBulkSelect(!bulkSelect);
    if (bulkSelect) {
      setSelectedRows([]);
    } else {
      setSelectedRows(page.map((row) => row.original));
    }
  };

  return (
    <Card extra={'w-full sm:overflow-auto p-4'}>
      <ToastContainer
        position="top-center"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {isDomainModalOpen && (
        <DomainModal
          modalOpen={true}
          domainModalData={domainModalData}
          setIsDomainModalOpen={setIsDomainModalOpen}
        />
      )}
      <header className="relative flex items-center justify-between">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          Marketplace
        </div>
      </header>
      {/* Search */}
      <div
        onChange={(e) => setGlobalFilter(e.target.value)}
        className="mt-2 flex w-full items-center rounded-[10px] bg-white shadow-2xl shadow-white dark:!bg-navy-800 dark:shadow-none md:w-3/4 md:w-[400px]"
      >
        <div className="flex h-9 w-full flex-grow items-center rounded-[10px] bg-lightPrimary text-sm text-gray-600 dark:!bg-navy-900">
          <FiSearch className="mx-2 h-4 w-4 !text-gray-700 dark:!text-white" />
          <input
            type="text"
            placeholder="Search for domain.."
            className="block h-full w-full rounded-full bg-lightPrimary text-sm text-navy-700 outline-none dark:!bg-navy-900 dark:text-white"
          />
        </div>
      </div>
      {/* Bulk actions */}
      <div className="mt-4 flex items-center justify-start">
        <div className="flex items-center gap-2">
          <Checkbox checked={bulkSelect} onChange={handleBulkSelect} />
          <span className="text-sm font-medium text-gray-600">Select all</span>
        </div>
        <div className="ml-2 flex items-center gap-2">
          <BulkActions
            data={selectedRows}
            pageName={pageName}
            setActiveComponent={setActiveComponent}
          />
        </div>
      </div>
      {!dataLoaded ? (
        <DataTable />
      ) : (
        <div className="mt-8 h-full max-w-[100%] overflow-x-auto">
          <table
            {...getTableProps()}
            className="w-full table-auto border-collapse whitespace-nowrap"
          >
            <thead>
              {headerGroups.map((headerGroup, index) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => {
                    const isActionColumn = column.Header === 'ORDER ACTIONS';
                    const isAhrefsColumn =
                      column.Header === 'DOMAIN RATING' ||
                      column.Header === 'ORGANIC TRAFFIC' ||
                      column.Header === 'ORGANIC KEYWORDS' ||
                      column.Header === 'REFERRING DOMAINS' ||
                      column.Header === 'LINKED DOMAINS' ||
                      column.Header === 'DO-FOLLOW LINKS';
                    const showColumn = marketplaceColumnsView.find(
                      (view) =>
                        view.Header === column.Header && view.showColumn,
                    );
                    if (!showColumn) return null;
                    return (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps(),
                        )}
                        className="border-b border-gray-200 pb-[10px] pr-6 dark:!border-navy-700"
                        key={index}
                      >
                        <div className="text-start text-xs font-bold tracking-wide text-gray-600 lg:text-xs">
                          <div className="flex items-center gap-2">
                            {isActionColumn ? (
                              ''
                            ) : isAhrefsColumn ? (
                              <TooltipHorizon
                                extra="max-w-[200px] overflow-y-auto"
                                trigger={
                                  <p className="cursor-pointer overflow-hidden overflow-ellipsis whitespace-nowrap">
                                    {column.render('Header') === 'DOMAIN RATING'
                                      ? 'DR'
                                      : column.render('Header') ===
                                        'ORGANIC TRAFFIC'
                                      ? 'OT'
                                      : column.render('Header') ===
                                        'ORGANIC KEYWORDS'
                                      ? 'OK'
                                      : column.render('Header') ===
                                        'REFERRING DOMAINS'
                                      ? 'RD'
                                      : column.render('Header') ===
                                        'LINKED DOMAINS'
                                      ? 'LD'
                                      : 'DFL'}
                                  </p>
                                }
                                content={
                                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                                    {column.render('Header')}
                                  </p>
                                }
                                placement="top"
                              />
                            ) : (
                              column.render('Header')
                            )}
                            {column.isSorted &&
                            column.Header !== 'ORDER ACTIONS' ? (
                              column.isSortedDesc ? (
                                <BsChevronUp className="mr-1 inline h-4 w-4" />
                              ) : (
                                <BsChevronDown className="mr-1 inline h-4 w-4" />
                              )
                            ) : (
                              column.Header !== 'ORDER ACTIONS' && (
                                <IoMdFunnel className="mr-1 inline h-4 w-4" />
                              )
                            )}
                          </div>
                        </div>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page
                .filter(
                  (row) =>
                    Object.values(blacklist).findIndex(
                      (item) => item.id === row.original.id,
                    ) < 0,
                )
                .map((row, index) => {
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps()}
                      key={index}
                      className="items-center border-b border-gray-200 dark:!border-white/10"
                    >
                      {row.cells
                        .filter((cell) => checkColumnView(cell.column.Header))
                        .map((cell, index) => {
                          let data = '';
                          if (cell.column.Header === 'ID') {
                            data = (
                              <div className="flex w-[100px] items-center gap-2">
                                <Checkbox
                                  checked={selectedRows.includes(
                                    cell.row.original,
                                  )}
                                  onChange={(e) =>
                                    handleSelectRow(e, cell.row.original)
                                  }
                                />
                                <div className="text-sm font-medium text-navy-700 dark:text-white">
                                  #{cell.value}
                                </div>
                                <MdInfoOutline
                                  className="cursor-pointer text-lg text-gray-500 hover:text-gray-700 dark:text-white dark:hover:text-gray-300"
                                  onClick={() => {
                                    setIsDomainModalOpen(true);
                                    setDomainModalData(cell.row.original);
                                  }}
                                />
                              </div>
                            );
                          } else if (cell.column.Header === 'DOMAIN') {
                            const orderId = cell.row.original.id;
                            const domain = cell.value;
                            data = (
                              <div className="mr-4 flex items-center gap-2">
                                <Checkbox
                                  checked={selectedRows.includes(
                                    cell.row.original,
                                  )}
                                  onChange={(e) =>
                                    handleSelectRow(e, cell.row.original)
                                  }
                                />
                                <MdInfoOutline
                                  className="cursor-pointer text-xl text-gray-500 hover:text-gray-700 dark:text-white dark:hover:text-gray-300"
                                  onClick={() => {
                                    setIsDomainModalOpen(true);
                                    setDomainModalData(cell.row.original);
                                  }}
                                />
                                <div>
                                  <a
                                    href={`https://app.ahrefs.com/v2-site-explorer/overview?target=${domain}`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <img
                                      src={ahrefs}
                                      alt="ahrefs"
                                      className="h-[16px] w-[16px]"
                                    />
                                  </a>
                                </div>
                                <TooltipHorizon
                                  extra="max-h-[180px] overflow-y-auto"
                                  trigger={
                                    <p
                                      className="w-[160px] cursor-pointer overflow-hidden overflow-ellipsis whitespace-nowrap text-sm
                                    font-bold text-navy-700 dark:text-white"
                                      onClick={() => {
                                        navigator.clipboard.writeText(
                                          cell.value,
                                        );
                                        setDomainCopied((prevCopied) => ({
                                          ...prevCopied,
                                          [orderId]: true,
                                        }));
                                        setTimeout(() => {
                                          setDomainCopied((prevCopied) => ({
                                            ...prevCopied,
                                            [orderId]: false,
                                          }));
                                        }, 500);
                                      }}
                                    >
                                      {domainCopied[orderId]
                                        ? 'Domain copied!'
                                        : domain}
                                    </p>
                                  }
                                  content={
                                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                                      {domain}
                                    </p>
                                  }
                                  placement="top"
                                />
                              </div>
                            );
                          } else if (cell.column.Header === 'DOMAIN RATING') {
                            data = (
                              <div className="w-[80px] text-sm font-bold text-navy-700 dark:text-white">
                                {cell.value && cell.value.toLocaleString()}
                              </div>
                            );
                          } else if (cell.column.Header === 'PRICE') {
                            data = (
                              <div className="w-[80px] text-sm font-bold text-navy-700 dark:text-white">
                                {currencySymbol}
                                {cell.value && cell.value}
                              </div>
                            );
                          } else if (cell.column.Header === 'ORGANIC TRAFFIC') {
                            data = (
                              <div className="w-[80px] text-sm font-bold text-navy-700 dark:text-white">
                                {cell.value && cell.value.toLocaleString()}
                              </div>
                            );
                          } else if (
                            cell.column.Header === 'ORGANIC KEYWORDS'
                          ) {
                            data = (
                              <div className="w-[80px] text-sm font-bold text-navy-700 dark:text-white">
                                {cell.value && cell.value.toLocaleString()}
                              </div>
                            );
                          } else if (
                            cell.column.Header === 'REFERRING DOMAINS'
                          ) {
                            data = (
                              <div className="w-[80px] text-sm font-bold text-navy-700 dark:text-white">
                                {cell.value && cell.value.toLocaleString()}
                              </div>
                            );
                          } else if (cell.column.Header === 'LINKED DOMAINS') {
                            data = (
                              <div className="w-[80px] text-sm font-bold text-navy-700 dark:text-white">
                                {cell.value && cell.value.toLocaleString()}
                              </div>
                            );
                          } else if (cell.column.Header === 'DO-FOLLOW LINKS') {
                            data = (
                              <div className="w-[80px] text-sm font-bold text-navy-700 dark:text-white">
                                {cell.value && cell.value.toLocaleString()}
                              </div>
                            );
                          } else if (cell.column.Header === 'TIME') {
                            data = (
                              <div
                                className={`mr-4 flex h-7 w-[100px] items-center justify-center text-sm ${
                                  cell.value === 'PERMANENT'
                                    ? 'bg-green-100 dark:bg-green-50'
                                    : 'bg-blue-100 dark:bg-blue-50'
                                } rounded-[10px] text-base font-bold `}
                              >
                                <div
                                  className={`${
                                    cell.value === 'PERMANENT'
                                      ? 'text-green-500 '
                                      : 'text-blue-500'
                                  } uppercase `}
                                >
                                  {cell.value}
                                </div>
                              </div>
                            );
                          } else if (cell.column.Header === 'DELIVERY TIME') {
                            data = (
                              <div className="w-[80px] text-sm font-bold text-navy-700 dark:text-white">
                                {cell.value && cell.value} days
                              </div>
                            );
                          } else if (cell.column.Header === 'ORDER ACTIONS') {
                            const id = cell.row.original.id;
                            const isFavorite = checkIfFavorite(id);
                            data = (
                              <div className="flex w-[100px] items-center justify-start gap-1">
                                {cartItems.findIndex(
                                  (cartItem) =>
                                    cartItem.uniqueId.includes(pageName) &&
                                    cartItem.id === id,
                                ) < 0 ? (
                                  <span
                                    className="cursor-pointer text-xl text-blue-500 hover:text-gray-700"
                                    onClick={() =>
                                      addToCart(cell.row.original, pageName)
                                    }
                                  >
                                    <BsCartPlus />
                                  </span>
                                ) : (
                                  <span
                                    className="cursor-pointer text-xl text-blue-500 hover:text-gray-700"
                                    onClick={() => removeFromCart(id, pageName)}
                                  >
                                    <BsCartDash />
                                  </span>
                                )}
                                <OrderActions
                                  data={cell.row.original}
                                  pageName={pageName}
                                />
                                {isFavorite ? (
                                  <BsStarFill
                                    className="ml-2 cursor-pointer text-lg text-yellow-500 hover:text-yellow-600"
                                    onClick={() => removeFromFavorites(id)}
                                  />
                                ) : (
                                  <BsStar
                                    className="ml-2 cursor-pointer text-lg text-brand-300 hover:text-brand-400"
                                    onClick={() =>
                                      addToFavorites(cell.row.original)
                                    }
                                  />
                                )}
                              </div>
                            );
                          }
                          return (
                            <td
                              {...cell.getCellProps()}
                              key={index}
                              className="pb-[16px] pt-[14px] sm:text-[14px]"
                            >
                              {data}
                            </td>
                          );
                        })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}
      {/* pagination */}
      <div className="mt-2 flex h-[80px] w-full items-center justify-between">
        {/* left side */}
        <div className="flex items-center gap-2">
          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
            className="h-10 w-[70px] rounded-xl border border-gray-200 px-2 text-sm  text-gray-600 dark:!border-white/10 dark:!bg-navy-800"
            name="pagination-select"
            id="pagination-select"
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </select>
          <p className="text-sm text-gray-700">
            Showing {page.length} of {rows.length}
          </p>
        </div>
        {/* right side */}
        <div className="flex items-center gap-2">
          <button
            id="pagination-previous"
            onClick={() => previousPage()}
            className={`linear flex items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
          >
            <MdChevronLeft />
          </button>

          <p className="text-sm text-gray-700">
            {state.pageIndex + 1} of{' '}
            {Math.ceil(rows.length / state.pageSize) || 1}
          </p>

          <button
            onClick={() => nextPage()}
            id="pagination-next"
            className={`linear flex items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
          >
            <MdChevronRight />
          </button>
        </div>
      </div>
    </Card>
  );
};

export default MarketplaceTable;
