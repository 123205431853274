import React, { useMemo, useContext, useState } from 'react';
import Card from 'components/card';
import Checkbox from 'components/checkbox';
import { CartContext } from 'contexts/CartContext';
import { ColumnsViewContext } from 'contexts/ColumnsViewContext';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { FiSearch } from 'react-icons/fi';
import {
  BsCartDash,
  BsCartPlus,
  BsChevronDown,
  BsChevronUp,
} from 'react-icons/bs';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { IoMdFunnel } from 'react-icons/io';
import OrderActions from './OrderActions';
import BulkActions from './BulkActions';
import TooltipHorizon from 'components/tooltip';
import ahrefs from 'assets/img/logo/ahrefs.png';

const CompetitorTable = (props) => {
  const { tableData } = props;
  const { cartItems, addToCart, removeFromCart } = useContext(CartContext);
  const { competitorColumnsView } = useContext(ColumnsViewContext);
  const [bulkSelect, setBulkSelect] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const pageName = 'competitors';
  const currencySymbol = localStorage.getItem('currency');
  const [domainCopied, setDomainCopied] = useState({});

  const checkColumnView = (columnName) => {
    return competitorColumnsView.find((view) => view.Header === columnName)
      .showColumn;
  };

  const handleBulkSelect = () => {
    setBulkSelect(!bulkSelect);
    if (bulkSelect) {
      setSelectedRows([]);
    } else {
      setSelectedRows(tableData);
    }
  };

  const handleSelectRow = (e, row) => {
    if (e.target.checked) {
      setSelectedRows([...selectedRows, row]);
    } else {
      setSelectedRows(
        selectedRows.filter((item) => item.orderNo !== row.orderNo),
      );
    }
  };

  const columns = useMemo(() => competitorColumnsView, [competitorColumnsView]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 15 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,

    prepareRow,
    nextPage,
    previousPage,

    setGlobalFilter,
    setPageSize,
    state,
    rows,
  } = tableInstance;

  const { pageSize } = state;

  return (
    <Card extra={'w-full sm:overflow-auto p-4'}>
      <header className="relative flex items-center justify-between">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          Competitor
        </div>
      </header>
      {/* Search */}
      <div
        onChange={(e) => setGlobalFilter(e.target.value)}
        className="flex w-3/4 items-center rounded-[10px] bg-white shadow-2xl shadow-white dark:!bg-navy-800 dark:shadow-none md:mt-2 md:w-[400px]"
      >
        <div className="flex h-9 w-full flex-grow items-center rounded-[10px] bg-lightPrimary text-sm text-gray-600 dark:!bg-navy-900">
          <FiSearch className="mx-2 h-4 w-4 !text-gray-700 dark:!text-white" />
          <input
            type="text"
            placeholder="Search for domain.."
            className="block h-full w-full rounded-full bg-lightPrimary text-sm text-navy-700 outline-none dark:!bg-navy-900 dark:text-white"
          />
        </div>
      </div>
      {/* Bulk actions */}
      <div className="mt-4 flex items-center justify-start">
        <div className="flex items-center gap-2">
          <Checkbox checked={bulkSelect} onChange={handleBulkSelect} />
          <span className="text-sm font-medium text-gray-600">Select all</span>
        </div>
        <div className="ml-2 flex items-center gap-2">
          <BulkActions data={selectedRows} pageName={pageName} />
        </div>
      </div>
      <div className="mt-8 h-full max-w-[100%] overflow-x-auto">
        <table
          {...getTableProps()}
          className="w-full table-auto border-collapse whitespace-nowrap"
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => {
                  const isActionColumn = column.Header === 'ORDER ACTIONS';
                  const showColumn = competitorColumnsView.find(
                    (view) => view.Header === column.Header && view.showColumn,
                  );
                  if (!showColumn) return null;
                  return (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className="border-b border-gray-200 pb-[10px] pr-6 dark:!border-navy-700"
                      key={index}
                    >
                      <div className="mr-[48px] text-start text-xs font-bold tracking-wide text-gray-600 lg:text-xs">
                        <div className="flex items-center gap-2">
                          {isActionColumn ? '' : column.render('Header')}
                          {column.isSorted &&
                          column.Header !== 'ORDER ACTIONS' ? (
                            column.isSortedDesc ? (
                              <BsChevronUp className="mr-1 inline h-4 w-4" />
                            ) : (
                              <BsChevronDown className="mr-1 inline h-4 w-4" />
                            )
                          ) : (
                            column.Header !== 'ORDER ACTIONS' && (
                              <IoMdFunnel className="mr-1 inline h-4 w-4" />
                            )
                          )}
                        </div>
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  key={index}
                  className=" items-center border-b border-gray-200 dark:!border-white/10"
                >
                  {row.cells
                    .filter((cell) => checkColumnView(cell.column.Header))
                    .map((cell, index) => {
                      let data = '';
                      if (cell.column.Header === 'ID') {
                        data = (
                          <div className="flex items-center gap-2">
                            <Checkbox
                              checked={selectedRows.includes(cell.row.original)}
                              onChange={(e) =>
                                handleSelectRow(e, cell.row.original)
                              }
                            />
                            <div className="text-sm font-medium text-navy-700 dark:text-white">
                              {cell.value}
                            </div>
                          </div>
                        );
                      } else if (cell.column.Header === 'DOMAIN') {
                        const orderId = cell.row.original.id;
                        const domain = cell.value;
                        data = (
                          <div className="flex items-center gap-2">
                            <div>
                              <a
                                href={`https://app.ahrefs.com/v2-site-explorer/overview?target=${domain}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  src={ahrefs}
                                  alt="ahrefs"
                                  className="h-[16px] w-[16px]"
                                />
                              </a>
                            </div>
                            <TooltipHorizon
                              extra="max-h-[200px] overflow-y-auto"
                              trigger={
                                <p
                                  className="w-[180px] cursor-pointer text-sm font-bold text-navy-700 dark:text-white"
                                  onClick={() => {
                                    navigator.clipboard.writeText(cell.value);
                                    setDomainCopied((prevCopied) => ({
                                      ...prevCopied,
                                      [orderId]: true,
                                    }));
                                    setTimeout(() => {
                                      setDomainCopied((prevCopied) => ({
                                        ...prevCopied,
                                        [orderId]: false,
                                      }));
                                    }, 500);
                                  }}
                                >
                                  {domainCopied[orderId]
                                    ? 'Domain copied!'
                                    : domain && domain.slice(0, 18)}
                                  {!domainCopied[orderId] &&
                                    domain &&
                                    domain.length > 18 && <span>...</span>}
                                </p>
                              }
                              content={
                                <p className="text-sm font-bold text-navy-700 dark:text-white">
                                  {domain}
                                </p>
                              }
                              placement="top"
                            />
                          </div>
                        );
                      } else if (cell.column.Header === 'COMPETITOR') {
                        data = (
                          <TooltipHorizon
                            trigger={
                              <p className="text-sm font-bold text-navy-700 dark:text-white">
                                {cell.value && cell.value.slice(0, 10)}
                                {cell.value && cell.value.length > 10 && (
                                  <span>...</span>
                                )}
                              </p>
                            }
                            content={
                              <p className="text-sm font-bold text-navy-700 dark:text-white">
                                {cell.value}
                              </p>
                            }
                            placement="top"
                          />
                        );
                      } else if (cell.column.Header === 'TIME') {
                        data = (
                          <div
                            className={`flex h-7 w-[110px] items-center justify-center text-sm ${
                              cell.value === 'Outreached'
                                ? 'bg-green-100 dark:bg-green-50'
                                : 'bg-red-100 dark:bg-red-50'
                            } rounded-[10px] text-base font-bold `}
                          >
                            <div
                              className={`${
                                cell.value === 'Outreached'
                                  ? 'text-green-500 '
                                  : 'text-red-500'
                              } uppercase `}
                            >
                              {cell.value}
                            </div>
                          </div>
                        );
                      } else if (cell.column.Header === 'METRICS') {
                        data = (
                          <div className="text-sm font-medium text-navy-700 dark:text-white">
                            {cell.value}
                          </div>
                        );
                      } else if (cell.column.Header === 'DR') {
                        data = (
                          <div className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.value}
                          </div>
                        );
                      } else if (cell.column.Header === 'ORGANIC TRAFFIC') {
                        data = (
                          <div className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.value.toLocaleString()}
                          </div>
                        );
                      } else if (cell.column.Header === 'ORGANIC KEYWORDS') {
                        data = (
                          <div className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.value && cell.value.toLocaleString()}
                          </div>
                        );
                      } else if (cell.column.Header === 'PRICE') {
                        data = (
                          <div className="text-sm font-bold text-navy-700 dark:text-white">
                            {currencySymbol}
                            {cell.value.toLocaleString()}
                          </div>
                        );
                      } else if (cell.column.Header === 'UPDATED') {
                        data = (
                          <div className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.value}
                          </div>
                        );
                      } else if (cell.column.Header === 'ORDER ACTIONS') {
                        data = (
                          <div className="flex items-center gap-1">
                            {cartItems.findIndex(
                              (cartItem) =>
                                cartItem.uniqueId.includes(pageName) &&
                                cartItem.id === cell.row.original.id,
                            ) < 0 ? (
                              <span
                                className="cursor-pointer text-xl text-blue-500 hover:text-gray-700"
                                onClick={() =>
                                  addToCart(cell.row.original, pageName)
                                }
                              >
                                <BsCartPlus />
                              </span>
                            ) : (
                              <span
                                className="cursor-pointer text-xl text-blue-500 hover:text-gray-700"
                                onClick={() =>
                                  removeFromCart(cell.row.original.id, pageName)
                                }
                              >
                                <BsCartDash />
                              </span>
                            )}
                            <OrderActions
                              data={cell.row.original}
                              pageName={pageName}
                            />
                          </div>
                        );
                      }
                      return (
                        <td
                          {...cell.getCellProps()}
                          key={index}
                          className="pb-[16px] pt-[14px] sm:text-[14px]"
                        >
                          {data}
                        </td>
                      );
                    })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* pagination */}
      <div className="mt-2 flex h-[80px] w-full items-center justify-between">
        {/* left side */}
        <div className="flex items-center gap-2">
          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
            className="h-10 w-[70px] rounded-xl border border-gray-200 px-2 text-sm  text-gray-600 dark:!border-white/10 dark:!bg-navy-800"
            name=""
            id=""
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </select>
          <p className="text-sm text-gray-700">
            Showing {page.length} of {rows.length}
          </p>
        </div>
        {/* right side */}
        <div className="flex items-center gap-2">
          <button
            onClick={() => previousPage()}
            className={`linear flex items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
          >
            <MdChevronLeft />
          </button>

          <p className="text-sm text-gray-700">
            {state.pageIndex + 1} of {Math.ceil(tableData.length / pageSize)}
          </p>

          <button
            onClick={() => nextPage()}
            className={`linear flex items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
          >
            <MdChevronRight />
          </button>
        </div>
      </div>
    </Card>
  );
};

export default CompetitorTable;
