const InterestedCard = () => {
  return (
    <div className="h-full max-w-md rounded-[20px] bg-gradient-to-br from-brand-400 to-brand-600">
      <div className="flex h-full w-full flex-col items-center justify-center p-5">
        <h4 className="text-2xl font-bold text-white">
          Please, contact with support
        </h4>
        <p className="mt-[4px] text-sm font-medium text-white">
          Apologies for the inconvenience. Due to our significantly low fees for
          backlinks, we're not yet large enough to afford this API tool. If
          you're interested, please click this button. Once we grow
          sufficiently, we plan to integrate this tool into our system.
          Alternatively, we can connect the API exclusively to your account.
        </p>
        <button className="mt-5 rounded-full bg-white px-4 py-2 font-bold text-brand-600">
          Interested
        </button>
      </div>
    </div>
  );
};

export default InterestedCard;
