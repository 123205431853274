import React, { useEffect, useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Modal, ModalOverlay, ModalContent, ModalBody } from '@chakra-ui/modal';
import { useDisclosure } from '@chakra-ui/hooks';
import Card from 'components/card';
import { MdCheckCircle } from 'react-icons/md';
import { CartContext } from 'contexts/CartContext';

const DomainModal = ({ modalOpen, domainModalData, setIsDomainModalOpen }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { addToCart } = useContext(CartContext);
  const pageName = 'marketplace';

  useEffect(() => {
    if (modalOpen && domainModalData) {
      setSearchParams({});
    }
  }, [modalOpen, domainModalData, setSearchParams]);

  const handleClose = () => {
    onClose();
    setIsDomainModalOpen(false);
  };

  useEffect(() => {
    if (modalOpen && domainModalData) {
      onOpen();
    } else {
      onClose();
      setIsDomainModalOpen(false);
    }
  }, [modalOpen, onOpen, onClose, setIsDomainModalOpen, domainModalData]);

  if (!domainModalData) {
    return null;
  }

  const {
    domaininfo,
    firstCountry,
    secondCountry,
    thirdCountry,
    domain,
    price,
    time,
    domainRating,
    organicTraffic,
    referringDomains,
    organicKeywords,
    linkedDomains,
    condition,
    rejectedTopics,
    wordCount,
    doFollowLinks,
  } = domainModalData;

  const dInfo = domaininfo && domaininfo.length > 0 ? domaininfo[0] : [];
  const country1 =
    firstCountry && firstCountry.length > 0 ? firstCountry[0] : undefined;
  const country2 =
    secondCountry && secondCountry.length > 0 ? secondCountry[0] : undefined;
  const country3 =
    thirdCountry && thirdCountry.length > 0 ? thirdCountry[0] : undefined;

  return (
    <div>
      <Modal className="!z-[100]" isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay className="bg-[#000] !opacity-30" />
        <ModalContent className="top-[10vh] !m-auto !h-[80vh] !max-w-[1080px] md:top-[5vh]">
          <ModalBody>
            <Card extra={'w-full sm:overflow-auto p-4'}>
              <h2 className="text-3xl font-semibold">{domain}</h2>
              <div className="flex flex-row justify-between">
                <div className="flex flex-row items-center">
                  {dInfo?.category !== 'null' && (
                    <p className="text-gray-600">
                      {dInfo?.category ? dInfo.category : 'n/A'}
                    </p>
                  )}
                  <p className="text-gray-600">
                    &nbsp;· {country1?.country ? country1.country : 'n/A'}
                  </p>
                  {country2 && (
                    <p className="text-gray-600">
                      &nbsp;· {country2?.country ? country2.country : 'n/A'}
                    </p>
                  )}
                  {country3 && (
                    <p className="text-gray-600">
                      &nbsp;· {country3?.country ? country3.country : 'n/A'}
                    </p>
                  )}
                  <p className="text-gray-600">
                    &nbsp;· {price && Number(price).toFixed(2)} €
                  </p>
                </div>
                <div>
                  <button
                    className="rounded-md bg-blue-500 px-4 py-2 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                    onClick={() => {
                      addToCart(domainModalData, pageName);
                      handleClose();
                      navigate('/admin/marketplace/cart');
                    }}
                  >
                    Buy now
                  </button>
                  <button
                    className="bg-transparent ml-2 rounded-md border border-gray-500 px-4 py-2 hover:bg-gray-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
                    onClick={() => {
                      addToCart(domainModalData, pageName);
                      handleClose();
                    }}
                  >
                    Add to cart
                  </button>
                </div>
              </div>
              <div className="mt-3 h-[2px] bg-gray-200"></div>
              <div className="mt-3 flex flex-row justify-between">
                <div>
                  <h3 className="text-lg font-semibold">
                    Webmaster condition for publication
                  </h3>
                  {dInfo?.condition !== 'null' && (
                    <p className="">{condition}</p>
                  )}
                  <div className="mt-3">
                    <div className="flex flex-row items-center">
                      <MdCheckCircle className="mr-2 inline-block text-xl text-[#F9B934]" />
                      <p className="inline-block text-sm">
                        Accepting{' '}
                        <span className="font-semibold">
                          {doFollowLinks === null ? 'n/A' : doFollowLinks}
                        </span>{' '}
                        do follow links per article to your website
                      </p>
                    </div>
                    <div className="mt-1 flex flex-row items-center">
                      <MdCheckCircle className="mr-2 inline-block text-xl text-[#F9B934]" />
                      <p className="inline-block text-sm">
                        Word requirement:{' '}
                        <span className="font-semibold">
                          {wordCount === null ? 'n/A' : wordCount}
                        </span>
                      </p>
                    </div>
                    <div className="mt-1 flex flex-row items-center">
                      <MdCheckCircle className="mr-2 inline-block text-xl text-[#F9B934]" />
                      <p className="inline-block text-sm">
                        Article will stay:{' '}
                        <span className="font-semibold">
                          {time === null ? 'Permanent' : time}
                        </span>
                      </p>
                    </div>
                    <div className="mt-1 flex flex-row items-center">
                      <MdCheckCircle className="mr-2 inline-block text-xl text-[#F9B934]" />
                      <p className="inline-block text-sm">
                        Rejected topic:{' '}
                        <span className="font-semibold">
                          {rejectedTopics === null ? 'n/A' : rejectedTopics}
                        </span>
                      </p>
                    </div>
                    <div className="mt-1 flex flex-row items-center">
                      <MdCheckCircle className="mr-2 inline-block text-xl text-[#F9B934]" />
                      <p className="inline-block text-sm">
                        3 year guaranty from Linkjuice.market
                      </p>
                    </div>
                    <div className="mt-1 flex flex-row items-center">
                      <MdCheckCircle className="mr-2 inline-block text-xl text-[#F9B934]" />
                      <p className="inline-block text-sm">
                        Free link monitoring for your orders
                      </p>
                    </div>
                    <div className="mt-1 flex flex-row items-center">
                      <MdCheckCircle className="mr-2 inline-block text-xl text-[#F9B934]" />
                      <p className="inline-block text-sm">
                        Google console integrated with backlink tracking to
                        analyze your campaign
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-[21%]">
                  <h3 className="text-center text-lg font-semibold">
                    Metrics from Ahrefs
                  </h3>
                  <div className="rounded-md border border-gray-200 p-2 text-sm">
                    <div className="flex flex-row items-center">
                      <p className="inline-block w-2/3 text-sm">
                        Domain Rating:
                      </p>
                      <p className="font-semibold">
                        {domainRating && domainRating !== 'null'
                          ? domainRating
                          : 'n/A'}
                      </p>
                    </div>
                    <div className="mt-1 flex flex-row items-center">
                      <p className="inline-block w-2/3 text-sm">
                        Organic Traffic:{' '}
                      </p>
                      <p className="font-semibold">
                        {organicTraffic && organicTraffic !== 'null'
                          ? organicTraffic.toLocaleString()
                          : 'n/A'}
                      </p>
                    </div>
                    <div className="mt-1 flex flex-row items-center">
                      <p className="inline-block w-2/3 text-sm">
                        Referring domains:
                      </p>
                      <p className="font-semibold">
                        {referringDomains && referringDomains !== 'null'
                          ? referringDomains.toLocaleString()
                          : 'n/A'}
                      </p>
                    </div>
                    <div className="mt-1 flex flex-row items-center">
                      <p className="inline-block w-2/3 text-sm">
                        Linked Domains:
                      </p>
                      <span className="font-semibold">
                        {linkedDomains && linkedDomains !== 'null'
                          ? linkedDomains.toLocaleString()
                          : 'n/A'}
                      </span>
                    </div>
                    <div className="mt-1 flex flex-row items-center">
                      <p className="inline-block w-2/3 text-sm">
                        Organic Keywords:
                      </p>
                      <p className="font-semibold">
                        {organicKeywords && organicKeywords !== 'null'
                          ? organicKeywords.toLocaleString()
                          : 'n/A'}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-3 w-full">
                <h3 className="text-lg font-semibold">Why to buy from us?</h3>
                <p className="mt-2 text-sm">
                  Based in Lithuania, our focus is on providing high-quality
                  products at competitive prices, along with a guarantee for
                  lasting value. We are dedicated to maintaining high standards
                  and offer personalized suggestions to enhance your website,
                  aiming for a blend of quality and affordability in our
                  partnerships.
                </p>
              </div>
              <div className="mt-3 w-full">
                <h3 className="text-lg font-semibold">Who we are</h3>
                <p className="mt-2 text-sm">
                  Located in Lithuania, our core team comprises decision-makers
                  and developers who specialize in building marketplaces and
                  enhancing our websites.
                </p>
                <p className="mt-2 text-sm">
                  Meanwhile, our outreach and research team in Indonesia is
                  dedicated to creating custom databases tailored to each
                  client's needs. This structure allows us to cater to diverse
                  industries with precision, combining technical expertise and
                  market insights for optimal client solutions.
                </p>
              </div>
              <div className="mt-5">
                <button
                  onClick={handleClose}
                  className="bg-transparent rounded-md border border-gray-500 px-4 py-2 hover:bg-gray-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
                >
                  Close
                </button>
              </div>
            </Card>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default DomainModal;
