import React, { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { toastSuccess, toastError } from 'utils/toasts';
import Card from 'components/card';
import InputField from 'components/fields/InputField';
import { post, getUserId } from 'api';

const Password = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatpassword] = useState('');
  const [oldPasswordError, setOldPasswordError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [repeatPasswordError, setRepeatPasswordError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInfoChange = (e) => {
    const { id, value } = e.target;
    if (id === 'oldPassword') {
      setOldPassword(value);
    }
    if (id === 'password') {
      setPassword(value);
    }
    if (id === 'repeatPassword') {
      setRepeatpassword(value);
    }
  };

  const validatePasswords = () => {
    if (oldPassword.length < 8) {
      setOldPasswordError('Password must be at least 8 characters');
      return false;
    } else {
      setOldPasswordError('');
    }

    if (password.length < 8) {
      setPasswordError('Password must be at least 8 characters');
      return false;
    } else {
      setPasswordError('');
    }

    if (repeatPassword.length < 8) {
      setRepeatPasswordError('Password must be at least 8 characters');
      return false;
    } else if (password !== repeatPassword) {
      setRepeatPasswordError('Passwords do not match');
      return false;
    } else {
      setRepeatPasswordError('');
    }

    return true;
  };

  const handleSubmit = async () => {
    const userId = getUserId();
    if (validatePasswords()) {
      setIsSubmitting(true);
      const body = {
        id: userId,
        oldPassword: oldPassword,
        newPassword: password,
      };
      try {
        const res = await post('/passwords/change-password', body);
        if (res.status === 200) {
          toastSuccess('Password changed successfully');
          setTimeout(() => {
            setIsSubmitting(false);
            setOldPassword('');
            setPassword('');
            setRepeatpassword('');
          }, 2000);
        } else {
          const data = await res.json();
          toastError(data.message);
          setIsSubmitting(false);
        }
      } catch (err) {
        console.log(err);
        toastError('Something went wrong');
        setIsSubmitting(false);
      }
    }
  };

  return (
    <Card extra={'w-full p-4'}>
      <ToastContainer
        position="top-center"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="w-full px-2">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Change Password
        </h4>
        <p className="mt-1 text-base text-gray-600">
          Here you can set your new password
        </p>
      </div>
      <div className="mt-7 grid grid-cols-1 gap-3">
        <InputField
          variant="auth"
          extra="mb-3"
          label={oldPasswordError ? oldPasswordError : 'Old Password*'}
          placeholder="Old Password"
          id="oldPassword"
          type="password"
          onChange={handleInfoChange}
          state={oldPasswordError ? 'error' : ''}
          value={oldPassword}
        />
        <InputField
          variant="auth"
          extra="mb-3"
          label={passwordError ? passwordError : 'Password*'}
          placeholder="Password"
          id="password"
          type="password"
          onChange={handleInfoChange}
          state={passwordError ? 'error' : ''}
          value={password}
        />
        <InputField
          variant="auth"
          extra="mb-3"
          label={repeatPasswordError ? repeatPasswordError : 'Repeat password*'}
          placeholder="Password"
          id="repeatPassword"
          type="password"
          onChange={handleInfoChange}
          state={repeatPasswordError ? 'error' : ''}
          value={repeatPassword}
        />

        <div className="flex w-full justify-end">
          <button
            className="w-full rounded-xl bg-brand-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 md:w-auto"
            onClick={() => handleSubmit()}
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <div className="flex items-center justify-center">
                <div className="mr-2 h-4 w-4 animate-spin rounded-full border-b-2 border-white" />
                <span>Changing Password..</span>
              </div>
            ) : (
              <span>Change Password</span>
            )}
          </button>
        </div>
      </div>
    </Card>
  );
};

export default Password;
