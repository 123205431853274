import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import InputField from './InputField';
import { get } from 'api';

const GeneralFilters = ({
  acceptedTopic,
  setSelectedAcceptedTopic,
  country,
  setSelectedCountry,
  category,
  setSelectedCategory,
  backlinkPrice,
  setBacklinkPrice,
  doFollowLinks,
  setDoFollowLinks,
}) => {
  const [countries, setCountries] = useState([]);
  const [categories, setCategories] = useState([]);

  const topicOptions = [
    { value: 'iGaming', label: 'iGaming' },
    { value: 'Adult', label: 'Adult' },
    { value: 'CBD', label: 'CBD' },
  ];

  const doFollowOptions = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
  ];

  const getCountries = async () => {
    await get('/api/admin/countries').then((res) =>
      res.json().then((data) => {
        setCountries(
          data
            .sort((a, b) => a.country.localeCompare(b.country))
            .map((item) => ({
              value: item.id,
              label: item.country,
            })),
        );
      }),
    );
  };

  const getCategories = async () => {
    await get('/api/admin/categories').then((res) =>
      res.json().then((data) => {
        setCategories(
          data.map((item) => ({
            value: item.id,
            label: item.category,
          })),
        );
      }),
    );
  };

  useEffect(() => {
    getCountries();
    getCategories();
  }, []);

  const handleCategoryChange = (selectedOptions) => {
    setSelectedCategory(selectedOptions);
  };

  const handleCountryChange = (selectedOptions) => {
    setSelectedCountry(selectedOptions);
  };

  // const handleAcceptedTopicsChange = (selectedOptions) => {
  //   setSelectedAcceptedTopic(selectedOptions);
  // };

  const handleDoFollowLinksChange = (selectedOptions) => {
    setDoFollowLinks(selectedOptions);
  };

  const handleBacklinkPriceChange = (event) => {
    const { id, value } = event.target;
    const convertedValue = Number(value);
    if (id === 'bprMin') {
      setBacklinkPrice({ ...backlinkPrice, from: convertedValue });
    } else if (id === 'bprMax') {
      setBacklinkPrice({ ...backlinkPrice, to: convertedValue });
    }
  };

  return (
    <div className="h-full w-full rounded-[20px]">
      {/* content */}
      <div className="mt-3">
        {/* <div>
          <p className="mb-1 text-sm font-bold text-gray-400 dark:text-white">
            Accepted topics
          </p>
          <Select
            isMulti
            options={topicOptions}
            value={acceptedTopic}
            onChange={handleAcceptedTopicsChange}
            closeMenuOnSelect={false}
          />
        </div> */}
        <div className="mt-3">
          <p className="mb-1 text-sm font-bold text-gray-400 dark:text-white">
            Category
          </p>
          <Select
            isMulti
            options={categories}
            value={category}
            onChange={handleCategoryChange}
            closeMenuOnSelect={false}
          />
        </div>
        <div className="mt-3">
          <p className="mb-1 text-sm font-bold text-gray-400 dark:text-white">
            Countries
          </p>
          <Select
            isMulti
            options={countries}
            value={country}
            onChange={handleCountryChange}
            closeMenuOnSelect={false}
          />
        </div>
        <div className="mt-3">
          <p className="mb-1 text-sm font-bold text-gray-400 dark:text-white">
            Do-follow links
          </p>
          <Select
            isMulti
            options={doFollowOptions}
            value={doFollowLinks}
            onChange={handleDoFollowLinksChange}
            closeMenuOnSelect={true}
          />
        </div>
        <div className="mt-3">
          <p className="mb-0 text-sm font-bold text-gray-400 dark:text-white">
            Backlink price ranges
          </p>
          <div className="grid grid-cols-2 gap-3">
            <InputField
              extra="mb-3"
              placeholder="$ 0.00"
              id="bprMin"
              type="number"
              onChange={handleBacklinkPriceChange}
              value={backlinkPrice.from || ''}
            />
            <InputField
              extra="mb-3"
              placeholder="$ 15,000"
              id="bprMax"
              type="number"
              onChange={handleBacklinkPriceChange}
              value={backlinkPrice.to || ''}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralFilters;
