import { useState, useEffect, useContext } from 'react';
import { ToastContainer } from 'react-toastify';
import { toastSuccess, toastError } from 'utils/toasts';
import Stepper from './components/Stepper';
import StepperControl from './components/StepperControl';
import { UseContextProvider } from './contexts/StepperContext';
import DomainTags from './components/steps/DomainTags';
import Domain from './components/steps/Domain';
import Card from 'components/card';
import { put, post, remove, getUserId } from 'api';
import { ProjectContext } from 'contexts/ProjectContext';

const EditProject = () => {
  const { currentProject, getProjectInfo } = useContext(ProjectContext);
  const [currentStep, setCurrentStep] = useState(1);
  const [projectInfo, setProjectInfo] = useState({});
  const [newCategories, setNewCategories] = useState([]);
  const [newCountries, setNewCountries] = useState([]);
  const [newLanguages, setNewLanguages] = useState([]);
  const [deletedCategories, setDeletedCategories] = useState([]);
  const [deletedCountries, setDeletedCountries] = useState([]);
  const [deletedLanguages, setDeletedLanguages] = useState([]);
  const [originalCategories, setOriginalCategories] = useState([]);
  const [originalCountries, setOriginalCountries] = useState([]);
  const [originalLanguages, setOriginalLanguages] = useState([]);

  useEffect(() => {
    if (currentProject) {
      const categories =
        currentProject.categories &&
        currentProject.categories.map((c) => {
          return {
            id: c.categoryId,
            value: c.category.category,
            label: c.category.category,
          };
        });
      const countries =
        currentProject.countries &&
        currentProject.countries.map((c) => {
          return {
            id: c.countryId,
            value: c.country.country,
            label: c.country.country,
          };
        });
      const languages =
        currentProject.languages &&
        currentProject.languages.map((l) => {
          return {
            id: l.languageId,
            value: l.language.language,
            label: l.language.language,
          };
        });
      const newData = {
        ...currentProject,
        categories,
        countries,
        languages,
      };
      setOriginalCategories(categories);
      setOriginalCountries(countries);
      setOriginalLanguages(languages);
      setProjectInfo(newData);
    }
  }, [currentProject]);

  const steps = [
    { stepNo: 1, name: 'Domain' },
    { stepNo: 2, name: 'Tags' },
  ];
  const displayStep = (step) => {
    switch (step.stepNo) {
      case 1:
        return (
          <Domain
            projectInfo={projectInfo}
            updateProjectInfo={updateProjectInfo}
          />
        );
      case 2:
        return (
          <DomainTags
            projectInfo={projectInfo}
            updateProjectInfo={updateProjectInfo}
            setNewCategories={setNewCategories}
            setNewCountries={setNewCountries}
            setNewLanguages={setNewLanguages}
            setDeletedCategories={setDeletedCategories}
            setDeletedCountries={setDeletedCountries}
            setDeletedLanguages={setDeletedLanguages}
            originalCategories={originalCategories}
            originalCountries={originalCountries}
            originalLanguages={originalLanguages}
          />
        );
      default:
    }
  };

  const updateProjectInfo = (info) => {
    setProjectInfo((prevInfo) => ({ ...prevInfo, ...info }));
  };

  const handleClick = (direction) => {
    let newStep = currentStep;
    direction === 'next' ? newStep++ : newStep--;
    newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);
  };

  const handleSubmit = async () => {
    const userId = getUserId();
    const categories = projectInfo.categories;
    const countries = projectInfo.countries;
    const languages = projectInfo.languages;
    const domainName = projectInfo.domainName;
    const description = projectInfo.description;
    const projectName = projectInfo.projectName;
    const isIgaming = projectInfo.isIgaming;
    if (!categories.length) {
      toastError('Please select at least one category');
      return;
    } else if (!countries.length) {
      toastError('Please select at least one country');
      return;
    } else if (!languages.length) {
      toastError('Please select at least one language');
      return;
    }
    const body = {
      userId,
      projectName,
      domainName,
      description,
      isIgaming,
    };
    await put(`/api/project/${currentProject.id}`, body).then((res) =>
      res.json().then((parsedData) => {
        if (res.status === 200) {
          if (newCategories.length > 0) {
            const categoriesBody = {
              projectId: currentProject.id,
              categories: newCategories,
            };
            post(`/api/project/project-categories`, categoriesBody);
          }
          if (newCountries.length > 0) {
            const countriesBody = {
              projectId: currentProject.id,
              countries: newCountries,
            };
            post(`/api/project/project-countries`, countriesBody);
          }
          if (newLanguages.length > 0) {
            const languagesBody = {
              projectId: currentProject.id,
              languages: newLanguages,
            };
            post(`/api/project/project-languages`, languagesBody);
          }
          if (deletedCategories.length > 0) {
            deletedCategories.forEach(async (item) => {
              await remove(`/api/project/project-categories/${item}`);
            });
          }
          if (deletedCountries.length > 0) {
            deletedCountries.forEach(async (item) => {
              await remove(`/api/project/project-countries/${item}`);
            });
          }
          if (deletedLanguages.length > 0) {
            deletedLanguages.forEach(async (item) => {
              await remove(`/api/project/project-languages/${item}`);
            });
          }
          toastSuccess('Project updated successfully');
          getProjectInfo(currentProject.id);
        } else {
          toastError('Project update failed');
        }
      }),
    );
  };

  const handleDelete = async () => {
    const userId = getUserId();
    if (
      window.confirm(
        'Are you sure you want to delete this project, as you will lose all the information related to this project?',
      )
    ) {
      const body = {
        userId: userId,
      };
      await put(`/api/project/archive/${currentProject.id}`, body).then(
        (res) => {
          if (res.status === 200) {
            toastSuccess('Project deleted successfully');
            getProjectInfo();
            setTimeout(() => {
              window.location.href = '/admin/dashboards/projects/overview';
            }, 2500);
          } else {
            toastError('Project deletion failed');
          }
        },
      );
    }
  };

  return (
    <div className="mt-3 h-full w-full">
      <ToastContainer
        position="top-center"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="h-[350px] w-full rounded-[20px] bg-gradient-to-br from-brand-400 to-brand-600 md:h-[390px]" />
      <div className="w-md:2/3 mx-auto h-full w-5/6 md:px-3  3xl:w-7/12">
        <div className="m-auto -mt-[280px] w-[50%] pb-10 md:-mt-[240px] md:px-[0px]">
          <Stepper
            action={setCurrentStep}
            steps={steps}
            currentStep={currentStep}
          />
        </div>
        <Card extra={'h-full mx-auto pb-3'}>
          <div className="rounded-[20px]">
            <UseContextProvider>
              {displayStep(steps[currentStep - 1])}
            </UseContextProvider>
          </div>
          {/* navigation button */}

          <StepperControl
            handleClick={handleClick}
            currentStep={currentStep}
            steps={steps}
            handleSubmit={handleSubmit}
            projectInfo={projectInfo}
          />
          <div className="mt-4 flex flex-col justify-end px-3 pt-2 md:flex-row md:px-8">
            <button
              onClick={() => handleDelete()}
              className="linear cursor-pointer rounded-xl bg-red-500 px-8 py-2 font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2"
            >
              Delete
            </button>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default EditProject;
