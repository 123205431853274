import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { ToastContainer } from 'react-toastify';
import { toastSuccess, toastError } from 'utils/toasts';
import Card from 'components/card';
import TooltipHorizon from 'components/tooltip';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import {
  MdCheckCircle,
  MdCancel,
  MdOutlineError,
  MdChevronLeft,
  MdChevronRight,
} from 'react-icons/md';
import { useMemo } from 'react';
import { FiSearch } from 'react-icons/fi';
import ColumnsViewModal from 'views/admin/marketplace/overview/components/ColumnsViewModal';
import { ColumnsViewContext } from 'contexts/ColumnsViewContext';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { IoMdFunnel } from 'react-icons/io';
import { get, put, getUserRole } from 'api';
import InputField from './InputField';
import Switch from 'components/switch';
import DataTable from 'components/loaders/TableSkeleton';
import moment from 'moment';

const articleStatuses = [
  { value: 'WAITING FOR ARTICLE', label: 'WAITING FOR ARTICLE' },
  { value: 'WRITING', label: 'WRITING' },
  { value: 'ARTICLE READY', label: 'ARTICLE READY' },
  { value: 'IN REVISION', label: 'IN REVISION' },
  { value: 'PENDING', label: 'PENDING' },
  { value: 'DONE', label: 'DONE' },
  { value: 'CANCELLED', label: 'CANCELLED' },
];

const orderStatuses = [
  { value: 'IN PROGRESS', label: 'IN PROGRESS' },
  { value: 'DRAFT ARTICLE', label: 'DRAFT ARTICLE' },
  { value: 'IN REVISION', label: 'IN REVISION' },
  { value: 'PUBLICATION URL', label: 'PUBLICATION URL' },
  { value: 'PENDING', label: 'PENDING' },
  { value: 'DONE', label: 'DONE' },
  { value: 'CANCELLED', label: 'CANCELLED' },
];

const OrderTable = () => {
  const navigate = useNavigate();
  const [projectOrders, setProjectOrders] = useState([]);
  const { orderAdminColumnsView } = useContext(ColumnsViewContext);
  const [activeFilter, setActiveFilter] = useState('ALL');
  const userRole = getUserRole();
  const [orderStatus, setOrderStatus] = useState([]);
  const [articleStatus, setArticleStatus] = useState([]);
  const [publicationUrl, setPublicationUrl] = useState([]);
  const [instructionsCopied, setInstructionCopied] = useState({});
  const [topicCopied, setTopicCopied] = useState({});
  const [urlCopied, setUrlCopied] = useState({});
  const [revisionTextCopied, setRevisionTextCopied] = useState({});
  const [submitting, setSubmitting] = useState({});
  const [excelSubmitting, setExcelSubmitting] = useState(false);
  const [domainCopied, setDomainCopied] = useState({});
  const [indexed, setIndexed] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const currencySymbol = localStorage.getItem('currency');

  useEffect(() => {
    setDataLoaded(true);
  }, [projectOrders]);

  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const handleExport = async () => {
    setExcelSubmitting(true);
    const fileName = `${activeFilter}_orders_${moment().format('YYYY-MM-DD')}`;
    let excelData = projectOrders.filter((order) => {
      return activeFilter === 'ALL' ? true : order.orderStatus === activeFilter;
    });

    excelData = excelData.map((report) => ({
      'Project Name': report.Project.projectName,
      'Domain Name': report.domain,
      URLS: report.urls.map((item) => item.urlSlug).join(', '),
      Anchors: report.anchors.map((item) => item.urlAnchor).join(', '),
      'Word Count': report.wordCount,
      'Topic Suggestion': report.topicSuggestion,
      Instructions: report.instructions,
      'Preferred Voice': report.preferredVoice,
      'Writing Style': report.writingStyle,
      'Revision Text': report.revisionText,
      'Article Status': report.articleStatus,
      'Order Status': report.orderStatus,
      'Publication Url': report.publicationUrl,
      'Provided Article': report.providedArticle,
      'Client Approval': report.clientApproval,
      Deadline: report.deadline,
      Price: `€ ${report.totalPrice}`,
      'Updated At': moment(report.updatedAt).format('YYYY-MM-DD'),
    }));

    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'orders');
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
    const data = new Blob([s2ab(excelBuffer)], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
    setExcelSubmitting(false);
  };

  function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  const handleUrlChange = (e, id) => {
    const { value } = e.target;
    setPublicationUrl((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleOrderStatusChange = (selectedOptions, id) => {
    setOrderStatus((prevState) => ({
      ...prevState,
      [id]: { value: selectedOptions.value, label: selectedOptions.label },
    }));
  };

  const handleArticleStatusChange = (selectedOptions, id) => {
    setArticleStatus((prevState) => ({
      ...prevState,
      [id]: { value: selectedOptions.value, label: selectedOptions.label },
    }));
  };

  const handleChatClick = (itemId) => {
    navigate(`/admin/chat?orderId=${itemId}`);
  };

  const fetchOrders = async () => {
    await get(`/api/orders/all`)
      .then((res) => res.json())
      .then((data) => {
        if (data.statusCode === 500) {
          setProjectOrders([]);
          return;
        }
        const newData = data.map((item) => {
          return {
            ...item,
            projectName: item.Project?.projectName,
            totalPrice: parseFloat(item.articlePrice) + parseFloat(item.price),
          };
        });
        setProjectOrders(newData);
        setArticleStatus(
          newData.reduce((acc, item) => {
            acc[item.id] = {
              value: item.articleStatus,
              label: item.articleStatus,
            };
            return acc;
          }, {}),
        );
        setOrderStatus(
          newData.reduce((acc, item) => {
            acc[item.id] = {
              value: item.orderStatus,
              label: item.orderStatus,
            };
            return acc;
          }, {}),
        );
        setIndexed(
          newData.reduce((acc, item) => {
            acc[item.id] = item.indexed;
            return acc;
          }, {}),
        );
        setPublicationUrl(
          newData.reduce((acc, item) => {
            acc[item.id] = item.publicationUrl;
            return acc;
          }, {}),
        );
        setSubmitting(
          newData.reduce((acc, item) => {
            acc[item.id] = false;
            return acc;
          }, {}),
        );
      });
  };

  const handleIndexedUpdate = (e, id) => {
    const { checked } = e.target;
    setIndexed((prevState) => ({
      ...prevState,
      [id]: checked,
    }));
  };

  const handleUpdateStatus = async (id) => {
    setSubmitting({ ...submitting, [id]: true });
    const data = {
      orderStatus: orderStatus[id].value,
      articleStatus: articleStatus[id].value,
      publicationUrl: publicationUrl[id],
      indexed: indexed[id],
    };
    await put(`/api/orders/status/${id}`, data)
      .then((res) => {
        if (res.status === 200) {
          toastSuccess('Status was updated successfully');
          setSubmitting({ ...submitting, [id]: false });
          fetchOrders();
          if (data.orderStatus === 'CANCELLED') {
            const order = projectOrders.find((item) => item.id === id);
            if (order.orderStatus !== 'CANCELLED') {
              const userId = order.userId;
              const totalPrice = order.totalPrice;
              const body = {
                credits: totalPrice,
                orderId: id,
              };
              put(`/api/orders/cancel/${userId}`, body).then((res) => {
                if (res.status === 200) {
                  toastSuccess('Order was cancelled successfully');
                  fetchOrders();
                }
              });
            }
          }
        }
      })
      .catch((err) => {
        toastError('Something went wrong, please try again');
      });
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const checkColumnView = (columnName) => {
    return orderAdminColumnsView.find((view) => view.Header === columnName)
      .showColumn;
  };

  const columns = useMemo(() => orderAdminColumnsView, [orderAdminColumnsView]);
  const data = useMemo(() => projectOrders, [projectOrders]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 15 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,

    prepareRow,
    nextPage,
    previousPage,

    setGlobalFilter,
    setPageSize,
    state,
    rows,
  } = tableInstance;

  const { pageSize } = state;

  const filteredRows = rows.filter(
    (row) => row.values.orderStatus === activeFilter || activeFilter === 'ALL',
  );

  return (
    <Card extra={'mt-5 w-full h-full p-4 sm:overflow-x-auto'}>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="flex flex-col items-center justify-between md:flex-row">
        <div className="flex w-full flex-col items-center justify-start gap-2 md:w-[50%] md:flex-row md:gap-0">
          <button
            onClick={() => setActiveFilter('ALL')}
            className={`
            ${activeFilter === 'ALL' ? 'bg-blue-500' : 'bg-gray-200'}
          h-[auto] w-3/4 rounded-full bg-blue-500 px-2 py-2 text-sm text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50`}
          >
            ALL
          </button>
          <button
            onClick={() => setActiveFilter('IN PROGRESS')}
            className={`
            ${activeFilter === 'IN PROGRESS' ? 'bg-blue-500' : 'bg-gray-200'}
          ml-2 h-[auto] w-3/4 rounded-full bg-blue-500 px-2 py-2 text-sm text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50`}
          >
            IN PROGRESS
          </button>
          <button
            onClick={() => setActiveFilter('NEED ATTENTION')}
            className={`
            ${activeFilter === 'NEED ATTENTION' ? 'bg-blue-500' : 'bg-gray-200'}
          ml-2 h-[auto] w-3/4 rounded-full bg-blue-500 px-2 py-2 text-sm text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50`}
          >
            NEED ATTENTION
          </button>
          <button
            onClick={() => setActiveFilter('DONE')}
            className={`
            ${activeFilter === 'DONE' ? 'bg-blue-500' : 'bg-gray-200'}
          ml-2 h-[auto] w-3/4 rounded-full bg-blue-500 px-2 py-2 text-sm text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50`}
          >
            DONE
          </button>
          <button
            onClick={() => setActiveFilter('CANCELLED')}
            className={`
            ${activeFilter === 'CANCELLED' ? 'bg-blue-500' : 'bg-gray-200'}
          ml-2 h-[auto] w-3/4 rounded-full bg-blue-500 px-2 py-2 text-sm text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50`}
          >
            CANCELLED
          </button>
        </div>
        <div className="flex h-full w-full items-end gap-2 md:w-auto">
          <button
            disabled={excelSubmitting}
            onClick={() => handleExport()}
            className="linear flex h-[48px] w-auto items-center justify-center rounded-full bg-brand-500 p-4 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          >
            Export to CSV
          </button>
        </div>
        <div className="mt-5 flex items-center justify-end md:mt-0">
          <ColumnsViewModal page="orderAdmin" />
        </div>
      </div>
      {/* Search */}
      <div
        onChange={(e) => setGlobalFilter(e.target.value)}
        className="mt-3 flex items-center rounded-[10px] bg-white shadow-2xl shadow-white dark:!bg-navy-800 dark:shadow-none md:mt-0 md:mt-4 md:w-3/4 md:w-[400px]"
      >
        <div className="flex h-9 w-full flex-grow items-center rounded-[10px] bg-lightPrimary text-sm text-gray-600 dark:!bg-navy-900">
          <FiSearch className="mx-2 h-4 w-4 !text-gray-700 dark:!text-white" />
          <input
            type="text"
            placeholder="Search...."
            className="block h-full w-full rounded-full bg-lightPrimary text-sm text-navy-700 outline-none dark:!bg-navy-900 dark:text-white"
          />
        </div>
      </div>
      {!dataLoaded ? (
        <DataTable />
      ) : (
        <div className="mt-8 h-full max-w-[100%] overflow-x-auto">
          <table
            {...getTableProps()}
            className="w-full table-auto border-collapse whitespace-nowrap"
          >
            <thead>
              {headerGroups.map((headerGroup, index) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => {
                    const isActionColumn = column.Header === 'ORDER ACTIONS';
                    const showColumn = orderAdminColumnsView.find(
                      (view) =>
                        view.Header === column.Header &&
                        view.showColumn &&
                        (userRole === 'admin'
                          ? view.admin === true || view.admin === false
                          : view.admin === false),
                    );
                    if (!showColumn) return null;
                    return (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps(),
                        )}
                        className="border-b border-gray-200 pb-[10px] dark:!border-navy-700"
                        key={index}
                      >
                        <div className="mr-[100px] text-start text-xs font-bold tracking-wide text-gray-600 lg:text-xs">
                          <div className="flex items-center gap-2">
                            {isActionColumn ? '' : column.render('Header')}
                            {column.isSorted &&
                            column.Header !== 'ORDER ACTIONS' ? (
                              column.isSortedDesc ? (
                                <BsChevronUp className="mr-1 inline h-4 w-4" />
                              ) : (
                                <BsChevronDown className="mr-1 inline h-4 w-4" />
                              )
                            ) : (
                              column.Header !== 'ORDER ACTIONS' && (
                                <IoMdFunnel className="mr-1 inline h-4 w-4" />
                              )
                            )}
                          </div>
                        </div>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, index) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} key={index}>
                    {filteredRows.includes(row) &&
                      row.cells
                        .filter((cell) => checkColumnView(cell.column.Header))
                        .map((cell, index) => {
                          let data = '';
                          if (cell.column.Header === 'ORDER ID') {
                            data = (
                              <p className="text-sm font-bold text-navy-700 dark:text-white">
                                #{cell.value}
                              </p>
                            );
                          } else if (cell.column.Header === 'CLIENT PROJECT') {
                            data = (
                              <p className="text-sm font-bold text-navy-700 dark:text-white">
                                {cell.value}
                              </p>
                            );
                          } else if (cell.column.Header === 'DOMAIN') {
                            const orderId = cell.row.original.id;
                            const domain = cell.value;
                            data = (
                              <TooltipHorizon
                                extra="max-h-[180px] overflow-y-auto"
                                trigger={
                                  <p
                                    className="w-[160px] cursor-pointer overflow-hidden overflow-ellipsis whitespace-nowrap text-sm
                                    font-bold text-navy-700 dark:text-white"
                                    onClick={() => {
                                      navigator.clipboard.writeText(cell.value);
                                      setDomainCopied((prevCopied) => ({
                                        ...prevCopied,
                                        [orderId]: true,
                                      }));
                                      setTimeout(() => {
                                        setDomainCopied((prevCopied) => ({
                                          ...prevCopied,
                                          [orderId]: false,
                                        }));
                                      }, 500);
                                    }}
                                  >
                                    {domainCopied[orderId]
                                      ? 'Domain copied!'
                                      : domain}
                                  </p>
                                }
                                content={
                                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                                    {domain}
                                  </p>
                                }
                                placement="top-start"
                              />
                            );
                          } else if (cell.column.Header === 'WORD COUNT') {
                            data = (
                              <div className="text-sm font-bold text-navy-700 dark:text-white">
                                {cell.value}
                              </div>
                            );
                          } else if (cell.column.Header === 'URL') {
                            const orderId = cell.row.original.id;
                            data = (
                              <div className="text-sm font-bold text-navy-700 dark:text-white">
                                {cell.value.map((item, index) => (
                                  <TooltipHorizon
                                    key={index}
                                    extra="max-h-[200px] overflow-y-auto"
                                    trigger={
                                      <p
                                        className="cursor-pointer text-sm font-bold text-navy-700 dark:text-white"
                                        onClick={() => {
                                          navigator.clipboard.writeText(
                                            item.urlSlug,
                                          );
                                          setUrlCopied((prevCopied) => ({
                                            ...prevCopied,
                                            [orderId]: true,
                                          }));
                                          setTimeout(() => {
                                            setUrlCopied((prevCopied) => ({
                                              ...prevCopied,
                                              [orderId]: false,
                                            }));
                                          }, 500);
                                        }}
                                      >
                                        {urlCopied[orderId] ? (
                                          'URL Copied!'
                                        ) : (
                                          <div>
                                            {item.urlSlug &&
                                              item.urlSlug.slice(0, 18)}
                                            {item.urlSlug &&
                                              item.urlSlug.length > 18 && (
                                                <span>...</span>
                                              )}
                                          </div>
                                        )}
                                      </p>
                                    }
                                    content={
                                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                                        {item.urlSlug}
                                      </p>
                                    }
                                    placement="top-start"
                                  />
                                ))}
                              </div>
                            );
                          } else if (cell.column.Header === 'ANCHORS') {
                            data = (
                              <div className="text-sm font-bold text-navy-700 dark:text-white">
                                {cell.value.map((item, index) => (
                                  <div key={index} className="flex">
                                    <span>{item.urlAnchor}</span>
                                  </div>
                                ))}
                              </div>
                            );
                          } else if (
                            cell.column.Header === 'TOPIC SUGGESTION'
                          ) {
                            const orderId = cell.row.original.id;
                            data = (
                              <div className="text-sm font-bold text-navy-700 dark:text-white">
                                <TooltipHorizon
                                  key={index}
                                  extra="max-h-[200px] overflow-y-auto"
                                  trigger={
                                    <p
                                      onClick={() => {
                                        navigator.clipboard.writeText(
                                          cell.value,
                                        );
                                        setTopicCopied((prevCopied) => ({
                                          ...prevCopied,
                                          [orderId]: true,
                                        }));
                                        setTimeout(() => {
                                          setTopicCopied((prevCopied) => ({
                                            ...prevCopied,
                                            [orderId]: false,
                                          }));
                                        }, 500);
                                      }}
                                      className="cursor-pointer text-sm font-bold text-navy-700 dark:text-white"
                                    >
                                      {topicCopied[orderId] ? (
                                        'Topic Copied!'
                                      ) : (
                                        <div>
                                          {cell.value &&
                                            cell.value.slice(0, 18)}
                                          {cell.value &&
                                            cell.value.length > 18 && (
                                              <span>...</span>
                                            )}
                                        </div>
                                      )}
                                    </p>
                                  }
                                  content={
                                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                                      {cell.value}
                                    </p>
                                  }
                                  placement="top-start"
                                />
                              </div>
                            );
                          } else if (cell.column.Header === 'INSTRUCTIONS') {
                            const orderId = cell.row.original.id;
                            data = (
                              <div className="text-sm font-bold text-navy-700 dark:text-white">
                                <TooltipHorizon
                                  key={index}
                                  extra="max-h-[200px] overflow-y-auto"
                                  trigger={
                                    <p
                                      onClick={() => {
                                        navigator.clipboard.writeText(
                                          cell.value,
                                        );
                                        setInstructionCopied((prevCopied) => ({
                                          ...prevCopied,
                                          [orderId]: true,
                                        }));
                                        setTimeout(() => {
                                          setInstructionCopied(
                                            (prevCopied) => ({
                                              ...prevCopied,
                                              [orderId]: false,
                                            }),
                                          );
                                        }, 500);
                                      }}
                                      className="cursor-pointer text-sm font-bold text-navy-700 dark:text-white"
                                    >
                                      {instructionsCopied[orderId] ? (
                                        'Instructions Copied!'
                                      ) : (
                                        <div>
                                          {cell.value &&
                                            cell.value.slice(0, 18)}
                                          {cell.value &&
                                            cell.value.length > 18 && (
                                              <span>...</span>
                                            )}
                                        </div>
                                      )}
                                    </p>
                                  }
                                  content={
                                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                                      {cell.value}
                                    </p>
                                  }
                                  placement="top-start"
                                />
                              </div>
                            );
                          } else if (cell.column.Header === 'PREFERRED VOICE') {
                            data = (
                              <div className="text-sm font-bold text-navy-700 dark:text-white">
                                {cell.value}
                              </div>
                            );
                          } else if (cell.column.Header === 'WRITING STYLE') {
                            data = (
                              <div className="text-sm font-bold text-navy-700 dark:text-white">
                                {cell.value}
                              </div>
                            );
                          } else if (cell.column.Header === 'REVISION TEXT') {
                            const orderId = cell.row.original.id;
                            data = (
                              <div className="text-sm font-bold text-navy-700 dark:text-white">
                                {revisionTextCopied[orderId] && (
                                  <div className="w-[70%] rounded-md bg-gray-800 px-2 py-1 text-white">
                                    Revision Text Copied!
                                  </div>
                                )}
                                <TooltipHorizon
                                  key={index}
                                  extra="max-h-[200px] overflow-y-auto"
                                  trigger={
                                    <p
                                      onClick={() => {
                                        navigator.clipboard.writeText(
                                          cell.value,
                                        );
                                        setRevisionTextCopied((prevCopied) => ({
                                          ...prevCopied,
                                          [orderId]: true,
                                        }));
                                        setTimeout(() => {
                                          setRevisionTextCopied(
                                            (prevCopied) => ({
                                              ...prevCopied,
                                              [orderId]: false,
                                            }),
                                          );
                                        }, 500);
                                      }}
                                      className="cursor-pointer text-sm font-bold text-navy-700 dark:text-white"
                                    >
                                      {cell.value && cell.value.slice(0, 18)}
                                      {cell.value && cell.value.length > 18 && (
                                        <span>...</span>
                                      )}
                                    </p>
                                  }
                                  content={
                                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                                      {cell.value}
                                    </p>
                                  }
                                  placement="top-start"
                                />
                              </div>
                            );
                          } else if (cell.column.Header === 'ARTICLE STATUS') {
                            const itemId = cell.row.original.id;
                            const cellValue =
                              articleStatus[itemId] &&
                              articleStatus[itemId].value;
                            const sendToPublisher =
                              cell.row.original.sendDirectlyToPublisher;
                            data = (
                              <div className="flex items-center gap-2">
                                <div className={`rounded-full text-xl`}>
                                  {cellValue === 'DONE' ? (
                                    <MdCheckCircle className="text-green-500" />
                                  ) : cellValue === 'CANCELLED' ? (
                                    <MdCancel className="text-red-500" />
                                  ) : cellValue === 'WAITING FOR ARTICLE' ? (
                                    <MdOutlineError className="text-yellow-300" />
                                  ) : cellValue === 'IN PROGRESS' ? (
                                    <MdOutlineError className="text-blue-300" />
                                  ) : cellValue === 'PENDING' ? (
                                    <MdOutlineError className="text-brand-400" />
                                  ) : cellValue === 'WRITING' ? (
                                    <MdOutlineError className="text-green-300" />
                                  ) : cellValue === 'ARTICLE READY' ? (
                                    <MdOutlineError className="text-green-500" />
                                  ) : cellValue === 'IN REVISION' ? (
                                    <MdOutlineError
                                      className={`${
                                        sendToPublisher
                                          ? 'text-orange-600'
                                          : 'text-orange-400'
                                      }`}
                                    />
                                  ) : (
                                    <MdOutlineError className="text-blue-500" />
                                  )}
                                </div>
                                <Select
                                  options={articleStatuses}
                                  value={articleStatus[itemId]}
                                  onChange={(values) =>
                                    handleArticleStatusChange(values, itemId)
                                  }
                                  name="articleStatus"
                                  menuPlacement="top"
                                  menuPortalTarget={document.body}
                                  className="w-2/3"
                                />
                              </div>
                            );
                          } else if (cell.column.Header === 'ORDER STATUS') {
                            const itemId = cell.row.original.id;
                            const cellValue =
                              orderStatus[itemId] && orderStatus[itemId].value;
                            const sendToPublisher =
                              cell.row.original.sendDirectlyToPublisher;
                            data = (
                              <div className="flex items-center gap-2">
                                <div className={`rounded-full text-xl`}>
                                  {cellValue === 'DONE' ? (
                                    <MdCheckCircle className="text-green-500" />
                                  ) : cellValue === 'CANCELLED' ? (
                                    <MdCancel className="text-red-500" />
                                  ) : cellValue === 'IN REVISION' ? (
                                    <MdOutlineError
                                      className={`${
                                        sendToPublisher
                                          ? 'text-orange-600'
                                          : 'text-orange-400'
                                      }`}
                                    />
                                  ) : cellValue === 'IN PROGRESS' ? (
                                    <MdOutlineError className="text-blue-300" />
                                  ) : cellValue === 'PENDING' ? (
                                    <MdOutlineError className="text-brand-400" />
                                  ) : cellValue === 'DRAFT ARTICLE' ? (
                                    <MdOutlineError className="text-green-300" />
                                  ) : cellValue === 'PUBLICATION URL' ? (
                                    <MdOutlineError className="text-green-300" />
                                  ) : (
                                    <MdOutlineError className="text-blue-500" />
                                  )}
                                </div>
                                <Select
                                  options={orderStatuses}
                                  value={orderStatus[itemId]}
                                  onChange={(values) =>
                                    handleOrderStatusChange(values, itemId)
                                  }
                                  name="orderStatus"
                                  menuPlacement="top"
                                  menuPortalTarget={document.body}
                                  className="w-2/3"
                                />
                              </div>
                            );
                          } else if (cell.column.Header === 'PUBLICATION URL') {
                            const itemId = cell.row.original.id;
                            data = (
                              <div className="flex items-center gap-2">
                                <InputField
                                  id={itemId}
                                  value={publicationUrl[itemId] || ''}
                                  type="text"
                                  placeholder="Enter publication url"
                                  name="publicationUrl"
                                  state="normal"
                                  onChange={(e) => handleUrlChange(e, itemId)}
                                />
                              </div>
                            );
                          } else if (
                            cell.column.Header === 'PROVIDED ARTICLE'
                          ) {
                            data = (
                              <TooltipHorizon
                                extra="max-h-[200px] overflow-y-auto"
                                trigger={
                                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                                    <a
                                      href={cell.value}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {cell.value && cell.value.slice(0, 25)}
                                      {cell.value && cell.value.length > 25 && (
                                        <span>...</span>
                                      )}
                                    </a>
                                  </p>
                                }
                                content={
                                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                                    {cell.value}
                                  </p>
                                }
                                placement="top-start"
                              />
                            );
                          } else if (cell.column.Header === 'CLIENT APPROVAL') {
                            data = (
                              <div
                                className={`flex h-7 w-[110px] items-center justify-center text-sm ${
                                  cell.value === true
                                    ? 'bg-green-100 dark:bg-green-50'
                                    : 'bg-red-100 dark:bg-red-50'
                                } rounded-[10px] text-base font-bold `}
                              >
                                {cell.value === true ? (
                                  <div
                                    className={`${
                                      cell.value === true
                                        ? 'text-green-500 '
                                        : 'text-red-500'
                                    } uppercase `}
                                  >
                                    Approved
                                  </div>
                                ) : (
                                  <div
                                    className={`${
                                      cell.value === true
                                        ? 'text-green-500 '
                                        : 'text-red-500'
                                    } uppercase `}
                                  >
                                    Pending
                                  </div>
                                )}
                              </div>
                            );
                          } else if (cell.column.Header === 'PRICE') {
                            data = (
                              <p className="text-sm font-bold text-navy-700 dark:text-white">
                                {currencySymbol}
                                {cell.value.toLocaleString()}
                              </p>
                            );
                          } else if (cell.column.Header === 'DEADLINE') {
                            data = (
                              <p className="text-sm font-bold text-navy-700 dark:text-white">
                                {cell.value}
                              </p>
                            );
                          } else if (cell.column.Header === 'INDEXED') {
                            const itemId = cell.row.original.id;
                            data = (
                              <div className="text-sm font-bold text-navy-700 dark:text-white">
                                <Switch
                                  checked={indexed[itemId]}
                                  onChange={(e) =>
                                    handleIndexedUpdate(e, itemId)
                                  }
                                />
                              </div>
                            );
                          } else if (cell.column.Header === 'CHAT') {
                            const itemId = cell.row.original.id;
                            data = (
                              <button
                                onClick={() => handleChatClick(itemId)}
                                className={`linear text-md flex items-center justify-center rounded-full bg-brand-500 p-2 text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
                              >
                                Let's Chat
                              </button>
                            );
                          } else if (cell.column.Header === 'UPDATE') {
                            const itemId = cell.row.original.id;
                            data = (
                              <button
                                disabled={submitting[itemId]}
                                onClick={() => handleUpdateStatus(itemId)}
                                className={`linear text-md flex items-center justify-center rounded-full bg-brand-500 p-2 text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
                              >
                                {submitting[itemId] ? (
                                  <div className="flex items-center justify-center">
                                    <div className="mr-2 h-4 w-4 animate-spin rounded-full border-b-2 border-white" />
                                    <span>Updating..</span>
                                  </div>
                                ) : (
                                  <span>Update</span>
                                )}
                              </button>
                            );
                          }
                          return (
                            <td
                              className="pb-[18px] pt-[14px] sm:text-[14px]"
                              {...cell.getCellProps()}
                              key={index}
                            >
                              {data}
                            </td>
                          );
                        })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      {/* pagination */}
      <div className="mt-2 flex h-[80px] w-full items-center justify-between">
        {/* left side */}
        <div className="flex items-center gap-2">
          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
            className="h-10 w-[70px] rounded-xl border border-gray-200 px-2 text-sm  text-gray-600 dark:!border-white/10 dark:!bg-navy-800"
            name=""
            id=""
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </select>
          <p className="text-sm text-gray-700">
            Showing {filteredRows.length} of {filteredRows.length}
          </p>
        </div>
        {/* right side */}
        <div className="flex items-center gap-2">
          <button
            onClick={() => previousPage()}
            className={`linear flex items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
          >
            <MdChevronLeft />
          </button>

          <p className="text-sm text-gray-700">
            {state.pageIndex + 1} of{' '}
            {Math.ceil(filteredRows.length / state.pageSize) || 1}
          </p>

          <button
            onClick={() => nextPage()}
            className={`linear flex items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
          >
            <MdChevronRight />
          </button>
        </div>
      </div>
    </Card>
  );
};

export default OrderTable;
