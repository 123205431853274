import { toastError } from 'utils/toasts';

export default function StepperControl(props) {
  const {
    steps,
    currentStep,
    handleClick,
    handleSubmit,
    dataExisted,
    handleUpdate,
    submitting,
    billingInfo,
  } = props;
  const { companyName, billingEmail, isVatPayer, companyCode } = billingInfo;
  return (
    <div className="mt-4 flex flex-col justify-between px-3 pt-2 md:flex-row md:px-8">
      {currentStep === steps.length - 2 ? (
        <div />
      ) : (
        <button
          onClick={() => handleClick()}
          className={`mb-3 cursor-pointer rounded-xl bg-lightPrimary px-16 py-2 font-medium text-navy-700 transition duration-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20`}
        >
          Prev
        </button>
      )}

      <button
        disabled={submitting}
        onClick={() => {
          if (currentStep === steps.length && !dataExisted) {
            handleSubmit();
          } else if (currentStep === steps.length && dataExisted) {
            handleUpdate();
          } else {
            if (currentStep === 1) {
              if (
                !companyName ||
                !billingEmail ||
                isVatPayer === undefined ||
                !companyCode
              ) {
                toastError('Please fill in all fields');
                return;
              }
            }
            handleClick('next');
          }
        }}
        className="linear cursor-pointer rounded-xl bg-brand-900 px-16 py-2 font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2"
      >
        {submitting ? (
          <div className="flex items-center justify-center">
            <div className="mr-2 h-4 w-4 animate-spin rounded-full border-b-2 border-white" />
            <span>Submitting..</span>
          </div>
        ) : (
          <span>
            {currentStep === steps.length && !dataExisted
              ? 'Submit'
              : currentStep === steps.length && dataExisted
              ? 'Update'
              : 'Next'}
          </span>
        )}
      </button>
    </div>
  );
}
