import React, { useState } from 'react';
import { MdCancel, MdOutlineCloudUpload } from 'react-icons/md';
import DropZonefile from './DropZonefile';
import TextField from 'components/fields/TextField';
import Checkbox from 'components/checkbox';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import AddNewAnchor from './AddNewAnchor';

const ProvideContentForm = ({
  itemId,
  provideContentData,
  handleProvideContentData,
  articleWordCount,
  articleCondition,
}) => {
  const { anchors, instructions, provideLater, uploadFiles, allUrls } =
    provideContentData[itemId] || {};
  const [showGuidelines, setShowGuidelines] = useState(true);
  const [selectedAnchors, setSelectedAnchors] = useState([]);

  const checkIfAnchorExists = (url, anchor) => {
    const existingItemIndex = selectedAnchors.findIndex(
      (item) => item.url.value === url && item.anchor.value === anchor,
    );
    return existingItemIndex !== -1;
  };

  const handleAnchorSelected = (selectedUrl, selectedAnchor) => {
    const existingItemIndex = selectedAnchors.findIndex(
      (item) => item.url.id === selectedUrl.id,
    );

    if (existingItemIndex !== -1) {
      setSelectedAnchors((prevSelectedAnchors) =>
        prevSelectedAnchors.filter(
          (item, index) => index !== existingItemIndex,
        ),
      );
    }

    setSelectedAnchors((prevSelectedAnchors) => [
      ...prevSelectedAnchors,
      { url: selectedUrl, anchor: selectedAnchor },
    ]);

    handleProvideContentData({
      itemId: itemId,
      anchors: {
        ...anchors,
        [selectedUrl.value]: {
          url: selectedUrl.value,
          anchor: {
            id: selectedAnchor.id || '',
            value: selectedAnchor.value,
            label: selectedAnchor.value,
          },
          urlSlugId: selectedUrl.id,
        },
      },
    });
  };

  const handleDataChange = (id, value, urlName, urlId) => {
    if (id === 'selectedAnchors') {
      const existingItemIndex = selectedAnchors.findIndex(
        (item) => item.url.value === urlName,
      );

      if (existingItemIndex !== -1) {
        setSelectedAnchors((prevSelectedAnchors) =>
          prevSelectedAnchors.filter(
            (item, index) => index !== existingItemIndex,
          ),
        );
      }

      setSelectedAnchors((prevSelectedAnchors) => [
        ...prevSelectedAnchors,
        {
          url: { value: urlName, label: urlName },
          anchor: { value: value, label: value },
        },
      ]);

      handleProvideContentData({
        itemId: itemId,
        anchors: {
          ...anchors,
          [urlName]: {
            url: urlName,
            anchor: {
              id: value.id || '',
              value: value,
              label: value,
            },
            urlSlugId: value.urlSlugId || urlId || '',
          },
        },
      });
    } else if (id === 'uploadFiles') {
      handleProvideContentData({
        itemId: itemId,
        uploadFiles: value,
      });
    } else {
      handleProvideContentData({ itemId: itemId, [id]: value });
    }
  };

  const handleRemoveSelection = (urlId, anchorId, urlName) => {
    const newAnchors = { ...anchors };
    delete newAnchors[urlName];
    handleProvideContentData({ itemId: itemId, anchors: newAnchors });

    setSelectedAnchors((prevSelectedAnchors) =>
      prevSelectedAnchors.filter(
        (item) =>
          item.url.id !== urlId ||
          item.anchor.id !== anchorId ||
          item.url.value !== urlName,
      ),
    );
  };

  return (
    <div className="flex flex-col">
      <div className="mt-3">
        <p
          className="mb-0 text-sm font-bold hover:cursor-pointer dark:text-white"
          onClick={() => setShowGuidelines(!showGuidelines)}
        >
          Article guidelines{' '}
          {showGuidelines ? (
            <BsChevronUp className="mr-1 inline h-4 w-4" />
          ) : (
            <BsChevronDown className="mr-1 inline h-4 w-4" />
          )}
        </p>
      </div>
      {showGuidelines && (
        <div className="mt-1">
          <div className="flex items-center gap-2">
            <p className="mb-0 text-sm font-medium  dark:text-white">
              <strong>Word Count:</strong>{' '}
              {articleWordCount ? articleWordCount : 'Minimum 500 words'}
            </p>
          </div>
          <div className="flex items-center gap-2">
            <p className="mb-0 text-sm font-medium  dark:text-white">
              <strong>Condition:</strong>{' '}
              {articleCondition
                ? articleCondition
                : 'Topics suppose to be related to website style'}
            </p>
          </div>
        </div>
      )}
      <div className="mt-5 flex w-full items-center justify-center rounded-[20px]">
        <DropZonefile
          content={
            <div className="flex h-[225px] w-full flex-col items-center justify-center rounded-xl border-[1px] border-dashed border-gray-200 bg-gray-100 dark:!border-none dark:!bg-navy-700 sm:w-[400px] md:w-[570px] lg:w-[700px] xl:w-[600px] 2xl:w-[690px] 3xl:w-[680px]">
              <p className="text-[80px] text-navy-700">
                <MdOutlineCloudUpload className="text-brand-500 dark:text-white" />
              </p>
              {uploadFiles &&
                uploadFiles.map((file, index) => {
                  return (
                    <p
                      key={index}
                      className="text-lg font-bold text-navy-700 dark:text-white"
                    >
                      {file.name}
                    </p>
                  );
                })}
              <p className="text-lg font-bold text-navy-700 dark:text-white">
                Drop your files here, or{' '}
                <span className="font-bold text-brand-500 dark:text-brand-400">
                  browse
                </span>
              </p>
              <p className="pt-2 text-sm text-gray-600">
                .docs, .docx and .pdf files are only allowed
              </p>
            </div>
          }
          onDrop={(acceptedFiles) =>
            handleDataChange('uploadFiles', acceptedFiles)
          }
          uploadFiles={uploadFiles}
        />
      </div>
      <div className="mt-5">
        <p className="text-sm font-bold  dark:text-white">
          Add one target URL and anchor to your website, another link you can
          suggest the authority website
        </p>
        <div className="mt-2 flex flex-col gap-2">
          <div className="flex w-full flex-col items-center gap-2">
            <AddNewAnchor
              allUrls={allUrls}
              onAnchorSelected={handleAnchorSelected}
              handleDataChange={handleDataChange}
            />
            <ul className="flex w-full flex-col gap-2">
              {selectedAnchors &&
                selectedAnchors.map((anchor) => (
                  <li
                    key={`${anchor.url.id}-${anchor.anchor.id}`}
                    className="flex items-center justify-start rounded-lg bg-gray-100 px-2 py-1 dark:bg-gray-800"
                  >
                    <button
                      onClick={() => {
                        handleRemoveSelection(
                          anchor.url.id,
                          anchor.anchor.id,
                          anchor.url.value,
                        );
                      }}
                      className="mr-2 flex items-center justify-center"
                    >
                      <MdCancel className="text-lg text-red-500 hover:text-red-600" />
                    </button>
                    {anchor.url.value} - {anchor.anchor.value}
                  </li>
                ))}
              {anchors &&
                Object.values(anchors).map((anchor) => {
                  if (checkIfAnchorExists(anchor.url, anchor.anchor.value)) {
                    return null;
                  }
                  return (
                    <li
                      key={anchor}
                      className="flex items-center justify-start rounded-lg bg-gray-100 px-2 py-1 dark:bg-gray-800"
                    >
                      <button
                        onClick={() => {
                          handleRemoveSelection(
                            anchor.urlSlugId,
                            anchor.anchor.id,
                            anchor.url,
                          );
                        }}
                        className="mr-2 flex items-center justify-center"
                      >
                        <MdCancel className="text-lg text-red-500 hover:text-red-600" />
                      </button>
                      {anchor.url} - {anchor.anchor.value}
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
        <div className="mt-1">
          <TextField
            placeholder="Add special instructions here"
            id="instructions"
            cols="30"
            rows="7"
            value={instructions || ''}
            onChange={(e) => handleDataChange(e.target.id, e.target.value)}
          />
        </div>
        <div className="mt-5">
          <div className="flex items-center justify-end">
            <Checkbox
              id="provideLater"
              checked={provideLater || false}
              onChange={(e) => handleDataChange(e.target.id, e.target.checked)}
            />
            <p className="ml-2 text-sm font-bold  dark:text-white">
              I will provide the article later
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProvideContentForm;
