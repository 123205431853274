import React, { useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalBody } from '@chakra-ui/modal';
import { useDisclosure } from '@chakra-ui/hooks';
import Card from 'components/card';
import Checkbox from 'components/checkbox';
import { MdEdit } from 'react-icons/md';

const TypeModal = ({ selectedSearchType, setSelectedSearchType }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedType, setSelectedType] = useState('1');

  const handleSave = () => {
    switch (selectedType) {
      case '1':
        setSelectedSearchType('Web');
        break;
      case '2':
        setSelectedSearchType('Image');
        break;
      case '3':
        setSelectedSearchType('Video');
        break;
      case '4':
        setSelectedSearchType('News');
        break;
      default:
        break;
    }
    onClose();
  };

  const handleReset = () => {
    onClose();
  };

  const handleCheckboxChange = (e) => {
    setSelectedType(e.target.id);
  };

  return (
    <div>
      <button
        onClick={onOpen}
        className="text-md ml-4 flex h-auto items-center rounded-full bg-blue-500 px-4 py-2 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
      >
        Search type:{' '}
        {selectedSearchType === '' ? 'Select Search Type' : selectedSearchType}
        <span className="ml-2">
          <MdEdit />
        </span>
      </button>

      <Modal className="!z-[100]" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay className="bg-[#000] !opacity-30" />
        <ModalContent className="top-[22vh] !m-auto !w-max !max-w-[85%] md:top-[7vh]">
          <ModalBody>
            <Card extra={'w-full sm:overflow-auto p-4 pt-4'}>
              <div className="mt-5 flex flex-col">
                <div className="text-2xl dark:text-white">Search Type</div>
                <div className="mt-1 flex flex-col">
                  <div className="mt-1 flex">
                    <Checkbox
                      label="Web"
                      checked={selectedType === '1' ? true : false}
                      onChange={handleCheckboxChange}
                      id="1"
                    />
                    <p className="ml-2 text-gray-800 dark:text-gray-400">Web</p>
                  </div>
                  <div className="mt-1 flex">
                    <Checkbox
                      label="Image"
                      checked={selectedType === '2' ? true : false}
                      onChange={handleCheckboxChange}
                      id="2"
                    />
                    <p className="ml-2 text-gray-800 dark:text-gray-400">
                      Image
                    </p>
                  </div>
                  <div className="mt-1 flex">
                    <Checkbox
                      label="Video"
                      checked={selectedType === '3' ? true : false}
                      onChange={handleCheckboxChange}
                      id="3"
                    />
                    <p className="ml-2 text-gray-800 dark:text-gray-400">
                      Video
                    </p>
                  </div>
                  <div className="mt-1 flex">
                    <Checkbox
                      label="News"
                      checked={selectedType === '4' ? true : false}
                      onChange={handleCheckboxChange}
                      id="4"
                    />
                    <p className="ml-2 text-gray-800 dark:text-gray-400">
                      News
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 flex justify-start">
                <button
                  onClick={() => handleSave()}
                  className="rounded-md bg-blue-500 px-4 py-2 text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                >
                  APPLY
                </button>
                <button
                  onClick={() => handleReset()}
                  className="bg-transparent ml-3 rounded-md border border-gray-500 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
                >
                  CANCEL
                </button>
              </div>
            </Card>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default TypeModal;
