// Assets
import TooltipHorizon from 'components/tooltip';
import { MdDownload } from 'react-icons/md';
const Banner = ({ invoice, handleExportPDF }) => {
  const ourCompany = {
    companyName: 'lo-k OÜ',
    address1: 'Sepapaja tn 6, 15551',
    address2: 'Tallinn, Harju Maakond',
    country: 'Estonia',
    vat: 'EE102625037',
    registryCode: '16753629',
  };

  return (
    <div className="mx-auto rounded-2xl bg-gradient-to-br from-brand-400 to-brand-600 px-4 pb-[140px] pt-9 font-dm md:w-[90%] md:pb-[20px] lg:h-[260px] lg:!px-[30px] lg:pt-[20px] xl:!px-[30px] 3xl:w-full">
      {/* Header */}
      <div className="mb-3 flex justify-between">
        <div className="flex h-7 w-[110px] items-center justify-center rounded-[10px] bg-brand-400 text-sm font-bold text-white">
          PAID INVOICE
        </div>

        {/* buttons */}
        <div className="flex gap-[12px]">
          {/* button invoice */}
          <button
            onClick={handleExportPDF}
            className="flex items-center justify-center rounded-lg bg-brand-400 p-2 text-xl text-white transition-opacity hover:cursor-pointer hover:opacity-80"
          >
            <TooltipHorizon
              trigger={
                <p className="text-lg font-bold text-white">
                  <MdDownload />
                </p>
              }
              content={
                <p className="text-sm font-bold text-navy-700 dark:text-white">
                  Download PDF
                </p>
              }
              placement="top"
            />
          </button>
        </div>
      </div>
      {/* banner content */}
      <div className="flex flex-col md:flex-row">
        <div className="mb-[0px]">
          <h1 className="text-xl font-bold text-white md:text-2xl lg:text-[32px]">
            Invoice
          </h1>
          <h5 className="lg:text-md mt-1 text-lg font-normal text-white">
            {invoice[0].invoiceNumber}
          </h5>
        </div>
        <div className="mx-[50px] h-0 w-px bg-gray-600 md:h-[115px]" />
        <div>
          <p className="leading-1 text-base text-white lg:text-lg">
            {ourCompany.companyName}
          </p>
          <p className="leading-1 text-base leading-10 text-white lg:text-lg">
            {ourCompany.address1}, {ourCompany.address2}, {ourCompany.country}
          </p>
          <p className="leading-1 text-base text-white lg:text-lg">
            VAT Number: {ourCompany.vat}
          </p>
          <p className="leading-1 text-base text-white lg:text-lg">
            Registry code: {ourCompany.registryCode}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Banner;
