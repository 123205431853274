export const marketplaceHeader = [
  {
    Header: 'ID',
    accessor: 'id',
    showColumn: false,
    editable: false,
    admin: false,
  },
  {
    Header: 'DOMAIN',
    accessor: 'domain',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'PRICE',
    accessor: 'price',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'DOMAIN RATING',
    accessor: 'domainRating',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'ORGANIC TRAFFIC',
    accessor: 'organicTraffic',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'ORGANIC KEYWORDS',
    accessor: 'organicKeywords',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'REFERRING DOMAINS',
    accessor: 'referringDomains',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'LINKED DOMAINS',
    accessor: 'linkedDomains',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'DO-FOLLOW LINKS',
    accessor: 'doFollowLinks',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'TIME',
    accessor: 'time',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'DELIVERY TIME',
    accessor: 'deliveryTime',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'ORDER ACTIONS',
    accessor: 'actions',
    showColumn: true,
    editable: false,
    admin: false,
  },
];

export const competitorHeader = [
  {
    Header: 'ID',
    accessor: 'id',
    showColumn: false,
    editable: false,
    admin: false,
  },
  {
    Header: 'DOMAIN',
    accessor: 'domain',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'COMPETITOR',
    accessor: 'competitor',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'TIME',
    accessor: 'time',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'METRICS',
    accessor: 'metrics',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'DOMAIN RATING',
    accessor: 'domainRating',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'ORGANIC TRAFFIC',
    accessor: 'organicTraffic',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'ORGANIC KEYWORDS',
    accessor: 'organicKeywords',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'PRICE',
    accessor: 'price',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'UPDATED',
    accessor: 'updated',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'ORDER ACTIONS',
    accessor: 'actions',
    showColumn: true,
    editable: false,
    admin: false,
  },
];

export const suggestionHeader = [
  {
    Header: 'ID',
    accessor: 'id',
    showColumn: false,
    editable: false,
    admin: false,
  },
  {
    Header: 'DOMAIN',
    accessor: 'domain',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'PRICE',
    accessor: 'price',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'DOMAIN RATING',
    accessor: 'domainRating',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'ORGANIC TRAFFIC',
    accessor: 'organicTraffic',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'ORGANIC KEYWORDS',
    accessor: 'organicKeywords',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'REFERRING DOMAINS',
    accessor: 'referringDomains',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'LINKED DOMAINS',
    accessor: 'linkedDomains',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'DO-FOLLOW LINKS',
    accessor: 'doFollowLinks',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'TIME',
    accessor: 'time',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'DELIVERY TIME',
    accessor: 'deliveryTime',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'ORDER ACTIONS',
    accessor: 'actions',
    showColumn: true,
    editable: false,
    admin: false,
  },
];

export const favoritesHeader = [
  {
    Header: 'ID',
    accessor: 'id',
    showColumn: false,
    editable: false,
    admin: false,
  },
  {
    Header: 'DOMAIN',
    accessor: 'domain',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'PRICE',
    accessor: 'price',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'DOMAIN RATING',
    accessor: 'domainRating',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'ORGANIC TRAFFIC',
    accessor: 'organicTraffic',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'ORGANIC KEYWORDS',
    accessor: 'organicKeywords',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'REFERRING DOMAINS',
    accessor: 'referringDomains',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'LINKED DOMAINS',
    accessor: 'linkedDomains',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'DO-FOLLOW LINKS',
    accessor: 'doFollowLinks',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'TIME',
    accessor: 'time',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'DELIVERY TIME',
    accessor: 'deliveryTime',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'ORDER ACTIONS',
    accessor: 'actions',
    showColumn: true,
    editable: false,
    admin: false,
  },
];

export const blacklistHeader = [
  {
    Header: 'ID',
    accessor: 'id',
    showColumn: false,
    editable: false,
    admin: false,
  },
  {
    Header: 'DOMAIN',
    accessor: 'domain',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'PRICE',
    accessor: 'price',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'DOMAIN RATING',
    accessor: 'domainRating',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'ORGANIC TRAFFIC',
    accessor: 'organicTraffic',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'ORGANIC KEYWORDS',
    accessor: 'organicKeywords',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'REFERRING DOMAINS',
    accessor: 'referringDomains',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'LINKED DOMAINS',
    accessor: 'linkedDomains',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'DO-FOLLOW LINKS',
    accessor: 'doFollowLinks',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'TIME',
    accessor: 'time',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'DELIVERY TIME',
    accessor: 'deliveryTime',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'ORDER ACTIONS',
    accessor: 'actions',
    showColumn: true,
    editable: false,
    admin: false,
  },
];
