import React, { useState, useEffect } from 'react';
import Card from 'components/card';
import { lineChartOptionsOverallRevenue } from 'variables/charts';
import LineChart from 'components/charts/LineChart';

const CTR = ({
  siteUrl,
  startDate,
  endDate,
  setIsCTRData,
  handleSelectProject,
}) => {
  const accessToken = window.localStorage.getItem('accessToken');
  const [CTR, setCTR] = useState(0);
  const [ctrArray, setCTRArray] = useState([]);
  const [chartOptions, setChartOptions] = useState({});
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    fetch(
      `https://www.googleapis.com/webmasters/v3/sites/${encodeURIComponent(
        siteUrl,
      )}/searchAnalytics/query`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          startDate: startDate,
          endDate: endDate,
          dimensions: ['date'],
          rowLimit: 25000,
          type: 'Web',
          dataState: 'all',
        }),
      },
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          setIsCTRData(false);
          return;
        }
        if (!data.rows) {
          setIsCTRData(false);
          return;
        }
        let totalClicks = 0;
        let totalImpressions = 0;
        const ctrArray = [];
        data.rows.forEach((row) => {
          totalClicks += row.clicks;
          totalImpressions += row.impressions;
          ctrArray.push(Math.round(row.ctr * 1000) / 10);
        });
        let averageCTR = totalClicks / totalImpressions;
        setCTR(averageCTR);
        setCTRArray(ctrArray);
        setIsCTRData(true);
      });
  }, [startDate, endDate, siteUrl, accessToken, setIsCTRData]);

  useEffect(() => {
    const chartData = [
      {
        name: 'Impressions',
        data: ctrArray,
        showData: true,
      },
    ];

    const newOptions = {
      ...lineChartOptionsOverallRevenue,
      chart: {
        toolbar: {
          show: false,
        },
        dropShadow: {
          enabled: true,
          top: 20,
          left: 0,
          blur: 12,
          opacity: 0.7,
          color: '#15803D',
        },
      },
      colors: ['#15803D'],
      xaxis: {
        labels: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
      },
      tooltip: {
        enabled: false,
      },
    };
    setChartData(chartData);
    setChartOptions(newOptions);
  }, [ctrArray]);

  return (
    <Card
      extra="flex flex-col w-full md:w-1/2 !p-4 bg-white rounded-[20px] shadow-none cursor-pointer"
      onClick={() => handleSelectProject()}
    >
      <div className="flex w-full flex-col">
        <p className="text-lg font-bold text-gray-600 dark:text-white">CTR</p>
        <p className="text-3xl font-bold text-gray-800 dark:text-white">
          {(CTR * 100).toFixed(2)}%
        </p>
      </div>
      <div className="flex h-full w-full flex-row sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden">
        <div className="h-full w-full">
          <LineChart
            chartData={chartData}
            chartOptions={chartOptions}
            key={`${JSON.stringify(chartData)}`}
          />
        </div>
      </div>
    </Card>
  );
};

export default CTR;
