import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalBody } from '@chakra-ui/modal';
import Invoice from './Invoice';

const InvoiceModal = (props) => {
  const { showModal, closeModal, invoice } = props;

  return (
    <div>
      <Modal className="!z-[100]" isOpen={showModal} onClose={closeModal}>
        <ModalOverlay className="bg-[#000] !opacity-30" />
        <ModalContent className="top-[5vh] !m-auto max-w-[90%] md:top-[2vh] md:!max-w-[50%]">
          <ModalBody>
            <Invoice invoice={invoice} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default InvoiceModal;
