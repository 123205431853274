import React from 'react';
import InputField from './InputField';

const AhrefsFilters = ({
  domainRating,
  setDomainRating,
  organicTraffic,
  setOrganicTraffic,
  referringDomains,
  setReferringDomains,
  linkedDomains,
  setLinkedDomains,
  organicKeywords,
  setOrganicKeywords,
}) => {
  const handleDomainRatingChange = (event) => {
    const { id, value } = event.target;
    const convertedValue = Number(value);
    if (id === 'drMin') {
      setDomainRating({ ...domainRating, from: convertedValue });
    } else if (id === 'drMax') {
      setDomainRating({ ...domainRating, to: convertedValue });
    }
  };

  const handleOrganicTrafficChange = (event) => {
    const { id, value } = event.target;
    const convertedValue = Number(value);
    if (id === 'dotMin') {
      setOrganicTraffic({ ...organicTraffic, from: convertedValue });
    } else if (id === 'dotMax') {
      setOrganicTraffic({ ...organicTraffic, to: convertedValue });
    }
  };

  const handleReferringDomainsChange = (event) => {
    const { id, value } = event.target;
    const convertedValue = Number(value);
    if (id === 'rdMin') {
      setReferringDomains({ ...referringDomains, from: convertedValue });
    } else if (id === 'rdMax') {
      setReferringDomains({ ...referringDomains, to: convertedValue });
    }
  };

  const handleLinkedDomainsChange = (event) => {
    const { id, value } = event.target;
    const convertedValue = Number(value);
    if (id === 'ldMin') {
      setLinkedDomains({ ...linkedDomains, from: convertedValue });
    } else if (id === 'ldMax') {
      setLinkedDomains({ ...linkedDomains, to: convertedValue });
    }
  };

  const handleOrganicKeywordsChange = (event) => {
    const { id, value } = event.target;
    const convertedValue = Number(value);
    if (id === 'okMin') {
      setOrganicKeywords({ ...organicKeywords, from: convertedValue });
    } else if (id === 'okMax') {
      setOrganicKeywords({ ...organicKeywords, to: convertedValue });
    }
  };

  return (
    <div className="h-full w-full rounded-[20px]">
      {/* content */}
      <div className="mt-3">
        <div>
          <p className="mb-0 text-sm font-bold text-gray-400 dark:text-white">
            Domain Rating
          </p>
          <div className="grid grid-cols-2 gap-3">
            <InputField
              extra="mb-1"
              placeholder="0"
              id="drMin"
              type="number"
              value={domainRating.from || ''}
              onChange={handleDomainRatingChange}
            />
            <InputField
              extra="mb-1"
              placeholder="100"
              id="drMax"
              type="number"
              value={domainRating.to || ''}
              onChange={handleDomainRatingChange}
            />
          </div>
        </div>
        <div className="mt-3">
          <p className="mb-0 text-sm font-bold text-gray-400 dark:text-white">
            Domain organic traffic
          </p>
          <div className="grid grid-cols-2 gap-3">
            <InputField
              extra="mb-1"
              placeholder="0"
              id="dotMin"
              type="number"
              value={organicTraffic.from || ''}
              onChange={handleOrganicTrafficChange}
            />
            <InputField
              extra="mb-1"
              placeholder="99,000,000"
              id="dotMax"
              type="number"
              value={organicTraffic.to || ''}
              onChange={handleOrganicTrafficChange}
            />
          </div>
        </div>
        <div className="mt-3">
          <p className="mb-0 text-sm font-bold text-gray-400 dark:text-white">
            Reffering domains
          </p>
          <div className="grid grid-cols-2 gap-3">
            <InputField
              extra="mb-1"
              placeholder="0"
              id="rdMin"
              type="number"
              value={referringDomains.from || ''}
              onChange={handleReferringDomainsChange}
            />
            <InputField
              extra="mb-1"
              placeholder="999,000"
              id="rdMax"
              type="number"
              value={referringDomains.to || ''}
              onChange={handleReferringDomainsChange}
            />
          </div>
        </div>
        <div className="mt-3">
          <p className="mb-0 text-sm font-bold text-gray-400 dark:text-white">
            Linked domains
          </p>
          <div className="grid grid-cols-2 gap-3">
            <InputField
              extra="mb-1"
              placeholder="0"
              id="ldMin"
              type="number"
              value={linkedDomains.from || ''}
              onChange={handleLinkedDomainsChange}
            />
            <InputField
              extra="mb-1"
              placeholder="999,000"
              id="ldMax"
              type="number"
              value={linkedDomains.to || ''}
              onChange={handleLinkedDomainsChange}
            />
          </div>
        </div>
        <div className="mt-3">
          <p className="mb-0 text-sm font-bold text-gray-400 dark:text-white">
            Organic keywords
          </p>
          <div className="grid grid-cols-2 gap-3">
            <InputField
              extra="mb-1"
              placeholder="0"
              id="okMin"
              type="number"
              value={organicKeywords.from || ''}
              onChange={handleOrganicKeywordsChange}
            />
            <InputField
              extra="mb-1"
              placeholder="99,000,000"
              id="okMax"
              type="number"
              value={organicKeywords.to || ''}
              onChange={handleOrganicKeywordsChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AhrefsFilters;
