import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Card from 'components/card';
import { ProjectContext } from 'contexts/ProjectContext';
import { get } from 'api';
import logoIcon from 'assets/img/logo/Icon.png';

const Widget = ({ icon, projectName, domainName, id, isClicksData }) => {
  const navigate = useNavigate();
  const { currentProject, allProjects, changeProject } =
    useContext(ProjectContext);
  const [avatar, setAvatar] = useState('');
  const [avatarValid, setAvatarValid] = useState(false);
  const [totalBacklinksBought, setTotalBacklinksBought] = useState(0);
  const [suggestionsData, setSuggestionsData] = useState([]);

  useEffect(() => {
    const getTotalBacklinksBought = async () => {
      await get(`/api/orders/total-backlinks/${id}`).then((res) => {
        res.json().then((data) => {
          setTotalBacklinksBought(data.orderFound.count);
        });
      });
    };
    getTotalBacklinksBought();
  }, []);

  const handleClick = () => {
    changeProject(allProjects.find((project) => project.id === id));
    navigate(`/admin/dashboards/projects/editProject`);
  };

  useEffect(() => {
    const fetchSuggestions = async () => {
      await get(`/api/suggestions/${id}`)
        .then((res) => res.json())
        .then((data) => {
          if (data.statusCode === 500) {
            return;
          } else {
            const suggestionsCount = data.length;
            setSuggestionsData(suggestionsCount);
          }
        });
    };
    fetchSuggestions();
  }, []);

  useEffect(() => {
    if (domainName) {
      const avatar = `https://www.google.com/s2/favicons?domain=${domainName}&sz=128`;
      fetch(avatar).then((res) => {
        if (!res.ok) {
          setAvatarValid(false);
        } else {
          setAvatarValid(true);
          setAvatar(avatar);
        }
      });
    }
  }, [domainName]);

  const handleViewMarketplace = () => {
    changeProject(allProjects.find((project) => project.id === id));
    navigate(`/admin/marketplace/overview`);
  };

  return (
    <Card extra="flex w-full md:w-1/2 h-full !p-4 bg-white rounded-[20px] shadow-none">
      <div className="flex h-auto w-full flex-col justify-between">
        <div className="flex w-full flex-wrap justify-start">
          <div className="mr-4 flex w-auto flex-row items-center">
            <div className="rounded-full bg-lightPrimary p-4 dark:bg-navy-700">
              {isClicksData ? (
                <img
                  src={avatarValid ? avatar : logoIcon}
                  alt="project icon"
                  className="h-14 w-14 object-cover"
                />
              ) : (
                <span className="flex items-center text-brand-500 dark:text-white">
                  {icon}
                </span>
              )}
            </div>
          </div>
          <div className="h-50 mr-4 flex w-auto flex-col justify-center">
            <h5 className="text-inherit block font-sans text-xl font-semibold leading-snug tracking-normal text-navy-800 antialiased dark:text-white">
              {projectName}
            </h5>
            <p className="font-dm text-sm font-medium text-gray-600">
              {domainName}
            </p>
          </div>
          <div className="ml-2 mt-2 flex flex-row items-center justify-start">
            <button
              onClick={() => handleClick()}
              className="rounded-[20px] bg-brand-300 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
            >
              + Edit
            </button>
            <button
              className="ml-2 rounded-[20px] bg-brand-300 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
              onClick={() => handleViewMarketplace()}
            >
              View Marketplace
            </button>
          </div>
        </div>
        <div className="flex">
          <div className="mt-10 flex w-full flex-col items-start justify-center md:w-1/2 md:!items-start md:items-center">
            <p className="text-center text-lg font-bold text-gray-600 dark:text-white">
              Total Backlinks Bought
            </p>
            <p className="w-full text-center text-3xl font-bold text-gray-800 dark:text-white md:text-left">
              {totalBacklinksBought}
            </p>
          </div>
          <div className="mt-10 flex w-full flex-col items-start justify-center md:w-1/2 md:!items-start md:items-center">
            <p className="text-center text-lg font-bold text-gray-600 dark:text-white">
              Total Active Suggestions
            </p>
            <p className="w-full text-center text-3xl font-bold text-gray-800 dark:text-white md:text-left">
              {suggestionsData}
            </p>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default Widget;
