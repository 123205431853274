import { createContext, useState, useEffect } from 'react';

export const BlacklistContext = createContext();

export const BlacklistProvider = ({ children }) => {
  const [blacklist, setBlacklist] = useState({});

  useEffect(() => {
    const data = window.localStorage.getItem('blacklist');
    if (data) {
      setBlacklist(JSON.parse(data));
    }
  }, []);

  const addToBlacklist = (item) => {
    setBlacklist((prev) => ({ ...prev, [item.id]: item }));
    window.localStorage.setItem(
      'blacklist',
      JSON.stringify({ ...blacklist, [item.id]: item }),
    );
  };

  const bulkAddToBlacklist = (data) => {
    const newBlacklist = { ...blacklist };
    data.forEach((item) => {
      newBlacklist[item.id] = item;
    });
    setBlacklist(newBlacklist);
    window.localStorage.setItem('blacklist', JSON.stringify(newBlacklist));
  };

  const removeFromBlacklist = (id) => {
    const { [id]: removed, ...rest } = blacklist;
    setBlacklist(rest);
    window.localStorage.setItem('blacklist', JSON.stringify(rest));
  };

  const bulkRemoveFromBlacklist = (data) => {
    const newBlacklist = { ...blacklist };
    data.forEach((item) => {
      delete newBlacklist[item.id];
    });
    setBlacklist(newBlacklist);
    window.localStorage.setItem('blacklist', JSON.stringify(newBlacklist));
  };

  return (
    <BlacklistContext.Provider
      value={{
        blacklist,
        addToBlacklist,
        removeFromBlacklist,
        bulkAddToBlacklist,
        bulkRemoveFromBlacklist,
      }}
    >
      {children}
    </BlacklistContext.Provider>
  );
};
