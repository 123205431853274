import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import Card from 'components/card';
import LineChart from 'components/charts/LineChart';
import { lineChartOptionsOverallRevenue } from 'variables/charts';
import Checkbox from 'components/checkbox';
import { ProjectContext } from 'contexts/ProjectContext';
import { get, getUserId } from 'api';

const OverallRevenue = ({
  startDate,
  endDate,
  showClicks,
  setShowClicks,
  showImpressions,
  setShowImpressions,
  showCTR,
  setShowCTR,
  showPosition,
  setShowPosition,
  queryFilter,
  pageFilter,
  deviceFilter,
  countryFilter,
  selectedSearchType,
  queryFilterType,
  pageFilterType,
  siteUrl,
  selectedRows,
}) => {
  const { currentProject } = useContext(ProjectContext);
  const [tableData, setTableData] = useState([]);
  const [totalClicks, setTotalClicks] = useState(0);
  const [totalImpressions, setTotalImpressions] = useState(0);
  const [averageCTR, setAverageCTR] = useState(0);
  const [averagePosition, setAveragePosition] = useState(0);
  const accessToken = window.localStorage.getItem('accessToken');
  const [clicksArray, setClicksArray] = useState([]);
  const [impressionsArray, setImpressionsArray] = useState([]);
  const [ctrArray, setCTRArray] = useState([]);
  const [positionArray, setPositionArray] = useState([]);
  const [datesArray, setDatesArray] = useState([]);
  const [datesToShowArray, setDatesToShowArray] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [chartOptions, setChartOptions] = useState({});
  const [chartData, setChartData] = useState([]);
  const [projectOrders, setProjectOrders] = useState([]);
  const [orderDates, setOrderDates] = useState([]);
  const [ordersCount, setOrdersCount] = useState(0);
  const [backlinksArray, setBacklinksArray] = useState([]);

  useEffect(() => {
    const userId = getUserId();
    const getOrders = async () => {
      await get(`/api/orders?userId=${userId}&projectId=${currentProject.id}`)
        .then((res) => res.json())
        .then((data) => {
          if (data.statusCode === 500) {
            setProjectOrders([]);
            return;
          }
          const newData = data
            .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
            .map((item) => {
              return {
                ...item,
                totalPrice:
                  parseFloat(item.articlePrice) + parseFloat(item.price),
              };
            });
          setProjectOrders(newData);
        });
    };
    if (!currentProject || Object.keys(currentProject).length === 0) {
      return;
    } else {
      getOrders();
    }
  }, [currentProject]);

  // useEffect(() => {
  //   const getOrdersCount = () => {
  //     const orderCounts = projectOrders.reduce((acc, order) => {
  //       const orderDate = moment(order.createdAt).format('YYYY-MM-DD');
  //       if (!acc[orderDate]) {
  //         acc[orderDate] = 0;
  //       }
  //       acc[orderDate]++;
  //       return acc;
  //     }, {});

  //     const uniqueDates = Object.keys(orderCounts);
  //     const counts = Object.values(orderCounts);

  //     setOrderDates(uniqueDates);
  //     setOrdersCount(counts);
  //   };

  //   getOrdersCount();
  // }, [projectOrders]);

  useEffect(() => {
    const specificDates = orderDates;
    const specificData = ordersCount;
    let newBacklinkData = new Array(datesArray.length).fill(null);

    specificDates.forEach((specificDate, i) => {
      let index = datesArray.findIndex((date) => date === specificDate);
      if (index !== -1) {
        newBacklinkData[index] = specificData[i];
      }
    });
    setBacklinksArray(newBacklinkData);
  }, [orderDates, ordersCount, datesArray]);

  useEffect(() => {
    const getSelectedOrdersCount = () => {
      const orderCounts = selectedRows.reduce((acc, order) => {
        const orderDate = moment(order.createdAt).format('YYYY-MM-DD');
        if (!acc[orderDate]) {
          acc[orderDate] = 0;
        }
        acc[orderDate]++;
        return acc;
      }, {});

      const uniqueDates = Object.keys(orderCounts);
      const counts = Object.values(orderCounts);

      setOrderDates(uniqueDates);
      setOrdersCount(counts);
    };

    getSelectedOrdersCount();
  }, [selectedRows]);

  const calculateDates = () => {
    const daysDifference = moment(endDate).diff(moment(startDate), 'days');
    let dateArray = [];
    if (daysDifference > 7 && daysDifference <= 31) {
      let currentDate = moment(startDate);
      while (currentDate <= moment(endDate)) {
        dateArray.push(currentDate.format('YYYY-MM-DD'));
        currentDate.add(5, 'days');
      }
    } else if (daysDifference >= 31 && daysDifference <= 93) {
      let currentDate = moment(startDate);
      while (currentDate <= moment(endDate)) {
        dateArray.push(currentDate.format('YYYY-MM-DD'));
        currentDate.add(7, 'days');
      }
    } else if (daysDifference >= 93 && daysDifference <= 186) {
      let currentDate = moment(startDate);
      while (currentDate <= moment(endDate)) {
        dateArray.push(currentDate.format('YYYY-MM-DD'));
        currentDate.add(14, 'days');
      }
    } else if (daysDifference >= 186 && daysDifference <= 365) {
      let currentDate = moment(startDate);
      while (currentDate <= moment(endDate)) {
        dateArray.push(currentDate.format('YYYY-MM-DD'));
        currentDate.add(28, 'days');
      }
    } else if (daysDifference >= 365) {
      let currentDate = moment(startDate);
      while (currentDate <= moment(endDate)) {
        dateArray.push(currentDate.format('YYYY-MM-DD'));
        currentDate.add(60, 'days');
      }
    } else {
      let currentDate = moment(startDate);
      while (currentDate <= moment(endDate)) {
        dateArray.push(currentDate.format('YYYY-MM-DD'));
        currentDate.add(1, 'days');
      }
    }
    setDatesToShowArray(dateArray);
  };

  useEffect(() => {
    calculateDates();
  }, [startDate, endDate]);

  const dimensionFilters = [
    {
      dimension: 'query',
      expression: queryFilter ? queryFilter : '',
      operator: queryFilter ? queryFilterType : 'contains',
    },
    {
      dimension: 'page',
      expression: pageFilter ? pageFilter : '',
      operator: pageFilter ? pageFilterType : 'contains',
    },
    {
      dimension: 'device',
      expression: deviceFilter ? deviceFilter : '',
      operator: deviceFilter ? 'equals' : 'contains',
    },
    {
      dimension: 'country',
      expression: countryFilter ? countryFilter : '',
      operator: countryFilter ? 'equals' : 'contains',
    },
  ];

  const dimensionFilterGroups = dimensionFilters.filter(
    (filter) => filter.expression !== '',
  );

  useEffect(() => {
    const googleConsole = async () => {
      fetch(
        `https://www.googleapis.com/webmasters/v3/sites/${encodeURIComponent(
          siteUrl,
        )}/searchAnalytics/query`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            startDate: startDate,
            endDate: endDate,
            dimensions: ['date'],
            rowLimit: 25000,
            type: selectedSearchType,
            dataState: 'all',
            dimensionFilterGroups: dimensionFilterGroups.length
              ? [{ filters: dimensionFilterGroups }]
              : undefined,
          }),
        },
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {
            setLoaded(false);
            return;
          }
          if (!data.rows) {
            return;
          }
          const groups = {};
          let clicks = 0;
          let impressions = 0;
          let ctr = 0;
          let position = 0;
          const clicksArray = [];
          const impressionsArray = [];
          const datesArray = [];
          const ctrArray = [];
          const positionArray = [];
          data.rows.forEach((row) => {
            const date = row.keys[0];
            const rowClicks = row.clicks;
            const rowImpressions = row.impressions;
            const rowCTR = row.ctr;
            const rowPosition = row.position;
            if (!groups[date]) {
              groups[date] = {
                date,
                clicks: 0,
                impressions: 0,
                ctr: 0,
                position: 0,
              };
            }
            groups[date].clicks += rowClicks;
            groups[date].impressions += rowImpressions;
            groups[date].ctr += row.ctr;
            groups[date].ctr = (groups[date].ctr * 100).toFixed(1) + '%';
            groups[date].position += row.position;
            groups[date].position = Math.round(groups[date].position * 10) / 10;
            clicks += rowClicks;
            impressions += rowImpressions;
            ctr += rowCTR;
            position += rowPosition;
            clicksArray.push(Number(rowClicks));
            impressionsArray.push(Number(rowImpressions));
            ctrArray.push(Math.round(rowCTR * 1000) / 10);
            positionArray.push(Math.round(rowPosition * 10) / 10);
            datesArray.push(date);
          });
          const tableData = Object.values(groups);
          const averageCTR = clicks / impressions;
          const averagePosition = position / data.rows.length;
          setTableData(tableData);
          setTotalClicks(clicks);
          setTotalImpressions(impressions);
          setAverageCTR(averageCTR || 0);
          setAveragePosition(averagePosition);
          setClicksArray(clicksArray);
          setImpressionsArray(impressionsArray);
          setCTRArray(ctrArray);
          setPositionArray(positionArray);
          setDatesArray(datesArray);
        })
        .catch((error) => {
          console.error(error);
        });
    };
    if (!siteUrl || !accessToken) {
      return;
    } else {
      googleConsole();
    }
  }, [
    siteUrl,
    accessToken,
    startDate,
    endDate,
    countryFilter,
    queryFilter,
    pageFilter,
    deviceFilter,
    selectedSearchType,
    queryFilterType,
    pageFilterType,
  ]);

  useEffect(() => {
    if (tableData.length && datesArray.length) {
      setLoaded(true);
    }
  }, [tableData, datesArray]);

  useEffect(() => {
    const colors = [];
    const series = [];
    const markerSize = [];

    if (showClicks) {
      colors.push('#3B82F6');
      series.push({
        show:
          showImpressions && showCTR
            ? false
            : showImpressions && showPosition
            ? false
            : showCTR && showPosition
            ? false
            : true,
        seriesName: 'Clicks',
        color: 'black',
        title: {
          text: 'Clicks',
          style: {
            color: '#3B82F6',
          },
        },
        labels: {
          formatter: function (value) {
            return value === null ? value : value.toLocaleString();
          },
        },
      });
      markerSize.push(0);
    }
    if (showImpressions) {
      colors.push('#1D4ED8');
      series.push({
        show:
          showClicks && showCTR
            ? false
            : showClicks && showPosition
            ? false
            : showCTR && showPosition
            ? false
            : true,
        seriesName: 'Impressions',
        opposite: showClicks ? true : false,
        color: 'black',
        title: {
          text: 'Impressions',
          style: {
            color: '#1D4ED8',
          },
        },
        labels: {
          formatter: function (value) {
            return value === null ? value : value.toLocaleString();
          },
        },
      });
      markerSize.push(0);
    }
    if (showCTR) {
      colors.push('#15803D');
      series.push({
        show:
          showImpressions && showClicks
            ? false
            : showClicks && showPosition
            ? false
            : showImpressions && showPosition
            ? false
            : true,
        seriesName: 'CTR',
        opposite: showClicks || showImpressions ? true : false,
        color: 'black',
        title: {
          text: 'CTR',
          style: {
            color: '#15803D',
          },
        },
      });
      markerSize.push(0);
    }
    if (showPosition) {
      colors.push('#C2410C');
      series.push({
        show:
          showImpressions && showClicks
            ? false
            : showClicks && showCTR
            ? false
            : showImpressions && showCTR
            ? false
            : true,
        seriesName: 'Position',
        opposite: true,
        color: 'black',
        title: {
          text: 'Position',
          style: {
            color: '#C2410C',
          },
        },
      });
      markerSize.push(0);
    }
    if (backlinksArray.length > 0) {
      colors.push('#fb0303');
      markerSize.push(6);
      series.push({
        labels: {
          show: false,
          formatter: function (value) {
            return value === null ? value : value.toLocaleString();
          },
        },
        seriesName: 'Backlinks',
        color: 'black',
      });
    }

    const newOptions = {
      ...lineChartOptionsOverallRevenue,
      chart: {
        toolbar: {
          show: true,
        },
      },
      colors,
      markers: {
        size: markerSize,
      },
      xaxis: {
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
        categories: datesArray,
        overwriteCategories: datesToShowArray,
        labels: {
          rotate: 0,
        },
      },
      yaxis: series,
    };

    const chartData = [
      {
        name: 'Clicks',
        data: clicksArray,
        showData: showClicks,
        type: 'line',
      },
      {
        name: 'Impressions',
        data: impressionsArray,
        showData: showImpressions,
        type: 'line',
      },
      {
        name: 'CTR',
        data: ctrArray,
        showData: showCTR,
        type: 'line',
      },
      {
        name: 'Position',
        data: positionArray,
        showData: showPosition,
        type: 'line',
      },
      {
        name: 'Backlinks',
        data: backlinksArray,
        showData: true,
        type: 'scatter',
      },
    ];

    setChartOptions(newOptions);
    setChartData(chartData);
  }, [
    datesArray,
    datesToShowArray,
    clicksArray,
    impressionsArray,
    ctrArray,
    positionArray,
    showClicks,
    showImpressions,
    showCTR,
    showPosition,
    backlinksArray,
  ]);

  const formatNumber = (num) => {
    if (num >= 1000 && num < 1000000) {
      return (num / 1000).toFixed(2) + 'K';
    } else if (num >= 1000000) {
      return (num / 1000000).toFixed(2) + 'M';
    } else {
      return num;
    }
  };

  if (!loaded) {
    return null;
  }

  return (
    <Card extra={'h-[60vh] pb-8 md:px-4 md:pt-6'}>
      <div className="flex justify-between md:px-3 md:pt-1">
        <div className="flex flex-row flex-wrap items-center gap-1 md:!flex-wrap md:gap-2">
          <div
            onClick={() => {
              if (!showImpressions && !showCTR && !showPosition) return;
              setShowClicks(!showClicks);
            }}
            className={`flex w-[49%] cursor-pointer flex-col rounded-lg p-2 hover:bg-blue-400 dark:bg-gray-800 dark:hover:bg-gray-700 md:w-auto 
            ${showClicks ? 'bg-blue-500' : 'border-2 bg-white'}`}
          >
            <div className="mr-2 flex">
              <Checkbox
                checked={showClicks}
                onChange={() => {
                  if (!showImpressions && !showCTR && !showPosition) return;
                  setShowClicks(!showClicks);
                }}
              />
              <p
                className={`ml-2 text-[12px] dark:text-gray-400 ${
                  showClicks ? 'text-white' : 'text-black'
                }`}
              >
                Total Clicks
              </p>
            </div>
            <div className="flex flex-col">
              <p
                className={`text-[32px] font-medium  dark:text-gray-400 ${
                  showClicks ? 'text-white' : 'text-black'
                }`}
              >
                {formatNumber(totalClicks)}
              </p>
            </div>
          </div>
          <div
            onClick={() => {
              if (!showClicks && !showCTR && !showPosition) return;
              setShowImpressions(!showImpressions);
            }}
            className={`flex w-[49%] cursor-pointer flex-col rounded-lg p-2 hover:bg-blue-600 dark:bg-gray-800 dark:hover:bg-gray-700 md:w-auto
            ${showImpressions ? 'bg-blue-700' : 'border-2 bg-white'}`}
          >
            <div className="mr-2 flex">
              <Checkbox
                checked={showImpressions}
                onChange={() => {
                  if (!showClicks && !showCTR && !showPosition) return;
                  setShowImpressions(!showImpressions);
                }}
              />
              <p
                className={`ml-2 text-[12px] dark:text-gray-400 ${
                  showImpressions ? 'text-white' : 'text-black'
                }`}
              >
                Total Impressions
              </p>
            </div>
            <div className="flex flex-col">
              <p
                className={`text-[32px] font-medium dark:text-gray-400 ${
                  showImpressions ? 'text-white' : 'text-black'
                }`}
              >
                {formatNumber(totalImpressions)}
              </p>
            </div>
          </div>
          <div
            onClick={() => {
              if (!showClicks && !showImpressions && !showPosition) return;
              setShowCTR(!showCTR);
            }}
            className={`flex w-[49%] cursor-pointer flex-col rounded-lg p-2 hover:bg-green-600 dark:bg-gray-800 dark:hover:bg-gray-700 md:w-auto
            ${showCTR ? 'bg-green-700' : 'border-2 bg-white'}`}
          >
            <div className="mr-2 flex">
              <Checkbox
                checked={showCTR}
                onChange={() => {
                  if (!showClicks && !showImpressions && !showPosition) return;
                  setShowCTR(!showCTR);
                }}
              />
              <p
                className={`ml-2 text-[12px] dark:text-gray-400 ${
                  showCTR ? 'text-white' : 'text-black'
                }`}
              >
                Average CTR
              </p>
            </div>
            <div className="flex flex-col">
              <p
                className={`text-[32px] font-medium  dark:text-gray-400 ${
                  showCTR ? 'text-white' : 'text-black'
                }`}
              >
                {(averageCTR * 100).toFixed(2)}%
              </p>
            </div>
          </div>
          <div
            onClick={() => {
              if (!showClicks && !showImpressions && !showCTR) return;
              setShowPosition(!showPosition);
            }}
            className={`flex w-[49%] cursor-pointer flex-col rounded-lg p-2 hover:bg-orange-600 dark:bg-gray-800 dark:hover:bg-gray-700 md:w-auto
            ${showPosition ? 'bg-orange-700' : 'border-2 bg-white'}`}
          >
            <div className="mr-2 flex">
              <Checkbox
                checked={showPosition}
                onChange={() => {
                  if (!showClicks && !showImpressions && !showCTR) return;
                  setShowPosition(!showPosition);
                }}
              />
              <p
                className={`ml-2 text-[12px] dark:text-gray-400 ${
                  showPosition ? 'text-white' : 'text-black'
                }`}
              >
                Average Position
              </p>
            </div>
            <div className="flex flex-col">
              <p
                className={`text-[32px] font-medium  dark:text-gray-400 ${
                  showPosition ? 'text-white' : 'text-black'
                }`}
              >
                {averagePosition.toFixed(2)}
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Charts */}
      <div className="mt-4 flex h-full w-full flex-row sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden">
        <div className="h-full w-full">
          <LineChart
            chartData={chartData}
            chartOptions={chartOptions}
            key={`${JSON.stringify(chartData)}-${JSON.stringify(chartOptions)}`}
          />
        </div>
      </div>
    </Card>
  );
};

export default OverallRevenue;
