import React, { useContext } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalBody } from '@chakra-ui/modal';
import { useDisclosure } from '@chakra-ui/hooks';
import Card from 'components/card';
import Checkbox from 'components/checkbox';
import { ColumnsViewContext } from 'contexts/ColumnsViewContext';
import { getUserRole } from 'api';

const ColumnsViewModal = ({ page }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    marketplaceColumnsView,
    competitorColumnsView,
    suggestionColumnsView,
    orderManagerColumnsView,
    orderAdminColumnsView,
    favoritesColumnsView,
    blacklistColumnsView,
    toggleColumnView,
  } = useContext(ColumnsViewContext);
  const userRole = getUserRole();

  return (
    <div className="w-full md:w-auto">
      <button
        onClick={onOpen}
        className="text-md w-full rounded-full bg-blue-500 px-4 py-2 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 md:ml-2 md:h-[50px] md:w-auto"
      >
        CHANGE COLUMNS VIEW
      </button>
      <Modal className="!z-[100]" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay className="bg-[#000] !opacity-30" />
        <ModalContent className="top-[22vh] !m-auto !w-max !max-w-[85%] md:top-[12vh]">
          <ModalBody>
            <Card extra={'w-full sm:overflow-auto p-4 pt-4'}>
              <div className="mt-5 flex flex-col">
                <div className="text-2xl dark:text-white">
                  Select Columns to View
                </div>
                <div className="mt-3 flex flex-col">
                  {page === 'marketplace' &&
                    marketplaceColumnsView
                      .filter(
                        (column) =>
                          column.editable === true && column.admin === false,
                      )
                      .map((column) => (
                        <div className="flex items-center" key={column.Header}>
                          <Checkbox
                            checked={column.showColumn}
                            onChange={() =>
                              toggleColumnView(column.Header, page)
                            }
                          />
                          <div className="ml-2">{column.Header}</div>
                        </div>
                      ))}
                  {page === 'suggestions' &&
                    suggestionColumnsView
                      .filter(
                        (column) =>
                          column.editable === true && column.admin === false,
                      )
                      .map((column) => (
                        <div className="flex items-center" key={column.Header}>
                          <Checkbox
                            checked={column.showColumn}
                            onChange={() =>
                              toggleColumnView(column.Header, page)
                            }
                          />
                          <div className="ml-2">{column.Header}</div>
                        </div>
                      ))}
                  {page === 'competitors' &&
                    competitorColumnsView
                      .filter(
                        (column) =>
                          column.editable === true && column.admin === false,
                      )
                      .map((column) => (
                        <div className="flex items-center" key={column.Header}>
                          <Checkbox
                            checked={column.showColumn}
                            onChange={() =>
                              toggleColumnView(column.Header, page)
                            }
                          />
                          <div className="ml-2">{column.Header}</div>
                        </div>
                      ))}
                  {page === 'orderManager' &&
                    orderManagerColumnsView
                      .filter(
                        (column) =>
                          column.editable === true &&
                          (userRole === 'admin'
                            ? column.admin === true || column.admin === false
                            : column.admin === false),
                      )
                      .map((column) => (
                        <div className="flex items-center" key={column.Header}>
                          <Checkbox
                            checked={column.showColumn}
                            onChange={() =>
                              toggleColumnView(column.Header, page)
                            }
                          />
                          <div className="ml-2">{column.Header}</div>
                        </div>
                      ))}
                  {page === 'orderAdmin' &&
                    orderAdminColumnsView
                      .filter(
                        (column) =>
                          column.editable === true &&
                          (userRole === 'admin'
                            ? column.admin === true || column.admin === false
                            : column.admin === false),
                      )
                      .map((column) => (
                        <div className="flex items-center" key={column.Header}>
                          <Checkbox
                            checked={column.showColumn}
                            onChange={() =>
                              toggleColumnView(column.Header, page)
                            }
                          />
                          <div className="ml-2">{column.Header}</div>
                        </div>
                      ))}
                  {page === 'favorites' &&
                    favoritesColumnsView
                      .filter(
                        (column) =>
                          column.editable === true && column.admin === false,
                      )
                      .map((column) => (
                        <div className="flex items-center" key={column.Header}>
                          <Checkbox
                            checked={column.showColumn}
                            onChange={() =>
                              toggleColumnView(column.Header, page)
                            }
                          />
                          <div className="ml-2">{column.Header}</div>
                        </div>
                      ))}
                  {page === 'blacklist' &&
                    blacklistColumnsView
                      .filter(
                        (column) =>
                          column.editable === true && column.admin === false,
                      )
                      .map((column) => (
                        <div className="flex items-center" key={column.Header}>
                          <Checkbox
                            checked={column.showColumn}
                            onChange={() =>
                              toggleColumnView(column.Header, page)
                            }
                          />
                          <div className="ml-2">{column.Header}</div>
                        </div>
                      ))}
                </div>
              </div>
              <div className="mt-5 flex justify-end">
                <button
                  onClick={onClose}
                  className="bg-transparent ml-3 rounded-md border border-gray-500 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
                >
                  Close
                </button>
              </div>
            </Card>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ColumnsViewModal;
