export const queriesHeadears = [
  {
    Header: 'Query',
    accessor: 'query',
  },
  {
    Header: 'Clicks',
    accessor: 'clicks',
  },
  {
    Header: 'Impressions',
    accessor: 'impressions',
  },
  {
    Header: 'CTR',
    accessor: 'ctr',
  },
  {
    Header: 'Position',
    accessor: 'position',
  },
];

export const pagesHeadears = [
  {
    Header: 'Page',
    accessor: 'page',
  },
  {
    Header: 'Clicks',
    accessor: 'clicks',
  },
  {
    Header: 'Impressions',
    accessor: 'impressions',
  },
  {
    Header: 'CTR',
    accessor: 'ctr',
  },
  {
    Header: 'Position',
    accessor: 'position',
  },
];

export const countriesHeadears = [
  {
    Header: 'Country',
    accessor: 'countryName',
  },
  {
    Header: 'Clicks',
    accessor: 'clicks',
  },
  {
    Header: 'Impressions',
    accessor: 'impressions',
  },
  {
    Header: 'CTR',
    accessor: 'ctr',
  },
  {
    Header: 'Position',
    accessor: 'position',
  },
];

export const devicesHeadears = [
  {
    Header: 'Device',
    accessor: 'device',
  },
  {
    Header: 'Clicks',
    accessor: 'clicks',
  },
  {
    Header: 'Impressions',
    accessor: 'impressions',
  },
  {
    Header: 'CTR',
    accessor: 'ctr',
  },
  {
    Header: 'Position',
    accessor: 'position',
  },
];

export const datesHeadears = [
  {
    Header: 'Date',
    accessor: 'date',
  },
  {
    Header: 'Clicks',
    accessor: 'clicks',
  },
  {
    Header: 'Impressions',
    accessor: 'impressions',
  },
  {
    Header: 'CTR',
    accessor: 'ctr',
  },
  {
    Header: 'Position',
    accessor: 'position',
  },
];

export const searchAppearanceHeadears = [
  {
    Header: 'Search Appearance',
    accessor: 'searchAppearance',
  },
  {
    Header: 'Clicks',
    accessor: 'clicks',
  },
  {
    Header: 'Impressions',
    accessor: 'impressions',
  },
  {
    Header: 'CTR',
    accessor: 'ctr',
  },
  {
    Header: 'Position',
    accessor: 'position',
  },
];

export const orderManagerHeaders = [
  {
    Header: 'ORDER ID',
    accessor: 'id',
    showColumn: true,
    editable: false,
    admin: false,
  },
  {
    Header: 'DOMAIN',
    accessor: 'domain',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'DATE',
    accessor: 'createdAt',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'PRICE',
    accessor: 'totalPrice',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'ANCHORS',
    accessor: 'anchors',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'PUBLICATION URL',
    accessor: 'publicationUrl',
    showColumn: true,
    editable: true,
    admin: false,
  },
  {
    Header: 'INDEXED',
    accessor: 'indexed',
    showColumn: true,
    editable: true,
    admin: false,
  },
];
