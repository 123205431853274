import React, { useState } from 'react';
import competitorData from './variables/competitorData.json';
import { competitorHeader } from './variables/columnsData';
import MarketplaceTable from './components/MarketplaceTable';
import CompetitorTable from './components/CompetitorTable';
import SuggestionTable from './components/SuggestionTable';
import FiltersModal from './components/FiltersModal';
import ShoppingCart from './components/ShoppingCart';
import ColumnsViewModal from './components/ColumnsViewModal';
import FavoritesTable from './components/FavoritesTable';
import BlacklistTable from './components/BlacklistTable';

const Marketplace = () => {
  const [activeComponent, setActiveComponent] = useState('marketplace');
  const [filters, setFilters] = useState({
    general: {
      // acceptedTopic: [
      //   { value: 'iGaming', label: 'iGaming' },
      //   { value: 'Adult', label: 'Adult' },
      //   { value: 'CBD', label: 'CBD' },
      // ],
      category: [],
      country: [],
      backlinkPrice: {},
      doFollowLinks: [],
    },
    ahrefs: {
      domainRating: {},
      organicTraffic: {},
      referringDomains: {},
      linkedDomains: {},
      organicKeywords: {},
    },
  });

  const handleButtonClick = (componentName) => {
    setActiveComponent(componentName);
  };

  return (
    <div>
      <div className="mt-5 flex items-center justify-end">
        <ShoppingCart />
      </div>
      <div className="sm:flex sm:items-center sm:justify-between sm:space-x-3 md:flex md:space-x-5">
        <div className="mt-5 flex h-[200px] w-full flex-col items-center rounded-[12px] bg-blue-500 p-1.5 md:h-[50px] md:w-[60%] md:flex-row md:rounded-full">
          <button
            className={`linear text-md flex h-full w-full cursor-pointer select-none items-center justify-center rounded-[20px] font-bold uppercase text-brand-500 transition duration-200 ${
              activeComponent === 'marketplace'
                ? 'bg-white text-brand-500'
                : 'bg-transparent text-white'
            } `}
            onClick={() => handleButtonClick('marketplace')}
          >
            Marketplace
          </button>
          <button
            className={`linear text-md flex h-full w-full cursor-pointer select-none items-center justify-center rounded-[20px] font-bold uppercase text-brand-500 transition duration-200 ${
              activeComponent === 'suggestions'
                ? 'bg-white text-brand-500'
                : 'bg-transparent text-white'
            } `}
            onClick={() => handleButtonClick('suggestions')}
          >
            Suggestions
          </button>
          <button
            className={`linear text-md flex h-full w-full cursor-pointer select-none items-center justify-center rounded-[20px] font-bold uppercase text-brand-500 transition duration-200 ${
              activeComponent === 'competitors'
                ? 'bg-white text-brand-500'
                : 'bg-transparent text-white'
            } `}
            onClick={() => handleButtonClick('competitors')}
          >
            Competitors
          </button>
          <button
            className={`linear text-md flex h-full w-full cursor-pointer select-none items-center justify-center rounded-[20px] font-bold uppercase text-brand-500 transition duration-200 ${
              activeComponent === 'favorites'
                ? 'bg-white text-brand-500'
                : 'bg-transparent text-white'
            } `}
            onClick={() => handleButtonClick('favorites')}
          >
            Favorites
          </button>
          <button
            className={`linear text-md flex h-full w-full cursor-pointer select-none items-center justify-center rounded-[20px] font-bold uppercase text-brand-500 transition duration-200 ${
              activeComponent === 'blacklist'
                ? 'bg-white text-brand-500'
                : 'bg-transparent text-white'
            } `}
            onClick={() => handleButtonClick('blacklist')}
          >
            Blacklist
          </button>
        </div>
        <div className="mt-5 flex flex-col items-center justify-end md:flex-row">
          <FiltersModal filters={filters} setFilters={setFilters} />
          <ColumnsViewModal page={activeComponent} />
        </div>
      </div>
      <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
        {activeComponent === 'marketplace' && (
          <MarketplaceTable
            filters={filters}
            setActiveComponent={setActiveComponent}
          />
        )}
        {activeComponent === 'suggestions' && (
          <SuggestionTable filters={filters} />
        )}
        {activeComponent === 'competitors' && (
          <CompetitorTable columnsData={competitorHeader} tableData={[]} />
        )}
        {activeComponent === 'favorites' && (
          <FavoritesTable
            filters={filters}
            setActiveComponent={setActiveComponent}
          />
        )}
        {activeComponent === 'blacklist' && (
          <BlacklistTable
            filters={filters}
            setActiveComponent={setActiveComponent}
          />
        )}
      </div>
    </div>
  );
};

export default Marketplace;
