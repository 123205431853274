import React from 'react';

function DashCurveDown() {
  return (
    <svg width="134" height="22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 1v0c40.269 25.626 91.731 25.626 132 0v0"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="6 6"
      />
    </svg>
  );
}

export default DashCurveDown;
