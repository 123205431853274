import React, { useContext } from 'react';
import Dropdown from 'components/dropdown';
import { CartContext } from 'contexts/CartContext';
import { FavoritesContext } from 'contexts/FavoritesContext';
import { BlacklistContext } from 'contexts/BlacklistContext';

const BulkActions = ({
  data,
  pageName,
  setActiveComponent,
  fetchSuggestions,
  setIsDenyReasonModalOpen,
  setDenyReasonData,
}) => {
  const { bulkAddToCart, removeBulkFromCart } = useContext(CartContext);
  const { bulkAddToFavorites, bulkRemoveFromFavorites } =
    useContext(FavoritesContext);
  const { bulkAddToBlacklist, bulkRemoveFromBlacklist } =
    useContext(BlacklistContext);

  const handleBulkCopy = (data) => {
    const text = data.map((item) => item.domain).join('\n');
    navigator.clipboard.writeText(text);
  };

  const handleDenySuggestion = async (data) => {
    setIsDenyReasonModalOpen(true);
    setDenyReasonData(data);
  };

  return (
    <Dropdown
      button={
        <button className="rounded-md border border-gray-300 px-2 py-1 text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50">
          Bulk Actions
        </button>
      }
      classNames="origin-top-right transition-all duration-300 ease-in-out"
    >
      <div className="rounded-md bg-white py-1 shadow-lg">
        <button
          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
          onClick={() => handleBulkCopy(data)}
        >
          Copy domains
        </button>
        {pageName === 'suggestions' ? (
          <button
            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
            role="menuitem"
            onClick={() => handleDenySuggestion(data)}
          >
            Deny suggestions
          </button>
        ) : null}
        {pageName === 'blacklist' || pageName === 'suggestions' ? null : (
          <button
            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
            role="menuitem"
            onClick={() => {
              pageName === 'favorites'
                ? bulkRemoveFromFavorites(data)
                : bulkAddToFavorites(data);

              pageName === 'favorites'
                ? setActiveComponent('marketplace')
                : setActiveComponent('favorites');
            }}
          >
            {pageName === 'favorites'
              ? 'Remove from favorites'
              : pageName === 'suggestions'
              ? null
              : 'Add to favorites'}
          </button>
        )}
        {pageName === 'favorites' || pageName === 'suggestions' ? null : (
          <button
            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
            role="menuitem"
            onClick={() => {
              pageName === 'blacklist'
                ? bulkRemoveFromBlacklist(data)
                : bulkAddToBlacklist(data);

              pageName === 'blacklist'
                ? setActiveComponent('marketplace')
                : setActiveComponent('blacklist');
            }}
          >
            {pageName === 'blacklist'
              ? 'Remove from blacklist'
              : 'Add to blacklist'}
          </button>
        )}
        <button
          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
          role="menuitem"
          onClick={() => bulkAddToCart(data, pageName)}
        >
          Add to cart
        </button>
        <button
          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
          role="menuitem"
          onClick={() => removeBulkFromCart(data, pageName)}
        >
          Remove from cart
        </button>
      </div>
    </Dropdown>
  );
};

export default BulkActions;
