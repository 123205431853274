import React, { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import TextField from 'components/fields/TextField';
import InputField from 'components/fields/InputField';
import { toastError } from 'utils/toasts';
import Radio from 'components/radio';

const Domain = ({ projectInfo, updateProjectInfo }) => {
  const { projectName, domainName, description, isIgaming } = projectInfo;
  const [googleSiteUrls, setGoogleSiteUrls] = useState([]);
  const accessToken = window.localStorage.getItem('accessToken');

  useEffect(() => {
    fetch('https://www.googleapis.com/webmasters/v3/sites', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        const { siteEntry } = data;
        if (!siteEntry) {
          return;
        }
        const siteUrls = siteEntry.map((site) => {
          return site.siteUrl;
        });
        const siteOptions = siteUrls.map((url) => {
          const newUrl = url
            .replace('http://', '')
            .replace('https://', '')
            .replace('sc-domain:', '');
          return { value: newUrl, label: newUrl };
        });
        setGoogleSiteUrls(siteOptions);
      });
  }, [accessToken]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'projectName' && value.length > 25) {
      toastError('Project name should not exceed 25 characters');
      return;
    }
    updateProjectInfo({ [name]: value });
  };

  const handleProjectDomainChange = (newValue) => {
    if (!newValue) {
      updateProjectInfo({ domainName: '' });
    } else {
      updateProjectInfo({ domainName: 'https://' + newValue.value });
    }
  };

  return (
    <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
      {/* Header */}
      <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
        Domain info
      </h4>
      {/* inputs */}
      <div className="mt-4">
        <InputField
          label="Project name"
          placeholder="Project"
          id="projectName"
          name="projectName"
          onChange={handleInputChange}
          value={projectName || ''}
          required
        />
      </div>
      <div className="mt-4">
        <div className="mb-2 text-sm font-bold text-navy-700 dark:text-white">
          <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
            Domain name
          </label>
        </div>
        <CreatableSelect
          isClearable
          isSearchable
          options={googleSiteUrls}
          placeholder="Select or create a domain"
          onChange={handleProjectDomainChange}
          value={domainName ? { value: domainName, label: domainName } : ''}
          styles={{
            control: (styles) => ({
              ...styles,
              backgroundColor: 'white',
              border: '1px solid #E5E5E5',
              borderRadius: '0.75rem',
              height: '48px',
              fontSize: '14px',
              lineHeight: '20px',
              color: '#4B5563',
              fontFamily: 'DM Sans',
              fontWeight: '500',
              boxShadow: 'none',
              '&:hover': {
                border: '1px solid #E5E5E5',
              },
            }),
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
              return {
                ...styles,
                backgroundColor: isDisabled
                  ? null
                  : isSelected
                  ? '#f5f5f5'
                  : isFocused
                  ? '#f5f5f5'
                  : null,
                color: isDisabled ? '#ccc' : '#4B5563',
                cursor: isDisabled ? 'not-allowed' : 'default',
                fontSize: '14px',
                lineHeight: '20px',
                fontFamily: 'DM Sans',
                fontWeight: '500',
                '&:active': {
                  backgroundColor: '#f5f5f5',
                },
              };
            },
            menu: (styles) => ({
              ...styles,
              marginTop: '4px',
              borderRadius: '8px',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            }),
            menuList: (styles) => ({
              ...styles,
              padding: '0',
              borderRadius: '8px',
            }),
            singleValue: (styles) => ({
              ...styles,
              color: '#4B5563',
              fontSize: '14px',
              lineHeight: '20px',
              fontFamily: 'DM Sans',
              fontWeight: '500',
            }),
            placeholder: (styles) => ({
              ...styles,
              color: '#A0AEC0',
              fontSize: '14px',
              lineHeight: '20px',
              fontFamily: 'DM Sans',
              fontWeight: '500',
            }),
          }}
        />
      </div>
      <div className="mt-4">
        <TextField
          label="Description"
          placeholder="What is your expectation?"
          id="description"
          cols="30"
          rows="8"
          onChange={handleInputChange}
          value={description || ''}
          required
        />
      </div>
      <div className="mt-4">
        <label
          htmlFor="vatNumber"
          className="ml-3 text-sm font-bold text-navy-700 dark:text-white"
        >
          Is your project related to iGaming or CBD industry?
        </label>
        <div className="mt-2 flex items-center">
          <div className="mt-2 flex items-center">
            <Radio
              id="isIgamingYes"
              color="blue"
              name="isIgamingYes"
              checked={isIgaming === true || false}
              onChange={() => updateProjectInfo({ isIgaming: !isIgaming })}
            />
            <span className="ml-2 text-sm font-bold text-navy-700 dark:text-white">
              Yes
            </span>
          </div>
          <div className="ml-8 mt-2 flex items-center">
            <Radio
              id="isIgamingNo"
              color="blue"
              name="isIgamingNo"
              checked={isIgaming === false || false}
              onChange={() => updateProjectInfo({ isIgaming: !isIgaming })}
            />
            <span className="ml-2 text-sm font-bold text-navy-700 dark:text-white">
              No
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Domain;
