import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment/moment';
import lookup from 'country-code-lookup';
import QueriesTable from './components/QueriesTable';
import OverallRevenue from './components/OverallRevenue';
import PagesTable from './components/PagesTable';
import {
  pagesHeadears,
  queriesHeadears,
  countriesHeadears,
  devicesHeadears,
  datesHeadears,
  searchAppearanceHeadears,
  orderManagerHeaders,
} from './variables/tableHeaders';
import CountriesTable from './components/CountriesTable';
import DevicesTable from './components/DevicesTable';
import DatesTable from './components/DatesTable';
import SearchAppearanceTable from './components/SearchAppearanceTable';
import DatesModal from './components/DatesModal';
import { MdCancel } from 'react-icons/md';
import TypeModal from './components/TypeModal';
import QueryFilterModal from './components/QueryFilterModal';
import PageFilterModal from './components/PageFilterModal';
import { post } from 'api';
import { ProjectContext } from 'contexts/ProjectContext';
import BacklinksTable from './components/BacklinksTable';

const SearchConsoleDashboard = () => {
  const { currentProject } = useContext(ProjectContext);
  const [siteUrl, setSiteUrl] = useState('');
  const threeMonthsAgo = moment().subtract(2, 'days').subtract(3, 'months');
  const today = moment();
  const [startDate, setStartDate] = useState(
    threeMonthsAgo.format('YYYY-MM-DD'),
  );
  const [endDate, setEndDate] = useState(
    today.subtract(2, 'days').format('YYYY-MM-DD'),
  );
  const [activeTab, setActiveTab] = useState('backlinks');
  const [showClicks, setShowClicks] = useState(true);
  const [showImpressions, setShowImpressions] = useState(true);
  const [showCTR, setShowCTR] = useState(false);
  const [showPosition, setShowPosition] = useState(false);
  const [queryFilter, setQueryFilter] = useState('');
  const [pageFilter, setPageFilter] = useState('');
  const [countryFilter, setCountryFilter] = useState('');
  const [deviceFilter, setDeviceFilter] = useState('');
  const [searchAppearanceFilter, setSearchAppearanceFilter] = useState('');
  const [selectedSearchType, setSelectedSearchType] = useState('Web');
  const [queryFilterType, setQueryFilterType] = useState('equals');
  const [pageFilterType, setPageFilterType] = useState('equals');
  const refreshToken = window.localStorage.getItem('refreshToken');
  const accessToken = window.localStorage.getItem('accessToken');
  const [loaded, setLoaded] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    if (currentProject && currentProject.domainName) {
      fetch('https://www.googleapis.com/webmasters/v3/sites', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          const { siteEntry } = data;
          if (!siteEntry) {
            return;
          }
          const googleSiteUrls = siteEntry.map((site) => {
            return site.siteUrl;
          });
          const hostname = new URL(currentProject.domainName).hostname;
          const domainName = hostname.split('.').slice(-2).join('.');
          const googleSiteUrl = googleSiteUrls.find((url) =>
            url.includes(domainName),
          );
          setSiteUrl(googleSiteUrl);
        });
    }
  }, [currentProject, accessToken]);

  const refreshAccessToken = async () => {
    await post('/login/refreshAccessToken', {
      refreshToken,
    })
      .then((response) => response.json())
      .then((data) => {
        const { accessToken, expiresIn } = data;
        window.localStorage.setItem('accessToken', accessToken);
        const expiresAt = moment().add(expiresIn, 'seconds');
        const expiresAtDateTime = expiresAt.format('YYYY-MM-DD HH:mm:ss');
        window.localStorage.setItem('expiresAt', expiresAtDateTime);
      })

      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    const expiresAt = window.localStorage.getItem('expiresAt');
    const currentDateTime = moment().format('YYYY-MM-DD HH:mm:ss');
    if (!expiresAt) {
      refreshAccessToken();
      setLoaded(true);
    } else if (expiresAt && currentDateTime > expiresAt) {
      refreshAccessToken();
      setLoaded(true);
    } else {
      setLoaded(true);
    }
  }, [
    refreshToken,
    startDate,
    endDate,
    countryFilter,
    queryFilter,
    pageFilter,
    deviceFilter,
    selectedSearchType,
  ]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  if (!loaded) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-col gap-5">
      <div className="flex flex-col">
        <div className="mt-5 flex w-full">
          <DatesModal
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
          <TypeModal
            setSelectedSearchType={setSelectedSearchType}
            selectedSearchType={selectedSearchType}
          />
        </div>
        <div className="mt-3 flex w-full">
          {queryFilter && (
            <QueryFilterModal
              queryFilter={queryFilter}
              setQueryFilter={setQueryFilter}
              queryFilterType={queryFilterType}
              setQueryFilterType={setQueryFilterType}
            />
          )}
          {pageFilter && (
            <PageFilterModal
              pageFilter={pageFilter}
              setPageFilter={setPageFilter}
              pageFilterType={pageFilterType}
              setPageFilterType={setPageFilterType}
            />
          )}
          {countryFilter && (
            <button className="ml-3 flex cursor-default items-center justify-between rounded-md bg-blue-500 px-2 py-1 text-white">
              Country: {lookup.byIso(countryFilter)?.country || countryFilter}
              <span
                className="ml-2 cursor-pointer"
                onClick={() => setCountryFilter('')}
              >
                <MdCancel />
              </span>
            </button>
          )}
          {deviceFilter && (
            <button className="ml-3 flex cursor-default items-center justify-between rounded-md bg-blue-500 px-2 py-1 text-white">
              Device: {deviceFilter}
              <span
                className="ml-2 cursor-pointer"
                onClick={() => setDeviceFilter('')}
              >
                <MdCancel />
              </span>
            </button>
          )}
          {searchAppearanceFilter && (
            <button className="ml-2 flex cursor-default items-center justify-between rounded-md bg-blue-500 px-2 py-1 text-white">
              Search Appearance: {searchAppearanceFilter}
              <span
                className="ml-2 cursor-pointer"
                onClick={() => setSearchAppearanceFilter('')}
              >
                <MdCancel />
              </span>
            </button>
          )}
        </div>
      </div>
      <div className="mt-3 flex h-full w-full flex-col gap-[20px] rounded-[20px] xl:flex-row">
        <div className="h-full w-full rounded-[20px]">
          <div className="col-span-9 h-full w-full rounded-t-2xl xl:col-span-6">
            <div className="col-span-6 h-full w-full rounded-xl 3xl:col-span-4">
              <OverallRevenue
                startDate={startDate}
                endDate={endDate}
                showClicks={showClicks}
                setShowClicks={setShowClicks}
                showImpressions={showImpressions}
                setShowImpressions={setShowImpressions}
                showCTR={showCTR}
                setShowCTR={setShowCTR}
                showPosition={showPosition}
                setShowPosition={setShowPosition}
                queryFilter={queryFilter}
                pageFilter={pageFilter}
                countryFilter={countryFilter}
                deviceFilter={deviceFilter}
                selectedSearchType={selectedSearchType}
                queryFilterType={queryFilterType}
                pageFilterType={pageFilterType}
                siteUrl={siteUrl}
                selectedRows={selectedRows}
              />
            </div>
            <div className="mt-3">
              {/* <div className="flex h-10 w-full flex-col items-center justify-around border-b md:flex-row"> */}
              <div className="mb-3 grid grid-cols-3 gap-2 md:mb-0 md:grid-cols-7 md:gap-2">
                <button
                  className={`w-full px-2 py-1 text-sm ${
                    activeTab === 'backlinks' && 'border-b-4 py-2 font-semibold'
                  }`}
                  onClick={() => handleTabChange('backlinks')}
                >
                  BACKLINKS
                </button>
                <button
                  className={`w-full px-2 py-1 text-sm ${
                    activeTab === 'queries' && 'border-b-4 py-2 font-semibold'
                  }`}
                  onClick={() => handleTabChange('queries')}
                >
                  QUERIES
                </button>
                <button
                  className={`w-full px-2 py-1 text-sm ${
                    activeTab === 'pages' && 'border-b-4 py-2 font-semibold'
                  }`}
                  onClick={() => handleTabChange('pages')}
                >
                  PAGES
                </button>
                <button
                  className={`w-full px-2 py-1 text-sm ${
                    activeTab === 'countries' && 'border-b-4 py-2 font-semibold'
                  }`}
                  onClick={() => handleTabChange('countries')}
                >
                  COUNTRIES
                </button>
                <button
                  className={`w-full px-2 py-1 text-sm ${
                    activeTab === 'devices' && 'border-b-4 py-2 font-semibold'
                  }`}
                  onClick={() => handleTabChange('devices')}
                >
                  DEVICES
                </button>
                <button
                  className={`w-full px-2 py-1 text-sm ${
                    activeTab === 'searchAppearance' &&
                    'border-b-4 py-2 font-semibold'
                  }`}
                  onClick={() => handleTabChange('searchAppearance')}
                >
                  SEARCH APPEARANCE
                </button>
                <button
                  className={`w-full px-2 py-1 text-sm ${
                    activeTab === 'dates' && 'border-b-4 py-2 font-semibold'
                  }`}
                  onClick={() => handleTabChange('dates')}
                >
                  DATES
                </button>
              </div>
              {activeTab === 'backlinks' && (
                <BacklinksTable
                  columnsData={orderManagerHeaders}
                  selectedRows={selectedRows}
                  setSelectedRows={setSelectedRows}
                />
              )}
              {activeTab === 'queries' && (
                <QueriesTable
                  columnsData={queriesHeadears}
                  startDate={startDate}
                  endDate={endDate}
                  showClicks={showClicks}
                  showImpressions={showImpressions}
                  showCTR={showCTR}
                  showPosition={showPosition}
                  queryFilter={queryFilter}
                  setQueryFilter={setQueryFilter}
                  pageFilter={pageFilter}
                  countryFilter={countryFilter}
                  deviceFilter={deviceFilter}
                  searchAppearanceFilter={searchAppearanceFilter}
                  selectedSearchType={selectedSearchType}
                  queryFilterType={queryFilterType}
                  pageFilterType={pageFilterType}
                  siteUrl={siteUrl}
                />
              )}
              {activeTab === 'pages' && (
                <PagesTable
                  columnsData={pagesHeadears}
                  startDate={startDate}
                  endDate={endDate}
                  showClicks={showClicks}
                  showImpressions={showImpressions}
                  showCTR={showCTR}
                  showPosition={showPosition}
                  pageFilter={pageFilter}
                  setPageFilter={setPageFilter}
                  queryFilter={queryFilter}
                  countryFilter={countryFilter}
                  deviceFilter={deviceFilter}
                  selectedSearchType={selectedSearchType}
                  queryFilterType={queryFilterType}
                  pageFilterType={pageFilterType}
                  siteUrl={siteUrl}
                />
              )}
              {activeTab === 'countries' && (
                <CountriesTable
                  columnsData={countriesHeadears}
                  startDate={startDate}
                  endDate={endDate}
                  showClicks={showClicks}
                  showImpressions={showImpressions}
                  showCTR={showCTR}
                  showPosition={showPosition}
                  countryFilter={countryFilter}
                  setCountryFilter={setCountryFilter}
                  queryFilter={queryFilter}
                  pageFilter={pageFilter}
                  deviceFilter={deviceFilter}
                  selectedSearchType={selectedSearchType}
                  queryFilterType={queryFilterType}
                  pageFilterType={pageFilterType}
                  siteUrl={siteUrl}
                />
              )}
              {activeTab === 'devices' && (
                <DevicesTable
                  columnsData={devicesHeadears}
                  startDate={startDate}
                  endDate={endDate}
                  showClicks={showClicks}
                  showImpressions={showImpressions}
                  showCTR={showCTR}
                  showPosition={showPosition}
                  deviceFilter={deviceFilter}
                  setDeviceFilter={setDeviceFilter}
                  queryFilter={queryFilter}
                  pageFilter={pageFilter}
                  countryFilter={countryFilter}
                  selectedSearchType={selectedSearchType}
                  queryFilterType={queryFilterType}
                  pageFilterType={pageFilterType}
                  siteUrl={siteUrl}
                />
              )}
              {activeTab === 'searchAppearance' && (
                <SearchAppearanceTable
                  columnsData={searchAppearanceHeadears}
                  startDate={startDate}
                  endDate={endDate}
                  showClicks={showClicks}
                  showImpressions={showImpressions}
                  showCTR={showCTR}
                  showPosition={showPosition}
                  searchAppearanceFilter={searchAppearanceFilter}
                  setSearchAppearanceFilter={setSearchAppearanceFilter}
                  queryFilter={queryFilter}
                  pageFilter={pageFilter}
                  deviceFilter={deviceFilter}
                  countryFilter={countryFilter}
                  selectedSearchType={selectedSearchType}
                  queryFilterType={queryFilterType}
                  pageFilterType={pageFilterType}
                  siteUrl={siteUrl}
                />
              )}
              {activeTab === 'dates' && (
                <DatesTable
                  columnsData={datesHeadears}
                  startDate={startDate}
                  endDate={endDate}
                  showClicks={showClicks}
                  showImpressions={showImpressions}
                  showCTR={showCTR}
                  showPosition={showPosition}
                  queryFilter={queryFilter}
                  pageFilter={pageFilter}
                  deviceFilter={deviceFilter}
                  countryFilter={countryFilter}
                  selectedSearchType={selectedSearchType}
                  queryFilterType={queryFilterType}
                  pageFilterType={pageFilterType}
                  siteUrl={siteUrl}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchConsoleDashboard;
