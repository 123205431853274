import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { toastSuccess, toastError } from 'utils/toasts';
import Stepper from './components/Stepper';
import StepperControl from './components/StepperControl';
import { UseContextProvider } from './contexts/StepperContext';
import DomainTags from './components/steps/DomainTags';
import Domain from './components/steps/Domain';
import Card from 'components/card';
import { post, get, getUserId } from 'api';

const NewProject = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [projectInfo, setProjectInfo] = useState({
    isIgaming: false,
  });
  const [companyDetails, setCompanyDetails] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const fetchBillingInfo = async () => {
      const userId = getUserId();
      await get(`/api/company/user/${userId}`)
        .then((res) => res.json())
        .then((data) => {
          setCompanyDetails(data.Company);
        });
    };
    fetchBillingInfo();
  }, []);

  const steps = [
    { stepNo: 1, name: 'Domain' },
    { stepNo: 2, name: 'Tags' },
  ];
  const displayStep = (step) => {
    switch (step.stepNo) {
      case 1:
        return (
          <Domain
            projectInfo={projectInfo}
            updateProjectInfo={updateProjectInfo}
          />
        );
      case 2:
        return (
          <DomainTags
            projectInfo={projectInfo}
            updateProjectInfo={updateProjectInfo}
          />
        );
      default:
    }
  };

  const updateProjectInfo = (info) => {
    setProjectInfo((prevInfo) => ({ ...prevInfo, ...info }));
  };

  const handleClick = (direction) => {
    let newStep = currentStep;
    direction === 'next' ? newStep++ : newStep--;
    newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);
  };

  const handleSubmit = async () => {
    setSubmitting(true);
    const userId = getUserId();
    const {
      categories = [],
      countries = [],
      languages = [],
      domainName,
      description,
      projectName,
      isIgaming,
    } = projectInfo;
    if (!categories.length) {
      toastError('Please select at least one category');
      setSubmitting(false);
      return;
    } else if (!countries.length) {
      toastError('Please select at least one country');
      setSubmitting(false);
      return;
    } else if (!languages.length) {
      toastError('Please select at least one language');
      setSubmitting(false);
      return;
    }
    const data = {
      userId,
      projectName,
      domainName,
      description,
      companyId: companyDetails.id,
      isIgaming,
    };
    await post('/api/project', data).then((res) =>
      res.json().then((parsedData) => {
        if (res.status === 200) {
          const projectId = parsedData.id;
          const project = { ...data, id: projectId };
          localStorage.setItem('currentProject', JSON.stringify(project));
          const categoriesBody = {
            projectId: projectId,
            categories: categories.map((category) => category.id),
          };
          const countriesBody = {
            projectId: projectId,
            countries: countries.map((country) => country.id),
          };
          const languagesBody = {
            projectId: projectId,
            languages: languages.map((language) => language.id),
          };
          post(`/api/project/project-categories`, categoriesBody).then(
            (categoriesRes) => {
              post(`/api/project/project-countries`, countriesBody).then(
                (countriesRes) => {
                  post(`/api/project/project-languages`, languagesBody).then(
                    (languagesRes) => {
                      if (
                        categoriesRes.status === 200 &&
                        countriesRes.status === 200 &&
                        languagesRes.status === 200
                      ) {
                        setProjectInfo({});
                        toastSuccess('Project created successfully');
                        setTimeout(() => {
                          setSubmitting(false);
                          window.location.href =
                            '/admin/dashboards/projects/overview';
                        }, 2500);
                      } else {
                        toastError('Project creation failed');
                      }
                    },
                  );
                },
              );
            },
          );
        } else {
          toastError('Project creation failed');
        }
      }),
    );
  };

  useEffect(() => {
    if (!companyDetails) {
      toastError('Please add billing information first');
      setTimeout(() => {
        navigate('/admin/account/billing');
      }, 2500);
    }
  }, [companyDetails]);

  return (
    <div className="mt-3 h-full w-full">
      <ToastContainer
        position="top-center"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="h-[350px] w-full rounded-[20px] bg-gradient-to-br from-brand-400 to-brand-600 md:h-[390px]" />
      <div className="w-md:2/3 mx-auto h-full w-5/6 md:px-3  3xl:w-7/12">
        <div className="m-auto -mt-[280px] w-[50%] pb-10 md:-mt-[240px] md:px-[0px]">
          <Stepper
            action={setCurrentStep}
            steps={steps}
            currentStep={currentStep}
          />
        </div>
        <Card extra={'h-full mx-auto pb-3'}>
          <div className="rounded-[20px]">
            <UseContextProvider>
              {displayStep(steps[currentStep - 1])}
            </UseContextProvider>
          </div>
          {/* navigation button */}

          <StepperControl
            handleClick={handleClick}
            currentStep={currentStep}
            steps={steps}
            handleSubmit={handleSubmit}
            submitting={submitting}
            projectInfo={projectInfo}
          />
        </Card>
      </div>
    </div>
  );
};

export default NewProject;
