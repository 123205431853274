import React, { useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalBody } from '@chakra-ui/modal';
import { useDisclosure } from '@chakra-ui/hooks';
import Card from 'components/card';
import GeneralFilters from './GeneralFilters';
import AhrefsFilters from './AhrefsFilters';
import InterestedCard from './InterestedCard';

const FiltersModal = ({ setFilters }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activeFilter, setActiveFilter] = useState('general');
  const [acceptedTopic, setSelectedAcceptedTopic] = useState([
    { value: 'iGaming', label: 'iGaming' },
    { value: 'Adult', label: 'Adult' },
    { value: 'CBD', label: 'CBD' },
  ]);
  const [category, setSelectedCategory] = useState([]);
  const [country, setSelectedCountry] = useState([]);
  const [domainRating, setDomainRating] = useState({});
  const [organicTraffic, setOrganicTraffic] = useState({});
  const [referringDomains, setReferringDomains] = useState({});
  const [linkedDomains, setLinkedDomains] = useState({});
  const [organicKeywords, setOrganicKeywords] = useState({});
  const [backlinkPrice, setBacklinkPrice] = useState({});
  const [doFollowLinks, setDoFollowLinks] = useState([]);

  const handleSave = () => {
    setFilters((prev) => ({
      ...prev,
      general: {
        acceptedTopic,
        category,
        country,
        backlinkPrice,
        doFollowLinks,
      },
      ahrefs: {
        domainRating,
        organicTraffic,
        referringDomains,
        linkedDomains,
        organicKeywords,
      },
    }));
    onClose();
  };

  const handleReset = () => {
    if (activeFilter === 'general') {
      setSelectedAcceptedTopic([]);
      setSelectedCategory([]);
      setSelectedCountry([]);
      setBacklinkPrice({});
      setDoFollowLinks([]);
      setFilters((prev) => ({
        ...prev,
        general: {
          acceptedTopic: [],
          category: [],
          country: [],
          backlinkPrice: {},
          doFollowLinks: [],
        },
      }));
    } else if (activeFilter === 'ahrefs') {
      setDomainRating({});
      setOrganicTraffic({});
      setReferringDomains({});
      setLinkedDomains({});
      setOrganicKeywords({});
      setFilters((prev) => ({
        ...prev,
        ahrefs: {
          domainRating: {},
          organicTraffic: {},
          referringDomains: {},
          linkedDomains: {},
          organicKeywords: {},
        },
      }));
    }
  };

  return (
    <div className="mb-2 w-full md:mb-0 md:w-auto">
      <button
        onClick={onOpen}
        className="text-md w-full rounded-full bg-blue-500 px-4 py-2 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 md:h-[50px] md:w-auto"
      >
        CHANGE FILTERS
      </button>
      <Modal className="!z-[100]" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay className="bg-[#000] !opacity-30" />
        <ModalContent className="top-[22vh] !m-auto !w-max !max-w-[85%] md:top-[7vh]">
          <ModalBody>
            <Card extra={'w-full sm:overflow-auto p-4 pt-4'}>
              <div className="relative flex items-center justify-between border-b-2 border-gray-200 dark:border-gray-700">
                <div
                  onClick={() => setActiveFilter('general')}
                  className={`${
                    activeFilter === 'general'
                      ? 'border-b-2 border-blue-400 font-bold text-blue-400'
                      : ' font-medium text-navy-700 dark:text-white'
                  } text-md hover:cursor-pointer`}
                >
                  GENERAL FILTERS
                </div>
                <div
                  onClick={() => setActiveFilter('ahrefs')}
                  className={`${
                    activeFilter === 'ahrefs'
                      ? 'border-b-2 border-blue-400 font-bold text-blue-400'
                      : ' font-medium text-navy-700 dark:text-white'
                  } text-md ml-7 hover:cursor-pointer`}
                >
                  AHREFS
                </div>
                <div
                  onClick={() => setActiveFilter('moz')}
                  className={`${
                    activeFilter === 'moz'
                      ? 'border-b-2 border-blue-400 font-bold text-blue-400'
                      : ' font-medium text-navy-700 dark:text-white'
                  } text-md ml-7 hover:cursor-pointer`}
                >
                  MOZ
                </div>
                <div
                  onClick={() => setActiveFilter('similar')}
                  className={`${
                    activeFilter === 'similar'
                      ? 'border-b-2 border-blue-400 font-bold text-blue-400'
                      : ' font-medium text-navy-700 dark:text-white'
                  } text-md ml-7 hover:cursor-pointer`}
                >
                  SIMILAR
                </div>
                <div
                  onClick={() => setActiveFilter('majestic')}
                  className={`${
                    activeFilter === 'majestic'
                      ? 'border-b-2 border-blue-400 font-bold text-blue-400'
                      : ' font-medium text-navy-700 dark:text-white'
                  } text-md ml-7 hover:cursor-pointer`}
                >
                  MAJESTIC
                </div>
              </div>
              <div className="mt-5 flex flex-col">
                <div className="text-3xl dark:text-white">
                  {activeFilter === 'general' && 'General Filters'}
                  {activeFilter === 'ahrefs' && 'Ahrefs'}
                  {activeFilter === 'moz' && 'Moz'}
                  {activeFilter === 'similar' && 'Similar'}
                  {activeFilter === 'majestic' && 'Majestic'}
                </div>
                <div className="mt-1 flex flex-col">
                  {activeFilter === 'general' && (
                    <GeneralFilters
                      acceptedTopic={acceptedTopic}
                      setSelectedAcceptedTopic={setSelectedAcceptedTopic}
                      category={category}
                      setSelectedCategory={setSelectedCategory}
                      country={country}
                      setSelectedCountry={setSelectedCountry}
                      backlinkPrice={backlinkPrice}
                      setBacklinkPrice={setBacklinkPrice}
                      doFollowLinks={doFollowLinks}
                      setDoFollowLinks={setDoFollowLinks}
                    />
                  )}
                  {activeFilter === 'ahrefs' && (
                    <AhrefsFilters
                      domainRating={domainRating}
                      setDomainRating={setDomainRating}
                      organicTraffic={organicTraffic}
                      setOrganicTraffic={setOrganicTraffic}
                      referringDomains={referringDomains}
                      setReferringDomains={setReferringDomains}
                      linkedDomains={linkedDomains}
                      setLinkedDomains={setLinkedDomains}
                      organicKeywords={organicKeywords}
                      setOrganicKeywords={setOrganicKeywords}
                    />
                  )}
                  {activeFilter === 'moz' && <InterestedCard />}
                  {activeFilter === 'similar' && <InterestedCard />}
                  {activeFilter === 'majestic' && <InterestedCard />}
                </div>
              </div>
              <div className="mt-5 flex justify-start">
                <button
                  onClick={() => handleSave()}
                  className="rounded-md bg-blue-500 px-4 py-2 text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                >
                  Save
                </button>
                <button
                  onClick={() => handleReset()}
                  className="bg-transparent ml-3 rounded-md border border-gray-500 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
                >
                  Reset
                </button>
              </div>
            </Card>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default FiltersModal;
