import React, { useState, useEffect } from 'react';
import Switch from 'components/switch';
import { get, put, getUserId } from 'api';

const Notifications = () => {
  const [notifications, setNotifications] = useState({});
  const userId = getUserId();

  useEffect(() => {
    get(`/api/notifications/${userId}`)
      .then((res) => res.json())
      .then((data) => {
        setNotifications(data);
      });
  }, [userId]);

  const updateNotification = (type, checked) => {
    const data = {};
    data[type] = checked;
    setNotifications({ ...notifications, ...data });
    put(`/api/notifications/${userId}`, data);
  };

  const handleToggle = (type) => (e) => {
    updateNotification(type, e.target.checked);
  };

  return (
    <div className="h-full w-full rounded-[20px]  px-3 pt-7 md:px-8">
      {/* content */}
      <div className="!z-5 relative flex w-full flex-col rounded-[20px] bg-white bg-clip-border !p-5 shadow-3xl  shadow-shadow-100 dark:!bg-navy-800  dark:text-white dark:shadow-none md:w-[50%]">
        <div className="grid gap-12 md:grid-cols-2">
          <div>
            <p className="text-lg font-bold">Order Confirmation</p>
            <Switch
              checked={notifications.orderConfirmations || false}
              onChange={handleToggle('orderConfirmations')}
            />
          </div>
          <div>
            <p className="text-lg font-bold">Order Actions</p>
            <Switch
              checked={notifications.orderActions || false}
              onChange={handleToggle('orderActions')}
            />
          </div>
          <div>
            <p className="text-lg font-bold">Order Completed</p>
            <Switch
              checked={notifications.orderCompletions || false}
              onChange={handleToggle('orderCompletions')}
            />
          </div>
          <div>
            <p className="text-lg font-bold">Link Suggestions</p>
            <Switch
              checked={notifications.linkSuggestions || false}
              onChange={handleToggle('linkSuggestions')}
            />
          </div>
          <div>
            <p className="text-lg font-bold">
              Do not send me any automated emails
            </p>
            <Switch
              checked={notifications.disableAllNotifications || false}
              onChange={handleToggle('disableAllNotifications')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
