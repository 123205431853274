import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Card from 'components/card';
import Widget from './Widget';
import Impressions from './Impressions';
import Clicks from './Clicks';
import CTR from './CTR';
import { ProjectContext } from 'contexts/ProjectContext';

const ProjectCard = ({ icon, projectName, domainName, id }) => {
  const navigate = useNavigate();
  const { allProjects, changeProject } = useContext(ProjectContext);
  const startDate = moment().subtract(3, 'months').format('YYYY-MM-DD');
  const endDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
  const [isClicksData, setIsClicksData] = useState(true);
  const [isImpressionsData, setIsImpressionsData] = useState(true);
  const [isCTRData, setIsCTRData] = useState(true);

  const handleSelectProject = () => {
    changeProject(allProjects.find((project) => project.id === id));
    navigate(`/admin/dashboards/searchConsole`);
  };

  return (
    <Card
      extra="flex h-full w-full bg-white shadow-none rounded-[20px] border-2
        hover:border-blue-500 dark:border-gray-700 dark:hover:border-blue-500 transition duration-300 ease-in-out
      "
    >
      <div className="flex w-full flex-row justify-between">
        <div className="flex w-full flex-col">
          <div className="flex w-full flex-col md:flex-row">
            <Widget
              icon={icon}
              projectName={projectName}
              domainName={domainName}
              id={id}
              isClicksData={isClicksData}
            />
            {!isClicksData && !isImpressionsData && !isCTRData && (
              <p className="m-auto flex w-full items-start justify-center p-4 text-center font-bold text-gray-500 dark:text-gray-300 md:w-1/3">
                No data available, please check your project domain name and
                make sure you have access to this domain in Google Search
                Console
              </p>
            )}
            {isImpressionsData && (
              <Impressions
                siteUrl={domainName}
                startDate={startDate}
                endDate={endDate}
                setIsImpressionsData={setIsImpressionsData}
                handleSelectProject={handleSelectProject}
              />
            )}
          </div>
          <div className="flex w-full flex-col md:flex-row">
            {isClicksData && (
              <Clicks
                siteUrl={domainName}
                startDate={startDate}
                endDate={endDate}
                setIsClicksData={setIsClicksData}
                handleSelectProject={handleSelectProject}
              />
            )}
            {isCTRData && (
              <CTR
                siteUrl={domainName}
                startDate={startDate}
                endDate={endDate}
                setIsCTRData={setIsCTRData}
                handleSelectProject={handleSelectProject}
              />
            )}
          </div>
        </div>
      </div>
      {isClicksData && isImpressionsData && isCTRData && (
        <p className="mt:ml-0 mb-2 ml-2 flex justify-center text-sm text-gray-500 dark:text-gray-300">
          *Data is shown for the last 3 months not including today
        </p>
      )}
    </Card>
  );
};

export default ProjectCard;
