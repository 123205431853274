import React, { useState, useEffect, useRef } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import moment from 'moment';
import EmojiPicker from 'emoji-picker-react';
import Card from 'components/card';
import SingleMessage from './SingleMessage';
import { MdChevronLeft, MdOutlineTagFaces } from 'react-icons/md';
import { IoPaperPlane } from 'react-icons/io5';
import {
  renderThumbMessages,
  renderTrackMessages,
  renderViewMessages,
} from 'components/scrollbar/Scrollbar';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { post, get } from 'api';
import { FiSearch } from 'react-icons/fi';
import { getUserRole } from 'api';

function SingleChat(props) {
  const {
    open,
    onClose,
    selectedUser,
    socket,
    currentUser,
    onlineUsers,
    setNewMessages,
  } = props;
  const [messages, setMessages] = useState([]);
  const messageInput = useRef(null);
  const messagesEndRef = useRef(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [typingUsers, setTypingUsers] = useState([]);
  const [messageSearchTerm, setMessageSearchTerm] = useState('');
  const isAdmin = getUserRole() === 'admin' ? true : false;

  const handleEmojiPickerhideShow = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  const fetchMessages = async () => {
    try {
      const response = await get(
        `/api/messages?fromUserId=${currentUser.id}&toUserId=${selectedUser.id}`,
      );
      const data = await response.json();
      setMessages(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchMessages();
  }, [selectedUser]);

  useEffect(() => {
    if (socket.current) {
      socket.current.on('receive-message', (chat) => {
        if (
          chat.fromUserId === selectedUser.id &&
          chat.toUserId === currentUser.id
        ) {
          setMessages((prevMessages) => [
            ...prevMessages,
            { fromMe: false, message: chat.message, time: chat.time },
          ]);
          setNewMessages((prevMessages) => {
            const newMessages = { ...prevMessages };
            delete newMessages[selectedUser.id];
            return newMessages;
          });
        } else if (chat.toUserId === currentUser.id) {
          setNewMessages((prevMessages) => ({
            ...prevMessages,
            [chat.fromUserId]: (prevMessages[chat.fromUserId] || 0) + 1,
          }));
        }
      });

      return () => {
        if (socket.current) {
          socket.current.off('receive-message');
        }
      };
    }
  }, [currentUser.id, selectedUser.id, setNewMessages, socket]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollToBottom();
    }
  }, [messages]);

  useEffect(() => {
    if (socket.current) {
      socket.current.on('user-typing', (userId) => {
        setTypingUsers((prevTypingUsers) => [...prevTypingUsers, userId]);
      });

      socket.current.on('user-stop-typing', (userId) => {
        setTypingUsers((prevTypingUsers) =>
          prevTypingUsers.filter((id) => id !== userId),
        );
      });

      return () => {
        if (socket.current) {
          socket.current.off('user-typing');
          socket.current.off('user-stop-typing');
        }
      };
    }
  }, [socket.current]);

  const saveMessage = async (messageData) => {
    try {
      await post('/api/messages', messageData);
    } catch (error) {
      console.log(error);
    }
  };

  const sendMessage = (event) => {
    event.preventDefault();
    const message = messageInput.current.value;
    if (message.trim() !== '') {
      const time = moment().format('YYYY-MM-DD HH:mm:ss');
      const messageData = {
        fromUserId: currentUser.id,
        toUserId: selectedUser.id,
        message: message,
        time: time,
        notificationType: 'liveMessage',
        notificationUserId: isAdmin ? selectedUser.id : 0,
      };
      socket.current.emit('send-message', messageData);
      setMessages((prevMessages) => [...prevMessages, messageData]);
      saveMessage(messageData);
      messageInput.current.value = '';
    }
  };

  const handleEmojiClick = (emojiObject) => {
    let message = messageInput.current.value;
    message += emojiObject.emoji;
    messageInput.current.value = message;
  };

  const debounced = useDebouncedCallback(() => {
    socket.current.emit('stop-typing', currentUser.id);
  }, 1000);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      sendMessage(event);
    } else {
      socket.current.emit('typing', currentUser.id);
      debounced(event.key);
    }
  };

  return (
    <div
      className={`duration-175 !fixed left-0 top-[20px] !z-[50] h-[calc(100vh-40px)] w-[calc(100vw-40px)] flex-col !font-dm transition-all md:h-[calc(100vh-40px)] lg:!relative lg:left-[unset] lg:top-[unset] lg:!z-[unset] lg:my-0 lg:flex lg:h-full lg:w-[unset] ${
        open
          ? 'translate-x-[20px] lg:translate-x-[0px]'
          : '-translate-x-[120%] lg:translate-x-[0px]'
      }`}
    >
      <Card extra={'w-full h-full md:h-auto md:min-h-[80vh] p-4 !font-dm'}>
        {/* header */}
        <div className="mb-auto flex flex-col items-center justify-between border-b border-gray-200 pb-3 dark:!border-navy-700 md:flex-row">
          <div className="flex w-full items-center gap-3">
            <div className="flex items-center justify-center gap-2">
              <div onClick={onClose} className={'block lg:hidden'}>
                <MdChevronLeft className="h-8 w-8 text-gray-500 dark:text-white" />
              </div>
              <div className="h-[50px] w-[50px] rounded-full">
                <img
                  src={selectedUser.avatar}
                  className="h-full w-full rounded-full"
                  alt=""
                />
              </div>
            </div>
            <div>
              <h1 className="font-dm text-[20px] font-bold text-navy-700 dark:text-white">
                {' '}
                {selectedUser.firstName} {selectedUser.lastName}
              </h1>
              {onlineUsers.includes(selectedUser.id) ? (
                <div className="flex items-center gap-2">
                  <div className="h-2 w-2 rounded-full bg-green-600"></div>
                  <h4 className="text-base text-navy-700 dark:text-white">
                    Online
                  </h4>
                </div>
              ) : (
                <div className="flex items-center gap-2">
                  <div className="h-2 w-2 rounded-full bg-gray-500"></div>
                  <h4 className="text-base text-navy-700 dark:text-white">
                    Offline
                  </h4>
                </div>
              )}
            </div>
          </div>
          {/* search */}
          <div className="mt-3 flex w-full items-center gap-2 md:w-1/4">
            <div className="flex h-[42px] w-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white ">
              <p className="pl-3 pr-2 text-xl">
                <FiSearch className="h-4 w-4 text-gray-400" />
              </p>
              <input
                type="text"
                placeholder="Search..."
                className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
                value={messageSearchTerm}
                onChange={(e) => setMessageSearchTerm(e.target.value)}
              />
            </div>
          </div>
        </div>
        {/* message content */}
        <div className="relative h-[calc(100%-70px)] pt-2">
          <div className="flex h-[calc(100%-74px)] w-full">
            <Scrollbars
              ref={messagesEndRef}
              renderTrackVertical={renderTrackMessages}
              renderThumbVertical={renderThumbMessages}
              renderView={renderViewMessages}
            >
              <div className="relative max-h-max overflow-hidden pb-[30px] lg:max-h-[unset] lg:overflow-visible">
                {messages
                  .filter((msg) =>
                    msg.message
                      .toLowerCase()
                      .includes(messageSearchTerm.toLowerCase()),
                  )
                  .map((msg, index) => {
                    const fromMe = msg.fromUserId === currentUser.id;
                    return (
                      <div
                        className={fromMe ? 'flex flex-col items-end' : ''}
                        key={index}
                      >
                        <SingleMessage
                          message={msg.message}
                          time={msg.time}
                          extra={
                            fromMe
                              ? 'bg-brand-500 w-fit rounded-l-xl rounded-br-xl text-white'
                              : '!bg-lightPrimary w-fit rounded-r-xl rounded-bl-xl'
                          }
                          text={fromMe ? 'text-white' : 'text-navy-700'}
                          timecolor="text-gray-400 dark:text-white"
                        />
                      </div>
                    );
                  })}
              </div>
            </Scrollbars>
          </div>
          <div className="bottom-15 absolute left-0 w-full">
            {typingUsers.includes(selectedUser.id) && (
              <p>{selectedUser.firstName} is typing...</p>
            )}
          </div>
          <div className="mt-6 flex items-center gap-2">
            <div className=" flex h-[50px] w-full items-center rounded-full bg-lightPrimary pr-3 dark:bg-navy-700">
              <div className="text-lightSecondary pl-3 text-xl hover:cursor-pointer">
                <MdOutlineTagFaces onClick={handleEmojiPickerhideShow} />
                {showEmojiPicker && (
                  <div className="absolute bottom-[55px]">
                    <EmojiPicker onEmojiClick={handleEmojiClick} />
                  </div>
                )}
              </div>
              <input
                ref={messageInput}
                placeholder="Write your message...."
                className="h-full w-full rounded-full bg-lightPrimary px-2 text-sm text-navy-700 outline-none dark:bg-navy-700 dark:text-white"
                type="text"
                onKeyDown={handleKeyDown}
              />
            </div>
            {/* button */}
            <button
              onClick={sendMessage}
              className="linear flex items-center justify-center rounded-full bg-brand-500 p-3 text-base text-white transition duration-200 hover:cursor-pointer  hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            >
              <IoPaperPlane />
            </button>
          </div>
        </div>
      </Card>
    </div>
  );
}

export default SingleChat;
