import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ActionButtons from 'components/actions/ActionButtons';
import Card from 'components/card';
import InvoiceModal from './components/InvoiceModal';
import { get, getUserId } from 'api';
import Spinner from 'components/loaders/Spinner';

const InvoicesList = () => {
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [invoicesLoaded, setInvoicesLoaded] = useState(false);

  useEffect(() => {
    setInvoicesLoaded(true);
  }, [invoices]);

  useEffect(() => {
    const fetchInvoices = async () => {
      const userId = getUserId();
      await get(`/api/company/user/${userId}`)
        .then((res) => {
          if (res.status === 200) return res.json();
          if (res.status === 500) return null;
        })
        .then((data) => {
          if (!data) return;
          get(
            `/api/transaction/invoices?companyId=${data.companyId}&userId=${userId}`,
          )
            .then((res) => res.json())
            .then((data) => {
              setInvoices(data);
            });
        });
    };
    fetchInvoices();
  }, []);

  useEffect(() => {
    if (invoices.length > 0) {
      if (location.state && location.state.invoiceNumber) {
        const findInvoice = invoices.find(
          (invoice) => invoice.invoiceNumber === location.state.invoiceNumber,
        );
        setSelectedInvoice([findInvoice]);
        setShowModal(true);
      }
    }
  }, [location, invoices]);

  const handleViewPDF = (invoice) => {
    setSelectedInvoice([invoice]);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
      <Card extra={'px-2 md:px-9 w-full md:w-1/2 h-full pt-[28px] pb-4'}>
        {/* Header */}
        <div className="mb-8 mt-3 flex items-center justify-between">
          <h5 className="text-lg font-bold text-navy-700 dark:text-white">
            Invoices
          </h5>
        </div>
        {!invoicesLoaded && <Spinner width={6} height={6} ml={0} />}
        {invoicesLoaded &&
          invoices.map((invoice) => {
            const updatedDate = new Date(invoice.createdAt)
              .toISOString()
              .slice(0, 10);
            const updatedAmountPaid = `${invoice.amountPaid.toLocaleString()} ${invoice.currency.toUpperCase()}`;
            return (
              <ActionButtons
                key={invoice.id}
                mb="mb-[42px]"
                name={invoice.invoiceNumber}
                date={updatedDate}
                sum={updatedAmountPaid}
                action={() => handleViewPDF(invoice)}
                actionName="View Invoice"
              />
            );
          })}
        {showModal && (
          <InvoiceModal
            invoice={selectedInvoice}
            showModal={showModal}
            closeModal={closeModal}
          />
        )}
      </Card>
    </div>
  );
};

export default InvoicesList;
