import React from 'react';
import Card from 'components/card';
import Banner from './Banner';
import Content from './Content';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const Invoice = ({ invoice }) => {
  const handleExportPDF = () => {
    const input = document.querySelector('#invoice');

    html2canvas(input, { scale: 1.5 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/jpeg', 1);
      const pdf = new jsPDF();
      pdf.addImage(
        imgData,
        'JPEG',
        0,
        0,
        pdf.internal.pageSize.getWidth(),
        pdf.internal.pageSize.getHeight(),
      );
      pdf.save(`invoice-${invoice[0].invoiceNumber}.pdf`);
    });
  };

  return (
    <div
      id="invoice"
      className="font-md m-auto max-h-[90vh] w-full overflow-auto rounded-[20px] md:max-h-[100vh]"
    >
      <Card extra={'w-full mx-auto p-4 h-auto'}>
        <div className="h-auto w-full">
          <Banner invoice={invoice} handleExportPDF={handleExportPDF} />
          <div className="mx-auto -mt-[40px] h-[100%] px-2 md:px-14">
            <Content invoice={invoice} />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default Invoice;
