import React, { useEffect, useState } from 'react';
import { toastSuccess, toastError } from 'utils/toasts';
import { Modal, ModalOverlay, ModalContent, ModalBody } from '@chakra-ui/modal';
import { useDisclosure } from '@chakra-ui/hooks';
import Card from 'components/card';
import InputField from './InputField';
import { put } from 'api';

const DenyReasonModal = ({
  modalOpen,
  denyReasonData,
  setIsDenyReasonModalOpen,
  fetchSuggestions,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [reason, setReason] = useState();

  const handleClose = () => {
    onClose();
    setIsDenyReasonModalOpen(false);
  };

  const handleReasonChange = (e, id) => {
    setReason((prev) => ({
      ...prev,
      [id]: e.target.value,
    }));
  };

  const handleSubmit = async () => {
    const allItemsHaveReason = denyReasonData.every(
      (item) => reason && reason[item.suggestionId],
    );

    if (!allItemsHaveReason) {
      toastError('Deny reason is required for all items.');
      return;
    }

    const allReasonsHaveTwoWords = denyReasonData.every(
      (item) => reason[item.suggestionId].split(' ').length >= 2,
    );

    if (!allReasonsHaveTwoWords) {
      toastError('Deny reason must be at least two words.');
      return;
    }

    for (const item of denyReasonData) {
      const denyReason = reason[item.suggestionId];

      const body = {
        id: item.suggestionId,
        denyReason: denyReason,
      };

      const res = await put('/api/suggestions/deny', body);
      if (res.status === 200) {
        fetchSuggestions();
        handleClose();
      }
    }
  };

  useEffect(() => {
    if (modalOpen) {
      onOpen();
    } else {
      onClose();
      setIsDenyReasonModalOpen(false);
    }
  }, [modalOpen, onOpen, onClose, setIsDenyReasonModalOpen]);

  return (
    <div>
      <Modal className="!z-[100]" isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay className="bg-[#000] !opacity-30" />
        <ModalContent className="top-[10vh] !m-auto !h-[80vh] !max-w-[720px] md:top-[5vh]">
          <ModalBody>
            <Card extra={'w-full sm:overflow-auto p-4'}>
              <h2 className="text-3xl font-semibold">Provide denial reasons</h2>
              <div className="mt-3 w-full">
                <h3 className="text-lg font-semibold">
                  Why its important to us?
                </h3>
                <p className="mt-2 text-sm">
                  In order to improve our services, we need to know why you are
                  denying this suggestion. So we can improve our services and
                  provide better suggestions in the future.
                </p>
              </div>
              <div className="mt-3 w-full">
                {denyReasonData?.map((item) => (
                  <div key={item.suggestionId} className="flex w-full">
                    <div className="w-1/2">
                      <InputField
                        extra="mb-1"
                        placeholder="Domain"
                        id="domain"
                        value={item.domain || ''}
                        disabled
                      />
                    </div>
                    <div className="ml-2 w-full">
                      <InputField
                        extra="mb-1"
                        placeholder="Reason"
                        id="reason"
                        value={reason?.[item.suggestionId] || ''}
                        onChange={(e) =>
                          handleReasonChange(e, item.suggestionId)
                        }
                      />
                    </div>
                  </div>
                ))}
              </div>
              <div className="mt-5">
                <button
                  className="focus:ring-primary-dark mr-2 rounded-md bg-brand-500 px-4 py-2 text-white hover:bg-brand-600 focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-opacity-50"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
                <button
                  onClick={handleClose}
                  className="bg-transparent rounded-md border border-gray-500 px-4 py-2 hover:bg-gray-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
                >
                  Close
                </button>
              </div>
            </Card>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default DenyReasonModal;
