import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { io } from 'socket.io-client';
import SingleChat from './components/SingleChat';
import { FiSearch } from 'react-icons/fi';
import Chat from './components/Chat';
import Card from 'components/card';
import { get, post, getUserId, put } from 'api';
import icon from 'assets/img/logo/Icon.png';

const OrderChat = () => {
  const socket = useRef();
  const location = useLocation();
  const [orderId, setOrderId] = useState(null);
  const [open, setOpen] = useState(false);
  const [admins, setAdmins] = useState([]);
  const [selectedOrderChat, setSelectedOrderChat] = useState(null);
  const [orderChats, setOrderChats] = useState([]);
  const [currentUser, setCurrentUser] = useState(undefined);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [newMessages, setNewMessages] = useState({});
  const isAdmin = localStorage.getItem('userRole') === 'admin';

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const orderId = params.get('orderId');
    setOrderId(orderId);
  }, [location.search]);

  useEffect(() => {
    const getUnreadMessages = async () => {
      const res = await get('/api/messages/orders-unread');
      const data = await res.json();

      const unreadCounts = data.reduce((counts, message) => {
        if (
          (isAdmin && message.admin === null) ||
          (!isAdmin && message.client === null)
        ) {
          counts[message.chatId] = (counts[message.chatId] || 0) + 1;
        }
        return counts;
      }, {});

      setNewMessages(unreadCounts);
    };

    if (currentUser) {
      getUnreadMessages();
    }
  }, [currentUser, isAdmin]);

  useEffect(() => {
    if (socket.current) {
      socket.current.on('receive-message', (chat) => {
        if (
          (isAdmin && chat.admin === null) ||
          (!isAdmin && chat.client === null)
        ) {
          setNewMessages((prevMessages) => ({
            ...prevMessages,
            [chat.chatId]: (prevMessages[chat.chatId] || 0) + 1,
          }));
        }
      });

      return () => {
        if (socket.current) {
          socket.current.off('receive-message');
        }
      };
    }
  }, [currentUser, socket.current, isAdmin]);

  useEffect(() => {
    const userRole = JSON.parse(localStorage.getItem('userData')).role;
    const userId = getUserId();
    const user = JSON.parse(localStorage.getItem('userData'));
    setCurrentUser(user);
    if (userRole === 'user') {
      const getUserChats = async () => {
        const res = await get(`/api/messages/order-chats/${userId}`);
        const data = await res.json();
        setOrderChats(data);
      };
      getUserChats();
    } else {
      const getAllChats = async () => {
        const res = await get('/api/messages/all-order-chats');
        const data = await res.json();
        setOrderChats(data);
      };
      getAllChats();
    }
    const getAdmins = async () => {
      const res = await get('/api/user/admins');
      const data = await res.json();
      setAdmins(data);
    };
    getAdmins();
  }, []);

  useEffect(() => {
    if (orderId) {
      const orderChat = orderChats.find(
        (orderChat) => orderChat.orderId === parseInt(orderId),
      );
      if (orderChat) {
        setSelectedOrderChat(orderChat);
        openChat(orderChat);
        setNewMessages((prevMessages) => {
          const newMessages = { ...prevMessages };
          delete newMessages[orderChat.id];
          return newMessages;
        });
      }
    }
  }, [orderId, orderChats]);

  useEffect(() => {
    if (currentUser) {
      socket.current = io(`${process.env.REACT_APP_BACKEND_URL}/order-chat`);
      socket.current.emit('add-user', currentUser.id);
    }

    return () => {
      if (socket.current) {
        socket.current.disconnect();
      }
    };
  }, [currentUser]);

  useEffect(() => {
    if (socket.current) {
      socket.current.on('users', (users) => {
        setOnlineUsers(users);
      });

      socket.current.on('user-connected', (userId) => {
        setOnlineUsers((prevUsers) => [...prevUsers, userId]);
      });
    }
  }, [socket.current]);

  const openChat = async (orderChat) => {
    setSelectedOrderChat(orderChat);
    setOpen(true);
    const adminIds = admins.map((admin) => admin.id);

    const res = await post('/api/messages/orders-read', {
      client: orderChat.userId,
      chatId: orderChat.id,
      adminIds: !isAdmin ? adminIds : [],
    });

    if (res.ok) {
      setNewMessages((prevMessages) => {
        const newMessages = { ...prevMessages };
        delete newMessages[orderChat.id];
        return newMessages;
      });
    }
  };

  const isActive = (userId) => {
    if (isAdmin) {
      return onlineUsers.includes(userId);
    } else {
      const adminIds = admins.map((admin) => admin.id);
      return adminIds.some((adminId) => onlineUsers.includes(adminId));
    }
  };

  useEffect(() => {
    if (open && window.innerWidth < 800)
      document.documentElement.style.overflow = 'hidden';
    else document.documentElement.style.overflow = 'unset';
  });

  return (
    <div className="mt-3 flex w-full grid-cols-10 flex-col gap-5 md:mt-[20px] md:mt-[80px] lg:mt-6 lg:min-h-[85vh] lg:flex-row">
      <div className="w-full md:w-[45%]">
        <Card extra={'w-full md:min-h-[80vh] 3xl:min-h-[80vh] p-4'}>
          {/* header */}
          <div className="mb-3 flex w-full flex-col justify-between md:flex-row md:items-center">
            <h1 className="font-dm text-[24px] font-bold text-navy-700 dark:text-white">
              Your Chats
            </h1>
            {/* search */}
            <div className="mt-2 flex items-center md:mt-0">
              <div className="flex h-[42px] w-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white ">
                <p className="pl-3 pr-2 text-xl">
                  <FiSearch className="h-4 w-4 text-gray-400" />
                </p>
                <input
                  type="text"
                  placeholder="Search..."
                  className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
          </div>
          {/* messages */}
          <div className="mt-4 overflow-y-auto scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300 2xl:max-h-[450px] 3xl:max-h-[600px]">
            {orderChats &&
              orderChats
                .filter(
                  (orderChat) =>
                    orderChat.Order?.orderStatus !== 'CANCELLED' ||
                    orderChat.OrderMessages?.some((message) => !message.isRead),
                )
                .sort((a, b) => {
                  const aCreatedAt = new Date(a.createdAt);
                  const bCreatedAt = new Date(b.createdAt);

                  const aLatestMessageTime = a.OrderMessages
                    ? a.OrderMessages.reduce((latest, message) => {
                        const messageTime = new Date(message.updatedAt);
                        return messageTime > latest ? messageTime : latest;
                      }, aCreatedAt)
                    : aCreatedAt;

                  const bLatestMessageTime = b.OrderMessages
                    ? b.OrderMessages.reduce((latest, message) => {
                        const messageTime = new Date(message.updatedAt);
                        return messageTime > latest ? messageTime : latest;
                      }, bCreatedAt)
                    : bCreatedAt;

                  const aHasNewMessages = newMessages[a.id] !== undefined;
                  const bHasNewMessages = newMessages[b.id] !== undefined;

                  if (aHasNewMessages && !bHasNewMessages) {
                    return -1;
                  } else if (!aHasNewMessages && bHasNewMessages) {
                    return 1;
                  } else {
                    return bLatestMessageTime - aLatestMessageTime;
                  }
                })
                .filter((orderChat) =>
                  orderChat.orderId.toString().includes(searchTerm),
                )
                .map((orderChat, index) => (
                  <div onClick={() => openChat(orderChat)} key={orderChat.id}>
                    <Chat
                      photo={isAdmin ? orderChat.User.avatar : icon}
                      active={isActive(orderChat.userId)}
                      name={
                        isAdmin
                          ? `Order ${orderChat.orderDomain} - ${orderChat.User.firstName} ${orderChat.User.lastName}`
                          : `Order ${orderChat.orderDomain} - Karolis Butkus`
                      }
                      status={orderChat.Order?.articleStatus}
                      message={`Order created on ${moment(
                        orderChat.createdAt,
                      ).format('YYYY-MM-DD HH:mm')}`}
                      last={index === orderChat.length - 1}
                      newMessage={newMessages[orderChat.id]}
                    />
                  </div>
                ))}
          </div>
        </Card>
      </div>
      {open ? (
        <div className="w-full lg:w-[60%]">
          <SingleChat
            open={open}
            onClose={() => setOpen(false)}
            selectedOrderChat={selectedOrderChat}
            socket={socket}
            currentUser={currentUser}
            avatar={isAdmin ? selectedOrderChat.User.avatar : icon}
            isActive={isActive(selectedOrderChat.userId)}
            setNewMessages={setNewMessages}
          />
        </div>
      ) : (
        <div className="hidden w-full md:block lg:w-[64.5%]">
          <Card extra={'w-full h-[94%] p-4'}>
            <div className="flex h-full items-center justify-center">
              <h1 className="text-2xl font-medium text-gray-400 dark:text-white">
                Select a chat to start messaging
              </h1>
            </div>
          </Card>
        </div>
      )}
    </div>
  );
};

export default OrderChat;
