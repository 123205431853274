import React, { useState, useEffect, useContext } from 'react';
import Select from 'react-select';
import InputField from 'components/fields/InputField';
import { get, getUserId } from 'api';
import { ProjectContext } from 'contexts/ProjectContext';

const Competitor = ({
  newCompetitorDomain,
  setNewCompetitorDomain,
  handleNewCompetitor,
}) => {
  const { currentProject, changeProject } = useContext(ProjectContext);
  const [projects, setProjects] = useState([]);

  const handleNewCompetitorAdd = (value) => {
    setNewCompetitorDomain(value);
  };

  const handleInputBlur = () => {
    if (
      !newCompetitorDomain.startsWith('http://') &&
      !newCompetitorDomain.startsWith('https://')
    ) {
      setNewCompetitorDomain('https://' + newCompetitorDomain);
    }
  };

  const handleProjectChange = (selectedOption) => {
    const selectedProject = projects.find(
      (project) => project.id === parseInt(selectedOption.value),
    );
    changeProject(selectedProject);
  };

  useEffect(() => {
    const fetchData = async () => {
      const userId = getUserId();
      await get(`/api/project/user/${userId}`)
        .then((res) => res.json())
        .then((data) => {
          setProjects(data);
        });
    };
    fetchData();
  }, []);

  return (
    <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
      {/* Header */}
      <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
        Competitors
      </h4>
      <div className="mt-4">
        <p className="mb-2 ml-3 text-sm font-bold text-navy-700 dark:text-white">
          Select Project
        </p>
        <Select
          options={projects.map((item) => ({
            value: item.id,
            label: item.projectName,
          }))}
          value={{
            value: currentProject.id || '',
            label: currentProject.projectName || '',
          }}
          onChange={handleProjectChange}
          name="categories"
        />
      </div>
      <div className="mt-4 flex flex-col md:flex-row">
        <InputField
          extra="w-full md:w-3/4"
          label={'Competitor Domain name'}
          placeholder="example.com"
          id="domainName"
          name="domainName"
          onChange={(e) => handleNewCompetitorAdd(e.target.value)}
          onBlur={handleInputBlur}
          value={newCompetitorDomain}
          required
        />
        <div className="flex w-full items-end md:w-1/4">
          <button
            onClick={() => {
              handleNewCompetitor(currentProject.id, newCompetitorDomain);
            }}
            className="mt-4 h-[48px] w-full rounded-md bg-blue-500 px-4 py-2 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 md:ml-2 md:mt-0"
          >
            Add
          </button>
        </div>
      </div>
    </div>
  );
};

export default Competitor;
