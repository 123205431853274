import React, { useState } from 'react';
import Categories from './components/Categories';
import Countries from './components/Countries';
import Languages from './components/Languages';
import {
  categoriesHeaders,
  countriesHeaders,
  languagesHeaders,
  companiesHeaders,
  usersHeaders,
  notificationsHeaders,
} from './variables/columnsData';
import Companies from './components/Companies';
import Users from './components/Users';
import Notifications from './components/Notifications';

const AdminPanel = () => {
  const [activeComponent, setActiveComponent] = useState('categories');

  const handleButtonClick = (componentName) => {
    setActiveComponent(componentName);
  };

  return (
    <div>
      <div className="sm:flex sm:items-center sm:justify-between sm:space-x-3 md:flex md:space-x-5">
        <div className="mt-5 flex h-[300px] w-full flex-col items-center bg-blue-500 p-1.5 md:h-[50px] md:w-[66%] md:flex-row md:rounded-full">
          <button
            className={`linear flex h-full w-full cursor-pointer select-none items-center justify-center rounded-[20px] text-sm font-bold uppercase text-brand-500 transition duration-200 ${
              activeComponent === 'categories'
                ? 'bg-white text-brand-500'
                : 'bg-transparent text-white'
            } `}
            onClick={() => handleButtonClick('categories')}
          >
            Categories
          </button>
          <button
            className={`linear flex h-full w-full cursor-pointer select-none items-center justify-center rounded-[20px] text-sm font-bold uppercase text-brand-500 transition duration-200 ${
              activeComponent === 'countries'
                ? 'bg-white text-brand-500'
                : 'bg-transparent text-white'
            } `}
            onClick={() => handleButtonClick('countries')}
          >
            Countries
          </button>
          <button
            className={`linear flex h-full w-full cursor-pointer select-none items-center justify-center rounded-[20px] text-sm font-bold uppercase text-brand-500 transition duration-200 ${
              activeComponent === 'languages'
                ? 'bg-white text-brand-500'
                : 'bg-transparent text-white'
            } `}
            onClick={() => handleButtonClick('languages')}
          >
            Languages
          </button>
          <button
            className={`linear flex h-full w-full cursor-pointer select-none items-center justify-center rounded-[20px] text-sm font-bold uppercase text-brand-500 transition duration-200 ${
              activeComponent === 'companies'
                ? 'bg-white text-brand-500'
                : 'bg-transparent text-white'
            } `}
            onClick={() => handleButtonClick('companies')}
          >
            Companies
          </button>
          <button
            className={`linear flex h-full w-full cursor-pointer select-none items-center justify-center rounded-[20px] text-sm font-bold uppercase text-brand-500 transition duration-200 ${
              activeComponent === 'users'
                ? 'bg-white text-brand-500'
                : 'bg-transparent text-white'
            } `}
            onClick={() => handleButtonClick('users')}
          >
            Users
          </button>
          <button
            className={`linear flex h-full w-full cursor-pointer select-none items-center justify-center rounded-[20px] text-sm font-bold uppercase text-brand-500 transition duration-200 ${
              activeComponent === 'notifications'
                ? 'bg-white text-brand-500'
                : 'bg-transparent text-white'
            } `}
            onClick={() => handleButtonClick('notifications')}
          >
            Notifications
          </button>
        </div>
      </div>
      <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
        {activeComponent === 'categories' && (
          <Categories columnsData={categoriesHeaders} />
        )}
        {activeComponent === 'countries' && (
          <Countries columnsData={countriesHeaders} />
        )}
        {activeComponent === 'languages' && (
          <Languages columnsData={languagesHeaders} />
        )}
        {activeComponent === 'companies' && (
          <Companies columnsData={companiesHeaders} />
        )}
        {activeComponent === 'users' && <Users columnsData={usersHeaders} />}
        {activeComponent === 'notifications' && (
          <Notifications columnsData={notificationsHeaders} />
        )}
      </div>
    </div>
  );
};

export default AdminPanel;
