import React, { useState, useEffect } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalBody } from '@chakra-ui/modal';
import { toastSuccess, toastError } from 'utils/toasts';
import { useDisclosure } from '@chakra-ui/hooks';
import Card from 'components/card';
import { MdEdit } from 'react-icons/md';
import InputField from 'components/fields/InputField';
import { put } from 'api';

const EditBacklinkModal = ({
  itemId,
  articleDomain,
  articleURL,
  targetURL,
  targetAnchor,
  getBacklinks,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [newArticleDomain, setNewArticleDomain] = useState(articleDomain);
  const [newArticleUrl, setNewArticleUrl] = useState(articleURL);
  const [newTargetUrl, setNewTargetUrl] = useState(targetURL);
  const [newTargetAnchor, setNewTargetAnchor] = useState(targetAnchor);

  const handleNewArticleDomain = (e) => {
    setNewArticleDomain(e.target.value);
  };

  const handleNewArticleUrl = (e) => {
    setNewArticleUrl(e.target.value);
  };

  const handleNewTargetUrl = (e) => {
    setNewTargetUrl(e.target.value);
  };

  const handleNewTargetAnchor = (e) => {
    setNewTargetAnchor(e.target.value);
  };

  const handleUpdateBacklink = async () => {
    if (
      newArticleDomain === '' ||
      newArticleUrl === '' ||
      newTargetUrl === '' ||
      newTargetAnchor === ''
    ) {
      toastError('Please fill all fields');
      return;
    }
    const body = {
      articleDomain: newArticleDomain,
      articleURL: newArticleUrl,
      targetURL: newTargetUrl,
      targetAnchor: newTargetAnchor,
    };
    await put(`/api/backlinks/${itemId}`, body).then((res) => {
      if (res.status === 200) {
        toastSuccess('Backlink updated successfully');
        getBacklinks();
        onClose();
      } else {
        toastError('Error updating backlink');
      }
    });
  };

  return (
    <div>
      <button
        onClick={onOpen}
        className="text-xl font-bold text-navy-700 hover:text-brand-500 dark:text-white dark:hover:text-red-500"
        role="menuitem"
      >
        <MdEdit />
      </button>
      <Modal className="!z-[100]" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay className="bg-[#000] !opacity-30" />
        <ModalContent className="top-[10vh] !m-auto max-w-[90%] md:!max-w-[30%]">
          <ModalBody className="max-h-[90vh] overflow-auto">
            <Card extra={'w-full p-4 rounded-md'}>
              <div className="relative flex items-center justify-center border-b-2 border-gray-200 dark:border-gray-700">
                Edit Backlink
              </div>
              <div className="mt-4 flex flex-col items-center gap-4">
                <InputField
                  extra="w-full"
                  label="Article Domain"
                  id="articleDomain"
                  type="text"
                  placeholder="Article domain eg. domain.com"
                  state="normal"
                  disabled={false}
                  onChange={(e) => handleNewArticleDomain(e)}
                  value={newArticleDomain || ''}
                />
                <InputField
                  extra="w-full"
                  label="Article URL"
                  id="articleUrl"
                  type="text"
                  placeholder="Article url eg. domain.com/article"
                  state="normal"
                  disabled={false}
                  onChange={(e) => handleNewArticleUrl(e)}
                  value={newArticleUrl || ''}
                />
                <InputField
                  extra="w-full"
                  label="Target URL"
                  id="domainName"
                  type="text"
                  placeholder="Target domain eg. https://domain.com"
                  state="normal"
                  disabled={false}
                  onChange={(e) => handleNewTargetUrl(e)}
                  value={newTargetUrl || ''}
                />
                <InputField
                  extra="w-full"
                  label="Target Anchor"
                  id="anchor"
                  type="text"
                  placeholder="Target anchor eg. anchor text"
                  state="normal"
                  disabled={false}
                  onChange={(e) => handleNewTargetAnchor(e)}
                  value={newTargetAnchor || ''}
                />
              </div>
              <div className="mt-5 flex justify-between">
                <button
                  onClick={() => onClose()}
                  className="bg-transparent ml-0 rounded-md border border-gray-500 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
                >
                  Cancel
                </button>
                <button
                  onClick={() => handleUpdateBacklink()}
                  className="rounded-md bg-blue-500 px-4 py-2 text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                >
                  Update
                </button>
              </div>
            </Card>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default EditBacklinkModal;
