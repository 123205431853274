import { useState, useEffect, useContext } from 'react';
import { ToastContainer } from 'react-toastify';
import { toastSuccess, toastError } from 'utils/toasts';
import Competitor from './components/Competitor';
import Card from 'components/card';
import { post, get, remove } from 'api';
import { ProjectContext } from 'contexts/ProjectContext';
import { MdCancel } from 'react-icons/md';

const AddCompetitor = () => {
  const { currentProject } = useContext(ProjectContext);
  const [submitting, setSubmitting] = useState(false);
  const [newCompetitorDomain, setNewCompetitorDomain] = useState('');
  const [competitorDomains, setCompetitorDomains] = useState([]);
  const [competitors, setCompetitors] = useState([]);
  const [message, setMessage] = useState('');
  const [loaded, setLoaded] = useState(false);
  const [shouldSubmit, setShouldSubmit] = useState(false);

  const fetchData = async () => {
    await get(`/api/competitors/domains/${currentProject.id}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.length > 0) {
          setCompetitorDomains(data);
        } else {
          setCompetitorDomains([]);
        }
        setLoaded(true);
      });
  };

  useEffect(() => {
    fetchData();
  }, [currentProject]);

  const handleNewCompetitor = (projectId, competitorDomain) => {
    setCompetitors((prevCompetitors) => {
      if (
        prevCompetitors.some(
          (competitor) => competitor.competitorDomain === competitorDomain,
        ) ||
        competitorDomains.some(
          (competitor) => competitor.competitorDomain === competitorDomain,
        )
      ) {
        setMessage(`Competitor domain already exists - ${competitorDomain}`);
        return prevCompetitors;
      }

      const newCompetitor = {
        projectId: projectId,
        competitorDomain: competitorDomain,
      };

      setMessage('');
      setNewCompetitorDomain('');
      setShouldSubmit(true);
      return [...prevCompetitors, newCompetitor];
    });
  };

  const handleRemoveCompetitor = async (competitorId) => {
    await remove(`/api/competitors/domains/${competitorId}`).then((res) => {
      if (res.status === 200) {
        toastSuccess('Competitor removed successfully');
        fetchData();
      } else {
        toastError('Error removing competitor');
      }
    });
  };

  const handleSubmit = async () => {
    setSubmitting(true);
    await post('/api/competitors/domains', competitors).then((res) => {
      if (res.status === 200) {
        toastSuccess('Competitors added successfully');
        setCompetitors([]);
        setSubmitting(false);
        fetchData();
      } else {
        toastError('Error adding competitors');
        setSubmitting(false);
      }
    });
  };

  useEffect(() => {
    if (shouldSubmit) {
      handleSubmit();
      setShouldSubmit(false);
    }
  }, [shouldSubmit]);

  if (!loaded) return null;

  return (
    <div className="mt-3 h-full w-full">
      <ToastContainer
        position="top-center"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="h-[350px] w-full rounded-[20px] bg-gradient-to-br from-brand-400 to-brand-600 md:h-[390px]" />
      <div className="w-md:2/3 mx-auto h-full w-5/6 md:px-3  3xl:w-7/12">
        <div className="m-auto -mt-[280px] w-[50%] pb-10 md:-mt-[240px] md:px-[0px]"></div>
        <Card extra={'h-full mx-auto pb-3'}>
          <div className="flex w-full flex-col items-center gap-2">
            <Competitor
              newCompetitorDomain={newCompetitorDomain}
              setNewCompetitorDomain={setNewCompetitorDomain}
              handleNewCompetitor={handleNewCompetitor}
            />
            {message && (
              <p className="text-sm font-bold text-red-500">{message}</p>
            )}
            <ul className="flex w-full flex-col gap-2 rounded-[20px] px-3 pt-3 md:px-8">
              {competitorDomains && competitorDomains.length > 0 && (
                <div className="mt-2 flex flex-col gap-1">
                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                    Existing Competitors
                  </p>
                  {competitorDomains.map((item, index) => {
                    return (
                      <li
                        key={index}
                        className="flex items-center justify-start rounded-xl bg-gray-100 px-2 py-1 dark:bg-gray-800"
                      >
                        <button
                          onClick={() => handleRemoveCompetitor(item.id)}
                          className="mr-2 flex items-center justify-center"
                        >
                          <MdCancel className="text-lg text-red-500 hover:text-red-600" />
                        </button>
                        {item.competitorDomain}
                      </li>
                    );
                  })}
                </div>
              )}
            </ul>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default AddCompetitor;
