import React, { useState, useEffect } from 'react';
import Card from 'components/card';
import LineChart from 'components/charts/LineChart';
import { lineChartOptionsOverallRevenue } from 'variables/charts';

const Clicks = ({
  siteUrl,
  startDate,
  endDate,
  setIsClicksData,
  handleSelectProject,
}) => {
  const accessToken = window.localStorage.getItem('accessToken');
  const [totalClicks, setTotalClicks] = useState(0);
  const [clicksArray, setClicksArray] = useState([]);
  const [chartOptions, setChartOptions] = useState({});
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    fetch(
      `https://www.googleapis.com/webmasters/v3/sites/${encodeURIComponent(
        siteUrl,
      )}/searchAnalytics/query`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          startDate: startDate,
          endDate: endDate,
          dimensions: ['date'],
          rowLimit: 25000,
          type: 'Web',
          dataState: 'all',
        }),
      },
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          setIsClicksData(false);
          return;
        }
        if (!data.rows) {
          setIsClicksData(false);
          return;
        }
        let totalClicks = 0;
        const clicksArray = [];
        data.rows.forEach((row) => {
          totalClicks += row.clicks;
          clicksArray.push(Number(row.clicks));
        });
        setClicksArray(clicksArray);
        setTotalClicks(totalClicks);
        setIsClicksData(true);
      });
  }, [startDate, endDate, siteUrl, accessToken, setIsClicksData]);

  useEffect(() => {
    const chartData = [
      {
        name: 'Clicks',
        data: clicksArray,
        showData: true,
      },
    ];

    const newOptions = {
      ...lineChartOptionsOverallRevenue,
      chart: {
        toolbar: {
          show: false,
        },
        dropShadow: {
          enabled: true,
          top: 20,
          left: 0,
          blur: 12,
          opacity: 0.7,
          color: '#3B82F6',
        },
      },
      colors: ['#3B82F6'],
      xaxis: {
        labels: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
      },
      tooltip: {
        enabled: false,
      },
    };
    setChartData(chartData);
    setChartOptions(newOptions);
  }, [clicksArray]);

  return (
    <Card
      extra="flex flex-col w-full md:w-1/2 !p-4 bg-white rounded-[20px] shadow-none cursor-pointer"
      onClick={() => handleSelectProject()}
    >
      <div className="flex w-full flex-col">
        <p className="text-lg font-bold text-gray-600 dark:text-white">
          Total Clicks
        </p>
        <p className="text-3xl font-bold text-gray-800 dark:text-white">
          {totalClicks.toLocaleString()}
        </p>
      </div>
      <div className="flex h-full w-full flex-row sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden">
        <div className="h-full w-full">
          <LineChart
            chartData={chartData}
            chartOptions={chartOptions}
            key={`${JSON.stringify(chartData)}`}
          />
        </div>
      </div>
    </Card>
  );
};

export default Clicks;
