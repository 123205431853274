import React, { useState, useEffect, useRef } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import moment from 'moment';
import EmojiPicker from 'emoji-picker-react';
import Card from 'components/card';
import SingleMessage from './SingleMessage';
import { MdChevronLeft, MdOutlineTagFaces } from 'react-icons/md';
import { IoPaperPlane } from 'react-icons/io5';
import {
  renderThumbMessages,
  renderTrackMessages,
  renderViewMessages,
} from 'components/scrollbar/Scrollbar';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { post, get } from 'api';
import { FiSearch } from 'react-icons/fi';

function SingleChat(props) {
  const {
    open,
    onClose,
    selectedOrderChat,
    socket,
    currentUser,
    avatar,
    isActive,
    setNewMessages,
  } = props;
  const [messages, setMessages] = useState([]);
  const messageInput = useRef(null);
  const messagesEndRef = useRef(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [messageSearchTerm, setMessageSearchTerm] = useState('');
  const [typingUsers, setTypingUsers] = useState([]);
  const isAdmin = localStorage.getItem('userRole') === 'admin';

  const handleEmojiPickerhideShow = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  const fetchMessages = async () => {
    try {
      const response = await get(
        `/api/messages/order-messages/${selectedOrderChat.id}`,
      );
      const data = await response.json();
      setMessages(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchMessages();
  }, [selectedOrderChat]);

  useEffect(() => {
    socket.current.emit('join-room', {
      userId: currentUser.id,
      chatId: selectedOrderChat.id,
    });

    socket.current.on('user-typing', (userId) => {
      setTypingUsers((prevUsers) => [...prevUsers, userId]);
    });

    socket.current.on('user-stop-typing', (userId) => {
      setTypingUsers((prevUsers) => prevUsers.filter((id) => id !== userId));
    });

    return () => {
      socket.current.emit('leave-room', {
        userId: currentUser.id,
        chatId: selectedOrderChat.id,
      });

      socket.current.off('user-typing');
      socket.current.off('user-stop-typing');
    };
  }, [selectedOrderChat, socket]);

  useEffect(() => {
    if (socket.current) {
      socket.current.on('receive-message', (chat) => {
        if (chat.orderId === selectedOrderChat.orderId) {
          setMessages((prevMessages) => [...prevMessages, chat]);
          setNewMessages((prevMessages) => {
            const newMessages = { ...prevMessages };
            delete newMessages[chat.chatId];
            return newMessages;
          });
        } else {
          setNewMessages((prevMessages) => ({
            ...prevMessages,
            [chat.chatId]: (prevMessages[chat.chatId] || 0) + 1,
          }));
        }
      });

      return () => {
        if (socket.current) {
          socket.current.off('receive-message');
        }
      };
    }
  }, [currentUser.id, selectedOrderChat.orderId, socket, setNewMessages]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollToBottom();
    }
  }, [messages]);

  useEffect(() => {
    setMessages([]);
  }, [selectedOrderChat]);

  const saveMessage = async (messageData) => {
    try {
      await post('/api/messages/order-messages', messageData);
    } catch (error) {
      console.log(error);
    }
  };

  const sendMessage = (event) => {
    event.preventDefault();
    const message = messageInput.current.value;
    if (message.trim() !== '') {
      const time = moment.utc().format('YYYY-MM-DD HH:mm:ss');
      const messageData = {
        notificationType: 'orderMessage',
        client:
          currentUser.id === selectedOrderChat.userId ? currentUser.id : null,
        admin: isAdmin ? currentUser.id : null,
        message: message,
        time: time,
        orderId: selectedOrderChat.orderId,
        chatId: selectedOrderChat.id,
        projectId: selectedOrderChat.projectId,
        notificationUserId:
          currentUser.id === selectedOrderChat.userId
            ? 0
            : selectedOrderChat.userId,
      };
      socket.current.emit('send-message', messageData);
      saveMessage(messageData);
      messageInput.current.value = '';
    }
  };

  const handleEmojiClick = (emojiObject) => {
    let message = messageInput.current.value;
    message += emojiObject.emoji;
    messageInput.current.value = message;
  };

  const debounced = useDebouncedCallback(() => {
    socket.current.emit('stop-typing', {
      userId: currentUser.id,
      chatId: selectedOrderChat.id,
    });
  }, 1000);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      sendMessage(event);
    } else {
      socket.current.emit('typing', {
        userId: currentUser.id,
        chatId: selectedOrderChat.id,
      });
      debounced(event.key);
    }
  };

  return (
    <div
      className={`duration-175 !fixed left-0 top-[20px] !z-[50] h-[calc(100vh-40px)] w-[calc(100vw-40px)] flex-col !font-dm transition-all md:h-[calc(100vh-40px)] lg:!relative lg:left-[unset] lg:top-[unset] lg:!z-[unset] lg:my-0 lg:flex lg:h-full lg:w-[unset] ${
        open
          ? 'translate-x-[20px] lg:translate-x-[0px]'
          : '-translate-x-[120%] lg:translate-x-[0px]'
      }`}
    >
      <Card extra={'w-full h-full md:h-auto md:min-h-[80vh] p-4 !font-dm'}>
        {/* header */}
        <div className="mb-auto flex flex-col items-center justify-between border-b border-gray-200 pb-3 dark:!border-navy-700 md:flex-row">
          <div className="flex w-full items-start gap-3">
            <div className="flex items-center justify-center gap-2">
              <div onClick={onClose} className={'block lg:hidden'}>
                <MdChevronLeft className="h-8 w-8 text-gray-500 dark:text-white" />
              </div>
              <div className="relative h-[50px] !w-[50px] rounded-full">
                <img
                  src={avatar}
                  className="h-full w-full rounded-full"
                  alt=""
                />
                {isActive ? (
                  <div className="absolute bottom-0 right-0 h-4 w-4 rounded-full border-2 border-white bg-green-500"></div>
                ) : (
                  <div className="absolute bottom-0 right-0 h-4 w-4 rounded-full border-2 border-white bg-gray-500"></div>
                )}
              </div>
            </div>
            {isAdmin ? (
              <div>
                <h1 className="font-dm text-[20px] font-bold text-navy-700 dark:text-white">
                  {selectedOrderChat.User.firstName}{' '}
                  {selectedOrderChat.User.lastName}
                </h1>
                <div className="flex flex-col items-start">
                  <p className="text-base text-navy-700 dark:text-white">
                    Order id <strong>#{selectedOrderChat.orderId}</strong>
                  </p>
                  <p className="text-base text-navy-700 dark:text-white">
                    Ordered domain:{' '}
                    <strong>{selectedOrderChat.orderDomain}</strong>
                  </p>
                </div>
              </div>
            ) : (
              <div>
                <h1 className="font-dm text-[20px] font-bold text-navy-700 dark:text-white">
                  Karolis Butkus
                </h1>
                <div className="flex flex-col items-start">
                  <p className="text-base text-navy-700 dark:text-white">
                    Order id <strong>#{selectedOrderChat.orderId}</strong>
                  </p>
                  <p className="text-base text-navy-700 dark:text-white">
                    Ordered domain:{' '}
                    <strong>{selectedOrderChat.orderDomain}</strong>
                  </p>
                </div>
              </div>
            )}
          </div>
          {/* search */}
          <div className="mt-3 flex w-full items-center gap-2 md:w-1/4">
            <div className="flex h-[42px] w-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white ">
              <p className="pl-3 pr-2 text-xl">
                <FiSearch className="h-4 w-4 text-gray-400" />
              </p>
              <input
                type="text"
                placeholder="Search..."
                className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
                value={messageSearchTerm}
                onChange={(e) => setMessageSearchTerm(e.target.value)}
              />
            </div>
          </div>
        </div>
        {/* message content */}
        <div className="relative h-[calc(100%-70px)] pt-2">
          <div className="flex h-[calc(100%-74px)] w-full">
            <Scrollbars
              ref={messagesEndRef}
              renderTrackVertical={renderTrackMessages}
              renderThumbVertical={renderThumbMessages}
              renderView={renderViewMessages}
            >
              <div className="relative max-h-max overflow-hidden pb-[30px] lg:max-h-[unset] lg:overflow-visible">
                {messages.length > 0 &&
                  messages
                    .filter((msg) =>
                      msg.message
                        .toLowerCase()
                        .includes(messageSearchTerm.toLowerCase()),
                    )
                    .map((msg, index) => {
                      // const fromMe = msg.fromUserId === currentUser.id;
                      const fromMe = isAdmin ? msg.admin : msg.client;
                      return (
                        <div
                          className={fromMe ? 'flex flex-col items-end' : ''}
                          key={index}
                        >
                          <SingleMessage
                            message={msg.message}
                            time={msg.time}
                            extra={
                              fromMe
                                ? 'bg-brand-500 w-fit rounded-l-xl rounded-br-xl text-white'
                                : '!bg-lightPrimary w-fit rounded-r-xl rounded-bl-xl'
                            }
                            text={fromMe ? 'text-white' : 'text-navy-700'}
                            timecolor="text-gray-400 dark:text-white"
                          />
                        </div>
                      );
                    })}
              </div>
            </Scrollbars>
          </div>
          <div className="bottom-15 absolute left-0 w-full">
            {typingUsers.includes(selectedOrderChat.userId) ? (
              <p>Client is typing...</p>
            ) : typingUsers.length > 0 &&
              !typingUsers.includes(selectedOrderChat.userId) ? (
              <p>Karolis is typing...</p>
            ) : null}
          </div>
          <div className="mt-6 flex items-center gap-2">
            <div className=" flex h-[50px] w-full items-center rounded-full bg-lightPrimary pr-3 dark:bg-navy-700">
              <div className="text-lightSecondary pl-3 text-xl hover:cursor-pointer">
                <MdOutlineTagFaces onClick={handleEmojiPickerhideShow} />
                {showEmojiPicker && (
                  <div className="absolute bottom-[55px]">
                    <EmojiPicker onEmojiClick={handleEmojiClick} />
                  </div>
                )}
              </div>
              <input
                ref={messageInput}
                placeholder="Write your message...."
                className="h-full w-full rounded-full bg-lightPrimary px-2 text-sm text-navy-700 outline-none dark:bg-navy-700 dark:text-white"
                type="text"
                onKeyDown={handleKeyDown}
              />
            </div>
            {/* button */}
            <button
              onClick={sendMessage}
              className="linear flex items-center justify-center rounded-full bg-brand-500 p-3 text-base text-white transition duration-200 hover:cursor-pointer  hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            >
              <IoPaperPlane />
            </button>
          </div>
        </div>
      </Card>
    </div>
  );
}

export default SingleChat;
