import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Dropdown from 'components/dropdown';
import { FiAlignJustify } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import navbarimage from 'assets/img/layout/Navbar.png';
import { BsArrowBarUp, BsBank, BsCart } from 'react-icons/bs';
import {
  TbMessageCircleExclamation,
  TbHomeExclamation,
  TbBellExclamation,
} from 'react-icons/tb';
import { FiSearch } from 'react-icons/fi';
import Configurator from './Configurator';
import {
  IoMdNotificationsOutline,
  IoMdInformationCircleOutline,
} from 'react-icons/io';
import { RiMoonFill, RiSunFill } from 'react-icons/ri';
import { get, put, getUserId, getUserRole } from 'api';
import { CartContext } from 'contexts/CartContext';
import Spinner from 'components/loaders/Spinner';
import { post } from 'api';

const Navbar = (props) => {
  const navigate = useNavigate();
  const [currentBalance, setCurrentBalance] = useState(0);
  const [reservedBalance, setReservedBalance] = useState(0);
  const [userFirstName, setUserFirstName] = useState('');
  const [avatar, setAvatar] = useState('');
  const [notifications, setNotifications] = useState([]);
  const { cartItems } = useContext(CartContext);
  const {
    onOpenSidenav,
    brandText,
    mini,
    // setMini,
    // theme,
    // setTheme,
    hovered,
  } = props;
  const [darkmode, setDarkmode] = React.useState(
    document.body.classList.contains('dark'),
  );
  const currencySymbol = localStorage.getItem('currency');
  const isAdmin = getUserRole() === 'admin' ? true : false;
  const [balanceLoaded, setBalanceLoaded] = useState(false);

  const fetchNotifications = async () => {
    const userId = getUserId();
    const isUser = isAdmin ? 0 : 1;
    await get(`/api/notifications/all/${userId}/${isUser}`)
      .then((res) => res.json())
      .then((data) => {
        setNotifications(data);
      });
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const fetchCredits = async () => {
    const userId = getUserId();
    if (!userId) return;
    await get(`/api/user/${userId}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          return;
        }
        const credits = data.credits;
        const reservedCredits = data.reservedCredits;
        setCurrentBalance(credits);
        setReservedBalance(reservedCredits);
        setBalanceLoaded(true);
      });
  };

  useEffect(() => {
    fetchCredits();
  }, [cartItems]);

  useEffect(() => {
    const fetchProfile = async () => {
      const userId = getUserId();
      await get(`/api/user/${userId}`)
        .then((res) => res.json())
        .then((data) => {
          setUserFirstName(data.firstName);
          setAvatar(data.avatar);
        });
    };
    fetchProfile();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('expiresAt');
    localStorage.removeItem('idToken');
    localStorage.removeItem('userId');
    localStorage.removeItem('token');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('userRole');
    localStorage.removeItem('cartItems');
    localStorage.removeItem('userActivated');
    localStorage.removeItem('userData');
    localStorage.removeItem('currentProject');
    localStorage.removeItem('referralCode');
    navigate('/');
  };

  const handleNotificationClick = async (notification) => {
    if (notification.type === 'orderMessage') {
      navigate(`/admin/chat?orderId=${notification.orderId}`);
      const res = await post(`/api/notifications/single-read`, {
        orderId: notification.orderId,
        userId: getUserId(),
        type: notification.type,
        notificationId: notification.id,
      });
      if (res.status === 200) {
        fetchNotifications();
      }
    } else if (notification.type === 'orderStatus') {
      navigate(`/admin/orders/manager`);
      const res = await post(`/api/notifications/single-read`, {
        orderId: notification.orderId,
        userId: getUserId(),
        type: notification.type,
        notificationId: notification.id,
      });
      if (res.status === 200) {
        fetchNotifications();
      }
    } else if (notification.type === 'liveMessage') {
      navigate(`/admin/live-chat`);
      const res = await post(`/api/notifications/single-read`, {
        orderId: notification.orderId,
        userId: getUserId(),
        type: notification.type,
        notificationId: notification.id,
      });
      if (res.status === 200) {
        fetchNotifications();
      }
    } else if (notification.type === 'generalMessage') {
      const res = await post(`/api/notifications/single-read`, {
        userId: getUserId(),
        type: notification.type,
        notificationId: notification.id,
      });
      if (res.status === 200) {
        fetchNotifications();
      }
    }
  };

  const handleMarkAllRead = async () => {
    const userId = getUserId();
    const promises = notifications.map(async (notification) => {
      const res = await post(`/api/notifications/single-read`, {
        userId: userId,
        notificationId: notification.id,
      });

      if (res.status === 200) {
        return true;
      } else {
        return false;
      }
    });

    const results = await Promise.all(promises);

    if (results.every((result) => result)) {
      fetchNotifications();
    }
  };

  return (
    <nav
      className={`duration-175 linear fixed right-3 top-3 flex flex-row flex-wrap items-center justify-between rounded-xl bg-white/30 transition-all ${
        mini === false
          ? 'w-[calc(100vw_-_6%)] md:w-[calc(100vw_-_8%)] lg:w-[calc(100vw_-_6%)] xl:w-[calc(100vw_-_307px)] 2xl:w-[calc(100vw_-_322px)]'
          : mini === true && hovered === true
          ? 'w-[calc(100vw_-_6%)] md:w-[calc(100vw_-_8%)] lg:w-[calc(100vw_-_6%)] xl:w-[calc(100vw_-_307px)] 2xl:w-[calc(100vw_-_322px)]'
          : 'w-[calc(100vw_-_6%)] md:w-[calc(100vw_-_8%)] lg:w-[calc(100vw_-_6%)] xl:w-[calc(100vw_-_180px)] 2xl:w-[calc(100vw_-_195px)]'
      }  p-2 backdrop-blur-xl dark:bg-[#0b14374d] md:right-[30px] md:top-4 xl:top-[20px]`}
    >
      <div className="ml-[6px]">
        <div className="h-6 w-[224px] pt-1">
          <Link
            className="text-sm font-normal text-navy-700 hover:underline dark:text-white dark:hover:text-white"
            to="/admin/dashboards/projects/overview"
          >
            Pages
            <span className="mx-1 text-sm text-navy-700 hover:text-navy-700 dark:text-white">
              {' '}
              /{' '}
            </span>
          </Link>
          <Link
            className="text-sm font-normal capitalize text-navy-700 hover:underline dark:text-white dark:hover:text-white"
            to="#"
          >
            {brandText}
          </Link>
        </div>
        <p className="shrink text-[33px] capitalize text-navy-700 dark:text-white">
          <Link
            to="#"
            className="font-bold capitalize hover:text-navy-700 dark:hover:text-white"
          >
            {brandText}
          </Link>
        </p>
      </div>

      <div className="relative mt-[3px] flex h-[61px] w-[275px] flex-grow items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:w-[320px] md:flex-grow-0 md:gap-1 xl:w-[380px] xl:gap-2">
        <div
          className="relative"
          onClick={() => navigate('/admin/marketplace/cart')}
        >
          <span
            className={`h-5 w-5 cursor-pointer ${
              cartItems.length > 0 ? 'text-blue-500' : 'text-gray-400'
            }`}
          >
            <BsCart />
          </span>
          {cartItems.length > 0 && (
            <div className="absolute bottom-3 left-2 right-0 flex h-4 w-4 items-center justify-center rounded-full bg-red-500 text-xs text-white">
              {cartItems.length}
            </div>
          )}
        </div>
        <Link
          to={'/admin/account/credits'}
          className="flex h-full w-[55%] items-center rounded-full bg-lightPrimary text-navy-700 hover:cursor-pointer hover:bg-gray-200 hover:text-white dark:bg-navy-900 dark:text-white md:w-1/2"
        >
          <p className="pl-3 pr-2">
            <BsBank className="h-5 w-5 text-gray-400 dark:text-white" />
          </p>
          <div className="flex h-full w-full flex-col justify-center">
            <p className="text-md flex items-center font-bold text-gray-700 dark:text-white">
              {currencySymbol}{' '}
              {balanceLoaded ? (
                currentBalance
              ) : (
                <Spinner width={4} height={4} ml={2} />
              )}
            </p>
            {reservedBalance > 0 && (
              <p className="text-sm font-normal text-gray-600 dark:text-white">
                ({currencySymbol} {reservedBalance} reserved)
              </p>
            )}
          </div>
        </Link>

        <span
          className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden"
          onClick={onOpenSidenav}
        >
          <FiAlignJustify className="h-5 w-5" />
        </span>
        {/* start Notification */}
        <Dropdown
          button={
            <p className="cursor-pointer">
              <IoMdNotificationsOutline
                className="h-5 w-5 text-gray-600 dark:text-white"
                onClick={fetchNotifications}
              />
              {notifications.length > 0 && (
                <span className="absolute bottom-3 left-2 right-0 flex h-4 w-4 items-center justify-center rounded-full bg-red-500 text-xs text-white">
                  {notifications.length}
                </span>
              )}
            </p>
          }
          animation="origin-[65%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
          children={
            <div className="flex w-[360px] flex-col gap-3 rounded-[20px] bg-white p-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none sm:w-[460px]">
              {notifications.length === 0 ? (
                <div className="flex items-center justify-center text-center">
                  <p className="text-base font-bold text-navy-700 dark:text-white">
                    No new notifications
                  </p>
                </div>
              ) : (
                <div className="flex items-center justify-between">
                  <p className="text-base font-bold text-navy-700 dark:text-white">
                    Notification
                  </p>
                  <p
                    className="cursor-pointer text-sm font-bold text-navy-700 hover:opacity-80 dark:text-white"
                    onClick={handleMarkAllRead}
                  >
                    Mark all read
                  </p>
                </div>
              )}
              {notifications.length > 0 &&
                notifications.map((notification) => (
                  <button
                    key={notification.id}
                    className="flex w-full items-center rounded-lg bg-white p-1 hover:bg-gray-200 hover:text-gray-800 dark:bg-navy-700 dark:text-white"
                    onClick={() => handleNotificationClick(notification)}
                  >
                    <div className="flex h-full w-[85px] items-center justify-center rounded-xl bg-gradient-to-b from-brand-400 to-brand-500 py-4 text-2xl text-white">
                      {notification.type === 'orderMessage' ||
                      notification.type === 'liveMessage' ? (
                        <TbMessageCircleExclamation />
                      ) : notification.type === 'generalMessage' ? (
                        <TbBellExclamation />
                      ) : (
                        <TbHomeExclamation />
                      )}
                    </div>
                    <div className="ml-2 flex h-full w-full flex-col justify-center rounded-lg px-1 text-sm">
                      <p className="mb-1 text-left text-base font-bold text-gray-900 dark:text-white">
                        {notification.title}
                      </p>
                      <p className="font-base text-left text-xs text-gray-900 dark:text-white">
                        {notification.message}
                      </p>
                    </div>
                  </button>
                ))}
            </div>
          }
          classNames={'py-2 top-4 -left-[230px] md:-left-[440px] w-max'}
        />
        {/* <div
          className="cursor-pointer text-gray-600"
          onClick={() => {
            if (darkmode) {
              document.body.classList.remove('dark');
              setDarkmode(false);
            } else {
              document.body.classList.add('dark');
              setDarkmode(true);
            }
          }}
        >
          {darkmode ? (
            <RiSunFill className="h-4 w-4 text-gray-600 dark:text-white" />
          ) : (
            <RiMoonFill className="h-4 w-4 text-gray-600 dark:text-white" />
          )}
        </div>
        <Configurator
          mini={props.mini}
          setMini={props.setMini}
          theme={props.theme}
          setTheme={props.setTheme}
          darkmode={darkmode}
          setDarkmode={setDarkmode}
        />
        <div
          className="cursor-pointer text-gray-600"
          onClick={() => {
            if (darkmode) {
              document.body.classList.remove('dark');
              setDarkmode(false);
            } else {
              document.body.classList.add('dark');
              setDarkmode(true);
            }
          }}
        ></div> */}
        {/* Profile & Dropdown */}
        <Dropdown
          button={
            <img
              className="h-10 w-10 cursor-pointer rounded-full"
              src={avatar}
              alt="Elon Musk"
            />
          }
          children={
            <div className="flex h-max w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat pb-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
              <div className="ml-4 mt-3">
                <div className="flex items-center gap-2">
                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                    👋 Hey, {userFirstName}
                  </p>{' '}
                </div>
              </div>
              <div className="mt-3 h-px w-full bg-gray-200 dark:bg-white/20 " />

              <div className="ml-4 mt-3 flex flex-col">
                <Link
                  to="/admin/account/settings"
                  className="text-sm text-gray-800 dark:text-white hover:dark:text-white"
                >
                  Profile Settings
                </Link>
                <button
                  onClick={() => handleLogout()}
                  className="mt-3 text-left text-sm font-medium text-red-500 hover:text-red-500"
                >
                  Log Out
                </button>
              </div>
            </div>
          }
          classNames={'py-2 top-8 -left-[180px] w-max'}
        />
      </div>
    </nav>
  );
};

export default Navbar;
