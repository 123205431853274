import { useState, useEffect } from 'react';

export const useCartState = () => {
  const [cartItems, setCartItems] = useState([]);

  useEffect(() => {
    const storedCartItems = JSON.parse(localStorage.getItem('cartItems'));
    if (storedCartItems) {
      setCartItems(storedCartItems);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
  }, [cartItems]);

  const addToCart = (item, pageName) => {
    const index = cartItems.findIndex(
      (cartItem) =>
        cartItem.orderNo === item.orderNo &&
        cartItem.id === item.id &&
        cartItem.uniqueId.includes(pageName),
    );
    const id = item.orderNo ? item.orderNo : item.id;
    if (index < 0) {
      setCartItems([...cartItems, { ...item, uniqueId: `${id}-${pageName}` }]);
    }
  };

  const bulkAddToCart = (items, pageName) => {
    const newCartItems = [...cartItems];
    items.forEach((item) => {
      const index = cartItems.findIndex(
        (cartItem) =>
          (cartItem.orderNo === item.orderNo || cartItem.id === item.id) &&
          cartItem.uniqueId.includes(pageName),
      );
      const id = item.orderNo ? item.orderNo : item.id;
      if (index < 0) {
        newCartItems.push({
          ...item,
          uniqueId: `${id}-${pageName}`,
        });
      }
    });
    setCartItems(newCartItems);
  };

  const removeFromCart = (orderNo, pageName) => {
    const item = cartItems.find(
      (i) =>
        (i.orderNo === orderNo || i.id === orderNo) &&
        i.uniqueId.includes(pageName),
    );
    if (item) {
      setCartItems(cartItems.filter((i) => i.uniqueId !== item.uniqueId));
    }
  };

  const removeBulkFromCart = (data, pageName) => {
    setCartItems(
      cartItems.filter((cartItem) => {
        const index = data.findIndex(
          (item) =>
            (item.orderNo === cartItem.orderNo || item.id === cartItem.id) &&
            cartItem.uniqueId.includes(pageName),
        );
        return index < 0;
      }),
    );
  };

  const removeAllFromCart = () => {
    setCartItems([]);
  };

  const removeFromCartTable = (uniqueId) => {
    setCartItems(cartItems.filter((i) => i.uniqueId !== uniqueId));
  };

  const updateCartArticle = (id, articleData) => {
    const index = cartItems.findIndex((i) => i.uniqueId === id);
    if (index >= 0) {
      const newCartItems = [...cartItems];
      newCartItems[index] = {
        ...newCartItems[index],
        ...articleData,
      };
      setCartItems(newCartItems);
    }
  };

  return {
    cartItems,
    addToCart,
    bulkAddToCart,
    removeFromCart,
    removeBulkFromCart,
    removeAllFromCart,
    removeFromCartTable,
    updateCartArticle,
  };
};
