import {
  MdChat,
  MdDashboard,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
  MdPerson,
} from 'react-icons/md';
import { BsShop } from 'react-icons/bs';

// Admin Imports
import ProjectOverview from 'views/admin/dashboards/projects/overview';
import NewProject from 'views/admin/dashboards/projects/newProject';
import EditProject from 'views/admin/dashboards/projects/editProject';
import AddCompetitor from 'views/admin/dashboards/projects/competitors';
import Backlinks from 'views/admin/dashboards/backlinks';
import UrlsOverview from 'views/admin/dashboards/urls/overview';
import NewUrl from 'views/admin/dashboards/urls/newUrl';
import EditUrl from 'views/admin/dashboards/urls/editUrl';
import SearchConsoleDashboard from 'views/admin/dashboards/searchConsole';
import AdminBacklinks from 'views/admin/dashboards/admin';

// Marketplace Imports
import Marketplace from 'views/admin/marketplace/overview';
import Cart from 'views/admin/marketplace/cart';
import MarketplaceAdmin from 'views/admin/marketplace/admin';

// Manage orders Imports
import OrderManager from 'views/admin/orders/manager';
import OrderAdmin from 'views/admin/orders/admin';

// Account Imports
import AccountBilling from 'views/admin/account/billing';
import AccountCredits from 'views/admin/account/credits';
import AccountSettings from 'views/admin/account/settings';
import AccountNotifications from 'views/admin/account/notifications';
import AccountInvoice from 'views/admin/account/invoice';
import AdminPanel from 'views/admin/account/admin';
import AccountReferrals from 'views/admin/account/referrals';

// Main Imports
import AccountBillingMain from 'views/admin/main/account/billing';
import AccountApplicationsMain from 'views/admin/main/account/application';
import AccountInvoiceMain from 'views/admin/main/account/invoice';
import AccountSettingsMain from 'views/admin/main/account/settings';
import AccountAllCourses from 'views/admin/main/account/courses';
import AccountCoursePage from 'views/admin/main/account/coursePage';

import UserNew from 'views/admin/main/users/newUser';
import UsersOverview from 'views/admin/main/users/overview';
import UsersReports from 'views/admin/main/users/reports';

import ProfileSettings from 'views/admin/main/profile/settings';
import ProfileOverview from 'views/admin/main/profile/overview';
import ProfileNewsfeed from 'views/admin/main/profile/newsfeed';

import ApplicationsKanban from 'views/admin/main/applications/kanban';
import ApplicationsDataTables from 'views/admin/main/applications/dataTables';
import ApplicationsCalendar from 'views/admin/main/applications/Calendar';

import EcommerceNewProduct from 'views/admin/main/ecommerce/newProduct';
import EcommerceProductSettings from 'views/admin/main/ecommerce/settingsProduct';
import EcommerceProductPage from 'views/admin/main/ecommerce/productPage';
import EcommerceOrderList from 'views/admin/main/ecommerce/orderList';
import EcommerceOrderDetails from 'views/admin/main/ecommerce/orderDetails';

// Others
import OthersNotifications from 'views/admin/main/others/notifications';
import OthersPricing from 'views/admin/main/others/pricing';
import OthersError from 'views/admin/main/others/404';
import Buttons from 'views/admin/main/others/buttons';

// Auth Imports
import ForgotPassword from 'views/auth/forgotPassword/ForgotPassword.jsx';
import LockCentered from 'views/auth/lock/LockCenter.jsx';
import SignIn from 'views/auth/signIn/SignIn.jsx';
import SignUp from 'views/auth/signUp/SignUp.jsx';
import ResetPassword from 'views/auth/resetPassword/ResetPassword.jsx';
import GoogleVerification from 'views/auth/google';

// Messages
import Messages from 'views/admin/messages';
import OrderChat from 'views/admin/order-messages';
import Reports from 'views/admin/dashboards/reports';

const routes = [
  {
    name: 'Dashboards',
    path: '/dashboards',
    icon: <MdHome className="text-inherit h-5 w-5" />,
    collapse: true,
    items: [
      {
        name: 'Projects',
        path: '/dashboards/projects',
        collapse: true,
        items: [
          {
            name: 'Overview',
            layout: '/admin',
            path: '/dashboards/projects/overview',
            exact: false,
            component: <ProjectOverview />,
          },
          {
            name: 'New Project',
            layout: '/admin',
            path: '/dashboards/projects/newProject',
            exact: false,
            component: <NewProject />,
          },
          {
            name: 'Edit Project',
            layout: '/admin',
            path: '/dashboards/projects/editProject',
            exact: false,
            component: <EditProject />,
          },
          {
            name: 'Add Competitors',
            layout: '/admin',
            path: '/dashboards/projects/competitors',
            exact: false,
            component: <AddCompetitor />,
          },
        ],
      },
      // {
      //   name: 'URLs',
      //   path: '/dashboards/urls',
      //   collapse: true,
      //   items: [
      //     {
      //       name: 'Overview',
      //       layout: '/admin',
      //       path: '/dashboards/urls/overview',
      //       exact: false,
      //       component: <UrlsOverview />,
      //     },
      //     {
      //       name: 'New URL',
      //       layout: '/admin',
      //       path: '/dashboards/urls/newUrl',
      //       exact: false,
      //       component: <NewUrl />,
      //     },
      //     {
      //       name: 'Edit URL',
      //       layout: '/admin',
      //       path: '/dashboards/urls/editUrl/:id',
      //       exact: false,
      //       hide: true,
      //       component: <EditUrl />,
      //     },
      //   ],
      // },
      {
        name: 'Search Console',
        path: '/dashboards/searchConsole',
        layout: '/admin',
        component: <SearchConsoleDashboard />,
        userActivated: true,
      },
      {
        name: 'Link Monitoring',
        layout: '/admin',
        path: '/dashboards/backlinks',
        component: <Backlinks />,
        userActivated: true,
      },
      {
        name: 'Reports',
        layout: '/admin',
        path: '/dashboards/reports',
        exact: false,
        component: <Reports />,
      },
      {
        name: 'Admin Links',
        layout: '/admin',
        path: '/dashboards/admin',
        component: <AdminBacklinks />,
        userActivated: true,
        adminView: true,
      },
    ],
  },
  // --- Marketplace ---
  {
    name: 'Marketplace',
    path: '/marketplace',
    icon: <MdOutlineShoppingCart className="text-inherit h-5 w-5" />,
    collapse: true,
    userActivated: true,
    items: [
      {
        name: 'Overview',
        layout: '/admin',
        path: '/marketplace/overview',
        component: <Marketplace />,
        secondary: true,
        userActivated: true,
      },
      {
        name: 'Cart',
        layout: '/admin',
        path: '/marketplace/cart',
        component: <Cart />,
        secondary: true,
        userActivated: true,
      },
      {
        name: 'Marketplace Admin',
        layout: '/admin',
        path: '/marketplace/admin',
        component: <MarketplaceAdmin />,
        secondary: true,
        adminView: true,
        userActivated: true,
      },
    ],
  },
  // --- Manage orders ---
  {
    name: 'Manage orders',
    path: '/orders',
    icon: <BsShop className="text-inherit h-5 w-5" />,
    collapse: true,
    userActivated: true,
    items: [
      {
        name: 'Order Manager',
        layout: '/admin',
        path: '/orders/manager',
        component: <OrderManager />,
        secondary: true,
        userActivated: true,
      },
      {
        name: 'Order Admin',
        layout: '/admin',
        path: '/orders/admin',
        component: <OrderAdmin />,
        secondary: true,
        adminView: true,
        userActivated: true,
      },
    ],
  },
  {
    name: 'Account',
    path: '/account',
    icon: <MdPerson className="text-inherit h-5 w-5" />,
    collapse: true,
    items: [
      {
        name: 'Profile Settings',
        layout: '/admin',
        path: '/account/settings',
        exact: false,
        component: <AccountSettings />,
      },
      {
        name: 'Billing Information',
        layout: '/admin',
        path: '/account/billing',
        exact: false,
        component: <AccountBilling />,
      },
      {
        name: 'Credits',
        layout: '/admin',
        path: '/account/credits',
        exact: false,
        component: <AccountCredits />,
        userActivated: true,
      },
      {
        name: 'Invoices',
        layout: '/admin',
        path: '/account/invoice',
        exact: false,
        component: <AccountInvoice />,
      },
      {
        name: 'Referrals',
        layout: '/admin',
        path: '/account/referrals',
        exact: false,
        component: <AccountReferrals />,
      },
      {
        name: 'Email Notifications',
        layout: '/admin',
        path: '/account/notifications',
        exact: false,
        component: <AccountNotifications />,
      },
      {
        name: 'Admin',
        layout: '/admin',
        path: '/account/admin',
        exact: false,
        component: <AdminPanel />,
        adminView: true,
      },
    ],
  },
  {
    name: 'Messages',
    path: '/messages',
    icon: <MdChat className="text-inherit h-5 w-5" />,
    collapse: true,
    items: [
      {
        name: 'Live Chat',
        layout: '/admin',
        path: '/live-chat',
        exact: true,
        component: <Messages />,
      },
      {
        name: 'Orders Chat',
        layout: '/admin',
        path: '/chat',
        exact: true,
        component: <OrderChat />,
      },
    ],
  },
  // --- Main pages ---
  // {
  //   name: 'Main Pages',
  //   path: '/main',
  //   icon: <MdDashboard className="text-inherit h-5 w-5" />,
  //   collapse: true,
  //   items: [
  //     {
  //       name: 'Account',
  //       path: '/main/account',
  //       collapse: true,
  //       items: [
  //         {
  //           name: 'Billing',
  //           layout: '/admin',
  //           path: '/main/account/billing',
  //           exact: false,
  //           component: <AccountBillingMain />,
  //         },
  //         {
  //           name: 'Application',
  //           layout: '/admin',
  //           path: '/main/account/application',
  //           exact: false,
  //           component: <AccountApplicationsMain />,
  //         },
  //         {
  //           name: 'Invoice',
  //           layout: '/admin',
  //           path: '/main/account/invoice',
  //           exact: false,
  //           component: <AccountInvoiceMain />,
  //         },
  //         {
  //           name: 'Settings',
  //           layout: '/admin',
  //           path: '/main/account/settings',
  //           exact: false,
  //           component: <AccountSettingsMain />,
  //         },
  //         {
  //           name: 'All Courses',
  //           layout: '/admin',
  //           path: '/main/account/all-courses',
  //           exact: false,
  //           component: <AccountAllCourses />,
  //         },
  //         {
  //           name: 'Course Page',
  //           layout: '/admin',
  //           path: '/main/account/course-page',
  //           exact: false,
  //           component: <AccountCoursePage />,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Ecommerce',
  //       path: '/ecommerce',
  //       collapse: true,
  //       items: [
  //         {
  //           name: 'New Product',
  //           layout: '/admin',
  //           path: '/main/ecommerce/new-prodcut',
  //           exact: false,
  //           component: <EcommerceNewProduct />,
  //         },
  //         {
  //           name: 'Product Settings',
  //           layout: '/admin',
  //           path: '/main/ecommerce/settings',
  //           exact: false,
  //           component: <EcommerceProductSettings />,
  //         },
  //         {
  //           name: 'Product Page',
  //           layout: '/admin',
  //           path: '/main/ecommerce/page-example',
  //           exact: false,
  //           component: <EcommerceProductPage />,
  //         },
  //         {
  //           name: 'Order List',
  //           layout: '/admin',
  //           path: '/main/ecommerce/order-list',
  //           exact: false,
  //           component: <EcommerceOrderList />,
  //         },
  //         {
  //           name: 'Order Details',
  //           layout: '/admin',
  //           path: '/main/ecommerce/order-details',
  //           exact: false,
  //           component: <EcommerceOrderDetails />,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Users',
  //       path: '/main/users',
  //       collapse: true,
  //       items: [
  //         {
  //           name: 'New User',
  //           layout: '/admin',
  //           path: '/main/users/new-user',
  //           exact: false,
  //           component: <UserNew />,
  //         },
  //         {
  //           name: 'Users Overview',
  //           layout: '/admin',
  //           path: '/main/users/users-overview',
  //           exact: false,
  //           component: <UsersOverview />,
  //         },
  //         {
  //           name: 'Users Reports',
  //           layout: '/admin',
  //           path: '/main/users/users-reports',
  //           exact: false,
  //           component: <UsersReports />,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Applications',
  //       path: '/main/applications',
  //       collapse: true,
  //       items: [
  //         {
  //           name: 'Kanban',
  //           layout: '/admin',
  //           path: '/main/applications/kanban',
  //           exact: false,
  //           component: <ApplicationsKanban />,
  //         },
  //         {
  //           name: 'Data Tables',
  //           layout: '/admin',
  //           path: '/main/applications/data-tables',
  //           exact: false,
  //           component: <ApplicationsDataTables />,
  //         },
  //         {
  //           name: 'Calendar',
  //           layout: '/admin',
  //           path: '/main/applications/calendar',
  //           exact: false,
  //           component: <ApplicationsCalendar />,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Profile',
  //       path: '/main/profile',
  //       collapse: true,
  //       items: [
  //         {
  //           name: 'Profile Overview',
  //           layout: '/admin',
  //           path: '/main/profile/overview',
  //           exact: false,
  //           component: <ProfileOverview />,
  //         },
  //         {
  //           name: 'Profile Settings',
  //           layout: '/admin',
  //           path: '/main/profile/settings',
  //           exact: false,
  //           component: <ProfileSettings />,
  //         },
  //         {
  //           name: 'News Feed',
  //           layout: '/admin',
  //           path: '/main/profile/newsfeed',
  //           exact: false,
  //           component: <ProfileNewsfeed />,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Others',
  //       path: '/main/others',
  //       collapse: true,
  //       items: [
  //         {
  //           name: 'Notifications',
  //           layout: '/admin',
  //           path: '/main/others/notifications',
  //           exact: false,
  //           component: <OthersNotifications />,
  //         },
  //         {
  //           name: 'Pricing',
  //           layout: '/auth',
  //           path: '/main/others/pricing',
  //           exact: false,
  //           component: <OthersPricing />,
  //         },
  //         {
  //           name: '404',
  //           layout: '/admin',
  //           path: '/main/others/404',
  //           exact: false,
  //           component: <OthersError />,
  //         },
  //         {
  //           name: 'Buttons',
  //           layout: '/admin',
  //           path: '/main/others/buttons',
  //           exact: false,
  //           component: <Buttons />,
  //         },
  //         {
  //           name: 'Messages',
  //           layout: '/admin',
  //           path: '/main/others/messages',
  //           exact: false,
  //           component: <Messages />,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // --- Authentication ---
  {
    name: 'Authentication',
    hide: true,
    path: '/',
    icon: <MdLock className="text-inherit h-5 w-5" />,
    collapse: true,
    items: [
      // --- Sign In ---
      {
        name: 'Log In',
        layout: '/auth',
        path: '/:referral?',
        component: <SignIn />,
      },
      {
        name: 'Google Log In',
        layout: '/auth',
        path: '/google',
        component: <GoogleVerification />,
      },
      {
        name: 'Sign Up',
        layout: '/auth',
        path: '/sign-up',
        component: <SignUp />,
      },
      {
        name: 'Reset Password',
        layout: '/auth',
        path: '/reset-password',
        component: <ResetPassword />,
      },
      {
        name: 'Forgot Password',
        layout: '/auth',
        path: '/forgot-password',
        component: <ForgotPassword />,
      },
      // {
      //   name: 'Centered',
      //   layout: '/auth',
      //   path: '/lock',
      //   component: <LockCentered />,
      // },
    ],
  },
];
export default routes;
