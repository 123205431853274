import React from 'react';
import OrderTable from './components/OrderTable';

const OrderAdmin = () => {
  return (
    <div>
      <OrderTable />
    </div>
  );
};

export default OrderAdmin;
