import React, { useState } from 'react';
import ProjectsTable from './components/ProjectsTable';
import SuggestionsTable from './components/SuggestionsTable';
import {
  projectsHeaders,
  suggestionsHeaders,
  marketplaceHeaders,
} from './variables/columnsData';
import MarketplaceTable from './components/MarketplaceTable';

const MarketplaceAdmin = () => {
  const [activeComponent, setActiveComponent] = useState('projects');

  const handleButtonClick = (componentName) => {
    setActiveComponent(componentName);
  };

  return (
    <div>
      <div className="sm:flex sm:items-center sm:justify-between sm:space-x-3 md:flex md:space-x-5">
        <div className="mt-5 flex h-[50px] w-full items-center rounded-full bg-blue-500 p-1.5 md:w-[520px]">
          <button
            className={`linear flex h-full w-full cursor-pointer select-none items-center justify-center rounded-[20px] text-sm font-bold uppercase text-brand-500 transition duration-200 ${
              activeComponent === 'projects'
                ? 'bg-white text-brand-500'
                : 'bg-transparent text-white'
            } `}
            onClick={() => handleButtonClick('projects')}
          >
            Projects
          </button>
          <button
            className={`linear flex h-full w-full cursor-pointer select-none items-center justify-center rounded-[20px] text-sm font-bold uppercase text-brand-500 transition duration-200 ${
              activeComponent === 'suggestions'
                ? 'bg-white text-brand-500'
                : 'bg-transparent text-white'
            } `}
            onClick={() => handleButtonClick('suggestions')}
          >
            Suggestions
          </button>
          <button
            className={`linear flex h-full w-full cursor-pointer select-none items-center justify-center rounded-[20px] text-sm font-bold uppercase text-brand-500 transition duration-200 ${
              activeComponent === 'marketplace'
                ? 'bg-white text-brand-500'
                : 'bg-transparent text-white'
            } `}
            onClick={() => handleButtonClick('marketplace')}
          >
            Marketplace
          </button>
        </div>
      </div>
      <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
        {activeComponent === 'projects' && (
          <ProjectsTable columnsData={projectsHeaders} />
        )}
        {activeComponent === 'suggestions' && (
          <SuggestionsTable columnsData={suggestionsHeaders} />
        )}
        {activeComponent === 'marketplace' && (
          <MarketplaceTable columnsData={marketplaceHeaders} />
        )}
      </div>
    </div>
  );
};

export default MarketplaceAdmin;
